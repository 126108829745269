import {
  Box,
  Button,
  Grid,
  Modal,
  ScrollBox,
  Separator,
  useTheme,
} from '@candisio/design-system';
import { Stepper } from 'components/Stepper/Stepper';
import { motion } from 'motion/react';
import { ReactNode } from 'react';

const transition = {
  duration: 0.3,
  ease: 'easeInOut',
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const MotionGrid = motion(Grid);

interface CreationModalMultiStepProps {
  /** Fixed header */
  title?: ReactNode;
  /** Content to be placed in the left column of the modal */
  leftSide: ReactNode;
  /** Contento that goes under the stepper component */
  children: ReactNode;
  /** Array of steps to be shown in stepper component*/
  steps: string[];
  /** Indicates which steps we are currently in the modal*/
  currentStep: number;
  /** callback invoked when closing the modal */
  onClose: () => void;
  /** Indicates if the modal is open or not */
  isOpen: boolean;
}

export const CREATION_MODAL_MIN_WIDTH = '68.75rem';
export const CREATION_MODAL_MIN_HEIGHT = '44rem';

export const CreationModalMultiStep = ({
  title,
  children,
  leftSide,
  currentStep,
  steps,
  isOpen,
  onClose,
}: CreationModalMultiStepProps) => {
  const { colors } = useTheme();

  return (
    <Modal
      padding="space2"
      minWidth={CREATION_MODAL_MIN_WIDTH}
      minHeight={CREATION_MODAL_MIN_HEIGHT}
      isOpen={isOpen}
      onClose={onClose}
      background={`linear-gradient(to right, ${colors.gray200} 0%, ${colors.gray200} 30%, ${colors.gray50} 30%, ${colors.gray50} 100%)`}
    >
      <Grid height="100%" templateRows="auto 1fr">
        <Grid
          templateColumns="1fr auto"
          padding="space16"
          paddingBottom={0}
          zIndex={1}
        >
          {title}
          <Button
            icon="close"
            justifySelf="end"
            variant="tertiary"
            onClick={onClose}
          />
        </Grid>
        <Grid templateColumns="3fr 7fr" height="100%">
          <Grid padding="space16">{leftSide}</Grid>
          <MotionGrid
            key={currentStep}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={transition}
            templateRows="auto 1fr"
            height="100%"
          >
            <Box>
              <Box padding="space16">
                <Stepper steps={steps} currentStep={currentStep} />
              </Box>
              <Separator color="gray250" />
            </Box>
            <ScrollBox>
              <Box padding="space16" height="100%">
                {children}
              </Box>
            </ScrollBox>
          </MotionGrid>
        </Grid>
      </Grid>
    </Modal>
  );
};

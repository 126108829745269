import {
  Flex,
  Grid,
  Item,
  Link,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { ErrorIcon, WarningIcon } from 'components/Icons/DefaultIcons';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { nocontactData } from 'components/Lottie/Lottie';
import {
  EmailInboxMessageAttachment,
  EmailInboxMessageAttachmentWithDocument,
  EmailInboxMessageAttachmentWithError,
} from 'generated-types/graphql.types';
import { DocumentType } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { isEmailAttachmentEInvoice } from 'views/Settings/MailSync/utils/utils';
import { AttachmentErrorTooltipContent } from './AttachmentErrorTooltipContent';

const AttachmentWithError = ({
  record,
}: {
  record: EmailInboxMessageAttachmentWithError;
}) => {
  const [t] = useTranslation();

  const renderErrorMessage = () => {
    switch (record.error.__typename) {
      case 'MaxAttachmentSizeExceeded':
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.maxSizeExceeded'
        );
      case 'AttachmentDocumentRemovedError':
        const { removedAt, removedBy } = record.error;
        if (removedAt && removedBy) {
          const removedByFormatted = DateFormatters.compact(
            new Date(removedAt)
          );
          return t(
            'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemovedWithInfo',
            { removedAt: removedByFormatted, removedBy }
          );
        }
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemoved'
        );
      case 'AttachmentDocumentNotFoundError':
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.documentNotFound'
        );
      case 'EmailMessageVirusDetected':
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.virusDetected'
        );
      case 'UnprocessableAttachment':
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.unprocessable'
        );
      case 'UnsupportedAttachmentType':
        return t(
          'settings.mailSync.details.tabs.metadata.attachments.errors.unsupported'
        );
      default:
        return null;
    }
  };

  return <Text>{renderErrorMessage()}</Text>;
};

const isErrorRecord = (
  record: EmailInboxMessageAttachment
): record is EmailInboxMessageAttachmentWithError => {
  return record.__typename === 'EmailInboxMessageAttachmentWithError';
};

const attachmentLink = (
  record: EmailInboxMessageAttachmentWithDocument,
  organizationSlug: string | null
) => {
  if (record.documentId && record.documentType === DocumentType.Invoice) {
    return `/${organizationSlug}${Routes.ARCHIVE}/${record.documentId}`;
  }
  if (record.globalDocumentId && record.documentType !== DocumentType.Invoice) {
    return `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${record.globalDocumentId}`;
  }
};

const FileName = ({ record }: { record: EmailInboxMessageAttachment }) => {
  const [t] = useTranslation();

  if (
    isErrorRecord(record) &&
    record.error.__typename !== 'UnprocessableAttachment' &&
    record.error.__typename !== 'MaxAttachmentSizeExceeded'
  ) {
    return (
      <Link fontSize="basic" disabled>
        {record.fileName ? (
          <TruncatedText>{record.fileName}</TruncatedText>
        ) : (
          t(
            'settings.mailSync.details.tabs.metadata.attachments.unknownFileName'
          )
        )}
      </Link>
    );
  }

  const href = isErrorRecord(record) ? record.fileUrl : record.url;
  const name = isErrorRecord(record)
    ? record.fileName
    : record.documentFileName;

  return (
    <Link fontSize="basic" download href={href ?? undefined}>
      {!isNil(name) && <TruncatedText>{name}</TruncatedText>}
    </Link>
  );
};

const RenderLink = ({ record }: { record: EmailInboxMessageAttachment }) => {
  const organizationSlug = useOrganizationId();
  const [t] = useTranslation();

  if (isErrorRecord(record)) {
    return (
      <Grid
        whiteSpace="normal"
        autoFlow="column"
        placeContent="start"
        alignItems="center"
        gap="space8"
        fontSize="small"
        lineHeight="0.8125rem"
        color={
          record.error.__typename === 'UnsupportedAttachmentType'
            ? 'yellow500'
            : 'red500'
        }
      >
        {record.error.__typename === 'UnsupportedAttachmentType' ? (
          <WarningIcon top="space2" size="space20" minWidth="space12" />
        ) : (
          <ErrorIcon top="space2" size="space20" minWidth="space12" />
        )}
        {record.error.__typename === 'AttachmentDocumentRemovedError' ? (
          <TruncatedText>
            {t(
              'settings.mailSync.details.tabs.metadata.attachments.errors.documentRemoved'
            )}
          </TruncatedText>
        ) : (
          <AttachmentWithError record={record} />
        )}

        <InfoIcon
          size="space20"
          closeDelay={500}
          message={
            record.error.__typename === 'AttachmentDocumentRemovedError' ? (
              <Grid>
                <AttachmentWithError record={record} />
                <Link
                  external
                  href={t(
                    'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentRemoved.helpCenterUrl'
                  )}
                >
                  {t(
                    'settings.mailSync.details.tabs.metadata.attachments.errorInfo.moreInfo'
                  )}
                </Link>
              </Grid>
            ) : (
              <AttachmentErrorTooltipContent err={record.error} />
            )
          }
        />
      </Grid>
    );
  }

  return (
    <Link
      external
      fontSize="basic"
      href={attachmentLink(record, organizationSlug)}
    >
      {t('settings.mailSync.details.tabs.metadata.attachments.documentLink')}
    </Link>
  );
};

interface AttachmentsListProps {
  attachments: EmailInboxMessageAttachment[];
  isLoading: boolean;
}

export const AttachmentsList = ({
  attachments,
  isLoading,
}: AttachmentsListProps) => (
  <Grid height="20rem">
    <FilterableList
      children={attachments.map(
        (attachment: EmailInboxMessageAttachment, index: number) => {
          return (
            <Item key={index}>
              <Grid
                gap="space32"
                paddingY="space8"
                paddingRight="space16"
                borderBottom="1px solid gray200"
                templateColumns="1fr auto"
              >
                <Flex gap="space8">
                  <FileName record={attachment} />
                  <EInvoiceInfoIcon
                    isEInvoice={isEmailAttachmentEInvoice(attachments)}
                  />
                </Flex>
                <RenderLink record={attachment} />
              </Grid>
            </Item>
          );
        }
      )}
      emptyDataState={
        <EmptyDataState
          lottieAnimation={nocontactData}
          translation="settings.mailSync.details.tabs.metadata.attachments.noData"
        />
      }
      isLoading={isLoading}
    />
  </Grid>
);

import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useDeleteExpense } from 'views/Reimbursement/hooks/useDeleteExpense';
import { useExtractGeneralInvoiceData } from 'views/Reimbursement/hooks/useExtractGeneralInvoiceData';
import { useExtractHospitalityInvoiceData } from 'views/Reimbursement/hooks/useExtractHospitalityInvoiceData';
import { useReimbursementUpdatePending } from 'views/Reimbursement/hooks/useReimbursementUpdatePending';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { FieldNames } from './generateGeneralExpenseFormFieldNames';
import { HospitalityFieldNames } from './generateHospitalityFormFieldNames';
import { useInvoiceDataFieldsUpdates } from './invoiceDataExtractionHelpers';

export type BooleanMap = { [key: string]: boolean };
interface UseExpensesFormActionsParams {
  formMethods: UseFormReturn<ExpensesFormOutput>;
  createExpenseInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  onRemoveForm: UseFieldArrayRemove;
}

export const useExpensesFormActions = ({
  createExpenseInvoice,
  onRemoveForm,
  formMethods,
}: UseExpensesFormActionsParams) => {
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<BooleanMap>({});
  const [isExtractingInvoiceData, setIsExtractingInvoiceData] =
    useState<BooleanMap>({});
  const [isDeletingForm, setIsDeletingForm] = useState<BooleanMap>({});
  const [clearableField, setClearableField] = useState<string>();
  const { setValue, getValues, watch } = formMethods;

  const { updateLastModifiedExpense } = useReimbursementFormsContext();

  const { updateHospitalityExpense, isUpdateHospitalityExpensePending } =
    useUpdateHospitalityExpense();
  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense();

  const { extractHospitalityInvoiceData, isHospitalityInvoiceDataPending } =
    useExtractHospitalityInvoiceData();

  const { extractGeneralInvoiceData, isGeneralInvoiceDataPending } =
    useExtractGeneralInvoiceData();

  const { deleteExpense } = useDeleteExpense();
  const { updateSearchParam } = useMutateSearchParams();

  const {
    updateGeneralExpenseInvoiceDataFields,
    updateHospitalityExpenseInvoiceDataFields,
  } = useInvoiceDataFieldsUpdates({
    setValue,
  });

  const handleClearableField = useCallback(
    (field?: string) => () => {
      setClearableField(field);
    },
    []
  );

  const handleUpdateHospitality = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`expenses.${index}`);
      updateLastModifiedExpense(index);
      handleClearableField(undefined)();
      await updateHospitalityExpense(formValues);
    },
    [
      getValues,
      handleClearableField,
      updateHospitalityExpense,
      updateLastModifiedExpense,
    ]
  );

  const handleUpdateGeneralExpense = useCallback(
    (index: number) => async () => {
      const formValues = getValues(`expenses.${index}`);

      updateLastModifiedExpense(index);
      setClearableField(undefined);
      await updateGeneralExpense(formValues);
    },
    [getValues, updateGeneralExpense, updateLastModifiedExpense]
  );

  const handleDeleteForm = useCallback(
    (index: number, expenseId: string) => async () => {
      setIsDeletingForm(prev => ({ ...prev, [index]: true }));

      const deleted = await deleteExpense(expenseId);
      setIsDeletingForm(prev => ({ ...prev, [index]: false }));

      if (!deleted) return;
      onRemoveForm(index);
      const expenses = watch('expenses');
      const expensesLength = expenses.length;

      if (!expensesLength) {
        updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, '');
      }
    },
    [deleteExpense, onRemoveForm, updateSearchParam, watch]
  );

  const handleInvoiceChange = useCallback(
    async (file: File, index: number) => {
      setIsCreatingInvoice(prev => ({ ...prev, [index]: true }));

      const invoice = await createExpenseInvoice(file);

      if (!invoice) {
        setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));

        return;
      }

      setValue(`expenses.${index}.files`, [invoice]);

      setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));
    },

    [createExpenseInvoice, setValue]
  );

  const handleUpdateTotalAmount = useCallback(
    (index: number) => {
      const grossAmount = watch(`expenses.${index}.receiptAmount`);
      const tipAmount = watch(`expenses.${index}.tipAmount`);

      if (isNil(grossAmount) && isNil(tipAmount)) {
        setValue(`expenses.${index}.totalAmount`, null, {
          shouldValidate: true,
        });

        return;
      }

      const totalAmount = (grossAmount ?? 0) + (tipAmount ?? 0);

      setValue(`expenses.${index}.totalAmount`, totalAmount, {
        shouldValidate: true,
      });
    },
    [setValue, watch]
  );

  const extractAndProcessHospitalityInvoice = useCallback(
    async (index: number, fields: HospitalityFieldNames) => {
      setIsExtractingInvoiceData(prev => ({ ...prev, [index]: true }));

      const formValuesBeforeExtraction = getValues(`expenses.${index}`);
      updateLastModifiedExpense(index);

      // Extract data
      await extractHospitalityInvoiceData(formValuesBeforeExtraction, data => {
        setValue(fields.isExtractedDataAccepted, data.isExtractionAccepted);
        setValue(fields.extractedData, data.extractedData);
        setIsExtractingInvoiceData(prev => ({ ...prev, [index]: false }));
      });

      const formValuesAfterExtraction = getValues(`expenses.${index}`);

      // Process extracted data
      updateHospitalityExpenseInvoiceDataFields(
        fields,
        formValuesAfterExtraction.extractedData
      );
      handleUpdateTotalAmount(index);
    },
    [
      getValues,
      setValue,
      extractHospitalityInvoiceData,
      updateHospitalityExpenseInvoiceDataFields,
      handleUpdateTotalAmount,
      updateLastModifiedExpense,
    ]
  );

  const extractAndProcessGeneralInvoice = useCallback(
    async (index: number, fields: FieldNames) => {
      setIsExtractingInvoiceData(prev => ({ ...prev, [index]: true }));

      const formValuesBeforeExtraction = getValues(`expenses.${index}`);
      updateLastModifiedExpense(index);

      // Extract data
      await extractGeneralInvoiceData(formValuesBeforeExtraction, data => {
        setValue(fields.isExtractedDataAccepted, data.isExtractionAccepted);
        setValue(fields.extractedData, data.extractedData);
        setIsExtractingInvoiceData(prev => ({ ...prev, [index]: false }));
      });

      const formValuesAfterExtraction = getValues(`expenses.${index}`);

      // Process extracted data
      updateGeneralExpenseInvoiceDataFields(
        fields,
        formValuesAfterExtraction.extractedData
      );
    },
    [
      getValues,
      setValue,
      extractGeneralInvoiceData,
      updateGeneralExpenseInvoiceDataFields,
      updateLastModifiedExpense,
    ]
  );

  const isUpdatePending =
    isGeneralInvoiceDataPending ||
    isHospitalityInvoiceDataPending ||
    isUpdateGeneralExpensePending ||
    isUpdateHospitalityExpensePending;

  useReimbursementUpdatePending(isUpdatePending);

  return {
    isDeletingForm,
    clearableField,
    isCreatingInvoice,
    isExtractingInvoiceData,
    handleClearableField,
    handleUpdateHospitality,
    handleDeleteForm,
    handleInvoiceChange,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
    extractAndProcessHospitalityInvoice,
    extractAndProcessGeneralInvoice,
  };
};

import { DocumentNode } from '@apollo/client';
import {
  PurchaseOrderEdge,
  PurchaserOrderQueryField,
  Query,
} from 'generated-types/graphql.types';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useUserRoles } from 'hooks/useUserRoles';
import { useSap } from 'orgConfig/sap';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { Filters, SortingRule } from 'react-table';
import { archivePurchaseOrdersQuery } from 'views/Archive/PurchaseOrders/queries';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { PurchaseOrdersTableData, ViewUsingPruchaseOrders } from './types';
import { mapToFilterInput, mapToSortInput } from './util';

export const defaultSort: SortingRule<PurchaseOrdersTableData>[] = [
  {
    id: 'status',
    desc: true,
  },
];

const sortByOrderNumber = [
  {
    id: 'orderNumber',
    desc: true,
  },
];

type QueryRootKey = 'inboxPurchaseOrders';

export interface UsePurchaseOrdersDataProps {
  searchValue: string;
  documentAmount?: number | null;
  routeType: ViewUsingPruchaseOrders;
  sortBy: SortingRule<PurchaseOrdersTableData>[];
  filters: Filters<PurchaseOrdersTableData>;
  queryFields?: PurchaserOrderQueryField[];
}

const queryList: {
  [key in ViewUsingPruchaseOrders]: {
    gqlQuery: DocumentNode;
    queryRootKey: QueryRootKey;
  };
} = {
  ARCHIVE: {
    gqlQuery: archivePurchaseOrdersQuery,
    queryRootKey: 'inboxPurchaseOrders',
  },
};

export const usePurchaseOrdersData = ({
  documentAmount,
  routeType,
  filters,
  sortBy,
  queryFields = [],
  searchValue = '',
}: UsePurchaseOrdersDataProps) => {
  const { isOnlyApprover } = useUserRoles();

  const [debouncedSearchValue] = useDebouncedValue(searchValue);
  const { shouldUseSapPurchaseOrder } = useSap();
  const gqlQuery = queryList[routeType].gqlQuery;

  const sortParameters = documentAmount ? sortByOrderNumber : defaultSort;

  const computeVariables = (endCursor: string | undefined) => ({
    input: {
      limit: getApproximateNumberOfRowForTable(),
      after: endCursor,
    },
    query: debouncedSearchValue,
    queryFields,
    filters: mapToFilterInput(filters),
    sortBy: !sortBy.length
      ? mapToSortInput(sortParameters, documentAmount)
      : mapToSortInput(sortBy),
  });

  const queryOptions = {
    variables: computeVariables(undefined),
    skip: !shouldUseSapPurchaseOrder || isOnlyApprover,
  };

  const {
    data,
    loading: isLoadingPurchaseOrderList,
    onLoadMore,
    refetch,
  } = usePagination<Pick<Query, QueryRootKey>>(
    gqlQuery,
    queryList[routeType].queryRootKey,
    queryOptions,
    {
      computeVariables,
    }
  );

  const listPurchaseOrders = data && data[queryList[routeType]?.queryRootKey];
  const { pageInfo, edges } = listPurchaseOrders || {};

  const purchaseOrderListCount = pageInfo?.totalCount || 0;
  const purchaseOrderList = (edges || []) as PurchaseOrderEdge[];
  const hasMoreData =
    (pageInfo?.hasNextPage ?? false) &&
    purchaseOrderListCount >= getApproximateNumberOfRowForTable();

  const isTableFiltered = filters.length > 0;

  const isTableEmpty =
    purchaseOrderList.length === 0 && !isLoadingPurchaseOrderList;

  return {
    hasMoreData,
    purchaseOrderList,
    isLoadingPurchaseOrderList,
    onLoadMore,
    refetch,
    purchaseOrderListCount,
    endCursor:
      purchaseOrderList[purchaseOrderList.length - 1]?.cursor ||
      pageInfo?.endCursor,
    isTableFiltered,
    isTableEmpty,
  };
};

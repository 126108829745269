import { Item } from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { useSap } from 'orgConfig/sap';
import { DocumentType } from 'types/DocumentTypeEnum';
import { sapDocumentTypesBlacklist } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { DocumentTypesSortByValues } from '../toolkit/consts';
import { useDocumentTypesList } from '../toolkit/useDocumentTypesList';
import { DocumentTypesListEmpty } from './DocumentTypesListEmptyDataState';
import { DocumentTypesListEmptySearchState } from './DocumentTypesListEmptySearchState';
import { DocumentTypesListItem } from './DocumentTypesListItem';

export function DocumentTypesList() {
  const {
    sortBy,
    onSortBy,
    sortMenuItems,
    sortMenuItemsMap,
    query,
    onSearchFilter,
    onSearchReset,
    searchPlaceholder,
    data,
    isLoading,
    viewDocumentsLinkPrefix,
  } = useDocumentTypesList();

  const { isActiveIntegration } = useSap();
  const filteredDocumentTypes = data.filter(({ name }) => {
    if (isActiveIntegration) {
      return sapDocumentTypesBlacklist.includes(name as DocumentType) === false;
    }
    return true;
  });

  return (
    <FilterableList
      selectionMode="none"
      isLoading={isLoading}
      emptyDataState={<DocumentTypesListEmpty />}
      emptySearchState={
        <DocumentTypesListEmptySearchState onSearchReset={onSearchReset} />
      }
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: value => {
            if (value?.length) {
              onSortBy(value[0] as DocumentTypesSortByValues);
            }
          },
          text: sortMenuItemsMap[sortBy],
          menuButtonItems: sortMenuItems,
        },
      ]}
      searchField={{
        onSearchFilter,
        placeholder: searchPlaceholder,
        searchQuery: query,
      }}
    >
      {filteredDocumentTypes.map(documentType => (
        <Item key={documentType.name} textValue={documentType.name}>
          <DocumentTypesListItem
            documentType={documentType}
            query={query}
            viewDocumentsLinkPrefix={viewDocumentsLinkPrefix}
          />
        </Item>
      ))}
    </FilterableList>
  );
}

import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { noop } from 'lodash';

import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { MiddleSectionSkeleton } from './MiddleSectionSkeleton';
import { REIMBURSEMENT_URL_PARAM } from './Reimbursement';
import { ExpenseSectionContainer } from './components/MiddleSection/ExpenseSectionContainer';
import { ExpensesFormsContainer } from './components/MiddleSection/ExpensesFormContainer';
import { ExpensesFormsContainerDeprecated } from './components/MiddleSection/ExpensesFormsContainerDeprecated';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useFormValidationTrigger } from './hooks/useFormValidationTrigger';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';

const FORM_KEY = 'expenseForms';
interface MiddleSectionContainerProps {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  isLoading: boolean;
}

export const MiddleSectionContainer = ({
  formHelpers,
  isLoading,
}: MiddleSectionContainerProps) => {
  const reimbursementExpenseItemFileExtractionFF = useCandisFeatureFlags(
    FEATURE_FLAGS.reimbursementExpenseItemFileExtraction
  );
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { searchParams } = useMutateSearchParams();
  const isItemsViewActive = searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);
  const { reimbursementItems, reimbursementItemsCount } =
    useReimbursementExpenseItemsList();

  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const { forms, formMethods } = formHelpers;

  useFormValidationTrigger(
    FORM_KEY,
    () => void formMethods.handleSubmit(noop)()
  );

  if (isLoading) {
    return (
      <SectionLayout>
        <MiddleSectionSkeleton isItemsViewActive={!!isItemsViewActive} />
      </SectionLayout>
    );
  }

  const showItemsView = Boolean(isItemsViewActive && forms.length);

  const ExpensesForms = reimbursementExpenseItemFileExtractionFF
    ? ExpensesFormsContainer
    : ExpensesFormsContainerDeprecated;

  return (
    <SectionLayout>
      <Grid
        height="100%"
        paddingTop="40.5px"
        paddingLeft="space16"
        paddingRight="space8"
        overflowY="auto"
        templateRows={showItemsView ? 'auto' : 'auto 1fr'}
      >
        {showItemsView ? (
          <ExpensesForms
            status={reimbursement?.status}
            formHelpers={formHelpers}
          />
        ) : (
          <ExpenseSectionContainer
            formHelpers={formHelpers}
            reimbursement={reimbursement}
            expenseCount={reimbursementItemsCount}
            reimbursmentItems={reimbursementItems}
          />
        )}
      </Grid>
    </SectionLayout>
  );
};

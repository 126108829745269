import { useApolloClient } from '@apollo/client';
import { Button, Card, Grid, Link, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentType,
  Query,
  useConvertDocumentMutation,
} from 'generated-types/graphql.types';
import { delay } from 'lodash';
import { Routes } from 'models';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CLASSIFICATION_QUERIES_TO_REFETCH } from './useClassificationCountPolling';

interface DocumentConversionWarningContainerProps {
  documentTypeLabel: string;
  globalDocumentId: string;
  documentType: string;
}

export const DocumentConversionWarningContainer = ({
  documentType,
  documentTypeLabel,
  globalDocumentId,
}: DocumentConversionWarningContainerProps) => {
  const [t] = useTranslation();
  const client = useApolloClient();
  const [convert, { loading }] = useConvertDocumentMutation();
  const { success, error } = useToastMessage();
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  return (
    <Card>
      <Grid gap="space24">
        <Grid gap="space8">
          <Text fontSize="medium" lineHeight="paragraph">
            {t('ecm:documentConversion.convertCta.title')}
          </Text>
          <Trans
            i18nKey="ecm:documentConversion.convertCta.description"
            parent="span"
          >
            <Text fontSize="basic" lineHeight="paragraph">
              All previously captured data in this form will be lost if document
              type is changed.
            </Text>{' '}
            <Link
              justifySelf="center"
              external
              href={t('ecm:documentConversion.convertCta.helpCenterUrl')}
              whiteSpace="nowrap"
              style={{ display: 'inline-block' }}
              fontSize="basic"
              lineHeight="paragraph"
            >
              Learn more
            </Link>
          </Trans>
        </Grid>
        <Grid gap="space16">
          <Button
            color="gray"
            variant="secondary"
            loading={loading}
            onClick={async () => {
              const result = await convert({
                variables: {
                  input: {
                    globalDocumentId,
                    documentType,
                  },
                },
                onCompleted: () => {
                  /**
                   * Document conversion is an async action that *does not* guarantee the list queries are going to be synced when mutation resolves.
                   * Until we find a better solution for query refetching for async actions, for now a simple delay is enough that refetched data is up-to-date.
                   */
                  delay(() => {
                    CLASSIFICATION_QUERIES_TO_REFETCH.map(query => {
                      client.cache.evict({ fieldName: query });
                    });
                    client.cache.evict({
                      fieldName: 'countClassifyingDocuments' as keyof Query,
                    });
                  }, 5000);
                },
              });
              if (result.data?.convertDocument.globalDocumentId) {
                success(t('ecm:documentConversion.messages.success'));
                if (result.data?.convertDocument.invoiceId)
                  navigate({
                    pathname: `/${orgId}${Routes.INBOX}/${result.data.convertDocument.invoiceId}`,
                  });
                else {
                  if (documentType === DocumentType.Contract)
                    navigate({
                      pathname: `/${orgId}${Routes.INBOX_CONTRACTS}/${result.data.convertDocument.globalDocumentId}`,
                    });
                  else
                    navigate({
                      pathname: `/${orgId}${Routes.INBOX_OTHER_DOCUMENTS}/${result.data.convertDocument.globalDocumentId}`,
                    });
                }
              } else {
                error(t('ecm:documentConversion.messages.error'));
              }
            }}
          >
            {t('ecm:documentConversion.convertCta.button', {
              documentTypeLabel,
            })}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

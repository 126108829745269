import { useCallback } from 'react';
import {
  ReimbursementItemStatus,
  ReimbursementItemType,
  useReimbursementItemsListQuery,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export interface ReimbursementItemListLeftSection {
  type: ReimbursementItemType;
  reason: string | undefined;
  totalAmount: string | undefined;
  status: ReimbursementItemStatus;
  id: string;
}

export const useReimbursementExpenseItemsList = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const {
    data,
    loading: loadingItems,
    refetch,
  } = useReimbursementItemsListQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const fetchedItems = data?.reimbursementItemsList ?? [];

  const reimbursementItemsCount = fetchedItems?.length ?? 0;

  const reimbursementItems = fetchedItems.map(item => ({
    type: item.type,
    reason: item.title ?? undefined,
    id: item.id,
    status: item.status,
    updatedAt: item.updatedAt,
    totalAmount: !isNil(item.totalAmount?.amount)
      ? transactionAmountFormatter({
          currency: item.totalAmount.currency,
          precision: item.totalAmount.precision,
          value: item.totalAmount.amount,
        })
      : undefined,
  }));

  const refetchReimbursementItemsList = useCallback(() => refetch(), [refetch]);

  return {
    loadingItems,
    reimbursementItemsCount,
    reimbursementItems,
    refetchReimbursementItemsList,
  };
};

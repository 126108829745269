import {
  Badge,
  Box,
  Button,
  Grid,
  MenuButton,
  Popover,
  PopoverProps,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useTourPopover } from 'components/TourPopover/useTourPopover';
import { motion } from 'framer-motion';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, RefObject, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface ApprovalFilterButtonProps {
  counters: {
    allApprovals: string | undefined;
    approvalsAssignedToCurrentUserCount: string | undefined;
    approvalsRequestedByCurrentUserCount: string | undefined;
  };
  isLoading: boolean;
  onChange: (value: Key[]) => void;
}

export const FilterItems = {
  requestedByMe: 'requestedByMe',
  assignedToMe: 'assignedToMe',
  all: 'all',
} as const;

export const ApprovalFilterButton = ({
  counters,
  isLoading,
  onChange,
}: ApprovalFilterButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  const user = useCurrentUser();
  const { searchParams } = useMutateSearchParams();
  const filterValue = useMemo(() => {
    if (searchParams.get('approvers') === user?.id) {
      return FilterItems.assignedToMe;
    }
    if (searchParams.get('requester') === user?.id) {
      return FilterItems.requestedByMe;
    }

    return FilterItems.all;
  }, [user?.id, searchParams]);

  const { targetProps, targetRef, popoverProps, popoverRef, close, isOpen } =
    useTourPopover({
      id: TOUR_POPOVER_IDS.FILTER_APPROVAL_VIEW_NEW,
      placement: 'bottom',
    });

  const filterButtonItems = useMemo(
    () => [
      {
        id: FilterItems.all,
        label: t('outsideFilter.showAll'),
      },
      {
        id: FilterItems.assignedToMe,
        label: t('outsideFilter.showAssignedToMe'),
      },
      {
        id: FilterItems.requestedByMe,
        label: t('outsideFilter.showMonitoring'),
      },
    ],
    [t]
  );

  const mappedTranslationsWithCounters: Record<
    keyof typeof FilterItems,
    { translation: string; count: string | undefined }
  > = {
    all: {
      translation: 'outsideFilter.showAllWithBadge',
      count: counters.allApprovals,
    },
    assignedToMe: {
      translation: 'outsideFilter.showAssignedToMeWithBadge',
      count: counters.approvalsAssignedToCurrentUserCount,
    },
    requestedByMe: {
      translation: 'outsideFilter.showMonitoringWithBadge',
      count: counters.approvalsRequestedByCurrentUserCount,
    },
  };

  const filterTranslationOption = mappedTranslationsWithCounters[filterValue];

  const counterBadge = filterTranslationOption.count ? (
    <Badge color="darkGray"> {filterTranslationOption.count}</Badge>
  ) : undefined;

  return (
    <Box {...targetProps} ref={targetRef}>
      <MenuButton
        selectionMode="single"
        items={filterButtonItems}
        variant="tertiary"
        value={[filterValue]}
        onChange={onChange}
      >
        <Trans
          t={t}
          i18nKey={filterTranslationOption.translation}
          values={{ count: filterTranslationOption.count }}
        >
          Filter type
          {isLoading ? (
            <Skeleton width="space20" height="space24" />
          ) : (
            counterBadge
          )}
        </Trans>
      </MenuButton>
      {isOpen && (
        <ApprovalViewsNewFiltersPopover
          onClose={close}
          popoverProps={popoverProps}
          popoverRef={popoverRef}
        />
      )}
    </Box>
  );
};

const MotionPopover = motion(Popover);

interface ApprovalViewsNewFiltersPopoverProps {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const ApprovalViewsNewFiltersPopover = ({
  onClose,
  popoverProps,
  popoverRef,
}: ApprovalViewsNewFiltersPopoverProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  return (
    <MotionPopover
      {...(popoverProps as any)}
      ref={popoverRef}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      transition={{ type: 'spring', duration: 2, bounce: 0.5 }}
      padding="space16"
      dismissWithOutsideClick={false}
      background="blue700"
      maxWidth="16.25rem"
    >
      <Grid gap="space12">
        <Text
          color="white"
          fontSize="basic"
          fontWeight="semibold"
          lineHeight="paragraph"
        >
          {t('newFilterPopover.title')}
        </Text>
        <Text color="white" fontSize="basic" lineHeight="paragraph">
          {t('newFilterPopover.description')}
        </Text>

        <Button
          justifySelf="right"
          color="whiteBlue"
          size="small"
          variant="primary"
          onClick={onClose}
        >
          {t('newFilterPopover.close')}
        </Button>
      </Grid>
    </MotionPopover>
  );
};

import { DocumentNode } from '@apollo/client';
import { queryParameter } from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo } from 'react';
import { Filters, SortingRule } from 'react-table';
import { archiveCardIssuerTransactionsQuery } from 'views/Archive/Transactions/queries';
import { unassociatedCardIssuerTransactionsQuery } from 'views/Inbox/DocumentProcessing/queries';
import { inboxCardIssuerTransactionsQuery } from 'views/Inbox/Transactions/gql';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { TransactionsTableData, ViewUsingTransactions } from './types';
import { mapToFilterInput, mapToSortInput } from './util';

export const defaultSort: SortingRule<TransactionsTableData>[] = [
  {
    id: 'transactionCreatedAt',
    desc: true,
  },
];

type QueryRootKey =
  | 'archiveCardIssuerTransactions'
  | 'inboxCardIssuerTransactions'
  | 'unassociatedCardIssuerTransactions';

interface UseCardIssuerTransactionsDataProps {
  routeType: ViewUsingTransactions;
  sortBy: SortingRule<TransactionsTableData>[];
  filters: Filters<TransactionsTableData>;
}

const queryList: {
  [key in ViewUsingTransactions]: {
    gqlQuery: DocumentNode;
    queryRootKey: QueryRootKey;
  };
} = {
  ARCHIVE: {
    gqlQuery: archiveCardIssuerTransactionsQuery,
    queryRootKey: 'archiveCardIssuerTransactions',
  },
  INBOX: {
    gqlQuery: inboxCardIssuerTransactionsQuery,
    queryRootKey: 'inboxCardIssuerTransactions',
  },
  PROCESSING_DOCUMENT: {
    gqlQuery: unassociatedCardIssuerTransactionsQuery,
    queryRootKey: 'unassociatedCardIssuerTransactions',
  },
};

export const useCardIssuerTransactionsData = ({
  routeType,
  filters,
  sortBy,
}: UseCardIssuerTransactionsDataProps) => {
  const gqlQuery = queryList[routeType].gqlQuery;
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const search = searchParams.get(queryParameter) ?? '';

  const pageSize = getApproximateNumberOfRowForTable();

  const queryOptions = {
    variables: {
      queries: {
        query: search,
      },
      input: {
        limit: pageSize,
      },
      filters: mapToFilterInput(filters),
      sortBy: !sortBy.length
        ? mapToSortInput(defaultSort)
        : mapToSortInput(sortBy),
    },
  };

  const computeVariables = (endCursor: string) => ({
    queries: {
      query: search,
    },
    input: {
      limit: pageSize,
      after: endCursor,
    },
    filters: mapToFilterInput(filters),
    sortBy: !sortBy.length
      ? mapToSortInput(defaultSort)
      : mapToSortInput(sortBy),
  });

  const {
    data,
    loading: isLoadingTransactionList,
    onLoadMore,
  } = usePagination<Pick<Query, QueryRootKey>>(
    gqlQuery,
    queryList[routeType].queryRootKey,
    queryOptions,
    { computeVariables }
  );

  const { pageInfo, edges } = data?.[queryList[routeType]?.queryRootKey] ?? {};

  const transactionListCount = pageInfo?.totalCount ?? 0;
  const hasMoreData = pageInfo?.hasNextPage ?? false;

  const isTableEmpty = (edges ?? []).length === 0 && !isLoadingTransactionList;
  const isTableFiltered = filters.length > 0;

  const handleDebounceSearch = useMemo(() => {
    const setSearch = (search: string) =>
      updateSearchParam(queryParameter, search);
    return debounce(setSearch, DEFAULT_DEBOUNCE_TIME, { leading: true });
  }, [updateSearchParam]);

  return {
    hasMoreData,
    transactionList: edges ?? [],
    transactionEdges: edges ?? [],
    isLoadingTransactionList,
    onLoadMore,
    transactionListCount,
    endCursor: pageInfo?.endCursor,
    isTableFiltered,
    isTableEmpty,
    handleDebounceSearch,
  };
};

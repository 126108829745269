import { useApprovalReimbursementsData } from '../toolkit/hooks/useApprovalReimbursementsData';
import { ApprovalReimbursements } from './ApprovalReimbursements';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ApprovalReimbursementsPromotion } from './ApprovalReimbursementsPromotion';
import { useApprovalReimbursementsFilterOptions } from '../toolkit/hooks/useApprovalReimbursementsFilterOptions';
import { useApprovalReimbursementsTableConfigurations } from '../toolkit/hooks/useApprovalReimbursementsTableConfigurations';
import { ApprovalReimbursementsFilterButtonContainer } from './ApprovalReimbursementsFilterButtonContainer';

export const ApprovalReimbursementsContainer = () => {
  const {
    filters,
    isLoadingReimbursements,
    handleDebounceSearch,
    reimbursements,
    sortBy,
    isTableEmpty,
    isTableFiltered,
    onFilter,
    onLoadMore,
    onSort,
  } = useApprovalReimbursementsData();

  const { variant } = useReimbursementsPromotions();

  const filterOptions = useApprovalReimbursementsFilterOptions();

  const {
    availableReimbursementsColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useApprovalReimbursementsTableConfigurations({ filters, sortBy });

  const isPromotionForReimbursementsVisible = variant !== 'noPromotion';

  if (isPromotionForReimbursementsVisible) {
    return <ApprovalReimbursementsPromotion />;
  }

  return (
    <ApprovalReimbursements
      reimbursements={reimbursements}
      filters={filters}
      visibleColumnIds={availableReimbursementsColumnIds}
      sortBy={sortBy}
      isLoadingReimbursements={
        isLoadingReimbursements || isResetPending || isSavingConfigurations
      }
      onDebounceSearch={handleDebounceSearch}
      isTableEmpty={isTableEmpty}
      isTableFiltered={isTableFiltered}
      filterOptions={filterOptions}
      configurations={configurationsTable}
      toolbarFilter={<ApprovalReimbursementsFilterButtonContainer />}
      onFilter={onFilter}
      onResetTableConfigurations={handleResetTableConfigurations}
      onUpdateConfigurations={handleUpdateConfigurations}
      onSort={onSort}
      onLoadMore={onLoadMore}
    />
  );
};

import { Box, Button, Modal, useModal } from '@candisio/design-system';
import { PurchaseOrderListDeprecated } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/PurchaseOrderListDeprecated';
import { useUserRoles } from 'hooks/useUserRoles';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MatchState } from '../../ThreeWayMatch/types';
import { LinkingTable } from './LinkingTable';

type AddNewPurchaseOrderButtonProps = {
  documentId: string;
  variant: MatchState;
  documentAmount?: number | null;
  contactName?: string;
  accountsPayableNumber?: string | null;
  disabled?: boolean;
};
// TODO select modal should be separate component since it's used in multiple places

export const AddNewPurchaseOrderButton = ({
  documentId,
  contactName,
  documentAmount,
  accountsPayableNumber,
  disabled,
  variant,
}: AddNewPurchaseOrderButtonProps) => {
  const { isOnlyApprover } = useUserRoles();
  const { shouldUseSapGoodsReceipts } = useSap();

  const { triggerProps, modalProps, isOpen, close } = useModal();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const titleText = contactName
    ? t('addPurchaseOrderModal.headerWithContactName', { contactName })
    : t('addPurchaseOrderModal.header');

  if (isOnlyApprover) {
    return <Box padding="space2" />;
  }

  return (
    <>
      <Button
        icon="plus"
        variant="tertiary"
        style={{
          flexGrow: '1',
          borderRadius: '0',
          padding: '.75rem',
          borderEndStartRadius: 'medium',
          borderEndEndRadius: 'medium',
          width: '100%',
          backgroundColor: variant === 'default' ? undefined : 'transparent',
        }}
        label={
          disabled
            ? t('threeWayMatch.indicator.disabledLinkingTooltip')
            : undefined
        }
        disabled={disabled}
        {...triggerProps}
      >
        {t('threeWayMatch.indicator.addNewPurchaseOrderButtonText')}
      </Button>
      {!disabled && isOpen && (
        <Modal
          {...modalProps}
          isOpen={isOpen}
          background="gray200"
          padding={0}
          minHeight="70vh"
          minWidth="80vw"
          shouldCloseOnBlur={true}
          closeLabel={t('addPurchaseOrderModal.close')}
          title={titleText}
        >
          {shouldUseSapGoodsReceipts ? (
            <LinkingTable
              documentId={documentId}
              documentAmount={documentAmount}
              accountsPayableNumber={accountsPayableNumber}
              closeModal={close}
              contactName={contactName}
            />
          ) : (
            <PurchaseOrderListDeprecated
              documentAmount={documentAmount}
              accountsPayableNumber={accountsPayableNumber}
              documentId={documentId}
              closeModal={close}
              contactName={contactName}
            />
          )}
        </Modal>
      )}
    </>
  );
};

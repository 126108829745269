import { mapDocumentStatusToStatus } from 'components/DocumentViewer/AttachmentsTable/utils';
import { Amount, DocumentsTableData } from 'components/DocumentsTable/types';
import {
  InboxInvoiceDocumentBooking,
  InboxInvoiceDocumentEdge,
  Maybe,
  PaymentMethod,
  PurchaseOrder,
  PurchaseOrderIntegrationType,
} from 'generated-types/graphql.types';
import { TFunction } from 'react-i18next';

type MappedBooking = {
  costCenter?: string;
  costObject?: string;
  extraCostInfo?: string;
  artistSocialInsuranceCode?: string;
  dueDate?: Date;
  discountAmount?: number;
  discountPercentage?: number;
  discountPaymentDate?: Date;
};

type AllBookingDetails = {
  costCenters: string[];
  costObjects: string[];
  extraCostInfos: string[];
  artistSocialInsuranceCodes: string[];
  dueDates: Date[];
  discountAmounts: number[];
  discountPercentages: number[];
  discountPaymentDates: Date[];
};
const calculateGoodsReceiptsCount = (
  purchaseOrders: Maybe<PurchaseOrder>[] | null
): string | undefined => {
  if (!purchaseOrders) return undefined;
  const count = purchaseOrders.reduce((acc, po) => {
    return acc + (po?.goodsReceipts?.length ?? 0);
  }, 0);

  return count.toString();
};

const mapToBookings =
  (t: TFunction) =>
  (booking: InboxInvoiceDocumentBooking | null | undefined): MappedBooking => {
    if (!booking) {
      return {};
    }

    const artistSocialInsuranceCode = booking.artistSocialInsuranceCode
      ? t(
          `document.requestApproval.inputs.artistSocialInsuranceCode.codes.${booking.artistSocialInsuranceCode}`
        )
      : undefined;

    return {
      costCenter: booking.costCenter?.readableName ?? undefined,
      costObject: booking.costObject?.readableName ?? undefined,
      extraCostInfo: booking.extraCostInfo?.readableName ?? undefined,
      artistSocialInsuranceCode,
      dueDate: booking.dueDate ? new Date(booking.dueDate) : undefined,
      discountAmount: booking.discountAmount ?? undefined,
      discountPercentage: booking.discountPercentage ?? undefined,
      discountPaymentDate: booking.discountPaymentDate
        ? new Date(booking.discountPaymentDate)
        : undefined,
    };
  };

const reduceAllDetails = (
  allBookingDetails: AllBookingDetails,
  booking: MappedBooking
): AllBookingDetails => {
  booking.costCenter && allBookingDetails.costCenters.push(booking.costCenter);
  booking.costObject && allBookingDetails.costObjects.push(booking.costObject);
  booking.extraCostInfo &&
    allBookingDetails.extraCostInfos.push(booking.extraCostInfo);
  booking.artistSocialInsuranceCode &&
    allBookingDetails.artistSocialInsuranceCodes.push(
      booking.artistSocialInsuranceCode
    );
  booking.discountAmount &&
    allBookingDetails.discountAmounts.push(booking.discountAmount);
  booking.discountPaymentDate &&
    allBookingDetails.discountPaymentDates.push(booking.discountPaymentDate);
  booking.discountPercentage &&
    allBookingDetails.discountPercentages.push(booking.discountPercentage);
  booking.dueDate && allBookingDetails.dueDates.push(booking.dueDate);

  return allBookingDetails;
};

export const mapInboxInvoicesToDocumentsTableData = (
  edges: InboxInvoiceDocumentEdge[],
  t: TFunction
) => {
  const documentsTableData: DocumentsTableData[] = edges.map(edge => {
    const amount = edge.node.amount?.amount;
    const currency = edge.node.amount?.currency;
    const grossAmount: Amount | undefined =
      amount && currency
        ? { amount, currency, isBasicMonetaryUnit: true }
        : undefined;

    const bookings: MappedBooking[] = (edge.node.bookings ?? []).map(
      mapToBookings(t)
    );

    const accumulatorDetails: AllBookingDetails = {
      costCenters: [],
      costObjects: [],
      extraCostInfos: [],
      artistSocialInsuranceCodes: [],
      discountAmounts: [],
      discountPaymentDates: [],
      discountPercentages: [],
      dueDates: [],
    };

    const allBookingDetails = bookings.reduce(
      reduceAllDetails,
      accumulatorDetails
    );

    const isSapPurchaseOrder =
      edge.node.purchaseOrderData?.integrationType ===
        PurchaseOrderIntegrationType.Sap ||
      Boolean(edge.node.purchaseOrders?.length);

    return {
      id: edge.node.id,
      status: {
        status: mapDocumentStatusToStatus(edge.node.status),
        isOverdue: !!edge.node.isOverdue,
        isDuplicate: !!edge.node.isDuplicate,
        isEInvoice: !!edge.node.isEInvoice,
        eInvoice: edge.node.eInvoice,
      },
      isEInvoice: !!edge.node.isEInvoice,
      eInvoice: edge.node.eInvoice,
      contact: edge.node.contact?.name ?? undefined,
      accountsPayableNumber:
        edge.node.contact?.accountsPayableNumber ?? undefined,
      createdAt: edge.node.createdAt
        ? new Date(edge.node.createdAt)
        : undefined,
      grossAmount,
      invoiceNumber: edge.node.invoiceNumber,
      invoiceDate: edge.node.invoiceDate
        ? new Date(edge.node.invoiceDate)
        : undefined,
      dueDate: allBookingDetails['dueDates'],
      costCenter: allBookingDetails['costCenters'],
      costObject: allBookingDetails['costObjects'],
      extraCostInfo: allBookingDetails['extraCostInfos'],
      artistSocialInsuranceCode:
        allBookingDetails['artistSocialInsuranceCodes'],
      isPayable: edge.node.isPayable ?? false,
      cursor: edge.cursor,
      deliveryDate: edge.node.invoiceDeliveryDate
        ? new Date(edge.node.invoiceDeliveryDate)
        : undefined,
      discountDateWPercentage: edge.node.bookings?.[0]
        ? {
            discountPaymentDate: bookings[0].discountPaymentDate,
            discountPercentage: bookings[0].discountPercentage,
          }
        : undefined,
      creditCardPayment:
        edge.node.payment?.isPaid === true &&
        edge.node.payment?.method === PaymentMethod.CardTransaction,
      purchaseOrderNumber: edge.node.purchaseOrderData?.orderNumber,
      hasPurchaseOrderLinked:
        edge.node.purchaseOrderData?.integrationType ===
        PurchaseOrderIntegrationType.Sap,
      tags: edge.node.tags,
      sapPurchaseOrderNumber: (edge.node.purchaseOrders ?? []).map(
        po => po?.orderNumber ?? ''
      ),
      goodsReceiptsCount: isSapPurchaseOrder
        ? calculateGoodsReceiptsCount(edge.node?.purchaseOrders ?? [])
        : undefined,
    };
  });

  return documentsTableData;
};

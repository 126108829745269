import { Link, Text } from '@candisio/design-system';

import { ProcessingFormSubmitErrors } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ArtistSocialInsuranceCode,
  DocumentCurrency,
  DocumentStatus,
  useAvailableDocumentCategoriesQuery,
  useDocumentRequestApprovalMutation,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';

import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { useIsSepaPaymentActive } from 'hooks/useIsSepaPaymentActive';
import { isNil } from 'lodash';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Trans } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { DOCUMENT_TYPES } from './consts';
import { getBookingInputDiscountAmount } from './getBookingInputDiscountAmount';
import { useGetRequestApprovalSubmitErrors } from './useGetRequestApprovalSubmitErrors';

type RequestApproval = (
  values: ProcessingFormValues
) => Promise<
  | { status: 'success' }
  | { status: 'error'; submitErrors?: ProcessingFormSubmitErrors }
>;

const invoiceApprovalAnalyticsEventProperties = ({
  documentId,
  values,
}: {
  documentId: string;
  values: ProcessingFormValues;
}) => ({
  document_id: documentId,
  note: values.bookings[0].note,
  tax_code: values.bookings[0].taxCode,
  workflow_template_id: values.workflow,
  posting_text: values.bookings[0].postingText,
  accounts_payable: values.accountsPayableNumber,
  cost_center: values.bookings[0].costCenter.inputValue,
  cost_object: values.bookings[0].costObject.inputValue,
  extra_cost_info: values.bookings[0].extraCostInfo.inputValue,
  general_ledger_account: values.bookings[0].generalLedgerAccount.inputValue,
  social_artist_security: values.bookings[0].artistSocialInsuranceCode,
});

/**
 * Requests approval for the document with the submitted values from processing
 * form.
 *
 * Excludes the following form fields when document has linked transaction:
 * - IBAN
 * - create transfer (sets to `false`)
 * - payment condition
 * - discount date, percentage and amount
 *
 * Excludes payment condition, discount date, percentage and amount fields when
 * gross amount is negative or document type does not have incoming direction
 *
 * Excludes approvers field when approval mode is set to workflow
 *
 * Excludes workflow field when approval mode is set to (manual) approvers
 *
 * @todo it might make sense to perform the above normalizations inside the
 * processing form instead of here(?)
 */
export const useRequestApproval = (documentId?: string) => {
  const [t] = useTranslation();
  const { success, error, warning, dismiss } = useToastMessage();
  const { dateStringToDateTimeString } = useDateConverter();
  const sap = useSap();
  const { track } = useAnalytics();

  const { shouldUseAccountingAreas } = useOtherIntegration();

  const [purchaseOrderNumberFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.purchaseOrderNumber,
  ]);

  const navigate = useNavigate();

  const getSubmitErrors = useGetRequestApprovalSubmitErrors();

  const { organizationSlug } = useParams<AppRouteParams>();

  const { data: documentData } = useGetDocumentForDraftQuery({
    variables: { id: documentId as string },
    skip: typeof documentId !== 'string',
  });

  const hasTransactionLinked =
    documentData?.getDocument?.hasTransactionLinked ?? false;

  const status = documentData?.getDocument?.status ?? DocumentStatus.New;
  const wasDuplicate = documentData?.getDocument?.isDuplicate ?? false;

  const { data: availableDocumentCategoriesData } =
    useAvailableDocumentCategoriesQuery();

  const availableDocumentCategories =
    availableDocumentCategoriesData?.availableDocumentCategories ?? [];

  const counterQueries = useCounterQueries();
  const [mutation] = useDocumentRequestApprovalMutation({
    awaitRefetchQueries: true,
    refetchQueries: [...counterQueries],
  });
  const isSepaPaymentActive = useIsSepaPaymentActive();

  const requestApproval: RequestApproval = async values => {
    if (isNil(status) || typeof documentId !== 'string') {
      return { status: 'error' };
    }

    const category = availableDocumentCategories.find(
      category => category.documentType === values.type
    );

    if (!category) {
      return { status: 'error' };
    }

    // Only include cash discount fields when document does not have linked
    // transaction, gross amount is positive and selected document type has
    // incoming direction
    const shouldIncludeCashDiscount =
      !hasTransactionLinked &&
      values.grossAmount > 0 &&
      category.direction === DocumentDirection.invoices_received;

    const isSapCreditMemoType =
      sap.isActiveIntegration &&
      values.type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

    const getIbanValue = (
      isSapCreditMemoType: boolean,
      hasTransactionLinked: boolean
    ) => {
      if (isSapCreditMemoType) {
        return null;
      }

      // Exclude IBAN when document has transaction linked
      return !hasTransactionLinked ? values.iban : null;
    };

    const getSwiftCodeValue = (
      isSapCreditMemoType: boolean,
      hasTransactionLinked: boolean
    ) => {
      if (isSapCreditMemoType) {
        return null;
      }

      // Exclude swift code when document has transaction linked
      return !hasTransactionLinked ? values.swiftCode : null;
    };

    const getCreateTransferValue = (
      isSapCreditMemoType: boolean,
      hasTransactionLinked: boolean
    ) => {
      if (
        sap.isActiveIntegration &&
        (!isSepaPaymentActive || isSapCreditMemoType)
      ) {
        return false;
      }

      // Create transfer is `false` when document has transaction linked
      return !hasTransactionLinked && values.createTransfer;
    };

    const { data } = await mutation({
      variables: {
        id: documentId,
        value: {
          amount: values.grossAmount,
          roundingAmount: sap.shouldUseSapNetAmount
            ? values.roundingAmount
            : undefined,
          approverIds:
            // Exclude workflow fields when approval mode is not set to
            // approvers
            values.approvalMode === 'approvers'
              ? values.approvers?.map(step => step.approvers)
              : null,
          createTransfer: getCreateTransferValue(
            isSapCreditMemoType,
            hasTransactionLinked
          ),
          bookings: values.bookings.map(booking => ({
            amount: booking.amount,
            netAmount:
              sap.shouldUseSapNetAmount && booking.netAmount
                ? booking.netAmount
                : undefined,
            taxAmount: sap.shouldUseSapNetAmount
              ? booking.taxAmount
              : undefined,
            artistSocialInsuranceCode: !isNil(booking.artistSocialInsuranceCode)
              ? (booking.artistSocialInsuranceCode as ArtistSocialInsuranceCode)
              : null,
            bookingKeyId: booking.taxCode ?? null,
            costCenterId: booking.costCenter?.value ?? null,
            costObjectId: booking.costObject?.value ?? null,
            ...(shouldIncludeCashDiscount
              ? {
                  discountAmount:
                    values.bookings.length === 1
                      ? (values.discountAmount ?? null)
                      : getBookingInputDiscountAmount(values, booking),
                  discountPaymentDate: values.discountDate
                    ? dateStringToDateTimeString(values.discountDate)
                    : null,
                  discountPercentage: values.discountPercentage ?? null,
                  paymentConditionId: values.paymentCondition ?? null,
                }
              : {
                  discountAmount: null,
                  discountPaymentDate: null,
                  discountPercentage: null,
                  paymentConditionId: null,
                }),
            dueDate: booking.dueDate
              ? dateStringToDateTimeString(booking.dueDate)
              : null,
            extraCostInfoId: booking.extraCostInfo?.value ?? null,
            generalLedgerAccountId:
              booking?.generalLedgerAccount?.value ?? null,
            id: booking.bookingId ?? null,
            note: booking.note ?? null,
            postingText: booking.postingText ?? null,
            vatRate: booking.vatRate ?? null,
            quantity: booking?.quantity ?? undefined,
            sapExpenseType: sap.shouldUseSapPurchaseOrder
              ? booking?.sapExpenseType
              : undefined,
            unitPrice:
              sap.shouldUseSapPurchaseOrder && sap.shouldUseSapNetAmount
                ? booking?.unitPrice
                : undefined,
            projectCodeId: sap.shouldUseSapProjectCodes
              ? booking?.projectCode?.value
              : undefined,
          })),
          category: {
            documentType: category.documentType,
            direction: category.direction,
          },
          contactId: values.contact.value,
          currency: values.currency as DocumentCurrency,
          iban: getIbanValue(isSapCreditMemoType, hasTransactionLinked),
          swiftCode: getSwiftCodeValue(
            isSapCreditMemoType,
            hasTransactionLinked
          ),
          invoiceDate: dateStringToDateTimeString(values.invoiceDate),
          invoiceDeliveryDate: values.deliveryDate
            ? dateStringToDateTimeString(values.deliveryDate)
            : null,
          invoicePostingDate: values.postingDate
            ? dateStringToDateTimeString(values.postingDate)
            : null,
          invoiceNumber: values.invoiceNumber,
          accountingAreaId:
            shouldUseAccountingAreas && values.accountingArea.value
              ? values.accountingArea.value
              : null,
          purchaseOrderNumber: purchaseOrderNumberFF
            ? values.purchaseOrderNumber
            : null,
          status,
          workflowTemplateId:
            // Exclude workflow fields when approval mode is not set to workflow
            values.approvalMode === 'workflow' ? values.workflow : null,
        },
        options: {
          duplicateCheckByContent: true,
        },
      },
    });

    if (data?.documentRequestApprovalUnionResponse?.__typename === 'Document') {
      const { documentFile, id, isDuplicate } =
        data.documentRequestApprovalUnionResponse;

      const duplicateDetected = !wasDuplicate && isDuplicate;

      if (duplicateDetected) {
        warning(
          <>
            <Text>{t('inbox:duplicate.contentWarning')}</Text>
            <br />
            <Text>{t('inbox:duplicate.contentNextStep')}</Text>
          </>
        );

        return { status: 'error' };
      }

      track(
        TrackingEvents.INVOICE_REQUEST_BTN_CLICKED,
        invoiceApprovalAnalyticsEventProperties({ documentId, values })
      );

      const path = organizationSlug
        ? generatePath(
            `/:${AppRouteParams.organizationSlug}${Routes.APPROVALS}/:${DocumentProcessingRouteParams.documentId}`,
            { organizationSlug, documentId: id }
          )
        : undefined;

      success(
        <Text data-testid="document-approval-request-success-notification">
          <Trans
            i18nKey="document.requestApproval.message.requested"
            values={{ name: documentFile?.name }}
          >
            Approval for
            <Link
              href={path}
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                dismiss();
                if (path) navigate(path);
              }}
            >
              {{ name: documentFile?.name } as any}
            </Link>{' '}
            was requested.
          </Trans>
        </Text>
      );

      return { status: 'success' };
    }

    if (
      data?.documentRequestApprovalUnionResponse?.__typename ===
      'DocumentRequestApprovalErrors'
    ) {
      const submitErrors = getSubmitErrors(
        data.documentRequestApprovalUnionResponse
      );

      return { status: 'error', submitErrors };
    }

    error(t('common.genericErrorMessage'));

    return { status: 'error' };
  };

  return requestApproval;
};

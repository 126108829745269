import { useApprovalData } from '../hooks/useApprovalData';
import { Key } from 'react';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import {
  ApprovalFilterButton,
  FilterItems,
} from 'components/ApprovalFilterButton/ApprovalFilterButton';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const FilterButtonContainer = () => {
  const user = useCurrentUser();
  const { searchParams, setSearchParams } = useMutateSearchParams();
  const {
    allDocumentsCount,
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
    isLoading,
  } = useApprovalData();

  const counters = {
    allApprovals: allDocumentsCount,
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
  };

  const handleChange = (value: Key[]) => {
    if (value?.[0] === FilterItems.all) {
      searchParams.delete('approvers');
      searchParams.delete('requester');
    }

    if (value?.[0] === FilterItems.assignedToMe) {
      searchParams.delete('requester');
      searchParams.set('approvers', user?.id ?? '');
    }

    if (value?.[0] === FilterItems.requestedByMe) {
      searchParams.delete('approvers');
      searchParams.set('requester', user?.id ?? '');
    }

    setSearchParams(searchParams);
  };

  return (
    <ApprovalFilterButton
      counters={counters}
      isLoading={isLoading}
      onChange={handleChange}
    />
  );
};

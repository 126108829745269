import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { Switcher } from 'components/Switcher/Switcher';
import { EntitlementsModal } from 'containers/Entitlements/components/Modal/EntitlementsModal';
import { DocumentStatus } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useMemo } from 'react';
import { ProcessingFormContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormContainer';
import { StorageFormContainer } from 'views/Inbox/DocumentProcessing/components/Ecm/StorageFormContainer';
import { useDocumentProcessingSwitcher } from 'views/Inbox/DocumentProcessing/useDocumentProcessingSwitcher';
import { useDocumentTypeContext } from './DocumentTypeContext';

interface NewProps {
  document: ProcessedDocument;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  cycleDocument: () => void;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const New = ({
  cycleDocument,
  document,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: NewProps) => {
  const { showEcm } = useEcm();

  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const { isInvoiceDocumentType } = useDocumentTypeContext();

  const { formType, setFormType, switcherProps, switcherShown } =
    useDocumentProcessingSwitcher(document);

  const childrenDeprecated = useMemo(() => {
    return (
      <ProcessSidebar
        key={purchaseOrderId}
        documentId={document?.id}
        globalDocumentId={document?.globalDocumentId ?? undefined}
        documentStatus={DocumentStatus.New}
        switcher={switcherShown ? <Switcher {...switcherProps} /> : undefined}
      >
        {showEcm && formType === 'storage' ? (
          <StorageFormContainer
            documentId={document?.id ?? ''}
            isInvoice
            onDeleteDocument={cycleDocument}
            onStoreDocument={cycleDocument}
          />
        ) : (
          <ProcessingFormContainer
            documentId={document?.id ?? ''}
            onApprove={cycleDocument}
            onDeleteDocument={cycleDocument}
            onRequestApproval={cycleDocument}
            contactItem={contactItem}
            onGetContactItem={onGetContactItem}
          />
        )}
        {!showEcm && (
          <EntitlementsModal
            feature={FEATURE.ECM}
            isOpen={formType === 'storage'}
            onClose={() => setFormType('approval')}
            utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.STORAGE}
          />
        )}
      </ProcessSidebar>
    );
  }, [
    contactItem,
    cycleDocument,
    document?.globalDocumentId,
    document?.id,
    formType,
    onGetContactItem,
    purchaseOrderId,
    setFormType,
    showEcm,
    switcherProps,
    switcherShown,
  ]);

  const children = useMemo(() => {
    return (
      <ProcessSidebar
        key={purchaseOrderId}
        documentId={document?.id}
        globalDocumentId={document?.globalDocumentId ?? undefined}
        documentStatus={DocumentStatus.New}
      >
        {showEcm && !isInvoiceDocumentType ? (
          <StorageFormContainer
            documentId={document?.id ?? ''}
            isInvoice
            onDeleteDocument={cycleDocument}
            onStoreDocument={cycleDocument}
          />
        ) : (
          <ProcessingFormContainer
            documentId={document?.id ?? ''}
            onApprove={cycleDocument}
            onDeleteDocument={cycleDocument}
            onRequestApproval={cycleDocument}
            contactItem={contactItem}
            onGetContactItem={onGetContactItem}
          />
        )}
        {!showEcm && (
          <EntitlementsModal
            feature={FEATURE.ECM}
            isOpen={formType === 'storage'}
            onClose={() => setFormType('approval')}
            utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.STORAGE}
          />
        )}
      </ProcessSidebar>
    );
  }, [
    contactItem,
    cycleDocument,
    document?.globalDocumentId,
    document?.id,
    formType,
    isInvoiceDocumentType,
    onGetContactItem,
    purchaseOrderId,
    setFormType,
    showEcm,
  ]);

  return llmClassificationFF ? children : childrenDeprecated;
};

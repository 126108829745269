import {
  Button,
  Flex,
  Link,
  Text,
  defaultTheme,
} from '@candisio/design-system';
import { PromotionDetail } from 'components/NewPromotions/types';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionButton = motion(Button);

export const CTASection = ({
  promotionDetails,
}: {
  promotionDetails: PromotionDetail;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { ctaButton } = promotionDetails;

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="start"
      paddingTop="space24"
      gap="space16"
      borderRadius="medium"
      position="sticky"
      bottom="0"
      style={{
        background: 'linear-gradient(180deg, #F8F6F533 0%, #F8F6F5 20%)',
      }}
    >
      {ctaButton && (
        <MotionButton
          as="a"
          color="purple"
          target="_blank"
          rel="noopener noreferer"
          href={ctaButton.url}
          icon="arrowRight"
          iconPosition="right"
          whileHover={{ color: defaultTheme.colors.gray0 }}
        >
          {ctaButton?.text}
        </MotionButton>
      )}
      <Text color="gray500" width="80%">
        <Trans t={t} i18nKey="promotionDetailsModal.ctaMarketingLink.text">
          Learn more about our new offering
          <Link
            as="a"
            color="gray"
            target="_blank"
            rel="noopener noreferer"
            href={t('promotionDetailsModal.ctaMarketingLink.url')}
            style={{ textDecoration: 'underline' }}
          >
            here
          </Link>
        </Trans>
      </Text>
    </Flex>
  );
};

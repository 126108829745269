import { Grid, Icon, Spinner, Text } from '@candisio/design-system';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { WideButton } from 'components/WideButton';
import {
  DocumentStatus,
  DocumentType,
  EcmDocumentStatus,
  GetDocumentForDraftQuery,
  Money,
} from 'generated-types/graphql.types';
import { Attachment } from 'hooks/useAttachments/useAttachments';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import { useGlobalDocumentId } from 'hooks/useGlobalDocumentId';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawerState } from 'views/DocumentDetails/useDrawerState';
import { useInboxContext } from 'views/Inbox/Context';
import { useDisabledAttachmentButtonLabelText } from '../util/useDisabledAttachmentButtonLabelText';
import { ContactSupplierLink } from './ContactSupplier/ContactSupplierLink';
import { DocumentSummaryCardListContainer } from './DocumentSummaryCard';
import { DocumentSummaryInformationDeprecated } from './DocumentSummaryInformationDeprecated';
import { MainDocumentSummaryCard } from './MainDocumentSummaryCard';
import {
  RelatedDocument,
  useDocumentRelations,
} from './hooks/useDocumentRelations';

export const DocumentSummaryInformationAdapter = ({
  transactionSection,
}: {
  transactionSection?: ReactNode;
}) => {
  const {
    documentId,
    amount,
    invoiceDate,
    invoiceNumber,
    documentStatus,
    selectedContactEmail,
    contactName,
    isEInvoice,
    eInvoice,
    eInvoiceValidationErrors,
    isDocumentFileLoading: isLoading,
    mainDocumentFile: documentFile,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  const { showDocumentRelationsImproved } = useEcm();

  const DocumentSummaryInformationComponent = showDocumentRelationsImproved
    ? DocumentSummaryInformation
    : DocumentSummaryInformationDeprecated;

  return (
    <DocumentSummaryInformationComponent
      documentId={documentId}
      amount={amount}
      invoiceDate={invoiceDate}
      invoiceNumber={invoiceNumber}
      documentStatus={documentStatus}
      selectedContactEmail={selectedContactEmail}
      contactName={contactName}
      isEInvoice={isEInvoice}
      eInvoiceValidationErrors={eInvoiceValidationErrors}
      eInvoice={eInvoice}
      isLoading={isLoading}
      documentFile={documentFile}
      attachments={attachments}
      attachPermissions={attachPermissions}
      selectedPdf={selectedPdf}
      onAttachmentClick={setSelectedPdf}
      transactionSection={transactionSection}
    />
  );
};

export type DocumentSummaryInformationProps = {
  documentId: string;
  amount: Money | null;
  invoiceDate: string | undefined;
  invoiceNumber: string | undefined;
  /** E-Invoice flag */
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  /** E-Invoice validation errors */
  eInvoiceValidationErrors: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['eInvoiceValidationErrors'];
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  documentStatus: DocumentStatus | EcmDocumentStatus | undefined;
  selectedContactEmail?: string;
  contactName: string | undefined;
  isLoading: boolean;
  documentFile: PDFDetails;
  attachments: Attachment[];
  attachPermissions: CanAddAttachments;
  selectedPdf: PDFDetails;
  onAttachmentClick: (..._args: any) => void;
  transactionSection?: ReactNode;
};

export const DocumentSummaryInformation = ({
  documentId: id,
  amount,
  invoiceDate,
  invoiceNumber,
  isEInvoice,
  eInvoice,
  eInvoiceValidationErrors,
  documentStatus,
  selectedContactEmail,
  contactName,
  isLoading,
  attachments,
  transactionSection,
  attachPermissions,
}: DocumentSummaryInformationProps) => {
  const [t] = useTranslation();

  const {
    toggleDrawer: toggleAttachmentDrawer,
    isDrawerOpen: isAttachmentDrawerOpen,
  } = useDrawerState('ATTACHMENTS');
  const {
    toggleDrawer: toggleRelationsDrawer,
    isDrawerOpen: isRelationsDrawerOpen,
  } = useDrawerState('RELATIONSHIPS');

  const globalDocumentId = useGlobalDocumentId(id);

  const {
    isLoading: isLoadingRelations,
    hasError,
    relatedDocuments,
  } = useDocumentRelations(globalDocumentId);

  const document: RelatedDocument = {
    id: id,
    amount,
    name: contactName ?? null,
    isEInvoice,
    eInvoice,
    eInvoiceValidationErrors,
    number: invoiceNumber ?? null,
    status: documentStatus ?? null,
    date: invoiceDate ?? null,
    type: DocumentType.Invoice,
  };

  const attachmentsTitle = t('document.tabs.oldAttachments.title');
  const relationsTitle = t('document.tabs.relationships.title');

  const canShowRelationsCounter = !isLoadingRelations && !hasError;

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;
  const isAttachmentsSegmantDisabled = !canAttach && attachments.length === 0;

  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  return (
    <DocumentSummaryCardListContainer borderRadius="medium" background="gray0">
      <MainDocumentSummaryCard
        borderTopRadius="medium"
        isLoading={isLoading}
        document={document}
        action={
          <ContactSupplierLink
            selectedContactEmail={selectedContactEmail}
            eInvoiceErrorCount={eInvoiceValidationErrors?.length ?? 0}
            trackingEventLocation="document_summary_card"
            documentId={id}
          />
        }
      />
      <Grid padding="space12" paddingTop="0" gap="space8">
        {transactionSection}
        <WideButton
          onClick={toggleAttachmentDrawer}
          isPressed={isAttachmentDrawerOpen}
          disabled={isAttachmentsSegmantDisabled}
          label={disabledLabel}
          icon={
            <Icon
              icon="attachment"
              size="space18"
              style={{ marginTop: '-2px' }}
            />
          }
        >
          <Text>{attachmentsTitle}</Text>
          {isLoadingRelations && <Spinner right="-.25rem" />}
          {canShowRelationsCounter && (
            <Text
              fontSize="small"
              style={{ fontVariantNumeric: 'tabular-nums' }}
            >
              {attachments.length ?? 0}
            </Text>
          )}
        </WideButton>
        <WideButton
          onClick={toggleRelationsDrawer}
          isPressed={isRelationsDrawerOpen}
          icon={
            <Icon
              icon="linkOutlined"
              size="space18"
              style={{ marginTop: '-1px' }}
            />
          }
        >
          <Text>{relationsTitle}</Text>
          {isLoadingRelations && <Spinner right="-.25rem" />}
          {canShowRelationsCounter && (
            <Text
              fontSize="small"
              style={{ fontVariantNumeric: 'tabular-nums' }}
            >
              {relatedDocuments.length}
            </Text>
          )}
        </WideButton>
      </Grid>
    </DocumentSummaryCardListContainer>
  );
};

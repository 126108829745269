import { useFastApproveReimbursementCaseMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsErrorsQuery,
} from '../toolkit/queries';

export const useFastApproveReimbursementCase = () => {
  const [fastApproveMutation, { loading: isFastApprovePending, client }] =
    useFastApproveReimbursementCaseMutation();

  const fastApprove = useCallback(
    async (reimbursementId: string) => {
      const response = await fastApproveMutation({
        variables: { id: reimbursementId },
        onCompleted: () => {
          // Instead of refetching the 3 different queries with different params we evict the cache
          client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
        },
        refetchQueries: [
          'inboxReimbursementCasesCount',
          'archiveReimbursementCasesCount',
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsErrorsQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
        ],
      });

      const isReponseInvalid =
        !response.data?.fastApproveReimbursementCase.success;

      if (isReponseInvalid) {
        return {
          status: 'error',
        };
      }

      return {
        status: 'success',
      };
    },
    [fastApproveMutation, client]
  );

  return { fastApprove, isFastApprovePending };
};

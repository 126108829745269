import { Grid, Spinner, Tag as TagDS } from '@candisio/design-system';
import { Tag } from 'generated-types/graphql.types';
import { debounce, isEqual } from 'lodash';
import { AnimatePresence, AnimationProps, motion } from 'motion/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentTagsField, DocumentTagsFieldProps } from './DocumentTagsField';
import {
  DocumentTagsFormOutput,
  DocumentTagsFormValues,
} from './documentTagsFormSchema';

export const UPDATE_DOCUMENT_TAGS_ON_BLUR_DEBOUNCE_MS = 500;
export const UPDATE_DOCUMENT_TAGS_WHILE_FOCUSED_DEBOUNCE_MS = 3000;

export const MotionSpinner = motion(Spinner);
export const MotionGrid = motion(Grid);

export const statusIndicatorAnimationProps: AnimationProps = {
  initial: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 3,
      opacity: {
        duration: 3,
      },
    },
  },
};

export interface DocumentTagsFormProps {
  defaultValues: DocumentTagsFormValues;
  tags: Pick<Tag, 'id' | 'name' | 'description'>[];
  submitting: boolean;
  success: boolean;
  readOnly?: boolean;
  onChange: (tagIds: string[]) => Promise<string[]>;
}

export const DocumentTagsForm = ({
  defaultValues,
  submitting,
  tags,
  onChange,
  success,
  readOnly,
}: DocumentTagsFormProps) => {
  const [t] = useTranslation();

  const previousDefaultValues = useRef(defaultValues);
  const form = useForm<DocumentTagsFormOutput>({
    defaultValues: {
      tags: [],
      ...defaultValues,
    },
  });

  const { formState, watch, reset, setValue } = form;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!isEqual(defaultValues, previousDefaultValues.current)) {
      // couldn't find a better way to discard previous default values
      previousDefaultValues.current = defaultValues;
    }
  }, [defaultValues, reset]);

  const tagIds = watch('tags');
  const handleOnChange = useCallback(
    async (tagIds: string[]) => {
      if (!formState.isDirty) return;

      const newTagIds = await onChange(tagIds);
      reset({ tags: newTagIds });
      previousDefaultValues.current = { tags: newTagIds };
    },
    [formState.isDirty, onChange, reset]
  );

  const handleOnValuesChangeDelay = useMemo(() => {
    return debounce(
      handleOnChange,
      UPDATE_DOCUMENT_TAGS_WHILE_FOCUSED_DEBOUNCE_MS,
      { trailing: true }
    );
  }, [handleOnChange]);

  const handleOnFocusChangeDelay = useMemo(() => {
    return debounce(handleOnChange, UPDATE_DOCUMENT_TAGS_ON_BLUR_DEBOUNCE_MS, {
      trailing: true,
    });
  }, [handleOnChange]);

  useEffect(() => {
    handleOnFocusChangeDelay.cancel();
    void handleOnValuesChangeDelay(tagIds);
  }, [handleOnFocusChangeDelay, handleOnValuesChangeDelay, tagIds]);

  const defaultItems: DocumentTagsFieldProps<any>['defaultItems'] = useMemo(
    () =>
      tags.map(tag => ({
        id: tag.id,
        name: tag.name,
        description: tag.description ?? undefined,
      })),
    [tags]
  );

  return (
    <FormProvider {...form}>
      <DocumentTagsField
        name="tags"
        readOnly={readOnly}
        forceInputFieldRef={false}
        defaultItems={defaultItems}
        onFocusChange={isFocused => {
          if (!isFocused) {
            handleOnValuesChangeDelay.cancel();
            void handleOnFocusChangeDelay(tagIds);
          } else {
            handleOnFocusChangeDelay.cancel();
            void handleOnValuesChangeDelay(tagIds);
          }
        }}
        statusIndicator={
          <AnimatePresence>
            {submitting && (
              <MotionSpinner
                size="space12"
                {...statusIndicatorAnimationProps}
              />
            )}
            {success && (
              <MotionGrid
                alignItems="center"
                {...statusIndicatorAnimationProps}
              >
                <TagDS color="green" variant="secondary" icon="checkCircle">
                  {t('documentTags.field.success')}
                </TagDS>
              </MotionGrid>
            )}
          </AnimatePresence>
        }
        onTagCreated={tag => {
          setValue('tags', [...tagIds, tag.id], {
            // this triggers `handleOnChange` and
            // applies newly created tag
            shouldDirty: true,
          });
        }}
      />
    </FormProvider>
  );
};

import { Grid, Item, ListView, Text, useTheme } from '@candisio/design-system';
import { motion } from 'motion/react';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../providers/LocaleProvider';
import { NotificationSummaryFooter } from './NotifcationSummaryFooter';
import { NotificationListItem } from './NotificationListItem';
import { NotificationLoadingState } from './NotificationLoadingState';
import { NotificationSummaryHeader } from './NotificationSummaryHeader';
import { InAppNotificationData } from './NotificationToast';

export interface NotificationSummaryProps {
  hasMore?: boolean;
  totalCount: number;
  loading: boolean;
  showOnlyUnread: boolean;
  onLoadMore?: OnLoadMore;
  selectedOrganization: string;
  notifications: Array<InAppNotificationData>;
  onClose: () => void;
  onSelectionChange: (key: Key[]) => void;
  onShowOnlyUnread: (toogled: boolean) => void;
}

const MotionGrid = motion(Grid);

export const NotificationSummary = ({
  loading,
  totalCount,
  hasMore,
  notifications,
  showOnlyUnread,
  selectedOrganization,
  onShowOnlyUnread,
  onClose,
  onLoadMore,
  onSelectionChange,
}: NotificationSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { radii } = useTheme();

  const isLoading = loading && totalCount === 0;
  const noNotifications = !loading && totalCount === 0;

  return (
    <Grid
      templateRows="auto 1fr"
      height="100%"
      gap="space16"
      borderRadius="medium"
    >
      <NotificationSummaryHeader
        selectedOrganization={selectedOrganization}
        notifications={notifications}
        onSelectionChange={onSelectionChange}
      />

      {isLoading && <NotificationLoadingState />}

      {noNotifications ? (
        <Grid placeContent="center" padding="space32" paddingTop={0}>
          <Text fontSize="large" color="gray500">
            {t('popover.emptyState')}
          </Text>
        </Grid>
      ) : (
        <MotionGrid
          height="100%"
          // Key is needed in order to trigger the animation while switching tabs and orgs
          key={`${showOnlyUnread}-${selectedOrganization}`}
          paddingX="space16"
          paddingRight="0px"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.8, delay: 0.25 }}
        >
          <ListView
            style={{
              borderRadius: radii.medium,
            }}
            height="100%"
            onEndReached={hasMore ? onLoadMore : undefined}
            isVirtualized
          >
            {notifications.map((n, _i) => (
              <Item key={n.id} textValue={n.id}>
                <NotificationListItem
                  key={n?.id}
                  notification={n}
                  onClose={onClose}
                />
              </Item>
            ))}
          </ListView>
        </MotionGrid>
      )}
      <NotificationSummaryFooter
        isToogled={showOnlyUnread}
        onToogle={onShowOnlyUnread}
      />
    </Grid>
  );
};

import {
  AriaTabListProps,
  Flex,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';

interface NotificationTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

export const NotificationTabsPanel = (props: NotificationTabsProps) => {
  const state = useTabListState(props);

  return (
    <Flex direction="column" height="100%">
      <Tabs
        {...props}
        state={state}
        style={{
          padding: '0 16px',
          borderBottom: `1px solid ${colors.gray100}`,
        }}
        variant="opaque"
      />

      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        height="100%"
        flexGrow={1}
        overflow="hidden"
      />
    </Flex>
  );
};

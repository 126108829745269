import { Grid, Heading, InfoPanel, Link } from '@candisio/design-system';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import {
  ContactRelationshipType,
  ContactType,
} from 'generated-types/graphql.types';
import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { Alpha2Code } from 'i18n-iso-countries';
import { isNil, merge } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { getFullName } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/utils';
import { ContactFormCoreDataApi } from 'views/Contacts/ContactDetails/ContactForm/CoreDataApi/ContactFormCoreDataApi';
import { CheckAccountsNumber } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { getContactFieldValuesToSubmit } from 'views/Contacts/ContactDetails/ContactForm/getContactFieldValuesToSubmit';
import { PaymentConditionsFormOutput } from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm';
import { useShouldRequireAccountsPayableNumber } from 'views/utils/useShouldRequireField';
import { useDatev } from '../../../../orgConfig/datev';
import { ContactFormAddressInfoFields } from './ContactFormAddressInfoFields';
import { ContactFormBankInfoFields } from './ContactFormBankInfoFields';
import { contactFormErrorMessages } from './contactFormErrorMessages';

import { UserFieldItem } from 'hooks/useUsersFieldOptions';
import { ContactEmployeeFields } from './ContactEmployeeFields/ContactEmployeeFields';
import { ContactRelationshipTypeFields } from './ContactRelationshipTypeFields/ContactRelationshipTypeFields';
import { ContactTaxNumberField } from './ContactTaxNumberField';
import { ContactInternalNameField } from './ContactTypeFields/ContactInternalNameField';
import { ContactTypeFields } from './ContactTypeFields/ContactTypeFields';
import { PaymentConditionItemProps } from './PaymentConditionItem';
import { PaymentConditionSelectField } from './PaymentConditionSelectField';
import { PaymentMediumSelectField } from './PaymentMediumSelectField';
import { SapContactForm } from './SapContactForm';
import {
  ContactFormOutput,
  ContactFormSchemaOptions,
  ContactFormValues,
  contactFormSchema,
} from './contactFormSchema';
import { CheckContactName } from './contactNameSchema';
import { GetContactEmployeeTypeByMembershipId } from '../useGetContactEmployeeTypeByMembershipId';
import { ContactTypeRadioButtonField } from './ContactTypeFields/ContactTypeRadioButtonField';

export interface ContactFormProps {
  /** Contact form action */
  action?: 'create' | 'edit';
  /** Function to check if given contact name is available */
  checkContactName?: CheckContactName;
  /** Function to check if given accounts payable number is available */
  checkAccountsPayableNumber?: CheckAccountsNumber;
  /** Function to check if given accounts receivable number is available */
  checkAccountsReceivableNumber?: CheckAccountsNumber;
  /** Initial form values */
  defaultValues?: ContactFormValues;
  /** Unique identifier of the form */
  id?: string;
  /** Called when new payment condition should be created */
  onCreatePaymentCondition?: (
    values: PaymentConditionsFormOutput
  ) => Promise<string | undefined>;
  /** Called when the form is submitted */
  onSubmit: (values: ContactFormOutput) => void;
  /** Payment condition field items */
  paymentConditionItems?: PaymentConditionItemProps[];
  /** Payment condition field placeholder text */
  paymentConditionPlaceholder?: string;
  /** Accounts payable number to suggest to user */
  suggestedAccountsPayableNumber?: string;
  /** Whether to render the SAP contact form */
  shouldUseSapContacts?: boolean;
  /**Whether to render Core Data API contact form */
  shouldUseCoreDataApi?: boolean;
  /** Function that closes the form */
  onClose?: () => void;
  /** Whether the contact is active or not (archived) */
  isActive?: boolean;
  onGetTeamMemberByContactName?: (
    name: string | undefined
  ) => Promise<string | undefined>;
  fieldOptions?: {
    teamMembers: {
      items: UserFieldItem[];
      loading: boolean;
    };
  };
  /** Whether the show the employee contact type option */
  isEmployeeContactTypeHidden?: boolean;
  onGetContactEmployeeTypeByMembershipId?: GetContactEmployeeTypeByMembershipId;
}

const getFallbackDefaultValues = (
  action: 'create' | 'edit'
): ContactFormValues => ({
  createTransfer: false,
  contactType: ContactType.Company,
  relationshipType: ContactRelationshipType.Supplier,
  ...(action === 'create' && { country: 'DE' as Alpha2Code }),
});

/**
 * Form for creating/editing a contact
 *
 * Use the `id` prop to associate a submit button with the form:
 *
 * ```
 * const formId = useId();
 * ...
 * <ContactForm id={formId} ... />
 * <Button form={formId} type="submit">Save</Button>
 * ```
 *
 */
export const ContactForm = ({
  action = 'create',
  checkAccountsPayableNumber,
  checkAccountsReceivableNumber,
  checkContactName,
  onGetTeamMemberByContactName,
  onGetContactEmployeeTypeByMembershipId,
  defaultValues: defaultValuesProp,
  id,
  onCreatePaymentCondition,
  onSubmit,
  paymentConditionItems,
  paymentConditionPlaceholder,
  shouldUseSapContacts,
  shouldUseCoreDataApi,
  suggestedAccountsPayableNumber,
  onClose,
  isActive = true,
  fieldOptions,
  isEmployeeContactTypeHidden: isEmployeeContactTypeHiddenProp = false,
}: ContactFormProps) => {
  const { bdsConnected } = useDatev(); // BDS-checked

  const [t] = useTranslation();
  const integrationSetting = useIntegrationSettings();

  const defaultValues: ContactFormValues = merge(
    {},
    getFallbackDefaultValues(action),
    defaultValuesProp
  );

  const { data: datevSettings } = useDatevSettings();

  const shouldRequireAccountsPayableNumber =
    useShouldRequireAccountsPayableNumber();

  const glaLength = !isNil(
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength
  )
    ? Number(datevSettings?.new_datevSettings?.chartOfAccount?.accountLength)
    : undefined;

  const context: ContactFormSchemaOptions = {
    checkAccountsPayableNumber,
    checkAccountsReceivableNumber,
    shouldRequireAccountsPayableNumber,
    checkContactName,
    checkContactEmployeeTypeByMembershipId:
      onGetContactEmployeeTypeByMembershipId,
    integration: integrationSetting,
    glaLength,
  };

  const form = useForm<ContactFormOutput, ContactFormSchemaOptions>({
    context,
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver({
      zodSchema: contactFormSchema,
      errorMessages: contactFormErrorMessages,
    }),
  });

  const contactNamesAreEqual =
    defaultValues.name === defaultValues.companyName ||
    defaultValues.name ===
      getFullName(
        defaultValues.individualFirstName,
        defaultValues.individualLastName
      ) ||
    defaultValues.name === defaultValues.notSpecifiedName;

  const contactType = form.watch('contactType');

  const isEmployeeContactTypeHidden =
    action === 'create' || isEmployeeContactTypeHiddenProp;

  // TODO: use a switch statement
  if (shouldUseSapContacts) {
    return (
      <SapContactForm
        defaultValues={defaultValues}
        paymentConditionItems={paymentConditionItems}
        isActive={isActive}
      />
    );
  }

  // TODO: use a switch statement
  if (shouldUseCoreDataApi) {
    return (
      <ContactFormCoreDataApi
        defaultValues={defaultValues}
        paymentConditionItems={paymentConditionItems}
      />
    );
  }

  if (contactType === 'EMPLOYEE') {
    return (
      <FormProvider {...form}>
        <Grid
          as="form"
          id={id}
          onSubmit={form.handleSubmit(values => {
            const sanitizedFormValues = getContactFieldValuesToSubmit(values);

            return onSubmit(sanitizedFormValues);
          })}
          gap="space32"
        >
          <Grid gap="space8">
            <Heading as="h3">
              {t('settings.contacts.details.edit.commonHeader')}
            </Heading>
            <Grid
              gap="space16"
              background="gray100"
              padding="space24"
              borderRadius="large"
            >
              <ContactTypeRadioButtonField
                disabled={!isActive}
                isEmployeeContactTypeHidden={isEmployeeContactTypeHidden}
              />

              <ContactEmployeeFields
                disabled={!isActive}
                fieldOptions={fieldOptions}
                onGetTeamMemberByContactName={onGetTeamMemberByContactName}
                checkContactName={checkContactName}
                onGetContactEmployeeTypeByMembershipId={
                  onGetContactEmployeeTypeByMembershipId
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        id={id}
        onSubmit={form.handleSubmit(values => {
          const sanitizedFormValues = getContactFieldValuesToSubmit(values);

          return onSubmit(sanitizedFormValues);
        })}
        gap="space32"
      >
        <Grid gap="space8">
          <Heading as="h3">
            {t('settings.contacts.details.edit.commonHeader')}
          </Heading>
          <Grid
            gap="space32"
            background="gray100"
            padding="space24"
            borderRadius="large"
          >
            <Grid gap="space8">
              <ContactTypeFields
                disabled={!isActive}
                isEmployeeContactTypeHidden={isEmployeeContactTypeHidden}
              />
              <ContactInternalNameField showField={!contactNamesAreEqual} />
            </Grid>
            <ContactRelationshipTypeFields
              isCreatingNewContact={action === 'create'}
              suggestedAccountsPayableNumber={suggestedAccountsPayableNumber}
              disabled={!isActive}
            />
          </Grid>
        </Grid>
        <Grid gap="space8">
          <Heading as="h3">
            {t('settings.contacts.details.edit.paymentHeader')}
          </Heading>
          <Grid
            gap="space16"
            background="gray100"
            padding="space24"
            borderRadius="large"
          >
            <ContactFormBankInfoFields disabled={!isActive} />
            {bdsConnected ? (
              <PaymentMediumSelectField
                name="paymentMedium"
                label={t('settings.contacts.details.edit.paymentMedium.label')}
                disabled={!isActive}
              />
            ) : (
              <HookFormCheckboxField<ContactFormValues>
                name="createTransfer"
                label={t('settings.contacts.details.edit.createTransfer.label')}
                disabled={!isActive}
              />
            )}

            <PaymentConditionSelectField
              name="paymentCondition"
              label={t('settings.contacts.details.edit.paymentCondition.label')}
              onCreatePaymentCondition={onCreatePaymentCondition}
              paymentConditionItems={paymentConditionItems}
              placeholder={
                paymentConditionPlaceholder ??
                t('settings.contacts.details.edit.paymentCondition.placeholder')
              }
              disabled={!isActive}
            />
          </Grid>
        </Grid>
        <Grid gap="space8">
          <Heading as="h3">
            {t('settings.contacts.details.edit.taxHeader')}
          </Heading>

          <Grid
            gap="space16"
            background="gray100"
            padding="space24"
            borderRadius="large"
          >
            <HookFormTextField<ContactFormValues>
              name="vatId"
              label={t('settings.contacts.details.edit.vatId.label')}
              placeholder={t(
                'settings.contacts.details.edit.vatId.placeholder'
              )}
              disabled={!isActive}
            />

            <ContactTaxNumberField disabled={!isActive} />
          </Grid>
        </Grid>
        <Grid gap="space8">
          <Heading as="h3">
            {t('settings.contacts.details.edit.addressHeader')}
          </Heading>

          <Grid gap="space16">
            <Grid
              gap="space8"
              background="gray100"
              padding="space24"
              borderRadius="large"
            >
              <ContactFormAddressInfoFields disabled={!isActive} />
            </Grid>

            <Grid
              gap="space16"
              background="gray100"
              padding="space24"
              borderRadius="large"
            >
              <>
                <HookFormTextField<ContactFormValues>
                  name="email"
                  label={t('settings.contacts.details.edit.email.label')}
                  placeholder={t(
                    'settings.contacts.details.edit.email.placeholder'
                  )}
                  inputProps={{
                    type: 'email',
                    autoComplete: 'off',
                  }}
                  disabled={!isActive}
                />

                <HookFormTextField<ContactFormValues>
                  name="phoneNumber"
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  label={t('settings.contacts.details.edit.phoneNumber.label')}
                  disabled={!isActive}
                />
              </>
            </Grid>
          </Grid>
        </Grid>
        <InfoPanel
          variant="information"
          title={t(
            'settings.contacts.details.edit.newContactFieldsFeedbackPanel.title'
          )}
        >
          <Grid paddingTop="space4">
            <Link
              external
              id="new-contact-fields-feedback-link"
              onClick={onClose}
            >
              {t(
                'settings.contacts.details.edit.newContactFieldsFeedbackPanel.text'
              )}
            </Link>
          </Grid>
        </InfoPanel>
      </Grid>
    </FormProvider>
  );
};

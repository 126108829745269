import { Grid, Tag, Tooltip, useTooltip } from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { getTooltipText } from '../../utils';

const MotionTooltip = motion(Tooltip);

export const ProcessingTag = ({
  isOpen,
  card,
}: {
  isOpen: boolean;
  card: RecurringPayment['linkedCard']['card'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { tooltipProps, tooltipRef, triggerProps, triggerRef } = useTooltip({
    isOpen,
    passiveTrigger: true,
  });

  const tooltipText = getTooltipText({ t, card });

  return (
    <Grid {...triggerProps} ref={triggerRef}>
      <Tag color="gray" variant="secondary">
        {t('recurringPaymentList.processing')}
      </Tag>

      {isOpen && (
        <MotionTooltip
          width="320px"
          // We cast as any because some props from tooltip give type errors
          {...(tooltipProps as any)}
          ref={tooltipRef}
          transition={{ delay: 0.7 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {tooltipText}
        </MotionTooltip>
      )}
    </Grid>
  );
};

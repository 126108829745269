import { Flex, Text, TruncatedText } from '@candisio/design-system';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { IndicatorStatus } from 'views/AppContainer/components/Header/components/ProgressIndicator';

const mapStatusToColor = (status: any) => {
  switch (status) {
    case IndicatorStatus.ERROR:
      return 'red500';
    case IndicatorStatus.WARNING:
      return 'yellow500';
    case IndicatorStatus.DONE:
      return 'green500';
    default:
      return 'gray800';
  }
};

export interface StatusProps {
  status: IndicatorStatus;
  label: string;
  details?: string;
}

export const Status = ({ status, label, details }: StatusProps) => {
  return (
    <Flex gap="space4" alignItems="center" justifyContent="end">
      <TruncatedText color={mapStatusToColor(status)}>{label}</TruncatedText>
      {details && (
        <InfoIcon
          flex="none"
          closeDelay={500}
          message={<Text whiteSpace="pre-line">{details}</Text>}
        />
      )}
    </Flex>
  );
};

import { Box, Flex, Tabs } from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { useCallback, useState } from 'react';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsList } from './GoodsReceiptList';
import { LinkableSearchField } from './LinkableSearchField';
import { LinkingFooterTarget } from './LinkingFooter';
import { PurchaseOrderList } from './PurchaseOrderList';
import { tableTabs } from './consts';

// TODO: TPX-1284 Modal title

type LinkingTableProps = {
  documentId: string;
  documentAmount?: number | null;
  contactName?: string;
  accountsPayableNumber?: string | null;
  disabled?: boolean;
  closeModal: () => void;
};
export const LinkingTable = ({
  documentId,
  documentAmount,
  contactName,
  accountsPayableNumber,
  closeModal,
}: LinkingTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const [activeTab, setActiveTab] = useState(tableTabs.GoodsReceipts);
  const [searchValue, setSearchValue] = useState('');

  const { tabsProps } = useTabs({
    items: [
      {
        key: tableTabs.GoodsReceipts,
        title: t('addPurchaseOrderModal.tabs.goodsReceipts'),
      },
      {
        key: tableTabs.PurchaseOrders,
        title: t('addPurchaseOrderModal.tabs.purchaseOrders'),
      },
    ],
    onSelectionChange: key => {
      setActiveTab(key as tableTabs);
    },
    selectedKey: activeTab,
  });

  const handleResetSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <Box height="100%" overflow="hidden">
      <Flex
        direction="column"
        height="100%"
        overflow="hidden"
        paddingTop="space16"
        paddingBottom="space32"
        paddingX="space32"
      >
        <Flex paddingBottom="space2" paddingLeft="space2">
          <Tabs {...tabsProps} />
        </Flex>
        <Flex
          borderTopRadius="basic"
          justifyContent="space-between"
          borderBottom="1px solid gray200"
          background="white"
          padding="space12"
        >
          <LinkableSearchField
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            linkType={activeTab}
            contactName={contactName}
          />

          <SyncFromSap
            type={
              activeTab === 'goodsReceipts'
                ? SAP_SYNC.GoodsReceipts
                : SAP_SYNC.PurchaseOrdersWithFilters
            }
            accountsPayableNumber={accountsPayableNumber ?? undefined}
          />
        </Flex>
        {activeTab === tableTabs.GoodsReceipts ? (
          <GoodsReceiptsList
            accountsPayableNumber={accountsPayableNumber}
            searchValue={searchValue}
            onResetSearch={handleResetSearch}
          />
        ) : (
          <PurchaseOrderList
            documentId={documentId}
            documentAmount={documentAmount}
            closeModal={closeModal}
            accountsPayableNumber={accountsPayableNumber}
            searchValue={searchValue}
            onResetSearch={handleResetSearch}
          />
        )}
      </Flex>
      <LinkingFooterTarget />
    </Box>
  );
};

import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { queryParameter } from 'components/Table/consts';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import {
  TransactionsTableData,
  ViewUsingTransactions,
} from 'components/Transactions/Table/types';
import { useCardIssuerTransactionsData } from 'components/Transactions/Table/useCardIssuerTransactionsData';
import {
  availableFilters,
  transactionToTableData,
} from 'components/Transactions/Table/util';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCallback, useMemo } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useParams } from 'react-router-dom';

import { Filters, SortingRule } from 'react-table';
import { appendParamsToQueryString } from 'utils/url-helper';
import { AutoMatchBannerContainer } from './AutoMatchBannerContainer';
import { InboxTransactionsEmptyState } from './InboxTransactionsEmptyState';
import { useGetInboxTransactionsTableConfigs } from './hooks/useGetInboxTransactionsTableConfigs';
import { useAutoMatchCountData } from './useAutoMatchCountData';
import {
  TRANSACTION_FILTER,
  useTransactionListFilters,
} from './useTransactionListFilters';

export const TransactionsMainContent = () => {
  const history = useHistory();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const { search } = history.location;

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const [creditCardsAutomatedMatchingFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsAutomatedMatching,
  ]);

  const { loading, autoMatchCount } = useAutoMatchCountData();

  const { filterOptions, isFilterLoading } = useTransactionListFilters({
    filters: [
      TRANSACTION_FILTER.status_inbox,
      TRANSACTION_FILTER.cardholderName,
      TRANSACTION_FILTER.type,
      TRANSACTION_FILTER.cardRefNum,
      TRANSACTION_FILTER.category,
    ],
    fetchCardholdersWithMissingInvoices: true,
  });

  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<TransactionsTableData>({
      availableFilters,
    });

  const {
    transactionList,
    isLoadingTransactionList,
    onLoadMore,
    isTableFiltered,
    isTableEmpty,
    handleDebounceSearch,
  } = useCardIssuerTransactionsData({
    routeType: ViewUsingTransactions.INBOX,
    filters,
    sortBy,
  });

  const {
    availableTransactionColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetInboxTransactionsTableConfigs({ filters, sortBy });

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <InboxTransactionsEmptyState
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<TransactionsTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<TransactionsTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const handleSearch = (search: string) => {
    handleDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const getPath = useCallback(
    ({ id, cursor }: TransactionsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? '',
      });

      return `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/${id}?${searchWithCursor}`;
    },
    [organizationSlug, search]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <Flex direction="column" height="100%" overflow="hidden">
      {creditCardsAutomatedMatchingFF && (
        <AutoMatchBannerContainer
          loading={loading}
          autoMatchCount={autoMatchCount}
        />
      )}
      <TransactionsTable
        isLoading={
          isLoadingTransactionList || isSavingConfigurations || isResetPending
        }
        columns={availableTransactionColumnIds}
        onEndReached={onLoadMore}
        onFilter={handleFilter}
        onSort={handleSort}
        onSearch={handleSearch}
        onResetTableConfigurations={handleResetTableConfigurations}
        search={queryStringFilter}
        customEmptyState={emptyState}
        data={transactionToTableData(transactionList ?? [])}
        onUpdateConfigurations={handleUpdateConfigurations}
        configurations={configurationsTable}
        filterOptions={filterOptions}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        borderTopRadius="none"
        isFilterLoading={isFilterLoading}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </Flex>
  );
};

import {
  Button,
  Card,
  Grid,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useArchiveReimbursement } from 'views/Reimbursement/hooks/useArchiveReimbursement';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ArchiveAction } from './ArchiveAction';
import { AutoSaveStatusContainer } from './AutoSaveStatusContainer';
import { ErrorSection } from './ErrorSection';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

export interface CheckFooterActionBoxProps {
  isSubmittingForReview: boolean;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
  }>;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
}

export const CheckFooterActionBox = ({
  isSubmittingForReview,
  reimbursement,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
}: CheckFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error, success } = useToastMessage();

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const {
    triggerAllValidations,
    lastModifiedExpense,
    hasReimbursementErrors,
    setHasReimbursementErrors,
  } = useReimbursementFormsContext();

  const { archivePending, archiveReimbursement } = useArchiveReimbursement();

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });

  const errMessage = t('reimbursementView.createErr');
  const successMessage = t(
    'reimbursementView.rightSection.footerActions.doubleConfirm.archiveSuccessToast'
  );

  const handleArchive = async () => {
    const status = await archiveReimbursement(reimbursementId);
    if (status === 'error') {
      error(errMessage);

      return;
    }

    success(successMessage);
    onCycleReimbursments();
  };

  const handleClick = async () => {
    const lastModifiedExpenseData =
      lastModifiedExpense !== null
        ? getExpenses('expenses')[lastModifiedExpense]
        : undefined;

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'general'
    ) {
      await updateGeneralExpense(lastModifiedExpenseData);
    }

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'hospitality'
    ) {
      await updateHospitalityExpense(lastModifiedExpenseData);
    }

    const response = await onSubmitForReview(reimbursementId);
    if (response.status === 'error') {
      triggerAllValidations();
      setHasReimbursementErrors(true);

      return;
    }

    successMessageWithDeepLink({
      key: 'reimbursementView.rightSection.footerActions.submitSuccess',
    });
    setHasReimbursementErrors(false);
    onCycleReimbursments();
  };

  const isReimbursementItemUpdatePending =
    isUpdateHospitalityExpensePending || isUpdateGeneralExpensePending;

  const isSubmitPending =
    isSubmittingForReview || isReimbursementItemUpdatePending;

  const isErrorSectionVisible = hasReimbursementErrors;

  return (
    <Card corners="top" boxShadow="elevatedShadow4" padding="space20">
      <Grid gap="space8">
        <Grid columns={2}>
          <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
            {t('reimbursementView.rightSection.footerActions.title')}
          </Text>
          <AutoSaveStatusContainer
            isSavePending={isReimbursementItemUpdatePending}
            reimbursement={reimbursement}
            reimbursementItems={reimbursementItems}
            refetchReimbursement={refetchReimbursement}
            refetchReimbursementItems={refetchReimbursementItems}
          />
        </Grid>
        {isErrorSectionVisible && <ErrorSection />}

        <Grid gap="space8">
          <Button
            color="blue"
            onClick={handleClick}
            disabled={isSubmitPending || archivePending}
            loading={isSubmitPending}
          >
            <TruncatedText>
              {t('reimbursementView.rightSection.footerActions.submitCta')}
            </TruncatedText>
          </Button>
          <ArchiveAction
            loading={archivePending}
            onArchive={handleArchive}
            disabled={isSubmitPending || archivePending}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

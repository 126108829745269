import { noop } from 'lodash';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
} from 'react';
import {
  UIConfigProps,
  defaultUIConfig,
} from '../hooks/useReimbursementUIConfig';
import { ReimbursementFormValues } from '../toolkit/reimbursementFormSchema';
import { ApprovalType } from '../hooks/useInitialReimbursementFormValues';

export interface FormRegistration {
  trigger?: () => void;
}

export type ApprovalMode = 'requestApproval' | 'approve';

export type ApprovalFieldData = Pick<
  ReimbursementFormValues,
  'approvalType' | 'approvers' | 'workflow'
>;

export const initialApprovalData = {
  approvalType: ApprovalType.APPROVERS,
  approvers: [],
  workflow: undefined,
};
interface ReimbursementFormsContextProps {
  lastModifiedExpense: null | number;
  registerForm: (formKey: string, registration: FormRegistration) => void;
  triggerAllValidations: () => void;
  reimbursementUIConfig: UIConfigProps;
  setReimbursementUIConfig: Dispatch<SetStateAction<UIConfigProps>>;
  updateLastModifiedExpense: (expenseFormIndex: number) => void;
  visibleItemId: null | string;
  setVisibleItemId: (id: string | null) => void;
  itemsContainerRef: RefObject<HTMLDivElement>;
  itemRefs: RefObject<{ [key: string]: HTMLElement | null }>;
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  isReimbursementUpdatePending: boolean;
  handleIsReimbursementUpdatePending: (val: boolean) => void;
  hasReimbursementErrors: boolean;
  setHasReimbursementErrors: (val: boolean) => void;
  approvalMode: ApprovalMode;
  setApprovalMode: (approval: ApprovalMode) => void;
  approvalData: ApprovalFieldData;
  setApprovalData: (values: ApprovalFieldData) => void;
}

export const ReimbursementFormsContext =
  createContext<ReimbursementFormsContextProps>({
    lastModifiedExpense: null,
    registerForm: noop,
    triggerAllValidations: noop,
    reimbursementUIConfig: defaultUIConfig,
    setReimbursementUIConfig: noop,
    updateLastModifiedExpense: noop,
    visibleItemId: null,
    setVisibleItemId: noop,
    itemsContainerRef: { current: null },
    itemRefs: { current: {} },
    selectedItemId: null,
    setSelectedItemId: noop,
    isReimbursementUpdatePending: false,
    handleIsReimbursementUpdatePending: noop,
    hasReimbursementErrors: false,
    setHasReimbursementErrors: noop,
    approvalMode: 'requestApproval',
    setApprovalMode: noop,
    approvalData: initialApprovalData,
    setApprovalData: noop,
  });

export const useReimbursementFormsContext = () => {
  const context = useContext(ReimbursementFormsContext);
  if (!context) {
    throw new Error(
      'useReimbursementFormsContext must be used within its ReimbursementFormsProvider'
    );
  }

  return context;
};

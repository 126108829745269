import {
  DocumentType,
  EcmDocumentTypeSettingRecord,
  EcmDocumentTypeSettingsSortOrder,
  useEcmDocumentTypeSettingsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { DEFAULT_SORT_BY } from './consts';

const ECM_DOCUMENT_TYPES: EcmDocumentTypeSettingRecord[] = [
  {
    name: DocumentType.Offer,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.OrderConfirmation,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.PurchaseOrder,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.DeliveryNote,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.Invoice,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.Contract,
    isNonEcmType: false,
    isVisible: true,
  },
  {
    name: DocumentType.Other,
    isNonEcmType: false,
    isVisible: true,
  },
];

const ECM_DOCUMENT_TYPES_WITH_REIMBURSEMENT_ITEMS_TYPES: EcmDocumentTypeSettingRecord[] =
  [
    {
      name: DocumentType.Offer,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.OrderConfirmation,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.ExpenseReimbursementItem,
      isNonEcmType: true,
      isVisible: false,
    },
    {
      name: DocumentType.TravelReimbursementItem,
      isNonEcmType: true,
      isVisible: false,
    },
    {
      name: DocumentType.PurchaseOrder,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.DeliveryNote,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.Invoice,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.Contract,
      isNonEcmType: false,
      isVisible: true,
    },
    {
      name: DocumentType.Other,
      isNonEcmType: false,
      isVisible: true,
    },
  ];

export function useDocumentTypesData(
  params: { isVisible?: boolean; includeNonEcmTypes?: boolean } = {}
) {
  const [documentTypesSettingFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.documentTypesSetting,
  ]);

  const response = useEcmDocumentTypeSettingsQuery({
    variables: {
      input: {
        sort: {
          [DEFAULT_SORT_BY]: EcmDocumentTypeSettingsSortOrder.Desc,
        },
        isVisible: params.isVisible,
        includeNonEcmTypes: params.includeNonEcmTypes,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !documentTypesSettingFF,
  });

  if (documentTypesSettingFF) return response;
  return {
    data: {
      ecmDocumentTypeSettings: params.includeNonEcmTypes
        ? ECM_DOCUMENT_TYPES_WITH_REIMBURSEMENT_ITEMS_TYPES
        : ECM_DOCUMENT_TYPES,
    },
    loading: false,
  };
}

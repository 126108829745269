import { IntegrationName } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';

type UseSapResult = {
  loading: boolean;
  shouldUseSapContacts: boolean;
  shouldUseSapPurchaseOrder: boolean;
  isActiveIntegration: boolean;
  isActive: boolean;
  shouldUseSapNetAmount: boolean;
  shouldUseSapProjectCodes: boolean;
  shouldUseSapGoodsReceipts: boolean;
  shouldUseSapProjectCodeImportHistory: boolean; // WIP FF
};

export const useSap = (): UseSapResult => {
  const [
    sapContactsFF,
    sapPurchaseOrderFF,
    sapNetAmountFF,
    sapProjectCodesFF,
    sapGoodsReceiptsFF,
    wipSapProjectCodeImportHistoryFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.sapContacts,
    FEATURE_FLAGS.sapPurchaseOrder,
    FEATURE_FLAGS.sapNetAmounts,
    FEATURE_FLAGS.sapProjectCodes,
    FEATURE_FLAGS.sapGoodsRceipts,
    FEATURE_FLAGS.wipSapProjectCodesImportHistory,
  ]);

  const organization = useFullOrganization();
  const { ft, show } = useFeatureToggle();

  if (
    organization?.integrationName !== IntegrationName.Sap ||
    !show(ft.INTEGRATION_SAP)
  ) {
    return {
      loading: false,
      shouldUseSapContacts: false,
      shouldUseSapPurchaseOrder: false,
      isActive: false,
      isActiveIntegration: false,
      shouldUseSapNetAmount: false,
      shouldUseSapProjectCodes: false,
      shouldUseSapGoodsReceipts: false,
      shouldUseSapProjectCodeImportHistory: false,
    };
  }

  return {
    loading: false,
    isActive: sapContactsFF,
    isActiveIntegration: true,
    shouldUseSapContacts: sapContactsFF,
    shouldUseSapPurchaseOrder: sapPurchaseOrderFF,
    shouldUseSapNetAmount: sapNetAmountFF,
    shouldUseSapProjectCodes: sapProjectCodesFF,
    shouldUseSapGoodsReceipts: sapPurchaseOrderFF && sapGoodsReceiptsFF,
    shouldUseSapProjectCodeImportHistory:
      sapProjectCodesFF && wipSapProjectCodeImportHistoryFF,
  };
};

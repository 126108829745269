import { Box, Flex, Modal, ModalProps, Spinner } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { useTranslation } from 'react-i18next';
import { ContactSupplierActionCard } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/ContactSupplierActionCard';
import { ContactSupplierForm } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/ContactSupplierForm';
import { useContactSupplier } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/useContactSupplier';

type ContactSupplierFormContainerProps = {
  onCloseModal: () => void;
  documentId: string;
  modalProps: ModalProps;
  selectedContactEmail?: string;
};

const formId = 'contact-supplier-form';

export const ContactSupplierFormContainer = ({
  documentId,
  onCloseModal,
  selectedContactEmail,
  modalProps,
}: ContactSupplierFormContainerProps) => {
  const [t] = useTranslation();

  const {
    mainDocumentFile,
    onSubmit,
    attachments,
    emailText,
    subjectText,
    isDataLoading,
    isFormSubmitting,
    defaultContactEmail,
  } = useContactSupplier({ documentId, onCloseModal, selectedContactEmail });

  return (
    <Modal
      background="gray200"
      padding="0"
      scrollDirection="none"
      overflow="hidden"
      height="90vh"
      width="80vw"
      closeLabel={t('common.close')}
      title={t('contactSupplierFromCandis.title')}
      {...modalProps}
    >
      {isDataLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="space32" />
        </Flex>
      ) : (
        <Flex direction="column" overflow="hidden" height="100%">
          <Flex
            flexGrow="1"
            gap="space16"
            overflow="hidden"
            paddingTop="space8"
            padding="space36"
            paddingBottom="0"
          >
            <Box width="100%" height="100%" maxWidth="100%">
              <DocumentViewer
                documentId={documentId}
                documentFile={mainDocumentFile}
                attachments={attachments}
                hideAttachmentSection
              />
            </Box>
            <ContactSupplierForm
              formId={formId}
              onSubmit={onSubmit}
              defaultEmailText={emailText}
              defaultSubjectText={subjectText}
              defaultEmailTo={defaultContactEmail}
            />
          </Flex>
          <ContactSupplierActionCard
            formId={formId}
            disabled={isFormSubmitting || isDataLoading}
            documentId={documentId}
          />
        </Flex>
      )}
    </Modal>
  );
};

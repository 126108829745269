/**
 * Error details for project code import history item
 */
import { useMemo } from 'react';
import { Column } from 'react-table';
import {
  Cell,
  Table,
  HeaderProps,
  TruncatedText,
  Flex,
  Grid,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ImportHistoryDetailsData } from '../types';
import { useGetProjectCodeImportItemError } from './useGetProjectCodeImportItemError';
import { ProjectCodeImportHistoryRouteParams as RouteParams } from '../types';
import { useParams } from 'react-router-dom-v5-compat';
import { ProjectCodeImportHistoryItem } from '../ProjectCodeImportHistoryItem';
import { historyItems } from '../mocks';

// TODO: TPX-1315 talk with team should BE store parent data in error details or FE should use store/context
// TODO: TPX-1315 after schema update use real query data
const parentItem = historyItems[0];

const getTranslationKey = (columnId: string): string => {
  const prefix = 'projectCodes.importHistory.errorTable.columns.';

  return prefix.concat(columnId);
};

const TableHeader = ({ column }: HeaderProps<ImportHistoryDetailsData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <TruncatedText wordBreak="break-all" color="gray500">
      {t(getTranslationKey(column.id))}
    </TruncatedText>
  );
};

export const ProjectCodeImportHistoryErrorTable = () => {
  const params = useParams<RouteParams>();

  const defaultColumn = useMemo(
    (): Partial<Column<ImportHistoryDetailsData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: TableHeader,
      Cell,
      disableFilters: true,
      disableSortBy: true,
    }),
    []
  );

  const columns = useMemo(
    (): Array<Column<ImportHistoryDetailsData>> => [
      {
        accessor: 'code',
      },
      {
        accessor: 'name',
      },
      {
        accessor: 'error',
      },
      {
        accessor: 'sapDocEntryId',
      },
    ],
    []
  );
  const getProjectCodeImportError = useGetProjectCodeImportItemError();

  const tableData = useMemo(
    () => getProjectCodeImportError(params?.id),
    [getProjectCodeImportError, params]
  );

  return (
    <Flex
      direction="column"
      borderTopRadius="basic"
      background="gray100"
      padding={0}
    >
      <Grid padding="space12 space20" alignItems="center">
        <ProjectCodeImportHistoryItem showStatus={false} {...parentItem} />
      </Grid>

      <Table<ImportHistoryDetailsData>
        height="100%"
        borderBottomRadius="medium"
        borderTopRadius="none"
        defaultColumn={defaultColumn}
        columns={columns}
        data={tableData}
        isLoading={false}
      />
    </Flex>
  );
};

import { Flex } from '@candisio/design-system';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { SearchOverlayUpsell } from './SearchFilterUpsell';

interface AdvancedSearchFieldFilterOverlayPromoProps {
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlayPromo({
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayPromoProps) {
  const handleOnFocus = () => {
    onShowPromoModal();
  };

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect onFocus={handleOnFocus} disabled={true} />
        <SearchScopeSelect onFocus={handleOnFocus} disabled={true} />
      </Flex>
      <SearchOverlayUpsell openModal={onShowPromoModal} />
    </Flex>
  );
}

import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import {
  CostCenterTypes,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';
import { ReimbursementFormFieldOptions } from './types';
import { Reimbursement } from './useFormattedReimbursement';
import {
  useApproversFieldOptions,
  useWorkFlowsFieldOptions,
} from 'views/CreditCards/hooks/AccountingInfoFormFieldData';
import { useFullOrganization } from 'providers/OrganizationProvider';

const editableStatuses = [
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Check,
];

export const useReimbursementFormFieldOptions = ({
  reimbursement,
}: {
  reimbursement?: Reimbursement;
}): ReimbursementFormFieldOptions => {
  const currentUser = useCurrentUser();
  const { hasActiveWorkflowTemplates } = useFullOrganization() ?? {};

  const [employeeContactTypeFF, expenseApprovalWorkflowFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.employeeContactType,
      FEATURE_FLAGS.expenseApprovalWorkflow,
    ]);
  const showCostCenterField = useShowCostCenterField();
  const costCenter = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
  });

  const showCostObjectField = useShowCostObjectField();

  const costObjects = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
  });

  const workflow = useWorkFlowsFieldOptions();
  const approvers = useApproversFieldOptions();

  const isOwner = reimbursement?.targetMembership?.id === currentUser?.id;

  const isReimbursementEditable =
    reimbursement?.status && editableStatuses.includes(reimbursement?.status);

  const isAddIbanButtonVisible = isOwner && isReimbursementEditable;

  const isEditIbanButtonVisible =
    isOwner && isReimbursementEditable && Boolean(reimbursement.iban);

  const showApproversOrWorkflows =
    expenseApprovalWorkflowFF &&
    reimbursement?.status === ReimbursementCaseStatus.Review;

  const showWorkflows =
    (showApproversOrWorkflows && hasActiveWorkflowTemplates) ?? false;

  const showApprovers = showApproversOrWorkflows;

  const { loading: isLoadingTargetMembers, users: targetMemberOptions } =
    useUsersFieldOptions({
      status: 'ActiveAndPending',
    });

  const fieldOptions: ReimbursementFormFieldOptions = {
    suggestedCostCenter: {
      isVisible:
        showCostCenterField &&
        reimbursement?.status === ReimbursementCaseStatus.Draft,
      options: costCenter?.props,
    },
    targetMembership: {
      isVisible: true,
      options: {
        defaultItems: targetMemberOptions,
        isLoading: isLoadingTargetMembers,
      },
    },
    suggestedCostObject: {
      isVisible:
        showCostObjectField &&
        reimbursement?.status === ReimbursementCaseStatus.Draft,
      options: costObjects?.props,
    },
    accountsPayableNumber: {
      isReadOnly: false,
      isVisible:
        reimbursement?.status !== ReimbursementCaseStatus.Draft &&
        reimbursement?.status !== ReimbursementCaseStatus.Check &&
        employeeContactTypeFF
          ? true
          : false,
    },
    iban: {
      isAddButtonVisible: isAddIbanButtonVisible ?? false,
      isEditButtonVisible: isEditIbanButtonVisible ?? false,
    },
    approvers: {
      isVisible: showApprovers,
      options: approvers,
    },
    workflow: { isVisible: showWorkflows, options: workflow },
  };

  return fieldOptions;
};

import { Link } from '@candisio/design-system';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { queryParams } from 'components/Transactions/Table/util';
import {
  DocumentStatus,
  InvoiceAssociationStatus,
  useAddFileUploadMutation,
  useDocumentFileUploadUrlMutation,
  useGetCardIssuerTransactionByIdQuery,
  useProcessDocumentMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';
import { useAttachments } from 'hooks/useAttachments/useAttachments';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import {
  MAX_FILE_SIZE_MB,
  fileUploadUtil,
} from 'views/AppContainer/components/Header/components/DocumentUploadModal/fileUploadUtil';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';
import { getRefetchCardIssuerTransaction } from './gql';
import {
  TransactionAssociationView,
  usePrefetchQueries,
  useTransactionAssociationNavigation,
} from './utils';

const ExportedStatuses = [
  DocumentStatus.Exported,
  DocumentStatus.Exporting,
] as const;

interface RouteParams {
  transactionId: string;
  organizationSlug: string;
  type: TransactionAssociationView;
}

interface TransactionAssociationContextResult {
  transactionId: any;
  handleBackToList: any;
  prevTransactionLink: any;
  prevTransactionPage: any;
  nextTransactionLink: any;
  nextTransactionPage: any;
  transactionListCount: any;
  isLoadingNavigationData: any;
  isLoadingCurrentCardTransaction: any;
  document: any;
  documentId: any;
  linkedDocumentStatus: any;
  attachmentListItems: any;
  selectedPdf: any;
  mainDocumentFile: any;
  attachments: any;
  attachPermissions: any;
  ownerTxMembershipId: any;
  isSectionLoading: any;
  setSelectedPdf: any;
  canUserLinkInvoice: any;
  handleUploaderChange: any;
  cycleTransactions: any;
  transactionAmount: any;
  isNoInvoiceNeededToggleActive: any;
  setDocumentsNoTxModalIsOpen: any;
  routeType: any;
  organizationSlug: any;
  isArchivedTx: any;
  onToggleEditDocumentMode: any;
  showAllExtraFeatures: any;
  transaction: any;
  isTransactionExported: any;
  closeModal: any;
  documentsNoTxModalIsOpen: any;
}

interface TransactionAssociationProviderProps {
  children: ReactNode;
}

const initialState: TransactionAssociationContextResult = {
  transactionId: undefined,
  handleBackToList: undefined,
  prevTransactionLink: undefined,
  prevTransactionPage: undefined,
  nextTransactionLink: undefined,
  nextTransactionPage: undefined,
  transactionListCount: undefined,
  isLoadingNavigationData: undefined,
  isLoadingCurrentCardTransaction: undefined,
  document: undefined,
  documentId: undefined,
  linkedDocumentStatus: undefined,
  attachmentListItems: undefined,
  selectedPdf: undefined,
  mainDocumentFile: undefined,
  attachments: undefined,
  attachPermissions: undefined,
  ownerTxMembershipId: undefined,
  isSectionLoading: undefined,
  setSelectedPdf: undefined,
  canUserLinkInvoice: undefined,
  handleUploaderChange: undefined,
  cycleTransactions: undefined,
  transactionAmount: undefined,
  isNoInvoiceNeededToggleActive: undefined,
  setDocumentsNoTxModalIsOpen: undefined,
  routeType: undefined,
  organizationSlug: undefined,
  isArchivedTx: undefined,
  onToggleEditDocumentMode: undefined,
  showAllExtraFeatures: undefined,
  transaction: undefined,
  isTransactionExported: undefined,
  closeModal: undefined,
  documentsNoTxModalIsOpen: undefined,
};

export const TransactionAssociationContext =
  createContext<TransactionAssociationContextResult>(initialState);

export const TransactionAssociationProvider = ({
  children,
}: TransactionAssociationProviderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();

  const isXmlDocumentUploadAllowed = true;

  const {
    transactionId,
    organizationSlug,
    type: routeType,
  } = useParams<RouteParams>();

  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const [isDocumentChanging, setIsDocumentChanging] = useState<boolean>(false);

  const isArchivedTx = searchParams.get('isArchivedTx') !== null;
  const [editRequestedDocumentMode, setEditRequestedDocumentMode] = useState(
    isArchivedTx ?? false
  );

  const route =
    editRequestedDocumentMode && isArchivedTx ? Routes.ARCHIVE : routeType;

  const [addFile] = useAddFileUploadMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const [documentsNoTxModalIsOpen, setDocumentsNoTxModalIsOpen] =
    useState(false);

  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();

  const [processDocument, { loading: isProcessDocumentLoading }] =
    useProcessDocumentMutation({
      awaitRefetchQueries: true,
      onCompleted: () => {
        setIsDocumentChanging(currentState => !currentState);
      },
    });

  const counterQueries = useCounterQueries();

  const cursor = searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR);
  const transactionListView = generatePath(
    `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
    { organizationSlug }
  );

  const {
    nextTransactionLink,
    prevTransactionLink,
    nextTransactionId,
    prevTransactionId,
    isLoadingNavigationData,
    transactionListCount,
    linkBackToList,
  } = useTransactionAssociationNavigation({
    cursor,
    organizationSlug,
    routeType: route,
  });

  usePrefetchQueries(prevTransactionId, nextTransactionId);

  const nextTransactionPage = useCallback(() => {
    if (nextTransactionLink) navigate(nextTransactionLink);
  }, [navigate, nextTransactionLink]);

  const prevTransactionPage = useCallback(() => {
    if (prevTransactionLink) navigate(prevTransactionLink);
  }, [navigate, prevTransactionLink]);

  const toAllDoneTransactionList = useCallback(() => {
    navigate({
      pathname: transactionListView,
      search: new URLSearchParams({ [queryParams.allDone]: 'true' }).toString(),
    });
  }, [navigate, transactionListView]);

  const cycleTransactions = useCallback(() => {
    if (nextTransactionLink) {
      nextTransactionPage();
    } else if (prevTransactionLink) {
      prevTransactionPage();
    } else {
      toAllDoneTransactionList();
    }
  }, [
    nextTransactionLink,
    nextTransactionPage,
    prevTransactionLink,
    prevTransactionPage,
    toAllDoneTransactionList,
  ]);

  const { data, loading: isLoadingCurrentCardTransaction } =
    useGetCardIssuerTransactionByIdQuery({
      variables: { id: transactionId },
    });

  const handleUploaderChange = useCallback(
    async (file: File) => {
      if (!file) return;

      setIsDocumentChanging(true);

      const selectedFile = file;
      const uploadResult = await fileUploadUtil(
        selectedFile,
        isXmlDocumentUploadAllowed,
        {
          addFile,
          documentFileUploadUrl,
          updateFile,
          onUploaded: async () => ({}),
        }
      );

      if (uploadResult?.errorMessage) {
        setIsDocumentChanging(false);
        const errorMessage = `common:${uploadResult.errorMessage}`;

        error(t(errorMessage, { size: MAX_FILE_SIZE_MB }));

        return;
      }

      if (uploadResult?.file) {
        const fileId = uploadResult.file.id;
        if (fileId && uploadResult?.file.name && transactionId) {
          const { data } = await processDocument({
            variables: {
              fileId,
              transactionIds: [transactionId],
            },
            refetchQueries: [
              ...getRefetchCardIssuerTransaction(transactionId),
              ...counterQueries,
            ],
          });

          const createdDocumentId = data?.processDocument?.id;
          const fileName = uploadResult?.file.name;
          if (createdDocumentId) {
            const linkToCreatedDocument = (
              <Trans
                values={{ fileName }}
                i18nKey="transactions:transactionAssociation.successToastMessage"
              >
                <Link
                  href={`/${organizationSlug}${Routes.INBOX}/${createdDocumentId}`}
                >
                  {{ fileName } as any}
                </Link>
                uploaded and linked successfully to transaction
              </Trans>
            );

            success(linkToCreatedDocument);
          } else {
            error(t('transactionAssociation.errorToastMessage'));
          }
        }
      }
    },
    [
      addFile,
      counterQueries,
      documentFileUploadUrl,
      error,
      organizationSlug,
      processDocument,
      success,
      t,
      transactionId,
      updateFile,
    ]
  );

  const transaction = data?.getCardIssuerTransactionById;
  const document = transaction?.documents[0];
  const documentId = document?.id ?? undefined;

  const ownerTxMembershipId = transaction?.member.membershipId ?? undefined;
  const invoiceAssociationStatus =
    transaction?.invoiceAssociationStatus ?? undefined;

  const linkedDocumentStatus = transaction?.documents[0]?.status ?? undefined;
  const transactionAmount = transaction?.transactionAmount ?? undefined;
  const isNoInvoiceNeededToggleActive = !isNil(transaction?.invoiceNotNeeded);

  const isTransactionExported =
    (linkedDocumentStatus &&
      ExportedStatuses.includes(linkedDocumentStatus as any)) ??
    data?.getCardIssuerTransactionById?.isExported ??
    false;

  const canUserLinkInvoice =
    invoiceAssociationStatus === InvoiceAssociationStatus.Missing ||
    (isNoInvoiceNeededToggleActive && !isTransactionExported);

  const mainDocumentFile: PDFDetails = useMemo(
    () => ({
      name: document?.documentFile?.name ?? '',
      url: document?.documentFile?.url ?? '',
      id: document?.documentFile?.id ?? documentId,
      size: document?.documentFile?.size ?? 0,
      eInvoice: document?.documentFile?.eInvoice ?? undefined,
    }),
    [document, documentId]
  );

  const documentAttachments = document?.attachments;

  const {
    attachPermissions,
    attachments,
    attachmentListItems,
    selectedPdf,
    setSelectedPdf,
  } = useAttachments({
    documentFile: mainDocumentFile,
    documentId,
    documentAttachments,
  });

  const onToggleEditDocumentMode = useCallback(() => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/:transactionId`,
      { organizationSlug, transactionId }
    );

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('isArchivedTx', '1');

    navigate({ pathname, search: newSearchParams.toString() });
    setEditRequestedDocumentMode(true);
  }, [navigate, organizationSlug, searchParams, transactionId]);

  useEffect(() => {
    const isPageDataValid =
      transaction ||
      isLoadingCurrentCardTransaction ||
      editRequestedDocumentMode;

    if (isPageDataValid) return;

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    );

    navigate(pathname);
  }, [
    editRequestedDocumentMode,
    isLoadingCurrentCardTransaction,
    navigate,
    organizationSlug,
    routeType,
    transaction,
  ]);

  const closeModal = () => {
    setDocumentsNoTxModalIsOpen(false);
  };

  const isSectionLoading =
    isLoadingCurrentCardTransaction ||
    isDocumentChanging ||
    isProcessDocumentLoading;

  const handleBackToList = useCallback(() => {
    if (!state?.from) return navigate(linkBackToList);
    navigate({ pathname: state.from, search: state.search });
  }, [linkBackToList, navigate, state?.from, state?.search]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const context = useMemo(
    () => ({
      transactionId,
      handleBackToList,
      prevTransactionLink,
      prevTransactionPage,
      nextTransactionLink,
      nextTransactionPage,
      transactionListCount,
      isLoadingNavigationData,
      isLoadingCurrentCardTransaction,
      document,
      documentId,
      linkedDocumentStatus,
      attachmentListItems,
      selectedPdf,
      mainDocumentFile,
      attachments,
      attachPermissions,
      ownerTxMembershipId,
      isSectionLoading,
      setSelectedPdf,
      canUserLinkInvoice,
      handleUploaderChange,
      cycleTransactions,
      transactionAmount,
      isNoInvoiceNeededToggleActive,
      setDocumentsNoTxModalIsOpen,
      routeType,
      organizationSlug,
      isArchivedTx,
      onToggleEditDocumentMode,
      showAllExtraFeatures,
      transaction,
      isTransactionExported,
      closeModal,
      documentsNoTxModalIsOpen,
    }),
    [
      attachPermissions,
      attachmentListItems,
      attachments,
      canUserLinkInvoice,
      cycleTransactions,
      document,
      documentId,
      documentsNoTxModalIsOpen,
      handleBackToList,
      handleUploaderChange,
      isArchivedTx,
      isLoadingCurrentCardTransaction,
      isLoadingNavigationData,
      isNoInvoiceNeededToggleActive,
      isSectionLoading,
      isTransactionExported,
      linkedDocumentStatus,
      mainDocumentFile,
      nextTransactionLink,
      nextTransactionPage,
      onToggleEditDocumentMode,
      organizationSlug,
      ownerTxMembershipId,
      prevTransactionLink,
      prevTransactionPage,
      routeType,
      selectedPdf,
      setSelectedPdf,
      showAllExtraFeatures,
      transaction,
      transactionAmount,
      transactionId,
      transactionListCount,
    ]
  );

  return (
    <TransactionAssociationContext.Provider value={context}>
      {children}
    </TransactionAssociationContext.Provider>
  );
};

export const useTransactionAssociationContext = () =>
  useContext(TransactionAssociationContext);

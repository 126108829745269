import {
  Avatar,
  Flex,
  Grid,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';
import { formatFromCentsWithCurrency } from 'views/CreditCards/utils/formatFromCentsWithCurrency';
import { useLinkedCardState } from '../../../hooks/useLinkedCardState';
import { FailedTransactionAction } from './LinkedCardState/Actions/FailedTransactionAction';
import { transactionStateWithAction } from './utils';

interface RecurringPaymentItemProps {
  vendor: RecurringPayment['vendor'];
  linkedCard: RecurringPayment['linkedCard'];
}

const notActionableStatuses = [CardStatus.Pending, CardStatus.Processing];

export const RecurringPaymentItem = ({
  vendor,
  linkedCard,
}: RecurringPaymentItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    id,
    limit,
    availableBalance,
    cardholder,
    refNum,
    type,
    status,
    pendingActions,
  } = linkedCard.card;

  const { isCardManager } = useUserRoles();
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const transactionState = linkedCard.transactionState;

  const availBalanceInEur = formatFromCentsWithCurrency(
    availableBalance?.currency,
    availableBalance?.value
  );

  const limitInEur = formatFromCentsWithCurrency(limit?.currency, limit?.value);

  const percentage =
    ((availableBalance?.value ?? 0) / (limit?.value ?? 0) || 0) * 100;

  const linkedCardState = useLinkedCardState({
    isHovered,
    percentage,
    transactionState,
    card: { id, cardholder, refNum, type, status, pendingActions },
    vendorName: vendor.vendorName,
    availableBalance: availBalanceInEur,
    limit: limitInEur,
  });

  const showFailedTransactionAction =
    transactionStateWithAction.includes(transactionState);

  const showCardRefNum = !notActionableStatuses.includes(status);
  const isClickable = !notActionableStatuses.includes(status) && isCardManager;

  const handleClick = (vendorId: string, e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isClickable) {
      return null;
    }

    navigate(
      `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.RECURRING_PAYMENTS}/${vendorId}`
    );
  };

  return (
    <Grid
      height="60px"
      transition="0.5"
      autoFlow="column"
      alignItems="center"
      borderBottom="1px solid gray200"
      padding="space8 space16"
      justifyContent="space-between"
      style={{
        cursor: isClickable ? 'pointer' : 'default',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        handleClick(vendor.id, e);
      }}
    >
      <Grid autoFlow="column" alignItems="center" gap="space10">
        <Avatar
          img={vendor.logoUrl}
          hasTooltip={false}
          name={vendor.vendorName}
          size="medium"
        />
        <Flex alignItems="baseline" gap="space8">
          <TruncatedText maxWidth="50ch" fontSize="basic" fontWeight="semibold">
            {vendor.vendorName}
          </TruncatedText>
          {cardholder && (
            <TruncatedText maxWidth="50ch" fontSize="small" color="gray600">
              {cardholder?.name}
            </TruncatedText>
          )}
        </Flex>
      </Grid>

      <Grid alignItems="center" autoFlow="column" gap="space48">
        {showFailedTransactionAction && (
          <FailedTransactionAction
            props={{
              cardId: id,
              cardholderId: cardholder?.id,
              vendorName: vendor.vendorName,
              transactionState,
            }}
          />
        )}
        <Grid
          alignItems="center"
          templateColumns={showCardRefNum ? '1fr auto' : 'auto'}
          gap="space8"
        >
          <Grid autoFlow="column" gap="space8" alignItems="center">
            {linkedCardState}
          </Grid>
          {showCardRefNum && <Text fontSize="basic">•••• {refNum}</Text>}
        </Grid>
      </Grid>
    </Grid>
  );
};

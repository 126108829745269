import { PDFDetails } from 'components/DocumentViewer/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNumberInRange } from 'utils/isNumberInRange';
import { PdfViewerProps } from '../PdfViewer';
import { type UseReactPdfControlsResult } from './useReactPdfControls';
import { getHighestVisiblePageNumber } from './utils';

export interface UseDocumentPagesProps
  extends Pick<
      UseReactPdfControlsResult,
      'setCurrentPage' | 'currentPage' | 'goToPage'
    >,
    Pick<PdfViewerProps, 'onSelectDocument' | 'selectedFile'> {
  documentFile: PDFDetails;
  attachments?: PDFDetails[];
}

export const useDocumentPages = ({
  documentFile,
  attachments,
  setCurrentPage,
  onSelectDocument,
  currentPage,
  selectedFile,
  goToPage,
}: UseDocumentPagesProps) => {
  const documentByIdMap = new Map(
    [documentFile, ...(attachments ?? [])].map(file => [file.id, file])
  );

  // ordered map of main doc + attachments as they appear in pdf viewer
  const [documentPages, setDocumentPages] = useState<Map<string, number>>(
    () =>
      new Map([
        [documentFile.id ?? '', 1],
        ...(attachments ?? []).map(file => [file.id ?? '', 0]),
      ] as [string, number][])
  );

  // map containing visibility of each page, e.g. [1, 0.2], [2, 0.5], [3, 0.3], used to determine which page is current one based on how much of it is visible
  const [pageVisibility, setPageVisibility] = useState<Map<number, number>>(
    () => new Map([[1, 1]])
  );

  // ordered map with more info about documents pages, its first and last page in total
  // e.g. ['id-1', {firstPage: 1, lastPage: 2}], ['attachment-2', {firstPage: 3, lastPage: 3}]
  // because all documents load asynchronously, we need to iterate over `documentPages` to update their info as they load
  const documentsWithFirstAndLastPage = useMemo(() => {
    const docsWithPagesMap = new Map<
      string,
      { firstPage: number; lastPage: number }
    >();

    let pageCount = 0;
    for (const [docId, docPages] of documentPages) {
      docsWithPagesMap.set(docId, {
        firstPage: pageCount + 1,
        lastPage: pageCount + docPages,
      });
      pageCount += docPages;
    }

    return docsWithPagesMap;
  }, [documentPages]);

  // total pages (main document + all attachments)
  // because `documentsWithFirstAndLastPage` is ordered, we know that last page is last's document `lastPage`
  const totalPages = useMemo(
    () => Array.from(documentsWithFirstAndLastPage.values()).pop()?.lastPage,
    [documentsWithFirstAndLastPage]
  );

  // called whenever visibility of pages change (e.g. by scrolling)
  // takes care of:
  // - setting the visibility
  // - setting current page
  // - notifying via `onSelectDocument` if currently viewed document changed
  const handleChangePageVisibility = useCallback(
    (pageNumber: number, pageVisibility: number) => {
      setPageVisibility(prev => {
        const newPagesVisibility = new Map(prev);
        newPagesVisibility.set(pageNumber, pageVisibility);

        return newPagesVisibility;
      });
    },
    []
  );

  useEffect(() => {
    const currentPage = getHighestVisiblePageNumber(pageVisibility);
    setCurrentPage(currentPage);
  }, [pageVisibility, setCurrentPage]);

  useEffect(() => {
    if (!onSelectDocument) return;
    let id;

    for (const [docId, pages] of documentsWithFirstAndLastPage) {
      id = docId;
      if (currentPage <= pages.lastPage) break;
    }

    const docToSelect = documentByIdMap.get(id) as PDFDetails;

    onSelectDocument(docToSelect);
  }, [
    currentPage,
    documentByIdMap,
    documentsWithFirstAndLastPage,
    onSelectDocument,
  ]);

  // calculate page offset based on rendered documents
  // again because documents render asynchronously we have to rely on `documentPages`
  const getPageNumberOffset = useCallback(
    (documentId: string) => {
      let pageCount = 0;
      for (const [id, totalPages] of documentPages) {
        if (id === documentId) return pageCount;
        pageCount += totalPages;
      }
    },
    [documentPages]
  );

  const onDocumentPagesLoaded = useCallback(
    (documentId: string, pagesCount: number) => {
      setDocumentPages(prev => {
        const newDocumentPages = new Map(prev);
        newDocumentPages.set(documentId, pagesCount);

        return newDocumentPages;
      });
    },
    []
  );

  // called when `selectedFile` changes (e.g. by selecting attachment in Attachments tab)
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!selectedFile?.id) return;

    const selectedDoc = documentsWithFirstAndLastPage.get(selectedFile.id);
    if (!selectedDoc) return;

    const isDocumentInViewport = isNumberInRange(
      currentPage,
      selectedDoc.firstPage,
      selectedDoc.lastPage
    );

    if (isDocumentInViewport) return;
    goToPage(selectedDoc.firstPage);
  }, [
    documentsWithFirstAndLastPage,
    selectedFile?.id,
    goToPage,
    /** `currentPage` is omitted here because it would trigger endless loop (setCurrentPage => onSelectDocument => this useEffect => setCurrentPage...) */
  ]);

  const result = useMemo(
    () => ({
      getPageNumberOffset,
      handleChangePageVisibility,
      totalPages,
      onDocumentPagesLoaded,
    }),
    [
      getPageNumberOffset,
      handleChangePageVisibility,
      totalPages,
      onDocumentPagesLoaded,
    ]
  );

  return result;
};

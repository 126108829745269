import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentStatus } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const availableFilters: Array<keyof DocumentsTableData> = [
  'status',
  'contact',
  'documentType',
  'requester',
  'approvers',
  'accountingArea',
  'costCenter',
  'costObject',
  'extraCostInfo',
  'generalLedgerAccount',
  'artistSocialInsuranceCode',
  'isPayable',
  'paymentStatus',
  'invoiceDate',
  'isInvoiceCorrection',
  'dueDate',
  'deliveryDate',
  'createdAt',
  'paidAt',
  'creditCardPayment',
  'tags',
  'discountDateWPercentage',
  'requestedAt',
  'accountsPayableNumber',
];

export const useStatusFilters = () => {
  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  const statusFilters: Array<{
    id: DocumentStatus | 'OVERDUE' | 'WAITING_FOR_CLARIFICATION';
    label: string;
  }> = [
    {
      id: DocumentStatus.New,
      label: 'statuses.new',
    },
    {
      id: DocumentStatus.Rejected,
      label: 'statuses.rejected',
    },
    {
      id: DocumentStatus.Approved,
      label: 'statuses.approved',
    },
    {
      id: DocumentStatus.Exported,
      label: 'statuses.exported',
    },
    {
      id: DocumentStatus.Open,
      label: 'statuses.open',
    },
    {
      id: 'OVERDUE',
      label: 'statuses.overdue',
    },
    {
      id: DocumentStatus.Archived,
      label: 'statuses.archived',
    },
  ];

  if (visualiseWaitingForClarificationFF)
    statusFilters.push({
      id: 'WAITING_FOR_CLARIFICATION',
      label: 'statuses.waitingForClarification',
    });

  return statusFilters;
};

import { getUniqueUsersById } from 'components/DocumentAccess/utils';
import {
  AccessLevelName,
  ActivityUser,
  useDocumentAccessLevelsQuery,
} from 'generated-types/graphql.types';

export enum AccessLevelRoleName {
  Admins = 'ADMINS',
  Accountants = 'ACCOUNTANTS',
  Requesters = 'REQUESTERS',
  Approvers = 'APPROVERS',
  Viewers = 'VIEWERS',
  Editors = 'EDITORS',
  ResponsibleAndNotifiedPersons = 'RESPONSIBLE_AND_NOTIFIED_PERSONS',
  ResponsibleNotifiedAndUploader = 'RESPONSIBLE_NOTIFIED_AND_UPLOADER',
}

export type AccessByData = {
  users: ActivityUser[];
  roleName: AccessLevelRoleName;
  roleTranslationKey: string;
  accessTranslationKey: string;
};

interface UseDocumentAccessLevelDataProps {
  globalDocumentId?: string;
}

export const useDocumentAccessLevelsData = ({
  globalDocumentId,
}: UseDocumentAccessLevelDataProps) => {
  const { data, loading } = useDocumentAccessLevelsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !globalDocumentId,
    variables: globalDocumentId ? { globalDocumentId } : undefined,
  });

  const documentAccessLevels =
    data?.documentAccessLevels?.__typename === 'AccessLevels'
      ? data.documentAccessLevels.levels
      : [];

  const accessLevels = documentAccessLevels.reduce(
    (acc, level) => {
      acc[level.name] = level.users ?? [];

      return acc;
    },
    {} as Partial<Record<AccessLevelName, ActivityUser[]>>
  );

  const uniqueResponsibleNotifiedUploaderUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
    ...(accessLevels[AccessLevelName.Uploader] ?? []),
  ]);

  const uniqueResponsibleNotifiedUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
  ]);

  // AccessByData
  const accessByRoleData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Admins] ?? [],
      roleName: AccessLevelRoleName.Admins,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.admins',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    {
      users: accessLevels[AccessLevelName.Accountants] ?? [],
      roleName: AccessLevelRoleName.Accountants,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.accountants',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    {
      users: accessLevels[AccessLevelName.Requesters] ?? [],
      roleName: AccessLevelRoleName.Requesters,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.requesters',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForSensitiveDocuments: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUploaderUsers,
      roleName: AccessLevelRoleName.ResponsibleNotifiedAndUploader,
      roleTranslationKey:
        'documentAccess.membership.access.contracts.notifiedResponsibleOrUploader',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForContracts: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUsers,
      roleName: AccessLevelRoleName.ResponsibleAndNotifiedPersons,
      roleTranslationKey:
        'documentAccess.membership.access.contracts.notifiedOrResponsiblePersons',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByApproversData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Approvers] ?? [],
      roleName: AccessLevelRoleName.Approvers,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.approvers',
      accessTranslationKey: 'documentAccess.membership.access.approve',
    },
  ];

  const accessByViewersData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Viewers] ?? [],
      roleName: AccessLevelRoleName.Viewers,
      roleTranslationKey: 'documentAccess.membership.access.viewers.title',
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  const accessByEditorsData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Editors] ?? [],
      roleName: AccessLevelRoleName.Editors,
      roleTranslationKey: 'documentAccess.membership.access.editors.title',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessData = {
    accessByRoleData,
    accessByApproversData,
    accessByViewersData,
    accessByEditorsData,
    accessDataForSensitiveDocuments,
    accessDataForContracts,
    loading,
  } as const;

  const pickId = (user: ActivityUser) => user.id;
  const membershipsWithIrrevocableAccess = new Set<string>([
    ...accessData.accessByRoleData.flatMap(role => role.users.map(pickId)),
    ...accessData.accessByApproversData.flatMap(role => role.users.map(pickId)),
    ...accessData.accessDataForSensitiveDocuments.flatMap(role =>
      role.users.map(pickId)
    ),
    ...accessData.accessDataForContracts.flatMap(role =>
      role.users.map(pickId)
    ),
  ]);

  return {
    ...accessData,
    membershipsWithIrrevocableAccess: Array.from(
      membershipsWithIrrevocableAccess
    ),
  };
};

import { Button, Card, Grid, Text } from '@candisio/design-system';

import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { startCase } from 'lodash';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { zodResolver } from 'utils/zodFormValidation';
import { useCreateCustomRecurringPaymentVendor } from 'views/CreditCards/hooks/useCreateCustomRecurringPaymentVendor';
import {
  VendorNameFormValues,
  errorMessages,
  vendorNameFormSchema,
} from './utils/vendorNameFormSchema';

interface VendorNameProps {
  onNextStep: () => void;
}

export const VendorNameForm = ({ onNextStep }: VendorNameProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const location = useLocation();
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const { createCustomVendor, loading } =
    useCreateCustomRecurringPaymentVendor();

  const form = useForm<VendorNameFormValues>({
    mode: 'onSubmit',
    resolver: zodResolver({
      errorMessages,
      zodSchema: vendorNameFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
  });

  const handleSubmit = async ({ vendorName }: VendorNameFormValues) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.append('onCreditCardsView', 'true');
    const data = await createCustomVendor({
      name: startCase(vendorName),
    });

    if (data?.status === 'error') {
      error(t('createPaymentContainer.errorMsg'));

      return;
    } else {
      navigate({
        pathname: `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.RECURRING_PAYMENTS}/${data?.vendorId}`,
        search: urlParams.toString(),
      });
      onNextStep();
    }
  };

  return (
    <FormProvider<VendorNameFormValues> {...form}>
      <Grid
        as="form"
        gap="space24"
        padding="space8 space32 space32"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Text fontSize="basic">{t('createPaymentContainer.description')}</Text>
        <Card background="gray100">
          <HookFormTextField
            autoFocus
            name="vendorName"
            label={t('createPaymentContainer.vendorName.label')}
            placeholder={t('createPaymentContainer.vendorName.placeholder')}
          />
        </Card>
        <Button
          type="submit"
          justifySelf="end"
          loading={loading}
          disabled={loading}
        >
          {t('ccRequestForm.nextStepCTA')}
        </Button>
      </Grid>
    </FormProvider>
  );
};

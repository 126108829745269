import {
  Grid,
  Item,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { ProvisionsPromotionApprovalsBanner } from 'components/ProductPromotions/Provisions/ProvisionsPromotionApprovalsBanner';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { AriaTabListProps } from 'react-aria';
import { useNavigate } from 'react-router-dom-v5-compat';

export type Tab = 'Invoices' | 'Reimbursements';

interface ApprovalsTabs {
  tabs: TabItem[];
  defaultSelectedKey: Tab;
}

const APPROVALS_PROMOTION_KEY = 'approvals_promotion_dismissed';

export const ApprovalTabs = ({ tabs, defaultSelectedKey }: ApprovalsTabs) => {
  const organizationId = useOrganizationId();
  const navigate = useNavigate();

  const user = useCurrentUser();

  const datev = useDatev(); // BDS-checked

  const [dismissed, setDismissed] = useLocalStorage(
    APPROVALS_PROMOTION_KEY,
    false
  );

  const handleTabChange = (tab: Key) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('approvers', user?.id ?? '');
    if (tab === 'Reimbursements') {
      navigate({
        pathname: `/${organizationId}${Routes.APPROVALS}${Routes.REIMBURSEMENTS}`,
        search: newSearchParams.toString(),
      });
    }

    if (tab === 'Invoices') {
      navigate({
        pathname: `/${organizationId}${Routes.APPROVALS}`,
        search: newSearchParams.toString(),
      });
    }
  };

  const stateFields: AriaTabListProps<TabItem> = {
    onSelectionChange: handleTabChange,
    defaultSelectedKey,
    items: tabs,
    children: item => (
      <Item key={item.key} title={item.title} textValue={item.title}>
        {item.children}
      </Item>
    ),
  };

  const state = useTabListState(stateFields);

  const showBdsPromotionModal =
    !datev.bdsBought &&
    !datev.hide &&
    !dismissed &&
    state.selectedKey === 'Invoices';

  const onCloseBanner = () => {
    setDismissed(true);
  };
  return (
    <Grid
      templateRows={showBdsPromotionModal ? 'auto auto 1fr' : 'auto 1fr'}
      flexGrow={1}
      overflow="hidden"
      rowGap="space24"
    >
      <Tabs {...stateFields} state={state} />
      {showBdsPromotionModal && !dismissed && (
        <ProvisionsPromotionApprovalsBanner onCloseBanner={onCloseBanner} />
      )}
      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        height="100%"
        overflow="hidden"
      />
    </Grid>
  );
};

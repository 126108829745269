import { EcmDocumentStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterOption {
  id: EcmDocumentStatus;
  label: string;
}

interface EcmDocumentStatusReturn {
  /** A list of filter options used in the table */
  ecmDocumentStatusFilterOptions: Array<FilterOption>;
}

const EXCLUDED_ECM_DOCUMENTS_STATUSES = [
  EcmDocumentStatus.Exporting,
  EcmDocumentStatus.Processing,
];

const isSupportedDocumentStatusFilter = ([id]: [id: string, value: string]) =>
  !EXCLUDED_ECM_DOCUMENTS_STATUSES.includes(id as EcmDocumentStatus);

/**
 * A custom hook to get the translation map and filter options for the ECM document status in inbox
 * based on the EcmDocumentStatus enum
 */

export const useInboxEcmDocumentStatusOptions = (): EcmDocumentStatusReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const ecmDocumentStatusTranslationMap: Record<
    EcmDocumentStatus | string,
    string
  > = useMemo(
    () => ({
      [EcmDocumentStatus.Processing]: t('status.processing'),
      [EcmDocumentStatus.New]: t('status.new'),
      [EcmDocumentStatus.Open]: t('status.open'),
      [EcmDocumentStatus.Rejected]: t('status.rejected'),
      [EcmDocumentStatus.Approved]: t('status.approved'),
      [EcmDocumentStatus.Exported]: t('status.exported'),
      [EcmDocumentStatus.Exporting]: t('status.exporting'),
      [EcmDocumentStatus.Stored]: t('status.stored'),
    }),
    [t]
  );

  const ecmDocumentStatusFilterOptions = Object.entries(
    ecmDocumentStatusTranslationMap
  )
    .filter(isSupportedDocumentStatusFilter)
    .map(([id, label]) => {
      return {
        id,
        label,
      } as FilterOption;
    });

  return {
    ecmDocumentStatusFilterOptions,
  };
};

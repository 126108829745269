import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { useProjectCodeLazyQuery } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';
import { getProjectCodeReadableName } from 'views/Settings/ProjectCodes/utils/readableName';
import { useProjectCodeFieldItems } from './useProjectCodeFieldItems';

export interface UseProjectCodeFieldOptions {
  skip?: boolean;
  ids?: string[];
}

/** Returns options for project code field */
export const useProjectCodeFieldOptions = ({
  skip,
  ids,
}: UseProjectCodeFieldOptions): ProcessingFormFieldOptions['projectCode'] => {
  const { shouldUseSapProjectCodes } = useSap();
  const [projectCodeQuery] = useProjectCodeLazyQuery();

  const getItem = useCallback(
    async (id: string) => {
      const { data } = await projectCodeQuery({ variables: { id } });

      return data?.projectCode?.__typename === 'ProjectCode'
        ? toAccountingDataFieldItem({
            id: data.projectCode.id,
            code: data.projectCode.code,
            name: data.projectCode.name,
            readableName: getProjectCodeReadableName(data.projectCode),
          })
        : undefined;
    },
    [projectCodeQuery]
  );

  const { fetchMoreItems, items, refetchItems } = useProjectCodeFieldItems({
    skip,
    ids,
  });

  if (skip) {
    return { hidden: true };
  }

  return {
    hidden: !shouldUseSapProjectCodes,
    props: {
      getItem,
      items,
      onEndReached: fetchMoreItems,
      onSearch: async (value: string) => {
        await refetchItems({ code: value, name: value });
      },
    },
  };
};

import { Money } from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isEmpty, keyBy } from 'lodash';
import { BookingsFormPartialData } from 'views/DocumentDetails/BookingsFormContext';
import {
  MatchState,
  ThreeWayMatchBookingData,
  ThreeWayMatchGoodsReceiptData,
  ThreeWayMatchPurchaseOrderData,
  ThreeWayMatchRowData,
  matchState,
} from 'views/DocumentDetails/components/ThreeWayMatch/types';

type PurchaseOrderPartialData = {
  price?: Money | null;
  quantity: number;
  description: string;
  articleNumber: string;
}[];

export type ThreeWayMatchResultData = {
  id: string;
  articleNumber: string;
  description: string;
  purchaseOrderQuantity: number;
  purchaseOrderAmount?: Money | null;
  invoiceQuantity: number;
  invoiceAmount: Money;
  goodsReceiptQuantity: number;
}[];

const isBookingAdditionalExpense = (
  booking: ThreeWayMatchBookingData
): boolean => {
  return (
    !booking?.quantity &&
    (booking?.description?.toLowerCase().includes('freight') ||
      booking?.description?.toLowerCase().includes('packaging'))
  );
};

export const getThreeWayMatchResultNew = ({
  bookingsData,
  purchaseOrdersData,
  goodsReceiptsData,
}: {
  bookingsData: ThreeWayMatchBookingData[];
  purchaseOrdersData: ThreeWayMatchPurchaseOrderData[];
  goodsReceiptsData: ThreeWayMatchGoodsReceiptData[];
}): ThreeWayMatchRowData[] => {
  const result: ThreeWayMatchRowData[] = [];
  if (!bookingsData.length || !purchaseOrdersData.length) {
    return result;
  }

  const purchaseOrderDataHashMap = keyBy(purchaseOrdersData, 'bookingId');
  const bookingFormDataHashMap = keyBy(bookingsData, 'bookingId');
  const goodsReceiptsDataHashMap = keyBy(goodsReceiptsData, 'bookingId');

  const mergedKeys = new Set([
    ...Object.keys(purchaseOrderDataHashMap),
    ...Object.keys(bookingFormDataHashMap),
    ...Object.keys(goodsReceiptsDataHashMap),
  ]);

  for (const [index, key] of mergedKeys.entries()) {
    // do not add Freight/Packaging items to quantity match logic:
    const isBookingIsAdditionalExpense = isBookingAdditionalExpense(
      bookingFormDataHashMap[key]
    );

    result.push({
      id: index,
      description: bookingFormDataHashMap[key]?.description ?? '',
      goodsReceiptQuantity: goodsReceiptsDataHashMap[key]?.quantity ?? 0,
      invoiceAmount: bookingFormDataHashMap[key]?.price || {
        amount: 0,
        currency: '',
      },
      invoiceQuantity: bookingFormDataHashMap[key]?.quantity ?? 0,
      purchaseOrderAmount: purchaseOrderDataHashMap[key]?.price,
      purchaseOrderQuantity: purchaseOrderDataHashMap[key]?.quantity ?? 0,
      articleNumber: purchaseOrderDataHashMap[key]?.articleNumber ?? '-',
      isAmountMatched: isAmountMatched(
        purchaseOrderDataHashMap[key],
        bookingFormDataHashMap[key]
      ),
      isQuantityMatched: isBookingIsAdditionalExpense
        ? true
        : purchaseOrderDataHashMap[key]?.quantity ===
            bookingFormDataHashMap[key]?.quantity &&
          goodsReceiptsDataHashMap[key]?.quantity ===
            bookingFormDataHashMap[key]?.quantity,
      goodsReceiptsDetails: goodsReceiptsDataHashMap[key]?.items ?? [],
    });
  }

  return result;
};

export const getThreeWayMatchGeneralState = (
  data: ThreeWayMatchRowData[]
): MatchState => {
  if (isEmpty(data)) return matchState.default;
  const isAnyQuantityMisMatch = data.some(row => !row.isQuantityMatched);
  const isAnyAmountMisMatch = data.some(row => !row.isAmountMatched);

  return isAnyQuantityMisMatch || isAnyAmountMisMatch
    ? matchState.warning
    : matchState.success;
};

const isAmountMatched = (
  purchaseOrder: PurchaseOrderPartialData[number] | undefined,
  bookingFormData: BookingsFormPartialData[number] | undefined
): boolean => {
  if (!purchaseOrder || !bookingFormData) {
    return false;
  }

  const bookingFormDecimalAmount = bookingFormData.price
    ? formatIntegerAmountToDecimal(bookingFormData.price.amount, 0)
    : 0;

  const purchaseOrderDecimalAmount = purchaseOrder.price
    ? formatIntegerAmountToDecimal(
        purchaseOrder.price.amount,
        purchaseOrder.price.precision
      )
    : 0;

  return bookingFormDecimalAmount === purchaseOrderDecimalAmount;
};

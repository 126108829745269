import { Grid } from '@candisio/design-system';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentStatus,
  useAvailableDocumentCategoriesQuery,
  useDocumentFastApproveMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSap } from 'orgConfig/sap';
import { useState } from 'react';
import { DocumentSelectionTable } from 'views/AppContainer/components/Header/components/QaUtils/BatchFastApprove/containers/DocumentSelectionTable';
import { FastApprovalForm } from 'views/AppContainer/components/Header/components/QaUtils/BatchFastApprove/containers/FastApprovalForm';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { useFormValuesToFastApproveInput } from 'views/Inbox/DocumentProcessing/useFastApprove';
import { useInboxDocumentsData } from 'views/Inbox/InboxDocuments/hooks/useInboxDocumentsData';
import { useInboxDocumentsDataDeprecated } from 'views/Inbox/InboxDocuments/hooks/useInboxDocumentsDataDeprecated';
import { List, getRefetchListQueries } from 'views/queries';

export const BatchFastApprove = () => {
  const { useNewInboxQuery } = useEcm();

  return useNewInboxQuery ? (
    <NewBatchFastApprove />
  ) : (
    <BatchFastApproveDeprecated />
  );
};

const NewBatchFastApprove = () => {
  const inboxDocumentDataProps = useInboxDocumentsData();

  return (
    <BatchFastApproveComponent
      inboxDocumentDataProps={inboxDocumentDataProps}
    />
  );
};

const BatchFastApproveDeprecated = () => {
  const inboxDocumentDataProps = useInboxDocumentsDataDeprecated();

  return (
    <BatchFastApproveComponent
      inboxDocumentDataProps={inboxDocumentDataProps}
    />
  );
};

type Props = {
  inboxDocumentDataProps:
    | ReturnType<typeof useInboxDocumentsDataDeprecated>
    | ReturnType<typeof useInboxDocumentsData>;
};

export const BatchFastApproveComponent = ({
  inboxDocumentDataProps,
}: Props) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { success } = useToastMessage();
  const counterQueries = useCounterQueries();
  const sap = useSap();
  const { dateStringToDateTimeString } = useDateConverter();

  const { data: availableDocumentCategoriesData } =
    useAvailableDocumentCategoriesQuery();

  const formValuesToFastApproveInput = useFormValuesToFastApproveInput();

  const availableDocumentCategories =
    availableDocumentCategoriesData?.availableDocumentCategories ?? [];

  const { loading, onLoadMore, documentsTableData } = inboxDocumentDataProps;

  const [mutation] = useDocumentFastApproveMutation({
    refetchQueries: [
      ...getRefetchListQueries(List.INBOX),
      ...getRefetchListQueries(List.EXPORT),
      ...counterQueries,
    ],
  });

  const useHandleApprove = async (values: ProcessingFormValues) => {
    const category = availableDocumentCategories.find(
      category => category.documentType === values.type
    );

    const isSapCreditMemoType =
      sap.isActiveIntegration &&
      values.type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

    const calculatePaymentFieldValue = (
      isSapCreditMemoType: boolean,
      hasTransactionLinked: boolean,
      value: string | null | undefined
    ) => {
      if (isSapCreditMemoType) {
        return null;
      }

      // Exclude swift code when document has transaction linked
      return !hasTransactionLinked ? value : null;
    };

    for (const documentId of selectedIds) {
      if (!category) {
        return;
      }

      await mutation({
        variables: {
          id: documentId,
          input: formValuesToFastApproveInput(values, {
            hasTransactionLinked: false,
            shouldIncludeCashDiscount: false,
            isSapCreditMemoType,
            calculatePaymentFieldValue,
            category,
            status: DocumentStatus.New,
            shouldUseAccountingArea: false,
            shouldUseSapNetAmount: sap.shouldUseSapNetAmount,
            dateValuesFormat: dateStringToDateTimeString,
            shouldUseSapPurchaseOrder: sap.shouldUseSapPurchaseOrder,
          }),
          options: {
            duplicateCheckByContent: false,
          },
        },
      });

      success(`document with id ${documentId} fast approved`);
    }
  };

  return loading ? null : (
    <Grid columns={2} gap="space16" width="100%" height="100%">
      <DocumentSelectionTable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        loading={loading}
        onLoadMore={onLoadMore}
        documentsTableData={documentsTableData}
      />
      <FastApprovalForm
        onApprove={useHandleApprove}
        documentId={documentsTableData[0]?.id ?? ''}
      />
    </Grid>
  );
};

import {
  MembershipStatus,
  useOrganizationMembershipsQuery,
} from 'generated-types/graphql.types';
import { TeamMembersComboBoxFieldOptions } from '../../ManageAccess/types';

interface UseTeamMembersFieldItemsProps {
  excludeFromSuggestions: string[];
}

export const useTeamMembersFieldItems = ({
  excludeFromSuggestions,
}: UseTeamMembersFieldItemsProps): TeamMembersComboBoxFieldOptions => {
  const { data, loading } = useOrganizationMembershipsQuery({
    variables: {
      filters: {
        status: MembershipStatus.Active,
      },
    },
  });

  const items: TeamMembersComboBoxFieldOptions['items'] = (
    data?.organizationMemberships ?? []
  )
    .map(member => ({
      key: member.id,
      memberName: member.name,
      children: member.name,
      avatarUrl: member.avatarUrl ?? undefined,
    }))
    .filter(member => !excludeFromSuggestions.includes(member.key));

  return {
    isLoading: loading,
    items,
  };
};

import {
  useReimbursementCaseWorkflowQuery,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

interface Approver {
  id: string;
  fullName: string;
  avatarUrl?: string;
  substitute?: Approver;
}

export interface ReimbursementWorkflowStep {
  id?: string;
  name?: string;
  approvers?: Approver[];
  resolutionType?: WorkflowStepResolutionTypes;
  resolutionApprovers?: Approver[];
}

const formatFullName = (firstName: string, lastName: string) =>
  `${firstName} ${lastName}`;

export interface ReimbursementWorkflow {
  id?: string;
  name?: string;
  description?: string;
  currentStepId?: string;
  currentStepApproverIds?: string[];
  steps: ReimbursementWorkflowStep[];
}

export const useWorkflowForReimbursementCaseById = (
  reimbursementId?: string
) => {
  const { canUseReimbursement } = useReimbursement();

  const { data, loading } = useReimbursementCaseWorkflowQuery({
    variables: { id: reimbursementId ?? '' },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const workflowData = data?.reimbursementCaseWorkflow.workflow;

  const workflow = !isEmpty(workflowData)
    ? {
        id: workflowData?.id ?? undefined,
        name: workflowData?.name ?? undefined,
        description: workflowData?.description ?? undefined,
        currentStepId: workflowData?.currentStep?.id ?? undefined,
        currentStepApproverIds: (
          workflowData?.currentStep?.approvers ?? []
        )?.map(approver => approver.id),
        steps: (workflowData?.steps ?? [])?.map(step => ({
          id: step.id ?? undefined,
          name: step.name ?? undefined,
          approvers: step.approvers?.map(approver => ({
            id: approver.id,
            fullName: formatFullName(approver.firstName, approver.lastName),
            avatarUrl: approver.avatarUrl ?? undefined,
            substitute: approver.substitute
              ? {
                  id: approver.substitute.id,
                  fullName: formatFullName(
                    approver.substitute.firstName,
                    approver.substitute.lastName
                  ),
                  avatarUrl: approver.substitute.avatarUrl ?? undefined,
                }
              : undefined,
          })),
          resolutionType: step.resolution?.type ?? undefined,
          resolutionApprovers: (step.resolution?.approvers ?? [])?.map(
            approver => ({
              id: approver.id,
              fullName: formatFullName(approver.firstName, approver.lastName),
              avatarUrl: approver.avatarUrl ?? undefined,
            })
          ),
        })),
      }
    : undefined;

  return { workflow, loading };
};

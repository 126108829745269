import {
  ExpenseItemArchivedEventDataFragment,
  ExpenseItemBookingAddedEventDataFragment,
  ExpenseItemBookingRemovedEventDataFragment,
  ExpenseItemBookingUpdatedEventDataFragment,
  ExpenseItemExpenseDateUpdatedEventDataFragment,
  ExpenseItemFileAddedEventDataFragment,
  ExpenseItemFileRemovedEventDataFragment,
  ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment,
  ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment,
  ExpenseItemHospitalityLocationUpdatedEventDataFragment,
  ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment,
  ExpenseItemHospitalityTipAmountUpdatedEventDataFragment,
  ExpenseItemInvoiceNumberUpdatedEventDataFragment,
  ExpenseItemTitleUpdatedEventDataFragment,
  ExpenseItemTotalAmountUpdatedEventDataFragment,
  ReimbursementCaseAmendmentRequestedEventDataFragment,
  ReimbursementCaseApprovedEventDataFragment,
  ReimbursementCaseCommentAddedEventDataFragment,
  ReimbursementCaseCreatedEventDataFragment,
  ReimbursementCaseItemAddedEventDataFragment,
  ReimbursementCaseItemExcludedEventDataFragment,
  ReimbursementCaseItemExportStatusUpdatedEventDataFragment,
  ReimbursementCaseItemIncludedEventDataFragment,
  ReimbursementCaseItemRemovedEventDataFragment,
  ReimbursementCaseNoteUpdatedEventDataFragment,
  ReimbursementCaseResetApprovalEventDataFragment,
  ReimbursementCaseSubmittedForReviewEventDataFragment,
  ReimbursementCaseTargetMembershipUpdatedEventDataFragment,
  ReimbursementCaseTitleUpdatedEventDataFragment,
  ReimbursementTimeLineItem,
  useGetReimbursementHistoryQuery,
} from 'generated-types/graphql.types';

export type SupportedFragmentEvents =
  | ReimbursementCaseTitleUpdatedEventDataFragment
  | ExpenseItemTitleUpdatedEventDataFragment
  | ReimbursementCaseAmendmentRequestedEventDataFragment
  | ReimbursementCaseApprovedEventDataFragment
  | ReimbursementCaseCreatedEventDataFragment
  | ReimbursementCaseSubmittedForReviewEventDataFragment
  | ReimbursementCaseTargetMembershipUpdatedEventDataFragment
  | ReimbursementCaseNoteUpdatedEventDataFragment
  | ExpenseItemTotalAmountUpdatedEventDataFragment
  | ExpenseItemFileAddedEventDataFragment
  | ExpenseItemFileRemovedEventDataFragment
  | ExpenseItemExpenseDateUpdatedEventDataFragment
  | ReimbursementCaseCommentAddedEventDataFragment
  | ExpenseItemArchivedEventDataFragment
  | ExpenseItemHospitalityExternalGuestsUpdatedEventDataFragment
  | ExpenseItemHospitalityInternalGuestsUpdatedEventDataFragment
  | ExpenseItemHospitalityLocationUpdatedEventDataFragment
  | ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment
  | ExpenseItemHospitalityTipAmountUpdatedEventDataFragment
  | ExpenseItemBookingAddedEventDataFragment
  | ExpenseItemBookingRemovedEventDataFragment
  | ExpenseItemBookingUpdatedEventDataFragment
  | ReimbursementCaseResetApprovalEventDataFragment
  | ReimbursementCaseItemAddedEventDataFragment
  | ReimbursementCaseItemAddedEventDataFragment
  | ReimbursementCaseItemIncludedEventDataFragment
  | ReimbursementCaseItemRemovedEventDataFragment
  | ReimbursementCaseItemExcludedEventDataFragment
  | ReimbursementCaseItemExportStatusUpdatedEventDataFragment
  | ExpenseItemInvoiceNumberUpdatedEventDataFragment;

export type SupportedReimbursementTimeLineEvents = ReimbursementTimeLineItem &
  SupportedFragmentEvents & {
    __typename: string;
  };

interface UseReimbursementHistoryParams {
  id: string;
}

const hasValidTypename = (event: {
  __typename: string;
}): event is SupportedReimbursementTimeLineEvents =>
  !!(event as SupportedReimbursementTimeLineEvents);

export const useReimbursementHistory = ({
  id,
}: UseReimbursementHistoryParams) => {
  const { data, loading } = useGetReimbursementHistoryQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const validEvents: SupportedReimbursementTimeLineEvents[] = [];

  for (const event of data?.reimbursementCaseById.timeline ?? []) {
    if (hasValidTypename(event)) {
      validEvents.push(event);
    }
  }

  return { loading, timeline: validEvents };
};

import { Flex } from '@candisio/design-system';
import { ApproversAccessCardContainer } from 'components/DocumentAccess/ApproversAccessCardContainer';
import { BuiltInRolesAccessCardContainer } from 'components/DocumentAccess/BuiltInRolesAccessCardContainer';
import { ContractAccessCardContainer } from 'components/DocumentAccess/ContractAccessCardContainer';
import { SensitiveContractAccessCardContainer } from 'components/DocumentAccess/SensitiveContractAccessCardContainer';
import { ViewersAccessCardContainer } from 'components/DocumentAccess/ViewersAccessCardContainer';
import { DocumentType } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { ManageAccessContainer } from './ManageAccess/ManageAccessContainer';
import { useCanModifyAccess } from './toolkit/hooks/useCanModifyAccess';

interface DocumentAccessSectionsProps {
  documentType: DocumentType;
  formId: string;
  globalDocumentId: string;
  isSensitiveDocument: boolean;
  onCloseDrawer: () => void;
  readOnly?: boolean;
  setSubmitting: (isSubmitting: boolean) => void;
}

export const DocumentAccessSections = ({
  documentType,
  formId,
  globalDocumentId,
  isSensitiveDocument,
  onCloseDrawer,
  setSubmitting,
}: DocumentAccessSectionsProps) => {
  const canModifyDocumentAccess = useCanModifyAccess({ isSensitiveDocument });

  const accessSections = useMemo(() => {
    switch (documentType) {
      case DocumentType.Invoice:
        return InvoiceAccessSections(globalDocumentId);
      case DocumentType.Contract:
        return isSensitiveDocument
          ? SensitiveContractAccessSections(globalDocumentId)
          : ContractAccessSections(globalDocumentId);
      default:
        return GenericEcmAccessSections(globalDocumentId);
    }
  }, [documentType, isSensitiveDocument, globalDocumentId]);

  return (
    <Flex direction="column" gap="space16">
      {canModifyDocumentAccess ? (
        <ManageAccessContainer
          formId={formId}
          globalDocumentId={globalDocumentId}
          onCloseDrawer={onCloseDrawer}
          setSubmitting={setSubmitting}
        />
      ) : (
        <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
      )}
      {accessSections}
    </Flex>
  );
};

const InvoiceAccessSections = (globalDocumentId: string) => (
  <>
    <ApproversAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const SensitiveContractAccessSections = (globalDocumentId: string) => (
  <>
    <SensitiveContractAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const ContractAccessSections = (globalDocumentId: string) => (
  <>
    <ContractAccessCardContainer globalDocumentId={globalDocumentId} />
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

const GenericEcmAccessSections = (globalDocumentId: string) => (
  <>
    <BuiltInRolesAccessCardContainer globalDocumentId={globalDocumentId} />
  </>
);

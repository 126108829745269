import { Box } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { CREDIT_CARDS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

export const CreditCardsPromotionSettings = () => {
  const { creditCardsPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'creditCards',
    CREDIT_CARDS_UTM_SOURCE.SETTINGS
  );

  return (
    <Box height="24rem">
      <PromotionsProvider promotions={[creditCardsPromotion]}>
        <PromotionCard
          promotion={creditCardsPromotion}
          closable={false}
          customPromotionDetails={customPromotionDetails}
        />
      </PromotionsProvider>
    </Box>
  );
};

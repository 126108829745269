import { Box, Flex, LinkProps, Text, useTheme } from '@candisio/design-system';
import {
  IndicatorStatus,
  PulseIndicator,
} from 'components/PulseIndicator/PulseIndicator';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { motion } from 'motion/react';
import { Children, ReactNode, forwardRef } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom-v5-compat';

export interface NavLinkProps
  extends Pick<
      RouterNavLinkProps,
      'exact' | 'isActive' | 'location' | 'replace' | 'strict' | 'to'
    >,
    Omit<LinkProps, 'href' | 'ref'> {
  to: string;
  requiresUpgrade?: boolean;
}

interface NavLinkLabelProps {
  children: ReactNode;
}

interface NavLinkIndicatorProps {
  status?: IndicatorStatus;
  source?: string;
}

const MotionFlex = motion(Flex);

const Divider = () => (
  <Box padding="space16 0">
    <Box height={1} width="100%" background="gray250" />
  </Box>
);

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, to, requiresUpgrade, ...restProps }, ref) => {
    const isActive = location.pathname === to;
    const { colors } = useTheme();

    const motionFlexVariants = {
      initial: {
        background: 'transparent',
        color: requiresUpgrade ? colors.purple600 : colors.gray800,
        transition: { color: { duration: 0 } },
      },
      hover: {
        background: colors.bluebg,
        color: requiresUpgrade ? colors.purple700 : colors.blue600,
        transition: { color: { duration: 0 } },
      },
    };

    return (
      /** @ts-expect-error framer-motion upgrade type mismatch  */
      <MotionFlex
        as={ReactRouterLink}
        alignItems="center"
        background="transparent"
        padding="space8"
        gap="space8"
        style={{ textDecoration: 'none' }}
        borderRadius="medium"
        variants={motionFlexVariants}
        initial={isActive ? 'hover' : 'initial'}
        animate={isActive ? 'hover' : 'initial'}
        whileHover="hover"
        whileFocus="hover"
        cursor="pointer"
        color="gray800"
        ref={ref}
        to={to}
        {...restProps}
        aria-current={isActive ? 'page' : undefined}
      >
        {children}
        {requiresUpgrade && <AddOnIcon />}
      </MotionFlex>
    );
  }
);

export const NavLinkLabel = ({ children }: NavLinkLabelProps) => (
  <Text fontSize="small">{children}</Text>
);

export const NavLinkIndicator = ({
  status = 'default',
  source,
}: NavLinkIndicatorProps) => (
  <Box paddingTop="space2">
    <PulseIndicator status={status} source={source} />
  </Box>
);

export const NavLinkGroup = ({
  children,
  showDivider = true,
}: {
  children: ReactNode;
  showDivider?: boolean;
}) => {
  const validChildren = Children.toArray(children).filter(child =>
    Boolean(child)
  );

  const hasValidChildren = Children.count(validChildren) > 0;

  return (
    <>
      {hasValidChildren && children}
      {showDivider && hasValidChildren && <Divider />}
    </>
  );
};

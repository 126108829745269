import { History } from 'components/History/History';
import { HistoryLoading } from 'components/History/HistoryLoading';
import { HistoryLayout } from 'components/History/styles';
import moment from 'moment';
import { SupportedReimbursementTimeLineEvents } from 'views/Reimbursement/hooks/useReimbursementHistory';
import { getActivityTypeToReimbursementHistoryComponent } from 'views/Reimbursement/utils/getActivityTypeToReimbursementHistoryComponent';

interface ReimbursementHistoryProps {
  isLoading: boolean;
  timeline: Array<SupportedReimbursementTimeLineEvents>;
}

export const ReimbursementHistory = ({
  isLoading,
  timeline,
}: ReimbursementHistoryProps) => {
  if (isLoading) {
    return <HistoryLoading itemCount={4} />;
  }

  if (timeline.length === 0) {
    return null;
  }

  return (
    <HistoryLayout>
      <History>
        {timeline.map((event, index) => {
          const typename = event.__typename;
          const historyConfig =
            getActivityTypeToReimbursementHistoryComponent[typename];

          if (!historyConfig) {
            return null;
          }

          const {
            item: HistoryItem,
            content: HistoryContent,
            ...others
          } = historyConfig;

          const user = event.user;
          const author = user?.name;
          const avatarUrl = user.avatarUrl ?? undefined;

          const createdAt = moment(event.createdAt);

          return (
            <HistoryItem
              size="big"
              type={typename}
              key={event.id || index}
              author={author}
              avatarUrl={avatarUrl}
              absoluteTime={createdAt.format('LLL')}
              header={createdAt.fromNow()}
              isCollapsible={others.collapsible}
              hideTail={timeline.length - 1 === index}
              defaultCollapse={false}
            >
              <HistoryContent {...(event as any)} />
            </HistoryItem>
          );
        })}
      </History>
    </HistoryLayout>
  );
};

import {
  ApprovalFilterButton,
  FilterItems,
} from 'components/ApprovalFilterButton/ApprovalFilterButton';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Key } from 'react';
import { useApprovalReimbursementsCounter } from '../toolkit/hooks/useApprovalReimbursementsCounter';

export const ApprovalReimbursementsFilterButtonContainer = () => {
  const user = useCurrentUser();
  const { searchParams, setSearchParams } = useMutateSearchParams();

  const {
    allReimbursementsCount,
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
    loading,
  } = useApprovalReimbursementsCounter();

  const counters = {
    allApprovals: allReimbursementsCount,
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
  };
  const handleChange = (value: Key[]) => {
    if (value?.[0] === FilterItems.all) {
      searchParams.delete('approvers');
      searchParams.delete('requester');
    }

    if (value?.[0] === FilterItems.assignedToMe) {
      searchParams.delete('requester');
      searchParams.set('approvers', user?.id ?? '');
    }

    if (value?.[0] === FilterItems.requestedByMe) {
      searchParams.delete('approvers');
      searchParams.set('requester', user?.id ?? '');
    }

    setSearchParams(searchParams);
  };

  return (
    <ApprovalFilterButton
      counters={counters}
      isLoading={loading}
      onChange={handleChange}
    />
  );
};

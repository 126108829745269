import { Box, FieldContainer, Grid, RadioGroup } from '@candisio/design-system';
import { ProcessingFormFieldMetadataMessage } from 'components/Form/ProcessingForm/ProcessingFormFieldMetadataMessage';
import { RadioWithTooltip } from 'components/RadioGroup/RadioWithTooltip';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';

export interface ProcessingFormPurchaseOrderTypeFieldProps {
  documentId?: string;
  hasTooltip?: boolean;
  hasBorder?: boolean;
}

export function ProcessingFormPurchaseOrderTypeField({
  documentId,
  hasTooltip,
  hasBorder,
}: ProcessingFormPurchaseOrderTypeFieldProps) {
  const [t] = useTranslation();
  const { purchaseOrder } = useLinkedPurchaseOrder(documentId);

  const message = hasTooltip ? (
    !isEmpty(purchaseOrder) ? (
      <ProcessingFormFieldMetadataMessage
        confidence={1}
        source="purchaseOrder"
      />
    ) : (
      <Grid fontSize="small" maxWidth="space256">
        {t('document.requestApproval.invoiceType.tooltip')}
      </Grid>
    )
  ) : undefined;

  const fieldContainerStyle = !hasBorder ? { borderBottom: 'none' } : {};

  return (
    <FieldContainer readOnly message={message} style={fieldContainerStyle}>
      <RadioGroup
        orientation="horizontal"
        value={purchaseOrder?.purchaseOrderType ?? PurchaseOrderType.Service}
      >
        <RadioWithTooltip
          isReadOnly
          value={PurchaseOrderType.Service}
          tooltipChildren={
            purchaseOrder?.purchaseOrderType !== PurchaseOrderType.Goods ? (
              <Box width="space256">
                {t(
                  'document.requestApproval.invoiceType.serviceInvoiceTooltip'
                )}
              </Box>
            ) : undefined
          }
        >
          {t('document.requestApproval.invoiceType.labels.service')}
        </RadioWithTooltip>
        <RadioWithTooltip
          isReadOnly
          value={PurchaseOrderType.Goods}
          tooltipChildren={
            purchaseOrder?.purchaseOrderType === PurchaseOrderType.Goods ? (
              <Box width="space256">
                {t('document.requestApproval.invoiceType.goodsInvoiceTooltip')}
              </Box>
            ) : undefined
          }
        >
          {t('document.requestApproval.invoiceType.labels.goods')}
        </RadioWithTooltip>
      </RadioGroup>
    </FieldContainer>
  );
}

import { useCallback } from 'react';
import { mockErrorData } from '../mocks';

// TODO: TPX-1315 after schema update use real query data

// TODO: TPX-1315 talk with team should BE store parent data in error details or FE should use store/context
export const useGetProjectCodeImportItemError = () => {
  const getProjectCodeImportError = useCallback((id?: string) => {
    if (!id) return [];
    return mockErrorData;
  }, []);
  return getProjectCodeImportError;
};

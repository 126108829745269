import { Box, Grid, Link, Text } from '@candisio/design-system';
import { HookFormApproversFieldArray } from 'components/HookFormFields/HookFormApproversFieldArray/HookFormApproversFieldArray';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom-v5-compat';
import { useWorkflowTemplateData } from 'views/CreditCards/hooks/AccountingInfoFormFieldData/useWorkflowTemplateData';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReimbursementWorkflowField } from './ReimbursementWorkflowField';
import { ReimbursementApproversField } from './ReimbursementApproversField';
import { useCallback } from 'react';

export type ApprovalType = ReimbursementFormValues['approvalType'];

const TRANSLATION_KEYS = {
  workflow: {
    label: 'document.requestApproval.inputs.workflow.label',
    placeholder: 'document.requestApproval.inputs.workflow.placeholder',
    selectApproversManually:
      'document.requestApproval.inputs.workflow.selectApproversManually',
    editWorkflowLocally:
      'document.requestApproval.inputs.workflow.editWorkflowLocally',
  },
  approvalStep: {
    selectExistingWorkflow:
      'document.requestApproval.inputs.approvalStep.selectExistingWorkflow',
    createWorkflow:
      'document.requestApproval.inputs.approvalStep.createWorkflow',
  },
} as const;

export interface ReimbursementApprovalWorkflowFieldsProps {
  readOnly?: boolean;
  approversFieldOptions?: ReimbursementFormFieldOptions['approvers'];
  workflowFieldOptions?: ReimbursementFormFieldOptions['workflow'];
}

export const ReimbursementApprovalWorkflowFields = ({
  readOnly,
  approversFieldOptions,
  workflowFieldOptions,
}: ReimbursementApprovalWorkflowFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);
  const { organizationSlug } = useParams<AppRouteParams>();
  const { setValue } = useFormContext<ReimbursementFormValues>();

  const [approvalType, workflow] =
    useWatch<ReimbursementFormValues, ['approvalType', 'workflow']>({
      name: ['approvalType', 'workflow'],
    }) ?? [];

  const { approversFromWorkflow } = useWorkflowTemplateData({
    workflow,
  });

  const hasWorkflows = Boolean(
    workflowFieldOptions?.options?.defaultItems?.length
  );

  const showWorkflowField = hasWorkflows && approvalType === 'workflow';

  const handleCreateWorkflow = useCallback(() => {
    if (!organizationSlug) return;

    const workflowSettingsPath = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}/create`,
      { organizationSlug }
    );
    window.open(workflowSettingsPath, '_blank', 'rel=noopener noreferrer');
  }, [organizationSlug]);

  const toggleApprovalMode = () => {
    if (hasWorkflows) {
      const newMode = approvalType === 'workflow' ? 'approvers' : 'workflow';
      setValue('approvalType', newMode);

      if (approvalType === 'workflow') {
        setValue('workflow', undefined);
      }
    } else {
      handleCreateWorkflow();
    }
  };

  const updateApproversField = () => {
    if (approversFromWorkflow) {
      setValue('approvers', approversFromWorkflow);
    }
  };

  const getToggleButtonText = () => {
    if (!hasWorkflows) {
      return t(TRANSLATION_KEYS.approvalStep.createWorkflow);
    }

    if (approvalType === 'workflow') {
      return isNil(workflow)
        ? t(TRANSLATION_KEYS.workflow.selectApproversManually)
        : t(TRANSLATION_KEYS.workflow.editWorkflowLocally);
    }

    return t(TRANSLATION_KEYS.approvalStep.selectExistingWorkflow);
  };

  return (
    <Grid gap="space4" alignContent="start">
      {showWorkflowField ? (
        <ReimbursementWorkflowField
          name="workflow"
          label={t('document.requestApproval.inputs.workflow.label')}
          placeholder={t(
            'document.requestApproval.inputs.workflow.placeholder'
          )}
          readOnly={readOnly}
          defaultItems={workflowFieldOptions?.options?.defaultItems}
        />
      ) : (
        <HookFormApproversFieldArray<ReimbursementFormValues>
          name="approvers"
          readOnly={readOnly}
          approversFieldOptions={approversFieldOptions?.options}
          renderApprovalStep={props => (
            <ReimbursementApproversField {...props} />
          )}
        />
      )}
      {!readOnly && (
        <Box>
          <Link
            as="button"
            external={!hasWorkflows}
            onClick={() => {
              toggleApprovalMode();
              updateApproversField();
            }}
          >
            <Text>{getToggleButtonText()}</Text>
          </Link>
        </Box>
      )}
    </Grid>
  );
};

import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { NavigationTableParams } from 'components/DocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { useCallback, useEffect, useMemo, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { appendParamsToQueryString } from 'utils/url-helper';
import { TabView } from 'views/Inbox/models';
import {
  ArchiveDocumentsTable,
  ArchiveDocumentsTableDSProps,
} from '../ArchiveDocumentsTable/ArchiveDocumentsTable';
import { useArchiveDocumentsData } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsData';
import { useArchiveDocumentsDataDeprecated } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsDataDeprecated';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

type Props = RouteComponentProps<{ organizationSlug: string }>;

type PreviewDocumentProps = {
  previewDocumentId: string | null;
  setPreviewDocumentId: (documentId: string | null) => void;
};

export const MAX_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD = 1000;
export const MIN_DOCUMENTS_FOR_ORIGINAL_FILES_ZIP_DOWNLOAD = 1;

type ArchiveDocumentsComponentProps = Props &
  PreviewDocumentProps & {
    showImprovedSearch?: boolean;
    archiveDocumentsDataProps:
      | ReturnType<typeof useArchiveDocumentsData>
      | ReturnType<typeof useArchiveDocumentsDataDeprecated>;
    shouldEnableSortByWithSearchQuery: boolean;
  };

export const ArchiveDocumentsContainer = (props: Props) => {
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const { showConsistentSortAndFiltering } = useEcm();

  return showConsistentSortAndFiltering ? (
    <ArchiveDocuments
      {...props}
      previewDocumentId={previewDocumentId}
      setPreviewDocumentId={setPreviewDocumentId}
    />
  ) : (
    <ArchiveDocumentsDeprecated
      {...props}
      previewDocumentId={previewDocumentId}
      setPreviewDocumentId={setPreviewDocumentId}
    />
  );
};

const ArchiveDocuments = (props: Props & PreviewDocumentProps) => {
  const archiveDocumentsDataProps = useArchiveDocumentsData({
    previewDocumentId: props.previewDocumentId,
  });
  const { showImprovedSearch } = useEcm();
  return (
    <ArchiveDocumentsComponent
      {...props}
      archiveDocumentsDataProps={archiveDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={true}
      showImprovedSearch={showImprovedSearch}
      previewDocumentId={props.previewDocumentId}
      setPreviewDocumentId={props.setPreviewDocumentId}
    />
  );
};

/**
 * @deprecated
 */
const ArchiveDocumentsDeprecated = (props: Props & PreviewDocumentProps) => {
  const archiveDocumentsDataProps = useArchiveDocumentsDataDeprecated({
    previewDocumentId: props.previewDocumentId,
  });

  return (
    <ArchiveDocumentsComponent
      {...props}
      archiveDocumentsDataProps={archiveDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={false}
      previewDocumentId={props.previewDocumentId}
      setPreviewDocumentId={props.setPreviewDocumentId}
    />
  );
};

export const ArchiveDocumentsComponent = ({
  archiveDocumentsDataProps,
  previewDocumentId,
  setPreviewDocumentId,
  shouldEnableSortByWithSearchQuery,
  match: {
    params: { organizationSlug },
  },
}: ArchiveDocumentsComponentProps) => {
  const { showDocumentPreview, showDocumentPreviewPromo } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const { search } = useLocation();
  const { identify } = useAnalytics();

  const orgId = useOrganizationId();

  const {
    documentsCount,
    documentsTableData,
    handleDebounceSearch,
    hasMoreData,
    loading,
    onLoadMore,
    selectedDocumentsCount,
    isUsingSearchQuery,
    searchQuery,
  } = archiveDocumentsDataProps;

  useEffect(() => {
    if (loading) return;

    identify({
      archive_documents_count: documentsCount ?? 0,
    });
  }, [documentsCount, identify, loading]);

  const documentPreviewRowOverlay: ArchiveDocumentsTableDSProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    setPreviewDocumentId(data.id);
                  }}
                  isSelected={data.id === previewDocumentId}
                />
              );
            }
          : undefined,
      [showPreview, previewDocumentId, setPreviewDocumentId]
    );

  const cursor =
    documentsTableData.find(d => d.id === previewDocumentId)?.cursor ?? null;

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);

      const path = `/${organizationSlug}${Routes.ARCHIVE}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocumentId(null);
  }, [setPreviewDocumentId]);

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
          promotionLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
          closePreviewDrawer={closePreview}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? '',
      });

      return `/${orgId}${Routes.ARCHIVE}/${id}?${searchWithCursor}`;
    },
    [orgId, search]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      <ArchiveDocumentsTable
        data={documentsTableData}
        isUsingSearchQuery={isUsingSearchQuery}
        isLoading={loading}
        hasMoreData={hasMoreData}
        selectedDocumentsCount={selectedDocumentsCount}
        organizationSlug={organizationSlug}
        shouldEnableSortByWithSearchQuery={shouldEnableSortByWithSearchQuery}
        searchFieldValue={searchQuery}
        searchFieldOnChange={query => {
          handleDebounceSearch(query);
        }}
        onEndReached={onLoadMore}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </ArchiveViewLayout>
  );
};

import {
  Box,
  Button,
  Flex,
  Grid,
  Popover,
  PopoverProps,
  Spinner,
  Text,
  TextField,
} from '@candisio/design-system';
import { AnimatePresence, Variants, motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ccPaginationFiltersHooks } from '../../utils/paginationFilterHooks';
import { WidgetFilter } from '../utils';
import { VirtualizedListBox } from './VirtualizedListBox';

export interface FilterProps extends PopoverProps {
  filterValue: string[];
  onApply: (filters: string[]) => void;
  onReset: () => void;
  filterName: WidgetFilter;
}

const MotionGrid = motion(Grid);

const spinnerVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.5,
    },
  },
  show: {
    opacity: 1,
    transition: { duration: 0.3, type: 'tween' },
  },
};

export const Filter = forwardRef<HTMLDivElement, FilterProps>(
  ({ filterValue = [], onApply, onReset, filterName, ...restProps }, ref) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
    const [currentFilters, setCurrentFilters] = useState<string[]>(filterValue);
    const [searchStr, setSearchStr] = useState('');

    const usePaginationWithSearchFilter = ccPaginationFiltersHooks[filterName];

    const { filterOptions, handleDebounceSearch, loadMore, loading } =
      usePaginationWithSearchFilter({ filteredValues: filterValue, searchStr });

    const sortedOptions = [
      ...filterOptions.filter(f => filterValue.includes(f.id)),
      ...filterOptions.filter(f => !filterValue.includes(f.id)),
    ];

    const searchInputId = `filter-input-${filterName}`;

    const resetSearch = () => {
      handleDebounceSearch?.('');
    };

    const submitSearch = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (sortedOptions.length !== 1) {
        return;
      }

      onApply([sortedOptions[0].id]);
    };

    const handleApply = () => {
      resetSearch();
      onApply(currentFilters);
    };

    const handleReset = () => {
      resetSearch();
      onReset();
    };

    return (
      <Popover {...restProps} ref={ref} minWidth="300px" padding={0}>
        <Grid height="100%" padding="space16" maxHeight="inherit" gap="space8">
          {handleDebounceSearch ? (
            <Box as="form" onSubmit={submitSearch}>
              <TextField
                flex="none"
                layout="compact"
                id={searchInputId}
                input={{
                  value: searchStr,
                  placeholder: t('filterSearchPlaceholder'),
                  onChange: e => {
                    handleDebounceSearch(e.target.value);
                    setSearchStr(e.target.value);
                  },
                }}
              />
            </Box>
          ) : null}
          {sortedOptions.length === 0 && !loading ? (
            <Text justifySelf="center">
              {t('filterWithPagination.nothingFound')}
            </Text>
          ) : (
            <VirtualizedListBox
              items={sortedOptions}
              aria-labelledby={`filter-${filterName}`}
              sortedOptions={sortedOptions}
              loadMore={loadMore}
              onSetCurrentFilters={setCurrentFilters}
              currentFilters={currentFilters}
            />
          )}
          <Flex justifyContent="space-between">
            <Button variant="tertiary" onClick={handleReset}>
              {t('filterReset')}
            </Button>

            <AnimatePresence initial={false}>
              {loading && (
                <MotionGrid
                  variants={spinnerVariants}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  key="loading-indicator"
                  placeContent="center"
                >
                  <Spinner size="space24" />
                </MotionGrid>
              )}
            </AnimatePresence>

            <Button variant="secondary" onClick={handleApply}>
              {t('filterApply')}
            </Button>
          </Flex>
        </Grid>
      </Popover>
    );
  }
);

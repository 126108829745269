import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { UseFormGetValues } from 'react-hook-form';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { FastApproveReturn } from './FooterActionBox';
import { ReviewStatusFooterActionBox } from './ReviewStatusFooterActionBox';
import { ReviewStatusFooterActionBoxDeprecated } from './ReviewStatusFooterActionBoxDeprecated';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface ReviewStatusFooterContainerProps {
  createdById?: string;
  isFastApprovePending: boolean;
  isRequestAmendmentPending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
}

export const ReviewStatusFooterContainer = ({
  createdById,
  isFastApprovePending,
  isRequestAmendmentPending,
  onRequestAmendment,
  onFastApprove,
  getExpenses,
  reimbursement,
  onCycleReimbursments,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
}: ReviewStatusFooterContainerProps) => {
  const expenseApprovalWorkflowFF = useCandisFeatureFlags(
    FEATURE_FLAGS.expenseApprovalWorkflow
  );

  if (expenseApprovalWorkflowFF) {
    return (
      <ReviewStatusFooterActionBox
        createdById={createdById}
        isFastApprovePending={isFastApprovePending}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
        onFastApprove={onFastApprove}
        getExpenses={getExpenses}
        reimbursement={reimbursement}
        onCycleReimbursments={onCycleReimbursments}
        reimbursementItems={reimbursementItems}
        refetchReimbursement={refetchReimbursement}
        refetchReimbursementItems={refetchReimbursementItems}
      />
    );
  }

  return (
    <ReviewStatusFooterActionBoxDeprecated
      createdById={createdById}
      isFastApprovePending={isFastApprovePending}
      onRequestAmendment={onRequestAmendment}
      isRequestAmendmentPending={isRequestAmendmentPending}
      onFastApprove={onFastApprove}
      getExpenses={getExpenses}
      reimbursement={reimbursement}
      reimbursementItems={reimbursementItems}
      refetchReimbursement={refetchReimbursement}
      refetchReimbursementItems={refetchReimbursementItems}
    />
  );
};

import { Flex, Grid, Icon, Text } from '@candisio/design-system';
import { Maybe } from '@graphql-tools/utils';
import { PurchaseOrderImportHistoryEntryStatus } from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ImportStatus, ImportStatusProps } from './ImportStatus';
import { mapStatusToIcon } from './utils';

export interface PurchaseOrderImportHistoryItemProps {
  id?: string;
  createdAt?: Maybe<string>;
  importStatus?: ImportStatusProps;
  username?: string;
  status?: PurchaseOrderImportHistoryEntryStatus;
}

const MotionGrid = motion(Grid);

export const PurchaseOrdersImportHistoryItem = ({
  status,
  createdAt,
  username,
  importStatus,
}: PurchaseOrderImportHistoryItemProps) => {
  const [t] = useTranslation();
  const dateFormatter = DateFormatters.compactDatetime;
  const successCount = importStatus?.successfullyCreatedDocumentsCount ?? 0;
  const failedCount = importStatus?.failedToImportDocumentsCount ?? 0;
  const hasFailedImport = successCount === 0 && failedCount > 0;

  if (status === PurchaseOrderImportHistoryEntryStatus.InProcess) return null;

  return (
    <MotionGrid
      gap="space16"
      height="space64"
      padding="space8 space20"
      templateColumns="space18 auto auto"
    >
      {status && (
        <Icon
          size="space24"
          alignSelf="center"
          justifySelf="center"
          {...(hasFailedImport
            ? mapStatusToIcon[PurchaseOrderImportHistoryEntryStatus.Failed]
            : mapStatusToIcon[status])}
        />
      )}
      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4"
      >
        <ImportStatus {...importStatus} />
        <Flex
          color="gray500"
          fontSize="small"
          fontWeight="regular"
          gap="space4"
        >
          {createdAt && (
            <>
              <Text>{dateFormatter(new Date(createdAt))}</Text>
              <Text>{t('importHistoryList.bulletPoint')}</Text>
            </>
          )}
          {username && <Text>{username}</Text>}
        </Flex>
      </Grid>
    </MotionGrid>
  );
};

import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

interface LastSaveIndicatorProps {
  mostRecentUpdatedAt: Date | null;
}

const RECENT_DURATION_MS = 5000;

export const useLastSaveIndicator = ({
  mostRecentUpdatedAt,
}: LastSaveIndicatorProps) => {
  const [showRecentStyle, setShowRecentStyle] = useState(false);

  const handleShowRecentStyle = useCallback((val: boolean) => {
    setShowRecentStyle(val);
  }, []);

  useEffect(() => {
    if (!mostRecentUpdatedAt) return;

    const now = new Date();
    const timeSinceUpdateMs = moment(now).diff(moment(mostRecentUpdatedAt));

    // Only show green style if the update happened within the last 3 seconds
    const shouldShowRecentStyle = timeSinceUpdateMs < RECENT_DURATION_MS;

    if (shouldShowRecentStyle) {
      handleShowRecentStyle(true);
      const timeoutDuration = RECENT_DURATION_MS - timeSinceUpdateMs;

      const timer = setTimeout(() => {
        handleShowRecentStyle(false);
      }, timeoutDuration);

      return () => clearTimeout(timer);
    }
  }, [handleShowRecentStyle, mostRecentUpdatedAt]);

  const formattedTime = mostRecentUpdatedAt
    ? moment(mostRecentUpdatedAt).fromNow()
    : undefined;

  return {
    showRecentStyle,
    formattedTime,
  };
};

import { IntegrationName } from 'generated-types/graphql.types';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

interface UseReimbursementResult {
  canUseReimbursement: boolean;
  canUseEmployeeAPNSettings: boolean;
  showSearchOverlay: boolean;
  showSearchOverlayPromo: boolean;
  showImprovedSearch: boolean;
}

export const supportedEmployeeAPNIntegrations = [
  IntegrationName.Datev,
  IntegrationName.DatevAdjacent,
];

export const useReimbursement = (): UseReimbursementResult => {
  const entitlements = useEntitlementsFlag();
  const { ft, promo, show } = useFeatureToggle();
  const integration = useIntegrationSettings();
  const [
    travelExpenseManagementFF,
    employeeContactTypeFF,
    searchOverlayFF,
    enterpriseContentManagementFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.travelExpenseManagement,
    FEATURE_FLAGS.employeeContactType,
    FEATURE_FLAGS.searchOverlay,
    FEATURE_FLAGS.enterpriseContentManagement,
  ]);

  const isEmployeeAPNSupported =
    supportedEmployeeAPNIntegrations.includes(integration);

  const canUseReimbursement =
    show(ft.REIMBURSEMENT) && travelExpenseManagementFF;

  const canUseEmployeeAPNSettings =
    canUseReimbursement && employeeContactTypeFF && isEmployeeAPNSupported;

  if (entitlements === Entitlements.LEGACY) {
    return {
      canUseReimbursement: false,
      canUseEmployeeAPNSettings: false,
      showSearchOverlayPromo:
        searchOverlayFF &&
        !enterpriseContentManagementFF &&
        promo(ft.SEARCH_OVERLAY),
      showSearchOverlay:
        searchOverlayFF &&
        enterpriseContentManagementFF &&
        show(ft.SEARCH_OVERLAY),
      showImprovedSearch: enterpriseContentManagementFF && !searchOverlayFF,
    };
  }

  return {
    canUseReimbursement,
    canUseEmployeeAPNSettings,
    showSearchOverlay: searchOverlayFF && show(ft.SEARCH_OVERLAY),
    showSearchOverlayPromo: searchOverlayFF && promo(ft.SEARCH_OVERLAY),
    showImprovedSearch: show(ft.FULL_TEXT_SEARCH) && !searchOverlayFF,
  };
};

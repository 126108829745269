import { QueryHookOptions } from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { SortingRule } from 'react-table';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { getPayableDocumentsPaginationNewEsQuery } from '../queries';
import { SimplifiedPayableDocument } from '../types';
import { mapToSortInput } from './mapToSort';

interface UsePayableDocumentQueryProps {
  sortBy: SortingRule<SimplifiedPayableDocument>[];
  skip?: boolean;
  onCompleted?: QueryHookOptions<
    Pick<Query, 'payableDocumentsEs'>
  >['onCompleted'];
}

export const usePayableDocumentsListQuery = ({
  sortBy,
  skip = false,
  onCompleted,
}: UsePayableDocumentQueryProps) => {
  const queryRootKey = 'payableDocumentsEs';
  const queryOptions = {
    variables: {
      limit: getApproximateNumberOfRowForTable(),
      ...(sortBy.length > 0 ? { sort: mapToSortInput(sortBy) } : {}),
    },
    skip,
    onCompleted,
  };

  const computeVariables = (endCursor: string) => ({
    limit: getApproximateNumberOfRowForTable(),
    after: endCursor,
    ...(sortBy.length > 0 ? { sort: mapToSortInput(sortBy) } : {}),
  });

  const {
    data,
    loading: isLoading,
    onLoadMore,
    refetch,
  } = usePagination<Pick<Query, 'payableDocumentsEs'>>(
    getPayableDocumentsPaginationNewEsQuery,
    queryRootKey,
    queryOptions,
    {
      computeVariables,
    }
  );

  const payableDocumentData = data && data[queryRootKey];
  const { pageInfo, edges } = payableDocumentData || {};

  const payableDocumentCount = pageInfo?.totalCount || 0;
  const payableDocumentsCombinedList = edges || [];

  const payableDocuments = edges || [];
  const hasMoreData = pageInfo?.hasNextPage ?? false;

  return {
    hasMoreData,
    payableDocuments,
    payableDocumentEdges: edges,
    payableDocumentsCombinedList,
    isLoading,
    onLoadMore,
    payableDocumentCount,
    endCursor: pageInfo?.endCursor,
    refetch,
  };
};

import {
  Button,
  Flex,
  Grid,
  Link,
  Popover,
  TruncatedText,
  defaultTheme,
  usePopover,
} from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const EcmSearchFeedbackButton = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Link
      id="dms-cm-search-intercom-feedback-button"
      icon="megaphone"
      iconPosition="left"
    >
      {t('search.feedbackButton')}
    </Link>
  );
};

const MotionButton = motion(Button);

export const EcmSearchHelpCenterButton = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top left' });

  return (
    <>
      <Link
        as="button"
        {...triggerProps}
        ref={triggerRef}
        icon="infoOutline"
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          minInlineSize: '10ch',
          padding: '.5rem',
        }}
      >
        <TruncatedText>{t('search.helpCenterTooltip.label')}</TruncatedText>
      </Link>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef}>
          <Grid width="18.5rem" gap="space16">
            {t('search.helpCenterTooltip.tooltipText')}
            <Flex gap="space16" alignItems="center">
              <MotionButton
                as="a"
                href={t('search.helpCenterTooltip.helpCenterLink.url')}
                color="blue"
                target="_blank"
                icon="newTab"
                iconPosition="right"
                transition={{ duration: '0' }}
                whileHover={{ color: defaultTheme.colors.gray0 }}
              >
                {t('search.helpCenterTooltip.helpCenterLink.label')}
              </MotionButton>
              <EcmSearchFeedbackButton />
            </Flex>
          </Grid>
        </Popover>
      )}
    </>
  );
};

/**
 * When adding a new tour popover, add a enum entry with its id here - this will
 * automatically disable the popover in E2E tests.
 */
export enum TOUR_POPOVER_IDS {
  EXAMPLE_TOUR_POPOVER = 'example-tour-popover',
  MANAGE_RELATIONSHIPS_SEEN = 'manage-relationships-activation-seen',
  BASELINE_SEARCH_PROMOTION_SEEN = 'baseline-search-promotion-seen',
  FILTER_APPROVAL_VIEW_NEW = 'filter-approval-view-new',
}

import { Button, Card, Grid } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { WaitingForClarificationCheckbox } from '../../WaitingForClarification';
import { useWaitingForClarificationCheckbox } from '../../useWaitingForClarificationCheckbox';

interface ContactSupplierActionCardProps {
  formId: string;
  disabled: boolean;
  documentId: string;
}

export const ContactSupplierActionCard = ({
  formId,
  disabled,
  documentId,
}: ContactSupplierActionCardProps) => {
  const { t } = useTranslation();
  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  const { selected, setSelected, invokeMutation } =
    useWaitingForClarificationCheckbox(documentId, true);

  const handleClick = async () => {
    if (visualiseWaitingForClarificationFF) await invokeMutation();
  };

  return (
    <Card background="bluebg" width="100%" zIndex={1000} borderTopRadius="0">
      <Grid autoFlow="column" justifyContent="space-between">
        <Button
          id="contact-supplier-intercom-feedback-button"
          icon="megaphone"
          variant="tertiary"
          width="fit-content"
          color="blue"
        >
          {t('contactSupplierFromCandis.feedbackButton')}
        </Button>
        <Grid
          autoFlow="column"
          justifyContent="right"
          gap="space12"
          alignItems="center"
        >
          {visualiseWaitingForClarificationFF && (
            <WaitingForClarificationCheckbox
              isSelected={selected}
              handleChange={setSelected}
            />
          )}
          <Button
            form={formId}
            disabled={disabled}
            type="submit"
            alignSelf="end"
            width="fit-content"
            onClick={handleClick}
          >
            {t('contactSupplierFromCandis.actions.sendEmail')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { CREDIT_CARDS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { Approvals } from 'views/Integrations/Export/elements/table/approvals/Approvals';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';
import { approvalsData } from './tableData';

export const CreditCardsPromotionExportApproval = () => {
  const { creditCardsPromotion } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'creditCards',
    CREDIT_CARDS_UTM_SOURCE.EXPORT_APPROVAL
  );

  return (
    <Wrapper>
      <TableWrapper>
        <Approvals data={approvalsData} isLoading={false} />
      </TableWrapper>
      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[creditCardsPromotion]}>
          <PromotionCard
            promotion={creditCardsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

import { ReimbursementItemStatus } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';
import { DeepPartial } from 'react-hook-form';
import { RefinementCtx, ZodIssueCode, z } from 'zod';

const AMOUNTS = {
  MIN: 0.01,
  MAX: 999999,
  MAX_TIP: 999,
} as const;

const fileSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
});

const guestFieldsSchema = z.object({
  internalGuests: z.string().nullish(),
  externalGuests: z.string().nullish(),
});

const amountsSchema = z.object({
  totalAmount: z.number().min(AMOUNTS.MIN).max(AMOUNTS.MAX).nullish(),
  receiptAmount: z.number().min(AMOUNTS.MIN).max(AMOUNTS.MAX).nullish(),
  tipAmount: z.number().min(AMOUNTS.MIN).max(AMOUNTS.MAX_TIP).nullish(),
});

// Common fields that both expense types share
const commonExpenseFields = {
  expenseId: z.string().uuid(),
  reason: z.string().trim().nullish(),
  files: z.array(fileSchema).min(1),
  invoiceNumber: z.string().trim().nullish(),
  isExpenseExcluded: z.boolean(),
  itemStatus: z.nativeEnum(ReimbursementItemStatus),
  isExtractedDataAccepted: z.boolean(),
  expenseDate: z.string().trim().nullish(),
};

// Form schemas
const baseGeneralExpenseFormSchema = z.object({
  ...commonExpenseFields,
  expenseType: z.literal('general'),
  totalAmount: amountsSchema.shape.totalAmount,
  extractedData: z
    .object({
      invoiceNumber: z.string().optional(),
      expenseDate: z.string().optional(),
      totalAmount: z.number().nullish(),
    })
    .optional(),
});

const baseHospitalityFormSchema = z.object({
  ...commonExpenseFields,
  expenseType: z.literal('hospitality'),
  location: z.string().trim().nullish(),
  ...guestFieldsSchema.shape,
  ...amountsSchema.shape,
  extractedData: z
    .object({
      invoiceNumber: z.string().optional(),
      location: z.string().optional(),
      expenseDate: z.string().optional(),
      receiptAmount: z.number().nullish(),
      tipAmount: z.number().nullish(),
    })
    .optional(),
});

// Validation functions
const validateDate = (ctx: RefinementCtx, values: ExpenseSchema) => {
  if (!values.expenseDate) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['expenseDate'],
    });
    return;
  }

  const valueMoment = moment(values.expenseDate, moment.ISO_8601);
  const now = moment.tz('Europe/Berlin');
  const minDate = now.clone().subtract(1, 'year').toDate();

  if (valueMoment.isBefore(minDate)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: ['expenseDate'],
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMinYear',
      },
    });
  }

  if (valueMoment.isAfter(now)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: ['expenseDate'],
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMaxDate',
      },
    });
  }
};

const validateHospitalitySpecificFields = (
  ctx: RefinementCtx,
  values: ExpenseSchema
) => {
  if (values.expenseType !== 'hospitality') return;

  if (!values.location) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['location'],
    });
  }

  if (!values.internalGuests && !values.externalGuests) {
    ['internalGuests', 'externalGuests'].forEach(field => {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [field],
      });
    });
  }
};

const validateAmounts = (ctx: RefinementCtx, values: ExpenseSchema) => {
  if (isNil(values.totalAmount)) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'number',
      received: 'null',
      path: ['totalAmount'],
    });
  }

  if (values.expenseType === 'hospitality' && isNil(values.receiptAmount)) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'number',
      received: 'null',
      path: ['receiptAmount'],
    });
  }
};

const validateCommonFields = (ctx: RefinementCtx, values: ExpenseSchema) => {
  if (!values.reason) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['reason'],
    });
  }

  if (!values.invoiceNumber) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['invoiceNumber'],
    });
  }
};

// Main schema
const expense = z
  .discriminatedUnion('expenseType', [
    baseHospitalityFormSchema,
    baseGeneralExpenseFormSchema,
  ])
  .superRefine((values, ctx) => {
    if (values.isExpenseExcluded) return;
    validateDate(ctx, values);
    validateAmounts(ctx, values);
    validateCommonFields(ctx, values);
    validateHospitalitySpecificFields(ctx, values);
  });

export const expensesFormSchema = z.object({
  expenses: z.array(expense),
});

// Types
export type ExpensesFormOutput = z.infer<typeof expensesFormSchema>;
export type ExpenseSchema = z.infer<typeof expense>;
export type ExpenseSchemaOutput = z.infer<typeof expense>;
export type ExpensesFormValues = DeepPartial<ExpensesFormOutput>;

import {
  Avatar,
  Grid,
  Item,
  Separator,
  TruncatedText,
} from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import {
  DirectDocumentAccess,
  DocumentAccessSubjectType,
} from 'generated-types/graphql.types';
import { Fragment, Key, useRef } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, zodResolver } from 'utils/zodFormValidation';
import {
  ManageAccessFormValues,
  ManageAccessSubmitValues,
  manageAccessFormSchema,
} from './manageAccessFormSchema';
import { TeamMembersComboBoxFieldOptions } from './types';

interface ManageAccessFormProps {
  formId: string;
  initialValues: ManageAccessFormValues;
  onSubmit: (values: ManageAccessSubmitValues) => void;
  readOnly?: boolean;
  teamMembersFieldOptions: TeamMembersComboBoxFieldOptions;
}

export const ManageAccessForm = ({
  formId,
  initialValues,
  onSubmit,
  readOnly,
  teamMembersFieldOptions,
}: ManageAccessFormProps) => {
  const [t] = useTranslation();
  const form = useForm<
    ManageAccessFormValues,
    undefined,
    ManageAccessSubmitValues
  >({
    defaultValues: initialValues,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: zodResolver({
      zodSchema: manageAccessFormSchema,
      // It's not possible for the form to have errors.
      errorMessages: {} as ErrorMessages<typeof manageAccessFormSchema>,
    }),
  });

  const comboxBoxRef = useRef<HTMLDivElement>(null);

  const { fields, insert, swap, update } = useFieldArray({
    name: 'memberships',
    keyName: 'fieldArrayId',
    control: form.control,
    shouldUnregister: false,
  });

  const selectedMembers = new Set(
    fields
      // Ensure members with directAccess = NONE are still included in the
      // suggestions
      .filter(field => field.directAccess !== DirectDocumentAccess.None)
      .map(field => field.id)
  );

  const filteredMembers = teamMembersFieldOptions.items.filter(
    ({ key }) => !selectedMembers.has(key.toString())
  );

  const onSelectionChange = (key: Key | null) => {
    try {
      if (!key) {
        return;
      }

      const member = teamMembersFieldOptions.items.find(m => m.key === key);
      const formFieldIdx = fields.findIndex(field => field.id === member?.key);

      // If the member is already in the form data but is hidden in the UI (directAccess = None),
      // we want to update that field array item instead of adding a new one.
      if (formFieldIdx >= 0) {
        update(formFieldIdx, {
          ...fields[formFieldIdx],
          directAccess: DirectDocumentAccess.View,
        });
        // Put it at the top of the list to ensure it's visible.
        swap(formFieldIdx, 0);

        return;
      }

      if (!selectedMembers.has(key.toString()) && member) {
        insert(0, {
          id: member.key,
          name: member.memberName,
          avatarUrl: member.avatarUrl,
          directAccess: DirectDocumentAccess.View,
          subjectType: DocumentAccessSubjectType.Membership,
        });
      }
    } finally {
      // We're re-purposing the combobox field as a search field with suggestions,
      // so we want to clear the input after a selection instead of putting the
      // selected item in the input field.
      form.resetField('search');
      const inputField = comboxBoxRef.current?.querySelector('input');
      inputField?.blur();
    }
  };

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        id={formId}
        gap="space16"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <HookFormComboBoxField<ManageAccessFormValues>
          isVirtualized
          autoFocus
          readOnly={readOnly}
          isLoading={teamMembersFieldOptions.isLoading}
          defaultItems={filteredMembers}
          name="search"
          label=""
          placeholder={t('documentAccess.membership.form.search.placeholder')}
          wrapperGridProps={{ ref: comboxBoxRef }}
          onChange={onSelectionChange}
        >
          {({ key, children, memberName, avatarUrl }) => (
            <Item key={key} textValue={memberName}>
              <HookFormUsersFieldOption avatarUrl={avatarUrl}>
                {children}
              </HookFormUsersFieldOption>
            </Item>
          )}
        </HookFormComboBoxField>
        <Grid gap="space8" templateColumns="70fr 30fr">
          {fields.map((field, index) => {
            if (field.directAccess === DirectDocumentAccess.None) {
              return null;
            }

            return (
              <Fragment key={field.fieldArrayId}>
                <Grid
                  gap="space8"
                  autoFlow="column"
                  alignItems="center"
                  templateColumns="auto 1fr"
                >
                  <Avatar
                    size="small"
                    img={field.avatarUrl}
                    name={field.name}
                  />
                  <TruncatedText>{field.name}</TruncatedText>
                </Grid>

                <Grid minWidth="max-content">
                  <HookFormSelectField<ManageAccessFormValues>
                    name={`memberships.${index}.directAccess`}
                    disabledKeys={['separator-key']}
                    readOnly={readOnly}
                    onChange={newValue => {
                      // We want to remove the row from the UI but keep the form data.
                      // react-hook-form does not re-render array fields when values
                      // change, so we use `update` here to force a re-render.
                      if (newValue?.toString() === DirectDocumentAccess.None) {
                        update(index, {
                          ...field,
                          directAccess: DirectDocumentAccess.None,
                        });
                      }
                    }}
                    items={[
                      {
                        key: DirectDocumentAccess.View,
                        children: t('documentAccess.membership.access.view'),
                      },
                      // Workaround to add a separator as the HookFormSelectField
                      // component does not support separators.between items yet.
                      {
                        key: 'separator-key',
                        children: <Separator />,
                      },
                      {
                        key: DirectDocumentAccess.None,
                        children: t('documentAccess.membership.access.remove'),
                      },
                    ]}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </FormProvider>
  );
};

import {
  Box,
  Card,
  Flex,
  Grid,
  Icon,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import {
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';

export type Expense = 'hospitality' | 'general';

interface ExpenseFormWrapperProps {
  totalAmount: number | null | undefined;
  reason: string | null | undefined;
  expenseType: Expense;
  expenseId: string;
  reimbursementStatus?: ReimbursementCaseStatus;
  itemStatus?: ReimbursementItemStatus;
  isExcluded: boolean;
  leftSectionInvoice: ReactNode;
  rightSectionFields: ReactNode;
  footer: ReactNode;
}

const gradientBorderStyle = {
  border: '2px solid transparent',
  background: `
          linear-gradient(transparent, transparent) padding-box,
          linear-gradient(180deg, blue300 0%, transparent 50%) border-box
        `,
};

export const mappedTypesToItem: Record<
  Expense,
  { placeholder: string; icon: JSX.Element }
> = {
  general: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: (
      <Grid
        background="green100"
        placeContent="center"
        borderRadius="small"
        padding="space4"
      >
        <Icon icon="receipt" size="space18" />
      </Grid>
    ),
  },
  hospitality: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: (
      <Grid
        background="pink100"
        placeContent="center"
        borderRadius="small"
        paddingLeft="6px"
        padding="space4"
      >
        <Icon icon="hospitality" size="space18" />
      </Grid>
    ),
  },
};

export const ExpenseLayout = ({
  totalAmount,
  expenseType,
  reason,
  expenseId,
  reimbursementStatus,
  itemStatus,
  isExcluded,
  leftSectionInvoice,
  footer,
  rightSectionFields,
}: ExpenseFormWrapperProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { itemRefs } = useReimbursementFormsContext();
  const { drawerView, activeBooking } = useReimbursementSplitBookingHelpers();

  const formattedAmount = totalAmount
    ? amountFormatNonNull(totalAmount, 'EUR')
    : amountFormatNonNull(0, 'EUR');

  const expense = mappedTypesToItem[expenseType];

  const title = reason ? reason : t(expense.placeholder);

  const showExportStatusInfo =
    reimbursementStatus === ReimbursementCaseStatus.PartiallyExported &&
    itemStatus === ReimbursementItemStatus.ReadyToExport;

  const shouldAddHighlight =
    drawerView === 'split-bookings' &&
    activeBooking.reimbursementItemId === expenseId;

  return (
    <Box
      as="form"
      id={expenseId}
      ref={(elm: HTMLFormElement) => {
        if (itemRefs?.current) {
          itemRefs.current[expenseId] = elm;
        }
      }}
      borderRadius="medium"
      {...(shouldAddHighlight && gradientBorderStyle)}
    >
      <Card
        background="gray100"
        padding="space16 space40"
        borderBottom="1px solid gray250"
        borderBottomRadius="none"
      >
        {showExportStatusInfo && (
          <Tag variant="secondary" color="red" justifySelf="end">
            {t('splitBookings.notExported')}
          </Tag>
        )}
        <Grid gap="space4">
          <Grid templateColumns="2fr 1.5fr" gap="space16" alignItems="center">
            <Flex gap="space8" alignItems="center">
              {expense.icon}
              <TruncatedText fontSize="xxlarge" height="fit-content">
                {title}
              </TruncatedText>
            </Flex>
            <Box justifySelf="end" fontSize="large">
              <Trans
                values={{ totalAmount: formattedAmount }}
                parent={TruncatedText}
                i18nKey="reimbursementView.middleSection.form.hospitality.totalAmountTitle"
                t={t}
              >
                Total amount
                <Text
                  fontSize="large"
                  fontWeight="semibold"
                  textDecoration={isExcluded ? 'line-through' : undefined}
                >
                  {formattedAmount}
                </Text>
              </Trans>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card
        borderTopRadius="none"
        background="gray50"
        padding="space24 space40"
      >
        <Grid gap="space16">
          <Grid templateColumns="1.5fr 1fr" gap="space16">
            {leftSectionInvoice}
            {rightSectionFields}
          </Grid>
          {footer}
        </Grid>
      </Card>
    </Box>
  );
};

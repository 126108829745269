import React from 'react';
import { IconKey } from '../../Particles/Spritemap';
import {
  IconSize,
  LayoutProps,
  StandardHTMLAttributes,
  TokenOrCSSValue,
} from '../../types';
import { Box } from '../Box';

type Color = TokenOrCSSValue<'colors', 'color'>;

export interface IconProps
  extends LayoutProps,
    StandardHTMLAttributes<SVGSVGElement> {
  color?: Color;
  icon: IconKey;
  size?: IconSize;
}

/**
 * Icon display a chosen icon from our design system
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-media-icon}
 *
 * @param {IconKey} icon Icon from our collection
 * @param {IconSize} size Design system space token or CSS length value
 * @param {Color} color Design system color token or CSS color value
 */
export const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color, icon, size = '1em', ...restProps }, ref) => {
    return (
      <Box
        as="svg"
        color={color}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="currentColor"
        {...restProps}
        ref={ref}
      >
        <use href={`#icon-${icon}`} />
      </Box>
    );
  }
);

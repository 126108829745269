import { motion } from 'motion/react';
import { ReactNode, forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box, BoxProps } from '../Box';
import { Grid } from '../Grid';
import { Tooltip, useTooltip } from '../Tooltip';

type Variant = 'error' | 'warning' | 'success';

export interface StatusIndicatorProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLElement> {
  message?: ReactNode;
  variant: Variant;
  showMessage?: boolean;
}

const variantMap: Record<Variant, Pick<BoxProps, 'background' | 'border'>> = {
  error: {
    background: 'red500',
    border: '1px solid red400',
  },
  warning: {
    background: 'yellow500',
    border: '1px solid yellow400',
  },
  success: {
    background: 'green500',
    border: '1px solid green400',
  },
};

const MotionBox = motion(Box);

export const StatusIndicator = forwardRef<HTMLDivElement, StatusIndicatorProps>(
  ({ message, variant, showMessage = false, ...restProps }, ref) => {
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({
        delay: 0,
        isOpen: showMessage ? true : undefined,
      });

    if (!message && !variant) {
      return null;
    }

    return (
      <>
        <Grid
          height="space16"
          width="space16"
          placeContent="center"
          ref={mergeRefs([triggerRef, ref])}
          {...mergeProps({ ...restProps, ...triggerProps })}
        >
          <MotionBox
            initial={{ scale: 0.4, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 0.3 } }}
            borderRadius="full"
            height="space8"
            width="space8"
            {...variantMap[variant]}
          />
        </Grid>
        {isOpen && message && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {message}
          </Tooltip>
        )}
      </>
    );
  }
);

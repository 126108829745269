import { Grid } from '@candisio/design-system';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormDatePickerField } from './ProcessingFormDatePickerField';
import { getUnusualInvoiceDateWarning } from './getUnusualInvoiceDateWarning';
import { ProcessingFormValues } from './processingFormSchema';

export interface ProcessingFormDateFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Display fields as read only */
  readOnly?: boolean;
  isLoading?: boolean | undefined;
}

/**
 * Displays date fields in the processing form:
 * - invoice date
 * - delivery date
 */
export const ProcessingFormDateFields = ({
  fieldOptions,
  readOnly,
  isLoading,
  ...restProps
}: ProcessingFormDateFieldsProps) => {
  const invoiceDateValue = useWatch<ProcessingFormValues, 'invoiceDate'>({
    name: 'invoiceDate',
  });

  const unusualInvoiceDateWarning = getUnusualInvoiceDateWarning({
    invoiceDate: invoiceDateValue,
  });

  const [t] = useTranslation();

  return (
    <Grid rowGap="space8">
      <Grid
        columnGap="space8"
        rowGap="space16"
        templateColumns={"repeat(auto-fit, minmax('10rem', 1fr))"}
      >
        <ProcessingFormDatePickerField
          name="invoiceDate"
          label={t('document.requestApproval.inputs.from.label')}
          readOnly={readOnly}
          isLoading={isLoading}
          variant={unusualInvoiceDateWarning ? 'warning' : undefined}
          description={
            unusualInvoiceDateWarning
              ? { text: t(unusualInvoiceDateWarning), color: 'warning' }
              : undefined
          }
          {...fieldOptions?.invoiceDate}
          {...restProps}
        />
        {!fieldOptions?.deliveryDate?.hidden && (
          <ProcessingFormDatePickerField
            name="deliveryDate"
            label={t(
              'document.requestApproval.inputs.invoiceDeliveryDate.label'
            )}
            readOnly={readOnly}
            isLoading={isLoading}
            {...restProps}
          />
        )}
        {!fieldOptions?.postingDate?.hidden && (
          <ProcessingFormDatePickerField
            name="postingDate"
            label={t(
              'document.requestApproval.inputs.invoicePostingDate.label'
            )}
            readOnly={readOnly}
            isLoading={isLoading}
            {...restProps}
          />
        )}
      </Grid>
    </Grid>
  );
};

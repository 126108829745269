import { Flex, useTheme } from '@candisio/design-system';
import { TokenOrCSSValue } from '@candisio/design-system/src/types';
import { ReactNode, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom-v5-compat';

export interface LinkWrapperProps {
  as: typeof Link | 'button';
  isActive?: boolean;
  children: ReactNode;
  padding?: TokenOrCSSValue<'space', 'padding'> | undefined;
  gap?: TokenOrCSSValue<'space', 'gap'> | undefined;
  width?: TokenOrCSSValue<'space', 'width'> | undefined;
  height?: TokenOrCSSValue<'space', 'height'> | undefined;
  to?: LinkProps['to'];
}

export const LinkWrapper = forwardRef<HTMLAnchorElement, LinkWrapperProps>(
  ({ as, isActive, ...props }, ref) => {
    const { colors } = useTheme();

    return (
      <Flex
        as={as as keyof JSX.IntrinsicElements}
        alignItems="center"
        background={isActive ? 'bluebg' : 'transparent'}
        border="none"
        borderRadius="medium"
        cursor="pointer"
        color={isActive ? 'blue600' : 'gray600'}
        justifyContent="space-between"
        focus={{
          background: colors.bluebg,
          color: colors.blue600,
        }}
        hover={{
          background: colors.bluebg,
          color: colors.blue600,
        }}
        tabIndex={0}
        textDecoration="none"
        transition="background 0.2s, color 0.2s"
        ref={ref}
        {...props}
      />
    );
  }
);

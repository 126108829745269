import { Grid } from '@candisio/design-system';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { AccountsPayableNumberField } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/active/AccountsPayableNumberField';
import { IbanField } from './IbanField';
import { ReactNode } from 'react';

interface ReadonlyReimbursementFormProps {
  isLoading: boolean;
  values: ReimbursementFormValues;
  fieldOptions: ReimbursementFormFieldOptions;
  splits: ReactNode;
}

export const ReadonlyReimbursementForm = ({
  isLoading,
  values,
  fieldOptions,
  splits,
}: ReadonlyReimbursementFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const {
    targetMembership,
    suggestedCostCenter,
    suggestedCostObject,
    accountsPayableNumber,
  } = fieldOptions;

  const form = useForm<ReimbursementFormValues>({ values });

  return (
    <HookFormProvider {...form}>
      <Grid gap="space24" as="form">
        <HookFormTextField
          name="title"
          readOnly
          label={t('reimbursementView.rightSection.form.title.label')}
          ariaLabel={t('reimbursementView.rightSection.form.title.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.title.placeholder'
          )}
          isLoading={isLoading}
        />
        <HookFormUsersField
          name="targetMembership"
          readOnly
          label={t('reimbursementView.rightSection.form.targetMember.label')}
          placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
          emptyListPlaceholder={t(
            'reimbursementView.rightSection.form.targetMember.noUserFound'
          )}
          {...targetMembership?.options}
          isLoading={isLoading}
        />
        <IbanField
          label={t('reimbursementView.rightSection.form.iban.label')}
          isLoading={isLoading}
        />
        {accountsPayableNumber.isVisible && (
          <AccountsPayableNumberField
            readOnly
            layout="regular"
            name="accountsPayableNumber"
            label={t(
              'common:settings.contacts.details.edit.accountsPayableNumber.label'
            )}
          />
        )}

        {suggestedCostCenter?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostCenter"
            readOnly
            label={t('reimbursementView.rightSection.form.costCenter.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costCenter.placeholder'
            )}
            {...suggestedCostCenter?.options}
            isLoading={isLoading}
          />
        )}
        {suggestedCostObject?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostObject"
            readOnly
            label={t('reimbursementView.rightSection.form.costObject.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costObject.placeholder'
            )}
            {...suggestedCostCenter?.options}
            isLoading={isLoading}
          />
        )}
        <HookFormTextareaField
          name="note"
          readOnly
          label={t('reimbursementView.rightSection.form.note.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.note.placeholder'
          )}
          infoTooltip={{
            message: t('reimbursementView.rightSection.form.note.tooltip'),
          }}
          isLoading={isLoading}
        />
        {splits}
      </Grid>
    </HookFormProvider>
  );
};

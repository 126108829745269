import { Checkbox, Grid, Icon, TruncatedText } from '@candisio/design-system';
import { Reorder, useDragControls, useMotionValue } from 'motion/react';
import { useTranslation } from 'react-i18next';
import { ReorderableConfiguration } from './ConfigurationsMenu';

interface ReorderableItemProps {
  item: ReorderableConfiguration;
  onCheck: (value: ReorderableConfiguration) => void;
}

export const ReorderableItem = ({ item, onCheck }: ReorderableItemProps) => {
  const [t] = useTranslation();
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  const isItemMoved = () => {
    return y.get() !== 0;
  };

  // This early return is needed because some of our items will not be rendered as they are not available
  // but in order for the re-order to keep track of them we need to have one empty re-order item

  if (item.isAvailable === false) {
    return (
      <Reorder.Item
        value={item}
        id={item.id}
        style={{ y, fontSize: '0px' }}
        dragControls={dragControls}
      />
    );
  }

  return (
    <Reorder.Item
      value={item}
      id={item.id}
      style={{ y, fontSize: '0px' }}
      dragControls={dragControls}
    >
      <Grid
        templateColumns="auto 1fr"
        justifyContent="start"
        alignItems="center"
        gap="space8"
        padding="space8 space4"
      >
        <Icon
          icon="dragDots"
          width="space14"
          height="space14"
          onPointerDown={event => dragControls.start(event)}
          style={{ cursor: 'grab' }}
        />
        <Checkbox
          isSelected={item.isVisible}
          value={item.label}
          onChange={() => {
            if (isItemMoved()) {
              return;
            }

            onCheck(item);
          }}
        >
          <TruncatedText fontSize="large" style={{ cursor: 'grab' }}>
            {t(item.label)}
          </TruncatedText>
        </Checkbox>
      </Grid>
    </Reorder.Item>
  );
};

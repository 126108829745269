import { Box } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { PromotionCard } from 'components/NewPromotions';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { UPSELL_MAX_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useGetCustomPromotionDetails } from 'components/ProductPromotions/hooks/useGetCustomPromotionDetails';
import { useOtherIntegration } from 'orgConfig/other';
import { useTranslation } from 'react-i18next';
import { SessionActionCard } from 'views/Settings/ApiSettings/elements/Card/SessionActionCard';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';

export const ApiSettings = () => {
  const [t] = useTranslation();

  const { shouldUseCandisApi } = useOtherIntegration();

  const { apiPromo } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'upsellMax',
    UPSELL_MAX_UTM_SOURCE.API_SETTINGS
  );

  return (
    <SettingsLayout
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      title={t('settings:apiSettings.title')}
    >
      {shouldUseCandisApi ? (
        <SessionActionCard />
      ) : (
        <Box height="24rem">
          <PromotionCard
            promotion={apiPromo}
            closable={false}
            customPromotionDetails={customPromotionDetails}
          />
        </Box>
      )}
      <InfoPanel
        externalLink={t('settings:apiSettings.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('settings:apiSettings.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings:apiSettings.infoPanel.title')}
      />
    </SettingsLayout>
  );
};

import { useDatevSettings } from 'hooks/useDatevSettings';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { debounce, isNil } from 'lodash';
import { useOtherIntegration } from 'orgConfig/other';
import { ReactNode, useMemo } from 'react';
import { useCheckAccountsNumber } from 'views/Contacts/ContactDetails/useCheckAccountsNumber';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useInitialReimbursementFormValues } from 'views/Reimbursement/hooks/useInitialReimbursementFormValues';
import { useReimbursementUpdatePending } from 'views/Reimbursement/hooks/useReimbursementUpdatePending';
import { ReimbursementFormSchemaContext } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { useGetAccountsPayableNumber } from 'views/Settings/TeamMembers/hooks/useGetAccountsPayableNumber';
import { useUpdateAccountsPayableNumber } from 'views/Settings/TeamMembers/hooks/useUpdateAccountsPayableNumber';
import { useShouldRequireAccountsPayableNumber } from 'views/utils/useShouldRequireField';
import { useReimbursementFormFieldOptions } from '../../hooks/useReimbursementFormFieldOptions';
import { ReadonlyReimbursementForm } from './ReadonlyReimbursementForm';
import { ReimbursementForm } from './ReimbursementForm';

interface ReimbursementFormContainerProps {
  isLoading: boolean;
  reimbursement?: Reimbursement;
  splits: ReactNode;
}

export const ReimbursementFormContainer = ({
  isLoading,
  reimbursement,
  splits,
}: ReimbursementFormContainerProps) => {
  const fieldOptions = useReimbursementFormFieldOptions({ reimbursement });

  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { accountsPayableNumber, employeeContactTypeId } =
    useGetAccountsPayableNumber({
      userId: reimbursement?.targetMembership?.id ?? '',
    });
  const { values, defaultValues } = useInitialReimbursementFormValues({
    reimbursement,
    accountsPayableNumber,
  });
  const shouldRequireAccountsPayableNumber =
    useShouldRequireAccountsPayableNumber();
  const { data: datevSettings } = useDatevSettings();
  const integrationSetting = useIntegrationSettings();

  const { reimbursementUIConfig, approvalMode, setApprovalData } =
    useReimbursementFormsContext();
  const { isReimbursementFormEditable } = reimbursementUIConfig;
  const { updateAccountPayableNumber, isUpdateAccountPayableNumberPending } =
    useUpdateAccountsPayableNumber();

  const handleUpdateAccountPayableNumber = useMemo(() => {
    return debounce((accountsPayableNumber: string | null | undefined) => {
      const targetMembershipId = reimbursement?.targetMembership?.id;
      if (!targetMembershipId) {
        return;
      }
      updateAccountPayableNumber(accountsPayableNumber, targetMembershipId);
    }, 500);
  }, [reimbursement?.targetMembership?.id, updateAccountPayableNumber]);

  const { checkAccountsPayableNumber } = useCheckAccountsNumber({
    originalPayableValue: accountsPayableNumber,
    contactId: employeeContactTypeId,
  });

  useReimbursementUpdatePending(isUpdateAccountPayableNumberPending);

  if (!isReimbursementFormEditable) {
    return (
      <ReadonlyReimbursementForm
        values={values}
        fieldOptions={fieldOptions}
        isLoading={isLoading}
        splits={splits}
      />
    );
  }

  const glaLength = !isNil(
    datevSettings?.new_datevSettings?.chartOfAccount?.accountLength
  )
    ? Number(datevSettings?.new_datevSettings?.chartOfAccount?.accountLength)
    : undefined;

  const isAccountPayableNumberRequired =
    shouldRequireAccountsPayableNumber || shouldUseCoreDataApi;

  const context: ReimbursementFormSchemaContext = {
    approvalMode,
    reimbursementStatus: reimbursement?.status,
    checkAccountsPayableNumber,
    glaLength,
    integration: integrationSetting,
    shouldRequireAccountsPayableNumber: isAccountPayableNumberRequired,
  };

  return (
    <ReimbursementForm
      isLoading={isLoading}
      values={values}
      formContext={context}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
      onUpdateAccountPayableNumber={handleUpdateAccountPayableNumber}
      splits={splits}
      onSetApprovalData={setApprovalData}
    />
  );
};

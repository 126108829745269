import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from './LocalStorage/useLocalStorage';
import {
  usePromoPreferences,
  useSavePromoPreferences,
} from './usePromoPreferences';

export const useSyncedPromoPreference = (promoId: string) => {
  const [localStorageState, _, removeLocalStorageState] = useLocalStorage(
    promoId,
    false
  );
  const { getIsPromoSeen } = usePromoPreferences();
  const { savePromoPreferences } = useSavePromoPreferences();

  const userPreferenceState = getIsPromoSeen(promoId);

  const isPromotionSeenState = useMemo(
    () => localStorageState || userPreferenceState,
    [localStorageState, userPreferenceState]
  );

  const setPromotionIsSeen = useCallback(() => {
    savePromoPreferences(promoId);
    if (localStorageState !== null) {
      removeLocalStorageState();
    }
  }, [
    localStorageState,
    promoId,
    removeLocalStorageState,
    savePromoPreferences,
  ]);

  useEffect(() => {
    if (userPreferenceState === false && localStorageState === true) {
      savePromoPreferences(promoId);
      removeLocalStorageState();
    }
  }, [
    localStorageState,
    userPreferenceState,
    promoId,
    savePromoPreferences,
    removeLocalStorageState,
  ]);

  return useMemo(
    () => ({
      isPromotionSeen: isPromotionSeenState,
      setPromotionIsSeen,
    }),
    [isPromotionSeenState, setPromotionIsSeen]
  );
};

import { Flex, Icon, Text } from '@candisio/design-system';
import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import { TagWithTooltip } from 'components/TagWithTooltip';
import { GetDocumentForDraftQuery } from 'generated-types/graphql.types';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface EInvoiceInfoIconProps {
  /** E-Invoice flag */
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  /** E-Invoice data */
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  /** Icon label */
  iconLabel?: ReactNode;
  /** Show warning description */
  showWarningDescription?: boolean;
}

export const EInvoiceInfoIcon = ({
  isEInvoice,
  eInvoice,
  iconLabel,
  showWarningDescription,
}: EInvoiceInfoIconProps) => {
  const [t] = useTranslation();

  const eInvoiceWarningMessage = (
    <Text>
      {t('common:document.compact.history.invalidEInvoice.warning.message')}
    </Text>
  );

  if (eInvoice?.error) {
    if (showWarningDescription) {
      return (
        <Flex gap="space4" alignItems="center">
          <StatusIcon
            label={iconLabel ?? t('documents-table:statuses.eInvoice')}
            icon="eInvoice"
            color="gray600"
            hasTooltip
          />
          <TagWithTooltip
            color="yellow"
            variant="secondary"
            label={
              <>
                <Icon icon="warning" color="yellow500" />
                <Text>
                  {t(
                    'common:document.compact.history.invalidEInvoice.warning.title'
                  )}
                </Text>
              </>
            }
            description={eInvoiceWarningMessage}
          />
        </Flex>
      );
    }

    return (
      <Flex gap="space4" alignItems="center">
        <StatusIcon
          label={iconLabel ?? t('documents-table:statuses.eInvoice')}
          icon="eInvoice"
          color="gray600"
          hasTooltip
        />
        <StatusIcon
          label={eInvoiceWarningMessage}
          icon="warning"
          color="yellow500"
        />
      </Flex>
    );
  }

  if (isEInvoice) {
    return (
      <StatusIcon
        label={iconLabel ?? t('documents-table:statuses.eInvoice')}
        icon="eInvoice"
        color="gray600"
        hasTooltip
      />
    );
  }
};

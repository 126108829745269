import { Box } from '@candisio/design-system';
import { HistoryLoading } from 'components/History/HistoryLoading';
import { TransactionHistory } from '../TransactionHistory';
import { useTransactionHistory } from './useTransactionHistory';

interface TransactionHistoryContainerProps {
  transactionId: string;
}

export const TransactionHistoryContainer = ({
  transactionId,
}: TransactionHistoryContainerProps) => {
  const { loading, transaction } = useTransactionHistory(transactionId);

  if (!loading && !transaction) {
    // TODO add error state
    return null;
  }

  if (loading) {
    return (
      <Box key="history-wrapper" padding="space16" paddingTop="space8">
        <HistoryLoading itemCount={4} />
      </Box>
    );
  }

  const supportedEvents = transaction?.timeline ?? [];

  return (
    <Box key="history-wrapper" paddingX="space8">
      <TransactionHistory events={supportedEvents} />
    </Box>
  );
};

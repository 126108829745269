import { Flex } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useDeferredValue, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { useSearchFilter } from './useSearchFilter';
import { SearchOverlayUpsell } from './SearchFilterUpsell';
import { SearchScope } from 'generated-types/graphql.types';

interface AdvancedSearchFieldFilterOverlayBaseProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlayBase({
  onApply,
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayBaseProps) {
  const [search] = useSearchParams();
  const query = useDeferredValue(search.get(queryParameter) ?? '');

  const { searchType, setSearchType, applySearchInputFilters } =
    useSearchFilter();
  const [hasSearchTypeUpdated, setHasSearchTypeUpdated] = useState(false);

  const onSearchTypeChange = (value: string) => {
    setSearchType(value);
    setHasSearchTypeUpdated(true);
  };

  useEffect(() => {
    if (!hasSearchTypeUpdated) {
      return;
    }
    applySearchInputFilters();
    setHasSearchTypeUpdated(false);
    onApply();
  }, [hasSearchTypeUpdated, applySearchInputFilters, onApply]);

  const handleOnFocus = () => {
    onShowPromoModal();
  };

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect
          query={query}
          value={searchType}
          onChange={onSearchTypeChange}
        />
        <SearchScopeSelect
          value={SearchScope.EntireDocument}
          disabled={true}
          onFocus={handleOnFocus}
        />
      </Flex>
      <SearchOverlayUpsell openModal={onShowPromoModal} />
    </Flex>
  );
}

import {
  Button,
  Grid,
  Popover,
  ScrollBox,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedItems } from './FixedItems';
import { ReorderableItems } from './ReorderableItems';

const MotionScrollBox = motion(ScrollBox);

interface BasicConfiguration {
  id: string;
  isVisible: boolean;
  label: string;
  /** property that indicates if the item is available in the org e.g.: GL, CC, CO */
  isAvailable?: boolean;
}

export interface ReorderableConfiguration extends BasicConfiguration {
  isFixed?: undefined | never;
}

export interface FixedConfiguration
  extends Omit<BasicConfiguration, 'isVisible'> {
  isVisible: true;
  isFixed: true;
}

export type Configuration = ReorderableConfiguration | FixedConfiguration;

export interface ConfigurationsMenuProps {
  configurationItems: Array<Configuration>;
  isLoading?: boolean;
  onUpdateConfigurations: (values: Array<Configuration>) => void;
  onResetConfigurations?: () => void;
}

export const ConfigurationsMenu = ({
  configurationItems,
  isLoading = false,
  onUpdateConfigurations,
  onResetConfigurations,
}: ConfigurationsMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover({
      placement: 'bottom',
    });

  const fixedItems: Array<FixedConfiguration> = configurationItems.filter(
    (item): item is FixedConfiguration => item.isFixed === true
  );

  const configurableItems: Array<ReorderableConfiguration> =
    configurationItems.filter(
      (item): item is ReorderableConfiguration => !item.isFixed
    );

  const [reorderableItems, setReorderableItems] =
    useState<Array<ReorderableConfiguration>>(configurableItems);

  const handleReset = () => {
    onResetConfigurations?.();
    close();
  };

  const handleClick = () => {
    onUpdateConfigurations([...fixedItems, ...reorderableItems]);
    close();
  };

  const handleRedorderItems = useCallback(
    (items: ReorderableConfiguration[]) => {
      setReorderableItems(items);
    },
    []
  );

  const handleCheck = useCallback(
    (configurableItem: ReorderableConfiguration) => {
      const visibleAndAvailableItemsLength = reorderableItems.filter(
        item => item.isVisible && item.isAvailable !== false
      ).length;

      const fixedItemsLength = fixedItems.length;

      const isOnlyOneItemChecked =
        fixedItemsLength === 0 &&
        visibleAndAvailableItemsLength === 1 &&
        configurableItem.isVisible === true;

      if (isOnlyOneItemChecked) {
        return;
      }

      const updatePreferences = reorderableItems.map(item => {
        if (configurableItem.id === item.id) {
          return {
            ...item,
            isVisible: !item.isVisible,
          };
        }

        return item;
      });

      setReorderableItems(updatePreferences);
    },
    [fixedItems.length, reorderableItems]
  );

  return (
    <>
      <Button
        variant="tertiary"
        icon="settings"
        loading={isLoading}
        disabled={isLoading}
        ref={triggerRef}
        minWidth="10ch"
        {...triggerProps}
      >
        <TruncatedText>{t('configurations.openButton')}</TruncatedText>
      </Button>
      {isOpen && (
        <Popover
          padding="space16"
          width="300px"
          maxHeight="500px"
          ref={popoverRef}
          {...popoverProps}
          onClose={() => {
            setReorderableItems(configurableItems);
            close();
          }}
        >
          <Grid maxHeight="400px" gap="space16">
            <MotionScrollBox layoutScroll maxHeight="inherit">
              <FixedItems fixedItems={fixedItems} />
              <ReorderableItems
                onCheck={handleCheck}
                reorderableItems={reorderableItems}
                onRedorderItems={handleRedorderItems}
              />
            </MotionScrollBox>
          </Grid>
          <Grid
            bottom={0}
            zIndex={1}
            autoFlow="column"
            background="white"
            paddingTop="16px"
            position="sticky"
          >
            {Boolean(onResetConfigurations) && (
              <Button
                onClick={handleReset}
                justifySelf="start"
                alignSelf="center"
                disabled={isLoading}
                variant="tertiary"
              >
                {t('configurations.reset')}
              </Button>
            )}

            <Button
              onClick={handleClick}
              justifySelf="end"
              disabled={isLoading}
              alignSelf="center"
              variant="secondary"
            >
              {t('configurations.cta')}
            </Button>
          </Grid>
        </Popover>
      )}
    </>
  );
};

import { Grid, Separator, Text } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { GeneralExpenseInvoiceDataFields } from './GeneralExpenseInvoiceDataFields';
import { generateGeneralExpenseFormFieldNames } from './utils/generateGeneralExpenseFormFieldNames';
import { getExpenseExtractionStatus } from './utils/invoiceDataExtractionHelpers';
import { BooleanMap } from './utils/useExpensesFormActions';

interface GeneralExpenseFieldsWithExtractionProps {
  formIndex: number;
  isExcluded: boolean;
  clearableField: string | undefined;
  invoice: ExpenseInvoice | undefined;
  isExpenseFormEditable: boolean;
  isCreatingInvoice: BooleanMap;
  isExtractingInvoiceData: BooleanMap;
  onCleareableField: (field?: string) => () => void;
  onUpdateExpense: (index: number) => () => Promise<void>;
}

export const GeneralExpenseFieldsWithExtraction = ({
  formIndex,
  isExcluded,
  clearableField,
  invoice,
  isExpenseFormEditable,
  isCreatingInvoice,
  isExtractingInvoiceData,
  onCleareableField,
  onUpdateExpense,
}: GeneralExpenseFieldsWithExtractionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generateGeneralExpenseFormFieldNames(formIndex);
  const { getValues, setValue } = useFormContext<ExpensesFormOutput>();

  const extractedData = getValues(fields.extractedData);
  const isExtractedDataAccepted = getValues(fields.isExtractedDataAccepted);

  const extractionStatus = getExpenseExtractionStatus({
    extractedData,
    isExtractedDataAccepted,
  });

  return (
    <Grid gap="space16" alignContent="start">
      <HookFormTextField
        name={fields.reason}
        maxLength={60}
        readOnly={isExcluded}
        label={t('reimbursementView.middleSection.form.general.reason.label')}
        ariaLabel={t(
          'reimbursementView.middleSection.form.general.reason.label'
        )}
        placeholder={t(
          'reimbursementView.middleSection.form.general.reason.placeholder'
        )}
        clearable={clearableField === fields.reason}
        onFocus={onCleareableField(fields.reason)}
        inputProps={{
          onBlur: onUpdateExpense(formIndex),
        }}
      />
      {!!invoice && (
        <Grid gap="space16">
          <Grid
            alignItems="center"
            templateColumns="auto 1fr"
            fontWeight="semibold"
            gap="space4"
          >
            <Text fontSize="basic">
              {t('reimbursementView.middleSection.form.invoiceData')}
            </Text>
            <Separator />
          </Grid>
          <GeneralExpenseInvoiceDataFields
            onSetValue={setValue}
            clearableField={clearableField}
            extractionStatus={extractionStatus}
            extractedData={extractedData}
            fields={fields}
            formIndex={formIndex}
            isExcluded={isExcluded}
            onCleareableField={onCleareableField}
            onUpdateExpense={onUpdateExpense}
            isExpenseFormEditable={isExpenseFormEditable}
            isLoadingInvoiceData={
              isCreatingInvoice[formIndex] || isExtractingInvoiceData[formIndex]
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

import { Flex, Grid } from '@candisio/design-system';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import { ArchiveKebabMenu } from 'components/Menu/ArchiveKebabMenu/ArchiveKebabMenu';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { FullTextSearchPromoLink } from 'containers/Entitlements/components/Upsell/components/FullTextSearch';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { AppRouteParams } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom-v5-compat';
import { DownloadMenuButtonContractsContainer } from 'views/Archive/Documents/DownloadMenuButtonContractsContainer';
import {
  DownloadMenuButtonEcmDocumentsContainer,
  DownloadMenuButtonEcmDocumentsDeprecatedContainer,
} from 'views/Archive/Documents/DownloadMenuButtonEcmDocumentsContainer';
import { EcmSearchHelpCenterButton } from 'views/Archive/shared/EcmSearchFeedbackButton';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';

export interface EcmDocumentsTableToolbarProps {
  selectedDocumentsCount: number;
  configurationsTable: Configuration[];
  searchQuery: string;
  isTableFiltered: boolean;
  isLoading: boolean;
  isLoadingConfigurations?: boolean;
  onSearchChange: (searchQuery: string) => void;
  onUpdateConfigurations: (values: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
  context:
    | 'documents'
    | 'contracts'
    | 'sensitiveDocuments'
    | 'documentRelationships';
}

export const EcmDocumentsTableToolbar = ({
  searchQuery,
  selectedDocumentsCount,
  configurationsTable,
  isLoading,
  isLoadingConfigurations,
  isTableFiltered,
  onSearchChange,
  onUpdateConfigurations,
  onResetTableConfigurations,
  context,
}: EcmDocumentsTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const {
    showImprovedSearch,
    showEcmDocumentsDownload,
    showSearchOverlay,
    showSearchOverlayPromo,
  } = useEcm();
  const { ft, promo } = useFeatureToggle();
  const promoImprovedSearch = promo(ft.FULL_TEXT_SEARCH);

  const newAllDocumentsQueryFF = useCandisFeatureFlags(
    FEATURE_FLAGS.useNewAllDocumentsQuery
  );

  const { organizationSlug } = useParams<AppRouteParams>();

  const DownloadMenuButton = newAllDocumentsQueryFF
    ? DownloadMenuButtonEcmDocumentsContainer
    : DownloadMenuButtonEcmDocumentsDeprecatedContainer;

  return (
    <Grid
      templateColumns={
        showSearchOverlay || showSearchOverlayPromo
          ? 'minmax(12rem, 24rem)  1fr'
          : 'minmax(12rem, 24rem) auto 1fr'
      }
      gap="space8"
      alignItems="center"
      borderTopRadius="basic"
      overflow="hidden"
      background="white"
      borderBottom="1px solid gray200"
      padding="space12"
    >
      {showSearchOverlay || showSearchOverlayPromo ? (
        <AdvancedSearchField
          clearTooltip={t('search.clearTooltip')}
          placeholder={t('search.placeholder')}
          initialValue={searchQuery}
          onQueryChange={onSearchChange}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
      ) : (
        <>
          <SearchField
            clearTooltip={t('search.clearTooltip')}
            placeholder={t('search.placeholder')}
            initialValue={searchQuery}
            onChange={onSearchChange}
            inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
          />
          <Flex>
            {promoImprovedSearch && (
              <UpsellPromo
                feature={FEATURE.FULL_TEXT_SEARCH}
                utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.DOCUMENTS_TABLE}
              >
                <FullTextSearchPromoLink />
              </UpsellPromo>
            )}
            {showImprovedSearch && <EcmSearchHelpCenterButton />}
          </Flex>
        </>
      )}

      <Flex direction="row" justifySelf="right" gap="space8">
        <ConfigurationsMenu
          configurationItems={configurationsTable}
          isLoading={isLoadingConfigurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetTableConfigurations}
        />

        {showEcmDocumentsDownload && (
          <Flex gap="space8" justifySelf="right">
            {context === 'documents' && (
              <DownloadMenuButton
                selectedDocumentsCount={selectedDocumentsCount}
                isTableLoading={isLoading}
                isTableFiltered={isTableFiltered}
              />
            )}
            {context === 'contracts' && (
              <DownloadMenuButtonContractsContainer
                selectedDocumentsCount={selectedDocumentsCount}
                isTableLoading={isLoading}
                isTableFiltered={isTableFiltered}
              />
            )}
            {(context === 'contracts' || context === 'documents') && (
              <ArchiveKebabMenu organizationSlug={organizationSlug} />
            )}
          </Flex>
        )}
      </Flex>
    </Grid>
  );
};

import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { DateFormatters } from 'utils/date_formatter';
import { ExpenseSchema } from 'views/Reimbursement/toolkit/expensesFormSchema';

export const useFormatGeneralExpenseData = () => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const formatData = (
    formValues: Extract<ExpenseSchema, { expenseType: 'general' }>,
    key: keyof Extract<ExpenseSchema, { expenseType: 'general' }>
  ) => {
    if (!key) {
      return;
    }
    const data = formValues[key];

    if (
      !data ||
      Array.isArray(data) ||
      typeof data === 'boolean' ||
      (typeof data === 'object' && data != null)
    ) {
      return;
    }

    if (key === 'expenseDate') {
      return DateFormatters.compact(new Date(data));
    }

    if (typeof data === 'number' && key === 'totalAmount') {
      return transactionAmountFormatter({
        value: data,
        currency: 'EUR',
        precision: 0,
      });
    }

    return data;
  };

  return formatData;
};

import {
  Box,
  Button,
  CustomEmptyStateProps,
  FilterOptionsAccessor,
  Flex,
  Grid,
  Heading,
} from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { NavLink } from 'components/NavLink/NavLink';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementsTableEmptyState } from 'views/Reimbursement/elements/Table/ReimbursementsTableEmptyState';
import { REIMBURSEMENT_VIEW_ROUTES } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { getFirstReimbursementLink } from './toolkit/utils';

interface InboxReimbursementsProps {
  reimbursementsCount: number;
  reimbursements: ReimbursementTableRow[];
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
  isLoadingReimbursements?: boolean;
  isLoadingConfigurations?: boolean;
  visibleColumnIds?: ReimbursementTableColumnKeys[];
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  configurations: Configuration[];
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onDebounceSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onLoadMore: OnLoadMore;
}

export const InboxReimbursements = ({
  reimbursementsCount,
  reimbursements,
  filters,
  sortBy,
  isLoadingReimbursements,
  isLoadingConfigurations,
  filterOptions,
  configurations,
  visibleColumnIds,
  isTableEmpty,
  isTableFiltered,
  onDebounceSearch,
  onFilter,
  onSort,
  onLoadMore,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: InboxReimbursementsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams } = useMutateSearchParams();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';
  const organizationSlug = useOrganizationId();

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <ReimbursementsTableEmptyState
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    ) : null;

  const getPath = useCallback(
    ({ id, cursor }: ReimbursementTableRow) => {
      searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor ?? '');
      const pathname = generatePath(REIMBURSEMENT_VIEW_ROUTES[Routes.INBOX], {
        organizationSlug,
        reimbursementId: id,
      });

      return `${pathname}?${searchParams.toString()}`;
    },
    [searchParams, organizationSlug]
  );

  const firstReimbursementLink = getFirstReimbursementLink({
    organizationSlug,
    reimbursementId: reimbursements?.[0]?.id,
    reimbursementCursor: reimbursements?.[0]?.cursor,
    searchParams,
  });

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);

  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <Grid templateRows="auto 1fr" overflow="hidden" height="100%">
      <Flex
        alignItems="center"
        gap="space16"
        paddingRight="space32"
        paddingLeft="space32"
        paddingTop="space20"
        paddingBottom="space16"
      >
        <Heading as="h1">{t('inbox.heading')}</Heading>
        {reimbursementsCount > 0 && firstReimbursementLink && (
          <NavLink to={firstReimbursementLink} style={{ padding: '0px' }}>
            <Button variant="primary" size="small">
              {t('inbox.startWorkkCTA')}
            </Button>
          </NavLink>
        )}
      </Flex>
      <InboxViewContainer activeTab={TabView.INBOX_REIMBURSEMENTS}>
        <Box
          height="100%"
          overflow="hidden"
          paddingRight="space32"
          paddingBottom="space24"
          paddingLeft="space32"
        >
          <ReimbursementsTable
            data={reimbursements}
            visibleColumns={visibleColumnIds}
            initialFilters={filters}
            initialSortBy={sortBy}
            isLoading={isLoadingReimbursements}
            filterOptions={filterOptions}
            configurations={configurations}
            search={queryStringFilter}
            isLoadingConfigurations={isLoadingConfigurations}
            onEndReached={onLoadMore}
            onFilter={onFilter}
            onResetTableConfigurations={onResetTableConfigurations}
            onSearch={onDebounceSearch}
            onSort={onSort}
            onUpdateTableConfigurations={onUpdateConfigurations}
            customEmptyState={customEmptyState}
            getCellStyles={cellStyle}
            cellWrapper={cellWrapper}
          />
        </Box>
      </InboxViewContainer>
    </Grid>
  );
};

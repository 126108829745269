import { LeftSection } from './LeftSection';
import { MiddleSectionContainer } from './MiddleSectionContainer';
import { RightSectionContainer } from './RightSectionContainer';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { ReimbursementLayout } from './components/ReimbursementLayout';
import { ExpensesFormOutput } from './toolkit/expensesFormSchema';

export const VIEW_PARAM_VALUE = 'reimbursement_items';

export const DASHBOARDLIST_PARAM_VALUES = {
  openReimbursements: 'openReimbursements',
  completedReimbursements: 'completedReimbursements',
} as const;

export const REIMBURSEMENT_URL_PARAM = {
  VIEW: 'view',
  DASHBOARDLIST: 'dashboardList',
  CURSOR: 'cursor',
} as const;

interface ReimbursementProps {
  defaultValues: ExpensesFormOutput;
  isLoading: boolean;
  nextReimbursementLink?: string;
  prevReimbursementLink?: string;
  reimbursementCount?: number;
  cycleReimbursements: () => void;
}

export const Reimbursement = ({
  defaultValues,
  isLoading,
  nextReimbursementLink,
  prevReimbursementLink,
  cycleReimbursements,
  reimbursementCount,
}: ReimbursementProps) => {
  const formHelpers = useExpenseFormsHelpers({ defaultValues });

  return (
    <ReimbursementLayout
      leftSection={
        <LeftSection
          formHelpers={formHelpers}
          isLoading={isLoading}
          reimbursementCount={reimbursementCount}
          nextReimbursementLink={nextReimbursementLink}
          prevReimbursementLink={prevReimbursementLink}
        />
      }
      middleSection={
        <MiddleSectionContainer
          isLoading={isLoading}
          formHelpers={formHelpers}
        />
      }
      rightSection={
        <RightSectionContainer
          isLoading={isLoading}
          onCycleReimbursments={cycleReimbursements}
          getExpenses={formHelpers.formMethods.getValues}
        />
      }
    />
  );
};

import { Grid, Item, ListView } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback, useMemo } from 'react';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementItemSplitBookingItem } from './ReimbursementItemSplitBookingItem';
import { ReimbursementSplitActionButton } from './ReimbursementSplitAction';
import { calculateSumOfBookingsSplitAmounts } from './hooks/useFormatAmountHelpers';
import { BookingItem } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';

export interface ReimbursementItemSplitBookingsListProps {
  reimbursementCaseStatus?: ReimbursementCaseStatus;
  isLoading: boolean;
  totalAmount: number;
  currency: DocumentCurrency;
  entryIndex: number;
  reimbursementItemId: string;
  bookings: BookingItem[];
  itemStatus: ReimbursementItemStatus;
  isCurrentUserCaseApprover: boolean;
}

export const ReimbursementItemSplitBookingsList = ({
  reimbursementCaseStatus,
  isLoading,
  totalAmount,
  currency,
  entryIndex,
  bookings,
  reimbursementItemId,
  itemStatus,
  isCurrentUserCaseApprover,
}: ReimbursementItemSplitBookingsListProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const {
    updateActiveBooking,
    setDrawerView,
    formMethods,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();
  const { setSelectedItemId } = useReimbursementFormsContext();

  const {
    formState: { errors },
  } = formMethods;

  const sumOfBookingsSplitAmounts = useMemo(
    () => calculateSumOfBookingsSplitAmounts(bookings),
    [bookings]
  );

  const { actionRules } = getSplitBookingsFormRules({
    status: reimbursementCaseStatus as ReimbursementCaseStatus,
    itemStatus,
    isCurrentUserCaseApprover,
  });
  const remainingAmount = totalAmount - sumOfBookingsSplitAmounts;
  const hasBookings = (bookings ?? [])?.length > 0;
  const hasRemainingAmount = Boolean(remainingAmount);
  const { canAddAndDeleteSplits, canAcceptAllSplits } = actionRules;

  const bookingErrors =
    errors.reimbursementItemBookings?.[entryIndex]?.bookings ?? [];

  const isItemExcluded = itemStatus === ReimbursementItemStatus.Excluded;

  const navigateToBooking = useCallback(
    (bookingId: string) => {
      updateActiveBooking({ entryIndex, bookingId, reimbursementItemId });
      setDrawerView('split-bookings');
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      setSelectedItemId(reimbursementItemId);
    },
    [
      entryIndex,
      reimbursementItemId,
      setDrawerView,
      setSelectedItemId,
      updateActiveBooking,
      updateSearchParam,
    ]
  );

  const handleItemClick = useCallback(
    (bookingId: string) => {
      navigateToBooking(bookingId);
    },
    [navigateToBooking]
  );

  const handleSplitDocumentClick = useCallback(() => {
    const previousBooking = bookings[bookings.length - 1];
    navigateToBooking(previousBooking.id);
  }, [bookings, navigateToBooking]);

  return (
    <Grid borderRadius="medium" overflow="hidden">
      <Grid
        borderBottom={
          hasBookings
            ? `1px solid ${!hasRemainingAmount ? 'gray200' : undefined}`
            : undefined
        }
      >
        <ListView>
          {(bookings ?? []).map((booking, bookingIndex) => (
            <Item key={String(bookingIndex)} textValue={booking.id}>
              <ReimbursementItemSplitBookingItem
                bookingId={booking?.id}
                key={booking.id}
                bookingIndex={bookingIndex}
                onEditSplit={() => {
                  handleItemClick(booking.id);
                }}
                isReadOnly={!canAcceptAllSplits}
                costCenter={booking.costCenter}
                costObject={booking.costObject}
                splitAmount={booking.splitAmount}
                vatRate={booking.vatRate}
                currency={currency}
                hasBookingError={!!bookingErrors[bookingIndex]}
                isItemExcluded={isItemExcluded}
              />
            </Item>
          ))}
        </ListView>
      </Grid>
      {!isLoading && hasBookings && (
        <ReimbursementSplitActionButton
          currency={currency}
          hasItems={hasBookings}
          readOnly={!canAddAndDeleteSplits || isItemExcluded}
          remainingAmount={remainingAmount}
          onSplitDocument={handleSplitDocumentClick}
        />
      )}
    </Grid>
  );
};

import {
  GeneralExpenseItem,
  Money,
  ReimbursementError,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { File } from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isEmpty, isNil } from 'lodash';
import { DateFormatters } from 'utils/date_formatter';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

type GeneralExpenseData = Omit<GeneralExpenseItem, 'bookings'>;

interface GeneralExtractedData {
  expenseDate?: string | null;
  invoiceNumber?: string | null;
  totalAmount?: Money | null;
}

export interface GeneralExpense {
  id: string;
  reason?: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  createdAt: string;
  files: File[];
  totalAmount: number | null;
  location?: string;
  errors: ReimbursementError[];
  expenseDate?: string;
  invoiceNumber?: string;
  isExtractedDataAccepted: boolean;
  extractedData?: {
    invoiceNumber?: string;
    expenseDate?: string;
    totalAmount: number | null;
  };
}

export const formattedGeneralExtractedData = (
  extractedData?: GeneralExtractedData
) => {
  const converter = getDateConverted();

  if (!extractedData) return undefined;

  return {
    invoiceNumber: extractedData.invoiceNumber ?? undefined,
    expenseDate: extractedData.expenseDate
      ? converter.dateTimeStringToDateString(extractedData.expenseDate)
      : undefined,
    totalAmount: !isNil(extractedData.totalAmount?.amount)
      ? formatFromCents(
          extractedData.totalAmount.currency,
          extractedData.totalAmount.amount
        )
      : null,
  };
};

export const mapToGeneralExpense = (expense: GeneralExpenseData) => {
  const converter = getDateConverted();

  const extractedData = expense?.extractedData?.[0];

  return {
    id: expense.id,
    errors: expense.errors,
    status: expense.status,
    type: ReimbursementItemType.GeneralExpense,
    reason: expense.title ?? undefined,
    invoiceNumber: expense.invoiceNumber ?? undefined,
    isExtractedDataAccepted: expense.extractedDataAccepted ?? false,
    createdAt: DateFormatters.compact(new Date(expense.createdAt)),
    expenseDate: expense.expenseDate
      ? converter.dateTimeStringToDateString(expense.expenseDate)
      : undefined,
    totalAmount: !isNil(expense.totalAmount?.amount)
      ? formatFromCents(
          expense.totalAmount.currency,
          expense.totalAmount.amount
        )
      : null,
    files: expense.files?.length
      ? expense.files.map(file => ({
          id: file.id,
          url: file.url,
          name: file.name,
        }))
      : [],
    extractedData: !isEmpty(extractedData)
      ? formattedGeneralExtractedData(extractedData)
      : undefined,
  } satisfies GeneralExpense;
};

import { Flex, Text, Grid, useTheme } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { StatusIcon } from 'components/HistoryList/components/StatusIcon';
import { ImportStatus } from 'components/HistoryList/components/ImportStatus'; // TODO use custom importstatus
import { ProjectCodeImportHistoryItemProps } from './types';

// TODO: TPX-1315 use real data after adding types to schema
// TODO: TPX-1206 add ImportStatus component

export const ProjectCodeImportHistoryItem = ({
  createdAt,
  username,
  showStatus = true,
}: ProjectCodeImportHistoryItemProps) => {
  const [t] = useTranslation();
  const dateFormatter = DateFormatters.compactDatetime;
  const { space } = useTheme();
  // if (status === ProjectCodeImportHistpryEntryStatus.InProcess) return null;

  return (
    <Grid
      width="100%"
      templateColumns={showStatus ? `${space.space18} auto auto` : 'auto auto'}
      gap="space16"
    >
      {showStatus && <StatusIcon hasErrorFile={false} />}
      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4"
      >
        <ImportStatus item={{}} />
        <Flex
          color="gray500"
          fontSize="small"
          fontWeight="regular"
          gap="space4"
        >
          {createdAt && (
            <>
              <Text>{dateFormatter(new Date(createdAt))}</Text>
              <Text>{t('importHistoryList.bulletPoint')}</Text>
            </>
          )}
          {username && <Text>{username}</Text>}
        </Flex>
      </Grid>
    </Grid>
  );
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateReimbursementCaseMutation } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseCreateReimbursementReturn {
  createReimbursementPending: boolean;
  createReimbursement: () => Promise<{
    createdReimbursementId: string | undefined;
  }>;
  isDuplicateErrorVisible: boolean;
}

export const useCreateReimbursement = (): UseCreateReimbursementReturn => {
  const [isDuplicateErrorVisible, setIsDuplicateErrorVisible] = useState(false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const currentUserId = useCurrentUser()?.id ?? '';
  const { error } = useToastMessage();
  const [createReimbursement, { loading: createReimbursementPending, client }] =
    useCreateReimbursementCaseMutation({
      onError: () => error(t('reimbursementView.createErr')),
      refetchQueries: [
        'archiveReimbursementCasesCount',
        'inboxReimbursementCasesCount',
      ],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'inboxReimbursementCases' });
        client.cache.evict({ fieldName: 'archiveReimbursementCases' });

        // Instead of refetching the 3 different queries with different params we evict the cache
        client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
      },
    });

  const handleCreateReimbursement = async () => {
    const { data, errors } = await createReimbursement({
      variables: {
        input: {
          targetMembershipId: currentUserId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));
      const hasDuplicateContactError =
        // XXX: We are making an anti pattern here: Parsing a message to determin if it's a specific error, YIKES!
        errors.filter(error => error.message.includes('CONTACT_NAME_DUPLICATE'))
          .length > 0;

      setIsDuplicateErrorVisible(hasDuplicateContactError);

      return {
        createdReimbursementId: undefined,
      };
    }

    setIsDuplicateErrorVisible(false);
    return {
      createdReimbursementId: data?.createReimbursementCase.id,
    };
  };

  return {
    createReimbursementPending,
    createReimbursement: handleCreateReimbursement,
    isDuplicateErrorVisible,
  };
};

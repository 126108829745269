// TODO: TPX-1315 update types and use real query data
import { historyItems } from './mocks';

export const useProjectCodesImportHistoryData = () => {
  return {
    items: historyItems,
    hasMore: false,
    loading: false,
    onLoadMore: () => {},
  };
};

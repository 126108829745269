import { useMemo } from 'react';
import { MIN_CONTENT_CARD_COUNT } from './constants';

export const useSwimlaneTileRules = ({
  contentTileCount,
  contentTileLimit,
  hasActionTile = false,
  hasPromoTile = false,
}: {
  contentTileCount: number;
  contentTileLimit: number;
  hasActionTile?: boolean;
  hasPromoTile?: boolean;
}) => {
  return useMemo(() => {
    let contentTileVisibleCount = contentTileLimit;
    let isActionTileVisible = hasActionTile;
    let isPromoTileVisible = hasPromoTile;
    let isShowAllTileVisible = false;

    // Reserve 2 slots for action card
    if (isActionTileVisible) {
      contentTileVisibleCount -= 2;
    }

    // Reserve 2 slots for promo card, if there are 4 available slots
    // If not, then we drop the promo card space
    if (isPromoTileVisible && contentTileVisibleCount >= 4) {
      contentTileVisibleCount -= 2;
    } else {
      isPromoTileVisible = false;
    }

    // Check to see if there are enough remaining slots for the content cards
    // If not, then make pace for the Show All card
    if (contentTileCount > contentTileVisibleCount) {
      contentTileVisibleCount -= 1;
      isShowAllTileVisible = true;
    }

    // We want to always display 1 content card
    contentTileVisibleCount = Math.max(
      contentTileVisibleCount,
      MIN_CONTENT_CARD_COUNT
    );

    // We only want to reserve space for the number of content tiles that we have, no more
    contentTileVisibleCount = Math.min(
      contentTileVisibleCount,
      contentTileCount
    );

    return {
      contentTileVisibleCount,
      isActionTileVisible,
      isPromoTileVisible,
      isShowAllTileVisible,
    };
  }, [contentTileCount, contentTileLimit, hasActionTile, hasPromoTile]);
};

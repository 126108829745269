import { Box } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { PromotionCard } from 'components/NewPromotions';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';

export const MobileApplicationContainer = () => {
  const [t] = useTranslation();

  const { mobileAppPromotion } = usePromotionContent();

  return (
    <SettingsLayout
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      title={t('settings:mobileApplication.title')}
    >
      <Box height="24rem">
        <PromotionsProvider promotions={[mobileAppPromotion]}>
          <PromotionCard promotion={mobileAppPromotion} closable={false} />
        </PromotionsProvider>
      </Box>

      <InfoPanel
        externalLink={t('settings:mobileApplication.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('settings:mobileApplication.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings:mobileApplication.infoPanel.title')}
      />
    </SettingsLayout>
  );
};

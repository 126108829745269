import { Spinner } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CheckProp } from './CheckList';

interface DataMap {
  [key: string]: CheckProp;
}

export enum StateKey {
  checkingService = 'checkingService',
  isClientConnectedSuccess = 'isClientConnectedSuccess',
  isClientConnectedError = 'isClientConnectedError',
  isGeneralLedgerAccountConnectedSuccess = 'isGeneralLedgerAccountConnectedSuccess',
  isGeneralLedgerAccountConnectedError = 'isGeneralLedgerAccountConnectedError',
  isMoneyTransitSetSuccess = 'isMoneyTransitSetSuccess',
  isMoneyTransitSetError = 'isMoneyTransitSetError',
  isCreditCardSetSuccess = 'isCreditCardSetSuccess',
  isCreditCardSetError = 'isCreditCardSetError',
  isCreditCardIdSetSuccess = 'isCreditCardIdSetSuccess',
  isCreditCardIdSetError = 'isCreditCardIdSetError',
  serviceConnected = 'serviceConnected',
  networkFailure = 'networkFailure',
}

const Loading = <Spinner />;
const Success = <CheckmarkIcon flex="none" size="space16" />;
const Error = <ErrorIcon flex="none" size="space16" />;

export const useCheckItem = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const keysToDataMap: DataMap = {
    [StateKey.checkingService]: {
      icon: Loading,
      text: t('creditCardExportingSap.checks.checkingService'),
    },
    [StateKey.isClientConnectedSuccess]: {
      icon: Success,
      text: t('creditCardExportingSap.checks.isClientConnected.success'),
    },
    [StateKey.isClientConnectedError]: {
      icon: Error,
      text: t('creditCardExportingSap.checks.isClientConnected.error'),
    },
    [StateKey.isGeneralLedgerAccountConnectedSuccess]: {
      icon: Success,
      text: t(
        'creditCardExportingSap.checks.isGeneralLedgerAccountConnected.success'
      ),
    },
    [StateKey.isGeneralLedgerAccountConnectedError]: {
      icon: Error,
      text: t(
        'creditCardExportingSap.checks.isGeneralLedgerAccountConnected.error'
      ),
    },
    [StateKey.isMoneyTransitSetSuccess]: {
      icon: Success,
      text: t('creditCardExportingSap.checks.isMoneyTransitSet.success'),
    },
    [StateKey.isMoneyTransitSetError]: {
      icon: Error,
      text: t('creditCardExportingSap.checks.isMoneyTransitSet.error'),
    },
    [StateKey.isCreditCardSetSuccess]: {
      icon: Success,
      text: t('creditCardExportingSap.checks.isCreditCardSet.success'),
    },
    [StateKey.isCreditCardSetError]: {
      icon: Error,
      text: t('creditCardExportingSap.checks.isCreditCardSet.error'),
    },
    [StateKey.isCreditCardIdSetSuccess]: {
      icon: Success,
      text: t('creditCardExportingSap.checks.isCreditCardIdSet.success'),
    },
    [StateKey.isCreditCardIdSetError]: {
      icon: Error,
      text: t('creditCardExportingSap.checks.isCreditCardIdSet.error'),
    },
    [StateKey.serviceConnected]: {
      icon: Success,
      text: t('creditCardExportingSap.checks.serviceConnected'),
    },
    [StateKey.networkFailure]: {
      icon: Error,
      text: t('settings.datev.connect.exportTypes.dxsoJobs.networkFailure'),
    },
  };

  const getCheckItemProps = (stateKey: string) => {
    return keysToDataMap[stateKey];
  };

  return { getCheckItemProps };
};

import { FieldMergeFunction } from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import {
  ApprovalInvoiceDocumentsResponse,
  ApprovalReimbursementCasesResponse,
  ArchiveAllDocumentsResponse,
  ArchiveInvoiceDocumentsResponse,
  ArchiveReimbursementCasesResponse,
  BookingAccountPaginationResult,
  CardIssuerSettlementsConnectionPageBased,
  CardIssuerStatementsConnectionPageBased,
  CardsConnection,
  CardsConnectionPageBased,
  ContactFilterOptionsPaginationResult,
  ContactsPaginationResult,
  CostCenterPaginationResult,
  DocumentConnection,
  EmailInboxPaginationResponse,
  ExportConnection,
  ExportableDocumentConnection,
  GetEcmDocumentsResponse,
  GoodsReceiptConnection,
  InAppNotificationPaginationResult,
  InboxInvoiceDocumentsResponse,
  InboxReimbursementCasesResponse,
  ListGoodsReceiptImportHistoryEntriesResult,
  ListPurchaseOrderImportHistoryEntriesResult,
  MerchantsConnectionPageBased,
  PageBasedRolesList,
  PageBasedTeamList,
  PageBasedTransactionDownloadHistoryList,
  PayableDocumentConnection,
  PaymentsResponse,
  ProjectCodePaginationResult,
  PurchaseOrderConnection,
  ReimbursementCaseApproverMembershipsPageBased,
  ReimbursementCaseTargetMembershipsPageBased,
  PayableDocumentsEsResponse,
  ExportDocumentsEsResponse,
  ReimbursementCasesConnection,
  TransactionConnection,
  VendorsForRecurringPaymentsConnectionPageBased,
} from 'generated-types/graphql.types';

type RecordConnection =
  | DocumentConnection
  | ReimbursementCasesConnection
  | TransactionConnection
  | ExportConnection
  | ExportableDocumentConnection
  | PayableDocumentConnection
  | CardsConnection;

export const paginationWithRecordConnectionsMerge =
  <Connection extends RecordConnection, T extends Connection['__typename']>(
    __typename: T
  ): FieldMergeFunction<Connection> =>
  (existing, incoming) => {
    // concat prev and next requests

    const edges = [...(existing?.edges || [])];
    const node = [...(existing?.node || [])];

    const incomingEdges = [...(incoming?.edges || [])];
    const incomingNodes = [...(incoming?.node || [])];

    incomingEdges.forEach(incomingEdge => {
      const existingEdge = edges.find(
        // @ts-ignore
        e => e.node.__ref === incomingEdge.node.__ref
      );

      if (!existingEdge) {
        edges.push(incomingEdge);
      }
    });

    incomingNodes.forEach(incomingNode => {
      // @ts-ignore
      const existingNode = node.find(e => e.__ref === incomingNode.__ref);

      if (!existingNode) {
        node.push(incomingNode);
      }
    });

    return {
      edges,
      node,
      __typename: incoming.__typename,
      pageInfo: incoming.pageInfo,
    } as SafeReadonly<Connection>;
  };

type RecordConnectionNoNodes =
  | PurchaseOrderConnection
  | GoodsReceiptConnection
  | ArchiveAllDocumentsResponse
  | InboxInvoiceDocumentsResponse
  | ArchiveReimbursementCasesResponse
  | ApprovalInvoiceDocumentsResponse
  | ArchiveInvoiceDocumentsResponse
  | InboxReimbursementCasesResponse
  | PayableDocumentsEsResponse
  | ExportDocumentsEsResponse
  | ApprovalReimbursementCasesResponse;

export const paginationWithRecordConnectionsMergeNoNodes =
  <
    Connection extends RecordConnectionNoNodes,
    T extends Connection['__typename'],
  >(
    __typename: T
  ): FieldMergeFunction<Connection> =>
  (existing, incoming) => {
    // concat prev and next requests

    const edges = [...(existing?.edges || [])];

    const incomingEdges = [...(incoming?.edges || [])];

    incomingEdges.forEach(incomingEdge => {
      const existingEdge = edges.find(
        // @ts-ignore
        e => e.node.__ref === incomingEdge.node.__ref
      );

      if (!existingEdge) {
        edges.push(incomingEdge);
      }
    });

    return {
      edges,
      __typename: incoming.__typename,
      pageInfo: incoming.pageInfo,
    } as SafeReadonly<Connection>;
  };

type SimplePagination =
  | MerchantsConnectionPageBased
  | BookingAccountPaginationResult
  | ContactsPaginationResult
  | CostCenterPaginationResult
  | PaymentsResponse
  | CardsConnectionPageBased
  | VendorsForRecurringPaymentsConnectionPageBased
  | CardIssuerSettlementsConnectionPageBased
  | CardIssuerStatementsConnectionPageBased
  | ContactFilterOptionsPaginationResult
  | ReimbursementCaseApproverMembershipsPageBased
  | ReimbursementCaseTargetMembershipsPageBased
  | InAppNotificationPaginationResult
  | EmailInboxPaginationResponse
  | ListPurchaseOrderImportHistoryEntriesResult
  | PageBasedRolesList
  | PageBasedTransactionDownloadHistoryList
  | PageBasedTeamList
  | ListGoodsReceiptImportHistoryEntriesResult
  | ProjectCodePaginationResult;

export const simplePaginationMergeWithoutDuplicates =
  <Connection extends SimplePagination, T extends Connection['__typename']>(
    __typename: T
  ): FieldMergeFunction<Connection> =>
  (existing, incoming, { readField }) => {
    const gotExisting = existing && existing.records?.length;

    if (!gotExisting) return incoming;

    const mergedRecords = [...existing.records];

    incoming.records?.forEach(incomingRecord => {
      if (!incomingRecord) return;

      const incomingRecordId = readField<string>('id', incomingRecord);

      const isADuplicate = mergedRecords.some(mergedRecord => {
        if (!mergedRecord) return false;

        const mergedRecordId = readField<string>('id', mergedRecord);

        return incomingRecordId === mergedRecordId;
      });

      if (!isADuplicate) mergedRecords.push(incomingRecord);
    });

    return {
      records: mergedRecords,
      pageInfo: incoming.pageInfo,
      __typename: incoming.__typename,
    } as SafeReadonly<Connection>;
  };

/** `GetEcmDocumentsResponse` has a different schema from other paginated queries responses,
 * so it requires its own slightly modified version of
 * `simplePaginationMergeWithoutDuplicates`.
 */
export const ecmSimplePaginationMergeWithoutDuplicates =
  <
    Connection extends GetEcmDocumentsResponse,
    T extends Connection['__typename'],
  >(
    __typename: T
  ): FieldMergeFunction<Connection> =>
  (existing, incoming, { readField }) => {
    const gotExisting = existing && existing.edges?.length;

    if (!gotExisting) return incoming;

    const mergedEdges = [...existing.edges];

    incoming.edges?.forEach(incomingRecord => {
      const incomingRecordId = readField<string>('id', incomingRecord.record);
      const isADuplicate = mergedEdges.some(mergedRecord => {
        const mergedRecordId = readField<string>('id', mergedRecord.record);

        return incomingRecordId === mergedRecordId;
      });

      if (!isADuplicate) mergedEdges.push(incomingRecord);
    });

    return {
      edges: mergedEdges,
      pageInfo: incoming.pageInfo,
      __typename: incoming.__typename,
    } as SafeReadonly<Connection>;
  };

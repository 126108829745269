import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  DocumentType,
  EcmDocumentStatus,
  GetInboxOtherDocumentsQuery,
  GetInboxOtherDocumentsQueryVariables,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce, merge } from 'lodash';
import {
  EcmDocumentsPaginationParams,
  useEcmPagination,
} from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useMemo, useState } from 'react';
import { getInboxOtherDocumentsQuery } from './gql';

export interface UseInboxOtherDocumentsDataReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: (searchQuery: string) => void;
  isEmpty: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  documentsCount: number;
}

const EXCLUDE_FROM_OTHER_DOC_TYPES = [
  DocumentType.Contract,
  DocumentType.Invoice,
];
export const OTHER_DOCUMENT_TYPES = Object.values(DocumentType).filter(
  docType => !EXCLUDE_FROM_OTHER_DOC_TYPES.includes(docType)
);

export const useInboxOtherDocumentsData = ({
  params,
}: {
  params: EcmDocumentsPaginationParams;
}): UseInboxOtherDocumentsDataReturn => {
  const [debounceSearchValue, setDebounceSearchValue] = useState(''); // TODO default from query params
  const { searchInputVariable } = useSearchInput();

  const otherDocumentsQueryParams: GetInboxOtherDocumentsQueryVariables = {
    search: debounceSearchValue,
    filter: {
      documentStatus: [EcmDocumentStatus.New],
      documentType: OTHER_DOCUMENT_TYPES,
    },
    searchInput: searchInputVariable,
  };

  const mergedParams = merge(otherDocumentsQueryParams, params);

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = useEcmPagination<GetInboxOtherDocumentsQuery, any>(
    getInboxOtherDocumentsQuery,
    'getEcmDocuments',
    {
      variables: mergedParams,
    }
  );

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME);
  }, []);

  const ecmDocumentsList = data?.getEcmDocuments;

  const isEmpty = !isLoading && data?.getEcmDocuments?.edges?.length === 0;

  const documentList = useMemo(
    () =>
      (ecmDocumentsList?.edges ?? []).map(
        ({ record, cursor }): EcmDocumentsTableData => {
          return {
            cursor,
            id: record?.id ?? '',
            globalDocumentId: record?.globalDocumentId ?? '',
            documentType: record?.documentType ?? '',
            contact: record?.contactName ?? '',
            invoiceId: record?.invoiceId ?? '',
            notes: record?.notes ?? '',
            documentName: record?.documentName ?? '',
            documentNumber: record?.documentNumber ?? '',
            documentDate: record?.documentDate
              ? new Date(record?.documentDate)
              : undefined,
            documentStatus: {
              status: record?.documentStatus ?? undefined,
              isSensitive: record?.isSensitive ?? false,
            },
            tags: record?.tags ?? [],
          };
        }
      ),
    [ecmDocumentsList?.edges]
  );

  const documentsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: documentList,
    handleDebounceSearch,
    isEmpty,
    isLoading,
    onLoadMore,
    documentsCount,
  };
};

import { Grid, Item } from '@candisio/design-system';
import { HookFormComboBoxField } from 'components/HookFormFields/HookFormComboBoxField';
import { ErrorIcon } from 'components/Icons/DefaultIcons';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { AnimatePresence, motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  sortCurrencies,
  toCurrencyFieldItem,
} from 'views/Inbox/DocumentProcessing/toCurrencyFieldItem';
import { TopLevelFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { AmountField } from './Fields/AmountField';

export interface ReimbursementSplitBookingsFormHeaderProps {
  remainingAmount: number;
  fields: TopLevelFieldNames;
  isReadOnly: boolean;
}

const MotionGrid = motion(Grid);
const RemainingAmountError = ({ errorMessage }: { errorMessage: String }) => {
  const transition = { duration: 0.3, ease: 'easeInOut' };

  return (
    <AnimatePresence>
      <MotionGrid
        gap="space4"
        templateColumns="auto 1fr"
        alignItems="center"
        justifyContent="start"
        color="red500"
        fontSize="small"
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto', transition }}
        exit={{ opacity: 0, height: 0, transition }}
      >
        <ErrorIcon size="space16" />
        {errorMessage}
      </MotionGrid>
    </AnimatePresence>
  );
};

export const ReimbursementSplitBookingsFormHeader = ({
  fields,
  remainingAmount,
  isReadOnly,
}: ReimbursementSplitBookingsFormHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const currencyItems = sortCurrencies(Object.values(DocumentCurrency)).map(
    toCurrencyFieldItem
  );

  return (
    <Grid gap="space8">
      <Grid
        whiteSpace="nowrap"
        templateColumns={
          isReadOnly
            ? '1fr minmax(min-content, 25%)'
            : 'minmax(80px, 1fr) minmax(59px, 80px) minmax(79px, 109px)'
        }
        gap="space8"
      >
        <AmountField
          readOnly
          name={fields.grossAmount}
          placeholder={t('inputs.grossAmount.placeholder.enabled')}
          label={t('splitBookings.totalAmount.label')}
        />
        <HookFormComboBoxField
          readOnly
          name={fields.currency}
          label={t('splitBookings.currency.label')}
          defaultItems={currencyItems}
          items={currencyItems}
          defaultValue={DocumentCurrency.Eur}
        >
          {currencyItems.map(({ key, children }) => {
            return (
              <Item key={key} textValue={children}>
                {children}
              </Item>
            );
          })}
        </HookFormComboBoxField>
        {!isReadOnly && (
          <AmountField
            readOnly
            name={fields.remainingAmount}
            label={t('splitBookings.remainingAmount.label')}
          />
        )}
      </Grid>
      {!!remainingAmount && (
        <RemainingAmountError
          errorMessage={t('splitBookings.remainingAmount.sumAmountError')}
        />
      )}
    </Grid>
  );
};

import debounce from 'debounce-promise';
import {
  useProcessingFormProjectCodeFieldItemsByIdsQuery,
  useProcessingFormProjectCodeFieldItemsQuery,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';
import { getProjectCodeReadableName } from 'views/Settings/ProjectCodes/utils/readableName';

export interface UseProjectCodeFieldItemsOptions {
  name?: string;
  skip?: boolean;
  ids?: string[];
}

/** Fetches items for project code field */
export const useProjectCodeFieldItems = ({
  name,
  skip,
  ids,
}: UseProjectCodeFieldItemsOptions) => {
  const byName = useProcessingFormProjectCodeFieldItemsQuery({
    variables: { name, code: name },
    skip,
  });

  const byIds = useProcessingFormProjectCodeFieldItemsByIdsQuery({
    variables: { ids },
    skip: !ids,
  });

  const { data, refetch, fetchMore } = ids ? byIds : byName;

  const items = (data?.projectCodesPagination?.records ?? []).map(item => {
    return toAccountingDataFieldItem({
      id: item.id,
      readableName: getProjectCodeReadableName(item),
    });
  });

  const refetchItems = useMemo(() => debounce(refetch, 500), [refetch]);

  const pageCount = data?.projectCodesPagination.pageInfo.pageCount ?? 1;

  const currentPage = data?.projectCodesPagination.pageInfo.currentPage ?? 1;

  const nextPage = currentPage + 1;

  const fetchMoreItems = async () => {
    if (nextPage <= pageCount) {
      await fetchMore({ variables: { page: nextPage } });
    }
  };

  return { items, refetchItems, fetchMoreItems };
};

import { useCallback, useReducer, useRef, useLayoutEffect } from 'react';
import { debounce } from 'lodash';
import { NavIconProps } from './useNavigationIcons';

const ITEM_HEIGHT_FALLBACK = 52;
const BOTTOM_PADDING = 24;
const DEBOUNCE_DELAY = 100;

interface IconState {
  visibleIcons: NavIconProps[];
  hiddenIcons: NavIconProps[];
}

type IconAction = {
  type: 'SET_ICONS';
  visibleIcons: NavIconProps[];
  hiddenIcons: NavIconProps[];
};

const iconReducer = (state: IconState, action: IconAction): IconState => {
  if (action.type === 'SET_ICONS') {
    if (
      state.visibleIcons === action.visibleIcons &&
      state.hiddenIcons === action.hiddenIcons
    ) {
      return state;
    }
    return {
      visibleIcons: action.visibleIcons,
      hiddenIcons: action.hiddenIcons,
    };
  }
  return state;
};

const calculateVisibleItems = ({
  availableHeight,
  items,
  itemHeight,
}: {
  availableHeight: number;
  items: NavIconProps[];
  itemHeight: number;
}) => {
  const maxVisibleItems = Math.floor(availableHeight / itemHeight);

  if (maxVisibleItems <= 0) {
    return {
      newVisibleIcons: [],
      newHiddenIcons: items,
    };
  }

  const isOverflown = maxVisibleItems < items.length;

  const newVisibleIcons = isOverflown ? items.slice(0, maxVisibleItems) : items;

  const newHiddenIcons = isOverflown ? items.slice(maxVisibleItems) : [];

  return { newVisibleIcons, newHiddenIcons };
};

export const useIconOverflow = (initialIcons: NavIconProps[]) => {
  const navigationSidebarRef = useRef<HTMLDivElement | null>(null);
  const overflowRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement | null>(null);

  const [{ visibleIcons, hiddenIcons }, dispatch] = useReducer(iconReducer, {
    visibleIcons: initialIcons,
    hiddenIcons: [],
  });

  const updateVisibleItems = useCallback(() => {
    if (
      !overflowRef.current ||
      !navigationSidebarRef.current ||
      !headerRef.current ||
      !bottomRef.current
    ) {
      return;
    }

    const sidebarHeight = navigationSidebarRef.current.offsetHeight;

    const headerHeight = headerRef.current.getBoundingClientRect().height || 0;

    const bottomHeight = bottomRef.current.getBoundingClientRect().height || 0;

    const availableHeight =
      sidebarHeight - bottomHeight - headerHeight - BOTTOM_PADDING;

    const result = calculateVisibleItems({
      items: initialIcons,
      availableHeight,
      itemHeight:
        itemRef.current?.getBoundingClientRect().height || ITEM_HEIGHT_FALLBACK,
    });

    dispatch({
      type: 'SET_ICONS',
      visibleIcons: result.newVisibleIcons,
      hiddenIcons: result.newHiddenIcons,
    });
  }, [initialIcons]);

  useLayoutEffect(() => {
    const debouncedUpdate = debounce(updateVisibleItems, DEBOUNCE_DELAY);
    const resizeObserver = new ResizeObserver(debouncedUpdate);

    if (navigationSidebarRef.current) {
      resizeObserver.observe(navigationSidebarRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [updateVisibleItems]);

  return {
    visibleIcons,
    hiddenIcons,
    navigationSidebarRef,
    overflowRef,
    headerRef,
    bottomRef,
    itemRef,
  };
};

import {
  Box,
  Flex,
  Grid,
  Image,
  Skeleton,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { InvalidTransactionInfoBox } from 'components/Transactions/TransactionDetailsCard/InvalidTransactionInfoBox';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import {
  type CardBrand,
  type CardType,
  InvoiceAssociationStatus,
  type MemberInfo,
  TransactionStatus,
  useGetCardIssuerTransactionByIdQuery,
  useUnlinkTransactionFromDocumentMutation,
} from 'generated-types/graphql.types';
import { DocumentStatus } from 'generated-types/resolvers-types';
import { GQLError } from 'gql';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { TransactionRelatedItem } from 'views/Inbox/DocumentProcessing/components/DocumentRelations';
import { getCardIssuerTransactionById } from 'views/TransactionAssociation/gql';
import { documentHistoryQuery } from 'views/queries';
import { AutomatchedInfoBox } from './AutomatchedInfoBox';
import { CreditCardImage } from './CreditCardImage';
import { CreditCardLinksTooltip } from './CreditCardLinksTooltip/CreditCardLinksTooltip';
import {
  TransactionDetails,
  TransactionDetailsDeprecated,
  TransactionDetailsReduced,
} from './TransactionDetails';
import { TransactionDetailsCardContextMenu } from './TransactionDetailsCardContextMenu';
import {
  type Transaction,
  TransactionDetailsCardProps,
  dataToTransactionDetails,
} from './utils';

import visaGrey from 'containers/credit-cards/CreditCard/media/visa-black.svg';

const hiddenCardRefNum = '••••';

const LOADING_TEXT_HEIGHT: number = 20;
const LOADING_TEXT_WIDTH: number = 80;

const LoadingTransactionDetailsCard = () => {
  return (
    <>
      <Skeleton height="93.7px" width="100%" />
      <Flex direction="column" gap="space4">
        <Flex justifyContent="space-between">
          {[1, 2].map(i => (
            <Skeleton
              key={i}
              height={`${LOADING_TEXT_HEIGHT}px`}
              width={`${
                i === 2 ? LOADING_TEXT_WIDTH + 10 : LOADING_TEXT_WIDTH
              }px`}
            />
          ))}
        </Flex>
        <Flex justifyContent="space-between">
          <Skeleton
            height={`${LOADING_TEXT_HEIGHT + 2}px`}
            width={`${LOADING_TEXT_WIDTH + 30}px`}
          />
          <Skeleton
            height={`${LOADING_TEXT_HEIGHT + 2}px`}
            width={`${LOADING_TEXT_WIDTH - 10}px`}
          />
        </Flex>
      </Flex>
    </>
  );
};

const TransactionWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    gap="space14"
    padding="space12"
    borderRadius="medium"
    direction="column"
    background="gray0"
    boxShadow="noShadow"
  >
    {children}
  </Flex>
);

export const TransactionDetailsCard = ({
  loading = false,
  transaction,
  contextMenu,
}: TransactionDetailsCardProps) => {
  const { showDocumentRelationsImproved } = useEcm();
  const { isOnlyApprover } = useUserRoles();
  const user = useCurrentUser();
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const {
    status,
    member,
    cardName,
    cardRefNum,
    localizedDeclineReason,
    invoiceAssociationStatus,
    cardId,
    cardBrand,
    cardType,
  } = transaction ?? {};

  const membershipId = member?.membershipId ?? undefined;
  const isTransactionOwner = membershipId === user?.id;

  const isTooltipHidden = isOnlyApprover && !isTransactionOwner;

  const isReversed = status === TransactionStatus.Reversed;
  const isDeclined = status === TransactionStatus.Declined;
  const isInvalidStatus = isReversed || isDeclined;

  const isTransactionAutomatched =
    invoiceAssociationStatus === InvoiceAssociationStatus.AutoMatched;

  const isAutomatchedInfoVisible =
    isTransactionAutomatched && creditCardsAutomatedMatchingFF;

  if (loading) {
    return (
      <TransactionWrapper>
        <LoadingTransactionDetailsCard />
      </TransactionWrapper>
    );
  }

  const automatchedInfo = isAutomatchedInfoVisible && <AutomatchedInfoBox />;
  const invalidStatus = isInvalidStatus && (
    <InvalidTransactionInfoBox
      status={status}
      localizedDeclineReason={localizedDeclineReason}
    />
  );

  const CardLayout = showDocumentRelationsImproved
    ? TransactionCardLayout
    : TransactionCardLayoutDeprecated;

  const CardWrapper = showDocumentRelationsImproved
    ? Fragment
    : TransactionWrapper;

  return (
    <CardWrapper>
      <CardLayout
        member={member}
        contextMenu={contextMenu}
        cardName={cardName}
        cardType={cardType}
        cardBrand={cardBrand}
        triggerProps={triggerProps}
        triggerRef={triggerRef}
        cardRefNum={cardRefNum}
        transaction={transaction}
        automatchedInfo={automatchedInfo}
        invalidStatus={invalidStatus}
      />
      {isOpen && !isTooltipHidden && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <CreditCardLinksTooltip cardId={cardId} membershipId={membershipId} />
        </Tooltip>
      )}
    </CardWrapper>
  );
};

export const TransactionsDetailsCardContainer = ({
  transactionId,
  linkedDocumentStatus,
  documentId,
}: {
  transactionId: string;
  documentId: string | undefined;
  linkedDocumentStatus: DocumentStatus | undefined;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const showGqlError = useShowError();
  const { showDocumentRelations } = useEcm();

  const { data, loading } = useGetCardIssuerTransactionByIdQuery({
    variables: { id: transactionId },
  });

  const { isOnlyApprover } = useUserRoles();

  const transaction = data?.getCardIssuerTransactionById;

  const [
    unlinkTransactionFromDocumentMutation,
    { loading: isUnlinkingLoading },
  ] = useUnlinkTransactionFromDocumentMutation();

  const counterQueries = useCounterQueries();

  const unlinkTransaction = async () => {
    try {
      if (!documentId) {
        return;
      }

      const { data, errors } = await unlinkTransactionFromDocumentMutation({
        variables: { transactionId, documentId },
        refetchQueries: [
          ...counterQueries,
          {
            query: getCardIssuerTransactionById,
            variables: { id: transactionId },
          },
          { query: documentHistoryQuery, variables: { id: documentId } },
        ],
      });

      if (data?.unlinkTransactionFromDocument) {
        success(t('transactionDetailsCardContextMenu.successMessage'));
      } else if (errors?.length) {
        error(t('transactionDetailsCardContextMenu.errorMessage'));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const isDocumentUnlinkable = linkedDocumentStatus
    ? [
        DocumentStatus.New,
        DocumentStatus.Open,
        DocumentStatus.Approved,
        DocumentStatus.Rejected,
      ].includes(linkedDocumentStatus)
    : false;

  const isTransactionAutomatched =
    transaction?.invoiceAssociationStatus ===
    InvoiceAssociationStatus.AutoMatched;

  const isDocumentUnlikableForApprover =
    linkedDocumentStatus === DocumentStatus.New ||
    linkedDocumentStatus === DocumentStatus.Rejected;

  const canApproverUnlink =
    isOnlyApprover &&
    isDocumentUnlikableForApprover &&
    isTransactionAutomatched;

  const isTransactionUnlinkable = !isOnlyApprover
    ? isDocumentUnlinkable
    : canApproverUnlink;

  const hasContextMenu =
    isTransactionUnlinkable && documentId && linkedDocumentStatus;

  const transactionDetails = dataToTransactionDetails(transaction);

  if (showDocumentRelations && transactionDetails) {
    return (
      <Grid borderRadius="medium" overflow="hidden">
        <TransactionRelatedItem
          documentId={documentId}
          transaction={transactionDetails}
          documentStatus={linkedDocumentStatus}
        />
      </Grid>
    );
  }

  return (
    <TransactionDetailsCard
      loading={loading}
      transaction={transactionDetails}
      contextMenu={
        hasContextMenu && (
          <TransactionDetailsCardContextMenu
            documentStatus={linkedDocumentStatus}
            onUnlinkTransaction={unlinkTransaction}
            isUnlinkPending={isUnlinkingLoading}
          />
        )
      }
    />
  );
};

type TransactionCardLayoutProps = {
  member?: MemberInfo;
  contextMenu: ReactNode;
  cardName?: string;
  cardType?: CardType;
  cardBrand?: CardBrand;
  triggerProps: ReturnType<typeof useTooltip>['triggerProps'];
  triggerRef: ReturnType<typeof useTooltip>['triggerRef'];
  cardRefNum?: string;
  transaction?: Transaction;
  invalidStatus: ReactNode;
  automatchedInfo: ReactNode;
};

const TransactionCardLayout = function TransactionCardLayout({
  member,
  contextMenu,
  cardName,
  triggerProps,
  triggerRef,
  cardRefNum,
  transaction,
  automatchedInfo,
  invalidStatus,
}: TransactionCardLayoutProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const memberFullName = [member?.firstName, member?.lastName].join(' ');

  const header = (isOpen: boolean) => (
    <>
      <Box width={contextMenu ? '90%' : '100%'}>
        {isOpen ? (
          <Text fontWeight="500">{t('transactionDetailsCard.category')}</Text>
        ) : (
          <TransactionDetailsReduced transaction={transaction} />
        )}
      </Box>
      {contextMenu && (
        <Box top="-space16" right="-space16">
          {contextMenu}
        </Box>
      )}
    </>
  );

  return (
    <Box background="gray200" borderRadius="inherit">
      <CollapsibleCard
        id="transaction"
        header={header}
        headerProps={{
          background: 'gray0',
          height: '36px',
          paddingX: 'space8',
        }}
        iconPosition="right"
      >
        <Flex
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          padding="space10"
          paddingTop="space5"
          background="gray0"
          color="gray500"
        >
          <TransactionDetails transaction={transaction} />
          {/* // TODO: remove later */}
          <Box height="space4" />
          <TruncatedText maxWidth="20ch">{memberFullName}</TruncatedText>
          <Flex alignItems="center" gap="space4">
            <Image src={visaGrey} alt="" width="space32" />
            <Grid
              autoFlow="column"
              placeItems="center"
              columnGap="space4"
              {...triggerProps}
              ref={triggerRef}
            >
              <Text color="gray600">{hiddenCardRefNum}</Text>
              {cardRefNum}
            </Grid>
            {cardName && (
              <TruncatedText maxWidth="20ch">{'– ' + cardName}</TruncatedText>
            )}
          </Flex>
        </Flex>
        {automatchedInfo}
        {invalidStatus}
      </CollapsibleCard>
    </Box>
  );
};

const TransactionCardLayoutDeprecated =
  function TransactionCardLayoutDeprecated({
    member,
    contextMenu,
    cardName,
    cardType,
    cardBrand,
    triggerProps,
    triggerRef,
    cardRefNum,
    transaction,
    automatchedInfo,
    invalidStatus,
  }: TransactionCardLayoutProps) {
    const memberFullName = [member?.firstName, member?.lastName].join(' ');

    return (
      <Grid gap="space8">
        <Flex
          gap="space2"
          borderRadius="medium"
          background="gray200"
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY="space8"
        >
          <Flex
            alignItems="center"
            paddingY="space5"
            justifyContent="center"
            width="100%"
          >
            <Flex gap="space8" alignItems="center">
              <AvatarWithStatusContainer
                flex="none"
                img={member?.avatarUrl ?? undefined}
                name={memberFullName}
                userId={member?.membershipId ?? undefined}
                size="small"
                hasTooltip={false}
              />
              <TruncatedText maxWidth="20ch">{memberFullName}</TruncatedText>
            </Flex>
            {contextMenu}
          </Flex>
          {cardName && (
            <TruncatedText maxWidth="20ch">{cardName}</TruncatedText>
          )}
          <Grid
            gap="space8"
            templateColumns="auto 1fr"
            alignItems="center"
            paddingY="space4"
          >
            <CreditCardImage cardType={cardType} cardBrand={cardBrand} />
            <Grid
              autoFlow="column"
              placeItems="center"
              gap="space8"
              {...triggerProps}
              ref={triggerRef}
            >
              <Text color="gray700" fontSize="small" fontWeight="regular">
                {cardRefNum && `${hiddenCardRefNum} ${cardRefNum}`}
              </Text>
            </Grid>
          </Grid>
        </Flex>
        <Flex direction="column" gap="space12">
          <TransactionDetailsDeprecated transaction={transaction} />
          {automatchedInfo}
          {invalidStatus}
        </Flex>
      </Grid>
    );
  };

import {
  Button,
  Grid,
  Item,
  TabPanel,
  Tabs,
  Text,
} from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTabs } from 'components/Tabs/useTabs';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { EmptyDataState } from './EmptyDataState';
import { ProjectCodeDrawer } from './ProjectCodesDrawer/ProjectCodeDrawer';
import { PROJECT_CODE_SEARCH_PARAM, SortBy, sortByMap } from './consts';
import { useDrawerNavigation } from './hooks/useDrawerNavigation';
import { useProjectCodeListData } from './hooks/useProjectCodeListData';
import { useSap } from 'orgConfig/sap';

export const ProjectCodes = () => {
  const { shouldUseSapProjectCodeImportHistory } = useSap();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.id);

  const [searchParams] = useSearchParams();
  const { id: selectedProjectCodeId } = useParams();

  const [queryString, setQueryString] = useState('');

  const isArchived =
    searchParams.get(PROJECT_CODE_SEARCH_PARAM.archived) === 'true';

  const sortItems = [
    {
      id: SortBy.alphabetically,
      label: t('projectCodes.sortItems.alphabetically'),
    },
    {
      id: SortBy.id,
      label: t('projectCodes.sortItems.id'),
    },
    {
      id: SortBy.lastAdded,
      label: t('projectCodes.sortItems.lastCreatedFirst'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const {
    handleDebounceSearch,
    isLoading,
    onEndReached,
    projectCodeData,
    activeCount,
    archivedCount,
    isCountLoading,
  } = useProjectCodeListData({
    showArchived: isArchived,
    sortBy: sortByMap[sortBy],
  });

  const { navigateToDetailsDrawer } = useDrawerNavigation();

  const onSearchFilter = (filter: string) => {
    handleDebounceSearch(filter);
    setQueryString(filter);
  };

  const handleSearchReset = () => {
    setQueryString('');
    handleDebounceSearch('');
  };
  const handleSortBy = (value: Key[]) => {
    if (value.length) {
      setSortBy(value[0] as SortBy);
    }
  };
  const handleSelect = (id: string) => {
    navigateToDetailsDrawer(id);
  };

  const filterableList = (
    <FilterableList
      children={projectCodeData.map(projectCode => (
        <Item key={projectCode.id} textValue={projectCode.name}>
          <FilterableListItem itemId={projectCode.id} onSelect={handleSelect}>
            <Grid>
              <Text
                fontWeight="semibold"
                fontSize="basic"
                overflowWrap="anywhere"
              >
                {renderQueryHighlight({
                  value: projectCode.name ?? '',
                  queryString: queryString,
                })}
              </Text>
              <Text color="gray500">
                {renderQueryHighlight({
                  value: projectCode.code,
                  queryString: queryString,
                })}
              </Text>
            </Grid>
          </FilterableListItem>
        </Item>
      ))}
      customCreate={
        <>
          <SyncFromSap type={SAP_SYNC.ProjectCodes} />
          {shouldUseSapProjectCodeImportHistory && (
            <Button
              width="max-content"
              size="small"
              variant="secondary"
              onClick={() => navigate('import_history')}
            >
              {t('projectCodes.contextMenu.historyLabel')}
            </Button>
          )}
        </>
      }
      emptyDataState={<EmptyDataState />}
      emptySearchState={<EmptySearchState searchReset={handleSearchReset} />}
      searchField={{
        onSearchFilter,
        placeholder: t('projectCodes.searchPlaceholder'),
        searchQuery: queryString,
      }}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      isLoading={isLoading}
      onEndReached={onEndReached}
    />
  );

  const { tabPanelProps, tabsProps } = useTabs({
    items: [
      {
        key: 'active',
        title: t('projectCodes.tabs.active'),
        badge: !isCountLoading ? String(activeCount) : undefined,
        children: filterableList,
      },
      {
        key: 'archived',
        title: t('projectCodes.tabs.archived'),
        badge: !isCountLoading ? String(archivedCount) : undefined,
        children: filterableList,
      },
    ],
    onSelectionChange: key => {
      searchParams.set('archived', key === 'archived' ? 'true' : 'false');
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    selectedKey: isArchived ? 'archived' : 'active',
  });

  const { key, ...restTabPanelProps } = tabPanelProps;

  return (
    <SettingsLayout
      title={t('projectCodes.title')}
      actions={<Tabs {...tabsProps} />}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <TabPanel key={tabPanelProps.key} {...restTabPanelProps} />
      <InfoPanel
        title={t('projectCodes.infoPanel.title')}
        additionalParagraph={t('projectCodes.infoPanel.description')}
      />
      <ProjectCodeDrawer isOpen={!!selectedProjectCodeId} />
    </SettingsLayout>
  );
};

import {
  Flex,
  Heading,
  Link,
  MessageBox,
  Paragraph,
} from '@candisio/design-system';
import { eInvoiceErrorTranslationKey } from 'components/DocumentHistory/history-components/InvalidEInvoiceDetectedComponent';
import type { GetDocumentForDraftQuery } from 'generated-types/graphql.types';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface EInvoicesFeedbackInfoBoxProps {
  isLoading: boolean;
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  contactSupplierButton?: ReactNode;
}

export const InvalidEInvoiceWarning = ({
  isLoading,
  eInvoice,
  contactSupplierButton,
}: EInvoicesFeedbackInfoBoxProps) => {
  const [t] = useTranslation();

  if (!eInvoice?.error || isLoading) {
    return null;
  }

  const error = eInvoiceErrorTranslationKey(eInvoice.error);

  return (
    <MessageBox
      message=""
      variant="warning"
      isLoading={isLoading}
      additionalContent={
        <Flex gap="space16" direction="column">
          <Heading as="h3">
            {t(
              'common:document.compact.history.invalidEInvoice.warning.headline'
            )}
          </Heading>
          <Paragraph whiteSpace="pre-line" color="gray800">
            {t('common:document.compact.history.invalidEInvoice.warning.info', {
              error: t(error),
            })}{' '}
            <Link
              href={t(
                'common:document.compact.history.invalidEInvoice.warning.helpCenterArticle.url'
              )}
              external
            >
              {t(
                'common:document.compact.history.invalidEInvoice.warning.helpCenterArticle.title'
              )}
            </Link>
          </Paragraph>
          {contactSupplierButton}
        </Flex>
      }
    />
  );
};

import gql from 'graphql-tag';
import { pageInfoDataFragment } from 'views/queries/fragments';

export const moneyFragment = gql`
  fragment MoneyFields on Money {
    amount
    currency
    precision
  }
`;

export const payableDocumentFragment = gql`
  fragment PayableDocumentData on PayableDocument {
    id
    amount {
      ...MoneyFields
    }
    contact {
      id
      name {
        value
      }
    }
    status
    currency
    invoiceDate
    invoiceNumber
    invoiceDueDate
    discountAmount {
      ...MoneyFields
    }
    discountPercentage
    discountPaymentDate
    ... on SepaExportableDocument {
      __typename
      creditorIban
      creditorSwiftCode
    }
    ... on DocumentWithIncompletePaymentData {
      __typename
    }
  }
  ${moneyFragment}
`;

export const payableDocumentsPaginationQuery = gql`
  query payableDocumentsPagination(
    $limit: Int
    $after: String
    $sort: SortInput
  ) {
    payableDocumentsPagination(limit: $limit, after: $after, sort: $sort) {
      edges {
        cursor
        node {
          ...PayableDocumentData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${payableDocumentFragment}
`;

const sepaTransferPaymentHistoryFragment = gql`
  fragment SepaTransferPaymentHistoryData on SepaTransferPayment {
    ... on SepaTransferPayment {
      id
      creationDate
      paymentInitiator {
        name
      }
      numberOfPaidDocuments
      totalAmount {
        ...MoneyFields
      }
      currency
    }
  }
  ${moneyFragment}
`;

export const paymentsHistoryQuery = gql`
  query paymentsHistory($before: String, $limit: Int, $after: String) {
    payments(before: $before, limit: $limit, after: $after)
      @connection(key: "paymentsHistory") {
      records {
        __typename
        ...SepaTransferPaymentHistoryData
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
  ${sepaTransferPaymentHistoryFragment}
`;

const paidDocumentFragment = gql`
  fragment PaidDocumentData on PaidDocument {
    documentId
    creditorIban
    creditorSwiftCode
    invoiceNumber
    amount {
      ...MoneyFields
    }
    currency
    invoiceDate
    invoiceDueDate
    creditor {
      name
    }
    discountAmount {
      ...MoneyFields
    }
    discountPaymentDate
  }
  ${moneyFragment}
`;

const sepaTransferPaymentFragment = gql`
  fragment SepaTransferPaymentData on SepaTransferPayment {
    ... on SepaTransferPayment {
      id
      creationDate
      paymentInitiator {
        name
        avatarUrl
      }
      paidDocuments {
        ...PaidDocumentData
      }
      totalAmount {
        ...MoneyFields
      }
      currency
      swiftCode
    }
  }
  ${paidDocumentFragment}
  ${moneyFragment}
`;

export const paymentQuery = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      __typename
      ...SepaTransferPaymentData
    }
  }
  ${sepaTransferPaymentFragment}
`;

export const sepaTransferXmlQuery = gql`
  query sepaTransferXml($paymentId: ID!) {
    sepaTransferXml(paymentId: $paymentId) {
      __typename
      ... on SepaXml {
        currency
        fileName
        content
      }
      ... on SepaXmlValidationError {
        message
      }
    }
  }
  ${sepaTransferPaymentFragment}
`;

export const generateSepaXmlMutation = gql`
  mutation generateSepaTransferXml($input: SepaXmlInput!) {
    generateSepaTransferXml(input: $input) {
      ... on SepaXml {
        currency
        fileName
        content
      }
      ... on SepaXmlValidationError {
        message
      }
    }
  }
`;

export const activatePayment = gql`
  mutation activatePayment($startDate: Date!) {
    activatePayment(startDate: $startDate)
  }
`;

export const deactivatePayment = gql`
  mutation deactivatePayment {
    deactivatePayment
  }
`;

export const updatePaymentStartDate = gql`
  mutation updatePaymentStartDate($startDate: Date!) {
    updatePaymentStartDate(startDate: $startDate)
  }
`;

export const updateExportConfiguration = gql`
  mutation updateExportConfiguration($datevSettings: DatevSettingsInput!) {
    updateExportConfiguration(datevSettings: $datevSettings)
  }
`;

export const getPayableDocumentsInfoQuery = gql`
  query getPayableDocumentsInfo($hasIban: Boolean) {
    getPayableDocumentsInfo(hasIban: $hasIban) {
      euValidIbanDocumentsCount
      gbValidIbanDocumentsCount
    }
  }
`;

export const getPayableAmountsQuery = gql`
  query getPayableAmounts {
    getPayableAmounts {
      totalPayableAmount {
        ...MoneyFields
      }
      discountedAmount {
        ...MoneyFields
      }
    }
  }
  ${moneyFragment}
`;

export const payableDocumentNewEsFragment = gql`
  fragment PayableDocumentNewEsData on PayableDocumentEs {
    id
    globalDocumentId
    status
    contact {
      id
      name
    }
    iban
    invoiceNumber
    isSEPAExportable
    bookings {
      artistSocialInsuranceCode
      dueDate
      discountAmount
      discountPercentage
      discountPaymentDate
      paymentConditionId
    }
    amount {
      amount
      currency
      precision
    }
    isPayable
    invoiceDate
    createdAt
    updatedAt
  }

`;

export const getPayableDocumentsPaginationNewEsQuery = gql`
  query payableDocumentsEs($limit: Int, $after: String, $sort: PayableDocumentsSortInput) {
    payableDocumentsEs(limit: $limit, after: $after, sort: $sort) {
      edges {
        cursor
        node {
          ...PayableDocumentNewEsData
        }
        __typename
      }
      pageInfo {
        startCursor
        endCursor
        totalCount
        hasNextPage
      }
      __typename
    }
  }
  ${payableDocumentNewEsFragment}

  
`;

import { getDateConverted } from 'hooks/useDateConverter';
import { isNil, values } from 'lodash';
import { UseFormSetValue } from 'react-hook-form';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { FieldNames } from './generateGeneralExpenseFormFieldNames';
import { HospitalityFieldNames } from './generateHospitalityFormFieldNames';

export const ExtractionStatus = {
  NO_EXTRACTION: 'no_extraction',
  PARTIAL_EXTRACTION: 'partial_extraction',
  FULL_EXTRACTION: 'full_extraction',
} as const;

export type ExtractionStatusType =
  (typeof ExtractionStatus)[keyof typeof ExtractionStatus];

export interface ExtractedData {
  invoiceNumber?: string;
  location?: string;
  expenseDate?: string;
  totalAmount?: number | null;
  receiptAmount?: number | null;
  tipAmount?: number | null;
}

interface ExtractionConfig {
  isExtractedDataAccepted: boolean;
  extractedData?: ExtractedData;
}

const convertDate = (date?: string): string => {
  if (!date) return '';
  const converter = getDateConverted();
  return converter.dateTimeStringToDateString(date);
};

export const getExpenseExtractionStatus = ({
  isExtractedDataAccepted,
  extractedData,
}: ExtractionConfig): ExtractionStatusType => {
  const extractedValues = values(extractedData ?? {});
  const hasNoExtractedData =
    !extractedValues.length ||
    extractedValues.every(value => isNil(value) || value === '');

  if (!isExtractedDataAccepted || hasNoExtractedData) {
    return ExtractionStatus.NO_EXTRACTION;
  }

  const hasAllValidValues = extractedValues.every(
    value => !isNil(value) && value !== ''
  );

  return hasAllValidValues
    ? ExtractionStatus.FULL_EXTRACTION
    : ExtractionStatus.PARTIAL_EXTRACTION;
};

export const useInvoiceDataFieldsUpdates = ({
  setValue,
}: {
  setValue: UseFormSetValue<ExpensesFormOutput>;
}) => {
  const updateGeneralExpenseInvoiceDataFields = (
    field: FieldNames,
    data?: ExtractedData
  ) => {
    const extractableFields = [
      { name: field.expenseDate, value: convertDate(data?.expenseDate) },
      { name: field.invoiceNumber, value: data?.invoiceNumber ?? '' },
      { name: field.totalAmount, value: data?.totalAmount },
    ];

    extractableFields.forEach(field =>
      setValue(field.name, field.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    );
  };

  const updateHospitalityExpenseInvoiceDataFields = (
    field: HospitalityFieldNames,
    data?: ExtractedData
  ) => {
    const extractableFields = [
      { name: field.expenseDate, value: convertDate(data?.expenseDate) },
      { name: field.invoiceNumber, value: data?.invoiceNumber ?? '' },
      { name: field.location, value: data?.location ?? '' },
      { name: field.tipAmount, value: data?.tipAmount },
      { name: field.receiptAmount, value: data?.receiptAmount },
    ];

    extractableFields.forEach(field =>
      setValue(field.name, field.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    );
  };

  return {
    updateGeneralExpenseInvoiceDataFields,
    updateHospitalityExpenseInvoiceDataFields,
  };
};

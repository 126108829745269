import {
  Button,
  Flex,
  Heading,
  Item,
  ListView,
  Switch,
  Text,
} from '@candisio/design-system';
import { IntegrationName } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import {
  Entitlements,
  entitlementsFlagName,
  useEntitlementsFlag,
  useLaunchDarklyDefaultEntitlement,
} from 'hooks/useCandisFeatureFlags';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { entitlementsToFeatureMap } from 'providers/FeatureToggleProvider/entitlements';
import { FEATURE, FEATURE_STATE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { prefix } from 'utils/localStorage';
import { qaUtilsOverwrites } from 'views/AppContainer/components/Header/components/QaUtils/utils';

const resetSSO = () => {
  localStorage.removeItem(`${prefix}-${qaUtilsOverwrites.sso.isActive}`);
  localStorage.removeItem(
    `${prefix}-${qaUtilsOverwrites.sso.provides.lastName}`
  );
  localStorage.removeItem(
    `${prefix}-${qaUtilsOverwrites.sso.provides.firstName}`
  );
  localStorage.removeItem(`${prefix}-${qaUtilsOverwrites.sso.provides.roles}`);
  window.location.reload();
};

const _OverwriteSSO = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.QA_UTILS);
  const [isActive, setIsActive] = useLocalStorage(
    qaUtilsOverwrites.sso.isActive,
    false
  );

  const [lastName, setLastName] = useLocalStorage(
    qaUtilsOverwrites.sso.provides.lastName,
    false
  );

  const [firstName, setFirstName] = useLocalStorage(
    qaUtilsOverwrites.sso.provides.firstName,
    false
  );

  const [roles, setRoles] = useLocalStorage(
    qaUtilsOverwrites.sso.provides.roles,
    false
  );

  return (
    <Flex direction="column" width="100%" alignItems="center" gap="space24">
      <Flex direction="column">
        <Heading as="h3">{t('sso.title')}</Heading>
      </Flex>
      <div style={{ width: '100%', height: '300px' }}>
        <ListView isVirtualized height="100%">
          <Item textValue="sso">
            <Flex padding="space16" justifyContent="space-between">
              <Flex direction="column">
                <Text>{t('sso.provides.isActive')}</Text>
                <Switch label="" checked={isActive} onChange={setIsActive} />
              </Flex>
              <Flex direction="column">
                <Text>{t('sso.provides.firstName')}</Text>
                <Switch label="" checked={firstName} onChange={setFirstName} />
              </Flex>
              <Flex direction="column">
                <Text>{t('sso.provides.lastName')}</Text>
                <Switch label="" checked={lastName} onChange={setLastName} />
              </Flex>
              <Flex direction="column">
                <Text>{t('sso.provides.roles')}</Text>
                <Switch label="" checked={roles} onChange={setRoles} />
              </Flex>
              <Button variant="secondary" onClick={resetSSO}>
                {t('organization.integration.resetButtonCTA')}
              </Button>
            </Flex>
          </Item>
        </ListView>
      </div>
    </Flex>
  );
};

const overrideEntitlement = (entitlement: Entitlements) => {
  localStorage.setItem(entitlementsFlagName, entitlement);
};

const changeIntegration = (integration: IntegrationName) => {
  localStorage.setItem(qaUtilsOverwrites.integration, integration);
  window.location.reload();
};

const resetIntegration = () => {
  localStorage.removeItem(qaUtilsOverwrites.integration);
  window.location.reload();
};

export const getLocalStorageIntegration = () => {
  return localStorage.getItem(qaUtilsOverwrites.integration);
};

const OrgInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.QA_UTILS);
  const organization = useFullOrganization();
  const integrationName = organization?.integrationName;
  const lsIntegrationName = getLocalStorageIntegration();

  return (
    <Flex direction="column" width="100%" alignItems="center" gap="space24">
      <Flex direction="column">
        <Heading as="h3">{t('organization.title')}</Heading>
        <Text>
          {t('organization.integration.title', { integrationName })}
          {integrationName === lsIntegrationName ? ' (Overwritten)' : ''}
        </Text>
      </Flex>
      <div style={{ width: '100%', height: '150px' }}>
        <ListView isVirtualized height="100%">
          <Item
            key="organization-integration-override"
            textValue={t('organization.integration.overwrite')}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="space16"
            >
              <Text>{t('organization.integration.overwrite')}</Text>
              <Flex gap="space16">
                {Object.values(IntegrationName).map(v => {
                  return (
                    <Button
                      key={v}
                      variant="secondary"
                      onClick={() => changeIntegration(v)}
                    >
                      {v}
                    </Button>
                  );
                })}
              </Flex>
            </Flex>
          </Item>
          <Item
            key="organization-integration-reset"
            textValue={t('organization.integration.reset')}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="space16"
            >
              <Text>{t('organization.integration.reset')}</Text>

              <Button variant="secondary" onClick={() => resetIntegration()}>
                {t('organization.integration.resetButtonCTA')}
              </Button>
            </Flex>
          </Item>
        </ListView>
      </div>
    </Flex>
  );
};

const availableStatesMap: Record<FEATURE, Record<FEATURE_STATE, boolean>> = {
  [FEATURE.ABSENCE_MANAGEMENT]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.BDS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.CONTRACTS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.FULL_TEXT_SEARCH]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.INTEGRATION_SAP]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.POSTING_TEXT]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.PROVISIONS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.PURCHASE_ORDERS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.SEPA_XML]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.DOCUMENT_RELATIONS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.ECM]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.REIMBURSEMENT]: {
    [FEATURE_STATE.VISIBLE]: false,
    [FEATURE_STATE.PROMO]: false,
    [FEATURE_STATE.HIDDEN]: false,
  },
  [FEATURE.DOCUMENT_PREVIEW]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: true,
  },
  [FEATURE.TAGS]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: true,
  },
  [FEATURE.SEARCH_OVERLAY]: {
    [FEATURE_STATE.VISIBLE]: true,
    [FEATURE_STATE.PROMO]: true,
    [FEATURE_STATE.HIDDEN]: true,
  },
};

const FeatureRow = ({ feature }: { feature: FEATURE }) => {
  const { overrideFeature: updateFeatureToggleLS, ft } = useFeatureToggle();

  return (
    <Flex gap="1rem">
      <Text width="200px" style={{ margin: 'auto' }}>
        {feature}
      </Text>
      {Object.values(FEATURE_STATE).map(v => {
        return (
          <Button
            disabled={!availableStatesMap[feature][v]}
            onClick={() => {
              const updated = { ...ft, [feature]: v };
              updateFeatureToggleLS(updated);
            }}
            key={v}
            color={ft[feature] === v ? 'success' : 'util'}
            variant={ft[feature] === v ? 'primary' : 'tertiary'}
          >
            {v}
          </Button>
        );
      })}
    </Flex>
  );
};

const FeatureToggle = () => {
  const launchDarklyEntitlement = useLaunchDarklyDefaultEntitlement();
  const currentEntitlement = useEntitlementsFlag();

  const { overrideFeature } = useFeatureToggle();

  return (
    <Flex direction="column" width="100%" alignItems="center" gap="space24">
      <Flex direction="column">
        <Heading as="h3">
          Feature Toggle (FF LD Value: {launchDarklyEntitlement})
        </Heading>
      </Flex>
      <Flex gap="1rem">
        {Object.values(Entitlements).map(e => {
          const currentFeatures = entitlementsToFeatureMap[e];
          const isActive = e === currentEntitlement;

          return (
            <Button
              color={isActive ? 'success' : 'util'}
              variant={isActive ? 'primary' : 'tertiary'}
              key={e}
              onClick={() => {
                overrideFeature(currentFeatures);
                overrideEntitlement(e);
              }}
            >
              {e}
            </Button>
          );
        })}
        <Button
          color="util"
          variant="tertiary"
          onClick={() => {
            const defaultFeatures =
              entitlementsToFeatureMap[launchDarklyEntitlement];

            overrideFeature(defaultFeatures);
            overrideEntitlement(launchDarklyEntitlement);
          }}
        >
          Reset to FF LD Value
        </Button>
      </Flex>
      <Flex direction="column" gap="1rem">
        {Object.values(FEATURE).map(f => {
          return <FeatureRow key={f} feature={f} />;
        })}
      </Flex>
    </Flex>
  );
};

export const Overview = () => {
  return (
    <Flex direction="column" width="100%" alignItems="center">
      <OrgInfo />
      <FeatureToggle />
    </Flex>
  );
};

import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  EcmDocumentStatus,
  GetEcmSensitiveDocumentsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce, merge } from 'lodash';
import {
  EcmDocumentsPaginationParams,
  useEcmPagination,
} from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useMemo, useState } from 'react';
import { getEcmSensitiveDocumentsQuery } from './gql';

export interface UseEcmSensitiveDocumentsDataReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: (searchQuery: string) => void;
  isEmpty: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  sensitiveDocumentsCount: number;
}

interface UseEcmSensitiveDocumentsDataParams {
  params?: Omit<EcmDocumentsPaginationParams, 'search'>;
  initialSearchQuery?: string;
}

/**
 * Fetches ECM sensitive documents and maps to EcmDocumentsTableData
 */
export const useEcmSensitiveDocumentsData = ({
  params,
  initialSearchQuery = '',
}: UseEcmSensitiveDocumentsDataParams): UseEcmSensitiveDocumentsDataReturn => {
  const [debounceSearchValue, setDebounceSearchValue] =
    useState(initialSearchQuery);

  const { searchInputVariable } = useSearchInput();

  const sensitiveDocumentsQueryParams = {
    search: debounceSearchValue,
    filter: {
      documentStatus: [EcmDocumentStatus.New],
      isSensitive: true,
    },
    searchInput: searchInputVariable,
  };

  const mergedParams = merge(sensitiveDocumentsQueryParams, params);

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = useEcmPagination<GetEcmSensitiveDocumentsQuery, any>(
    getEcmSensitiveDocumentsQuery,
    'getEcmDocuments',
    {
      variables: mergedParams,
    }
  );

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME);
  }, []);

  const ecmDocumentsList = data?.getEcmDocuments;

  const isEmpty = !isLoading && data?.getEcmDocuments?.edges?.length === 0;

  const sensitiveDocumentsList = useMemo(
    () =>
      ecmDocumentsList?.edges
        ? ecmDocumentsList.edges.map(
            ({ record, cursor }): EcmDocumentsTableData => {
              const uploadDate = record?.createdAt
                ? new Date(record?.createdAt)
                : undefined;

              return {
                cursor,
                id: record?.id ?? '',
                globalDocumentId: record?.globalDocumentId ?? '',
                createdAt: uploadDate,
                contact: record?.contactName ?? '',
                invoiceId: record?.invoiceId ?? '',
                documentName: record?.documentName ?? '',
                documentNumber: record?.documentNumber ?? '',
                tags: record?.tags ?? [],
              };
            }
          )
        : [],
    [ecmDocumentsList?.edges]
  );

  const sensitiveDocumentsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: sensitiveDocumentsList,
    handleDebounceSearch,
    isEmpty,
    isLoading,
    onLoadMore,
    sensitiveDocumentsCount,
  };
};

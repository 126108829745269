import { Button, Flex, Modal, defaultTheme } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { AttachmentsTableContainer } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTable';
import { DocumentType } from 'generated-types/graphql.types';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisabledAttachmentButtonLabelText } from '../util/useDisabledAttachmentButtonLabelText';
import { AttachmentItem, AttachmentsSegmentProps } from './AttachmentsSegment';
import { ContactSupplierLink } from './ContactSupplier/ContactSupplierLink';
import {
  DocumentSummaryCard,
  DocumentSummaryCardList,
  DocumentSummaryCardListContainer,
} from './DocumentSummaryCard';
import { DocumentSummaryCardActionMenu } from './DocumentSummaryCardActionMenu';
import { DocumentSummaryInformationProps } from './DocumentSummaryInformation';
import { RelatedDocument } from './hooks/useDocumentRelations';
import { useFilesSelected } from './useFilesSelected';

export const DocumentSummaryInformationDeprecated = ({
  documentId,
  amount,
  invoiceDate,
  invoiceNumber,
  isEInvoice,
  eInvoiceValidationErrors,
  eInvoice,
  documentStatus,
  selectedContactEmail,
  contactName,
  isLoading,
  documentFile,
  attachments,
  attachPermissions,
  selectedPdf,
  onAttachmentClick,
}: DocumentSummaryInformationProps) => {
  const hasAttachments = attachments.length > 0;

  // TODO: align the handlers for Summary Cards and Atttachments
  const handleItemClick = hasAttachments
    ? () => onAttachmentClick(documentFile)
    : undefined;

  const document: RelatedDocument = {
    id: documentId,
    amount,
    name: contactName ?? null,
    isEInvoice,
    eInvoice,
    eInvoiceValidationErrors,
    number: invoiceNumber ?? null,
    status: documentStatus ?? null,
    date: invoiceDate ?? null,
    type: DocumentType.Invoice,
  };

  return (
    <DocumentSummaryCardListContainer>
      <DocumentSummaryCard
        as="div"
        borderTopRadius="medium"
        isLoading={isLoading}
        document={document}
        onAction={handleItemClick}
        action={
          <ContactSupplierLink
            selectedContactEmail={selectedContactEmail}
            eInvoiceErrorCount={eInvoiceValidationErrors?.length}
            trackingEventLocation="document_summary_card"
            documentId={documentId}
          />
        }
      />
      <AttachmentsSegmentDeprecated
        attachments={attachments}
        selectedPdf={selectedPdf}
        documentFile={documentFile}
        documentId={documentId}
        attachPermissions={attachPermissions}
        onAttachmentClick={onAttachmentClick}
      />
    </DocumentSummaryCardListContainer>
  );
};

export const AttachmentsSegmentDeprecated = ({
  attachments,
  selectedPdf,
  documentId,
  documentFile,
  attachPermissions,
  onAttachmentClick,
}: AttachmentsSegmentProps) => {
  const [t] = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const { onAttachFiles, isAttachingFiles } = useFilesSelected(
    attachments,
    documentFile,
    documentId
  );

  const hasAttachments = attachments.length > 0;

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;

  const canOnlyAttachFromDisk =
    attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const isAttachActionDisabled =
    !attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onUploadFileClick = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = () => {
    closeModal();
    onUploadFileClick();
  };

  const onFilesChanged = () => {
    if (!fileUploadRef.current?.files) return;

    onAttachFiles(Array.from(fileUploadRef.current.files));
    fileUploadRef.current.value = '';
  };

  const attachmentsTitle = t('document.tabs.attachments.title', {
    count: attachments.length,
  });

  return (
    <>
      {hasAttachments && (
        <CollapsibleCard
          maxHeight="35cqh"
          borderRadius="inherit"
          id="attachments-section-open"
          headerProps={{
            background: 'gray50',
            hover: { background: 'gray250' },
          }}
          header={attachmentsTitle}
          defaultOpen
        >
          <DocumentSummaryCardListContainer key="attachments">
            <DocumentSummaryCardList background="gray50">
              {attachments.map(attachment => (
                <AttachmentItem
                  key={attachment.id}
                  isActive={selectedPdf?.id === attachment.id}
                  attachment={attachment}
                  onAction={onAttachmentClick}
                />
              ))}
            </DocumentSummaryCardList>
          </DocumentSummaryCardListContainer>
        </CollapsibleCard>
      )}
      <AttachActionButton
        openModal={openModal}
        isLoading={isAttachingFiles}
        disabledLabel={disabledLabel}
        isAttachActionDisabled={isAttachActionDisabled}
        canOnlyAttachFromDisk={canOnlyAttachFromDisk}
        onUploadFileClick={onUploadFileClick}
      />

      <input
        type="file"
        accept="application/pdf"
        ref={fileUploadRef}
        onChange={onFilesChanged}
        disabled={!canAttach}
        multiple
        hidden
      />

      <Modal
        background="gray200"
        closeLabel={t('common.close')}
        width="80vw"
        isOpen={isModalOpen}
        onClose={closeModal}
        padding="0"
        scrollDirection="none"
        title={t('document.attachments.attachPdfFromCandis')}
      >
        <AttachmentsTableContainer
          documentId={documentId}
          onDocumentsAttached={closeModal}
          onUploadFileClick={handleFileUpload}
        />
      </Modal>
    </>
  );
};

type AttachActionButtonProps = {
  onUploadFileClick: () => void;
  openModal: () => void;
  isLoading: boolean;
  isAttachActionDisabled: boolean;
  canOnlyAttachFromDisk: boolean;
  disabledLabel?: string;
};

const AttachActionButton = ({
  openModal,
  isAttachActionDisabled,
  canOnlyAttachFromDisk,
  disabledLabel,
  onUploadFileClick,
  isLoading: isAttachingFiles,
}: AttachActionButtonProps) => {
  const [t] = useTranslation();

  if (canOnlyAttachFromDisk || isAttachActionDisabled) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        background="gray50"
        borderBottomLeftRadius="medium"
        borderBottomRightRadius="medium"
      >
        <Button
          icon="plus"
          variant="tertiary"
          style={{
            flexGrow: '1',
            padding: '.75rem 1rem',
            borderRadius: '0',
            borderEndStartRadius: defaultTheme.radii.medium,
            borderEndEndRadius: defaultTheme.radii.medium,
          }}
          label={disabledLabel}
          loading={isAttachingFiles}
          disabled={isAttachActionDisabled}
          onClick={onUploadFileClick}
        >
          {t('document.tabs.attachments.addAttachment')}
        </Button>
      </Flex>
    );
  }

  return (
    <DocumentSummaryCardActionMenu
      items={[
        {
          id: 'fromCandis',
          label: t('document.attachments.attachPdfFromDisk'),
          onAction: onUploadFileClick,
        },
        {
          id: 'fromDisk',
          label: t('document.attachments.attachPdfFromCandis'),
          onAction: openModal,
        },
      ]}
      loading={isAttachingFiles}
    >
      {t('document.tabs.attachments.addAttachment')}
    </DocumentSummaryCardActionMenu>
  );
};

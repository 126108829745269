import {
  ProcessingForm,
  ProcessingFormSubmitErrors,
} from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useCallback, useState } from 'react';
import { ProcessingFormActionsContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormActionsContainer';
import { useProcessingFormFieldOptions } from 'views/Inbox/DocumentProcessing/useProcessingFormFieldOptions';

export const FastApprovalForm = ({
  documentId,
  onApprove,
}: {
  documentId: string;
  onApprove: (
    values: ProcessingFormValues
  ) => Promise<ProcessingFormSubmitErrors | void>;
}) => {
  const [contactItem, setContactItem] =
    useState<ProcessingFormContactFieldItem>();

  const { fieldOptions, loading: loadingFieldOptions } =
    useProcessingFormFieldOptions({
      accountsPayableNumber: contactItem?.accountsPayableNumber,
    });

  const integration = useIntegrationSettings();

  const contact = fieldOptions.contact?.items?.[0]
    ? {
        value: fieldOptions.contact?.items?.[0].key,
        inputValue: fieldOptions.contact?.items?.[0].children,
      }
    : {
        value: '',
        inputValue: '',
      };

  const costCenter = fieldOptions.costCenter?.props?.items?.[0]
    ? {
        value: fieldOptions.costCenter?.props?.items?.[0].key,
        inputValue: fieldOptions.costCenter?.props?.items?.[0]
          .children as string,
      }
    : {
        value: '',
        inputValue: '',
      };

  const costObject = fieldOptions.costObject?.props?.items?.[0]
    ? {
        value: fieldOptions.costObject?.props?.items?.[0].key,
        inputValue: fieldOptions.costObject?.props?.items?.[0]
          .children as string,
      }
    : {
        value: '',
        inputValue: '',
      };

  const generalLedgerAccount = fieldOptions.generalLedgerAccount?.props
    ?.items?.[0]
    ? {
        value: fieldOptions.generalLedgerAccount?.props?.items?.[0].key,
        inputValue: fieldOptions.generalLedgerAccount?.props?.items?.[0]
          .children as string,
      }
    : {
        value: '',
        inputValue: '',
      };

  const taxCode = fieldOptions.taxCode?.props?.defaultItems?.[0]
    ? {
        value: fieldOptions.taxCode?.props?.defaultItems?.[0].key,
        inputValue: fieldOptions.taxCode?.props?.defaultItems?.[0]
          .children as string,
      }
    : {
        value: '',
        inputValue: '',
      };

  const tableKey =
    contact.value +
    costCenter.value +
    costObject.value +
    generalLedgerAccount.value +
    taxCode.value;

  const handleGetContactItem = useCallback(
    (value?: ProcessingFormContactFieldItem) => {
      setContactItem(value);
    },
    []
  );

  return loadingFieldOptions ? null : (
    <ProcessingForm
      key={tableKey} // Reset form state when switching document
      actions={
        <ProcessingFormActionsContainer
          documentId={documentId}
          onApprove={async (values: ProcessingFormValues) => {
            void onApprove(values);

            return;
          }}
        />
      }
      fieldOptions={fieldOptions}
      integration={integration}
      documentId={documentId}
      contactItem={contactItem}
      onGetContactItem={handleGetContactItem}
      defaultValues={{
        type: 'Rechnungseingang',
        currency: 'EUR',
        invoiceNumber: '2023-000001',
        grossAmount: 100,
        roundingAmount: 0,
        netAmount: 93.46,
        taxAmount: 6.54,
        invoiceDate: '2023-01-01',
        createTransfer: false,
        bookings: [
          {
            vatRate: 7,
            netAmount: 93.46,
            taxAmount: 6.54,
            amount: 100,
            dueDate: null,
            note: 'note',
            costCenter,
            costObject,
            generalLedgerAccount,
            taxCode: taxCode.value,
            extraCostInfo: {
              value: null,
              inputValue: '',
            },
          },
        ],
        contact,
      }}
      onSubmit={async (_values: ProcessingFormValues) => {
        return;
      }}
    />
  );
};

import {
  Avatar,
  Card,
  Flex,
  Grid,
  Image,
  Tag,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { mappedCategoriesIcons } from 'components/Transactions/Table/util';
import { AmountText } from 'components/Transactions/TransactionDetailsCard/AmountText/AmountText';
import { CreditCardImage } from 'components/Transactions/TransactionDetailsCard/CreditCardImage';
import { CreditCardLinksTooltip } from 'components/Transactions/TransactionDetailsCard/CreditCardLinksTooltip/CreditCardLinksTooltip';
import { InvalidTransactionInfoBox } from 'components/Transactions/TransactionDetailsCard/InvalidTransactionInfoBox';
import { getAmountTextStyle } from 'components/Transactions/TransactionDetailsCard/utils';
import { TransactionStatusTag as StatusTag } from 'components/Transactions/TransactionStatusTag/TransactionStatusTag';
import {
  CardBrand,
  CardType,
  DocumentStatus,
  InvoiceAssociationStatus,
  MemberInfo,
  TransactionAmount,
  TransactionCategory,
  TransactionDeclinedReason,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { isNil } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import sparkles from 'views/CreditCards/CreditCardsInsights/media/sparkles.svg';
import { DocumentSummaryCardContainer } from './DocumentSummaryCard';
import { DocumentInfoTag, FallbackStatusTag } from './utils';

type CreditCardNumberProps = {
  cardRefNum?: string | null;
  cardName?: string | null;
  member?: MemberInfo;
};

export const CreditCardNumber = ({
  cardRefNum: lastDigits,
  cardName,
  member,
}: CreditCardNumberProps) => (
  <Grid alignContent="center" alignItems="center" justifyContent="start">
    <Grid>
      {member && (
        <Text fontWeight="semibold" fontSize="small">
          {member.firstName} {member.lastName}
        </Text>
      )}
    </Grid>
    <Grid
      columnGap="space8"
      autoFlow="column"
      alignItems="center"
      fontWeight="semibold"
      fontSize="xsmall"
      width="fit-content"
    >
      <Grid
        width="fit-content"
        columnGap="space8"
        autoFlow="column"
        alignItems="center"
        style={{ userSelect: 'none' }}
      >
        <Flex gap="space4">
          <Text>••••</Text>
          <Text color="gray600">{lastDigits}</Text>
        </Flex>
      </Grid>
      {cardName && (
        <Flex gap="space8" color="gray600">
          -<TruncatedText maxWidth="20ch">{cardName}</TruncatedText>
        </Flex>
      )}
    </Grid>
  </Grid>
);

export type Transaction = {
  id: string;
  cardId: string;
  cardRefNum?: string;
  cardName?: string;
  type?: TransactionType;
  status?: TransactionStatus;
  member?: MemberInfo;
  merchantName?: string;
  merchantLegalName?: string;
  transactionCreatedAt?: string;
  billingAmount?: TransactionAmount;
  transactionAmount?: TransactionAmount;
  localizedDeclineReason?: TransactionDeclinedReason;
  invoiceAssociationStatus?: InvoiceAssociationStatus;
  merchantLogoUrl: string | undefined;
  category: TransactionCategory | undefined;
  cardType: CardType | undefined;
  cardBrand: CardBrand | undefined;
};

export interface TransactionDetailsCardProps
  extends ComponentProps<typeof Grid> {
  transaction: Transaction;
  linkedDocumentStatus?: DocumentStatus;
  loading?: boolean;
  menu?: ReactNode;
}

const transaltionMap: Record<TransactionType, string> = {
  CASH_WITHDRAWAL: 'transactionDetailsCard.type.cash_withdrawal',
  CHARGEBACK: 'transactionDetailsCard.type.chargeback',
  PURCHASE: 'transactionDetailsCard.type.purchase',
  RECHARGE: 'transactionDetailsCard.type.recharge',
  REFUND: 'transactionDetailsCard.type.refund',
  STATUS_INQUIRY: 'transactionDetailsCard.type.status_inquiry',
};

interface TransactionDetailsProps {
  transaction?: Transaction;
}

const TransactionSummary = ({ transaction }: TransactionDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    type,
    status,
    transactionCreatedAt,
    transactionAmount,
    billingAmount,
    merchantLegalName,
    merchantName,
    merchantLogoUrl,
    category,
  } = transaction || {};

  const isTransactionPaidInForeignCurrency =
    !isNil(transactionAmount) && transactionAmount?.currency !== 'EUR';

  const isRefundType = type === TransactionType.Refund;
  const showTransactionType = type && type !== TransactionType.Purchase;

  const { textDecoration, color } = getAmountTextStyle(status, type);

  const dateField = transactionCreatedAt
    ? DateFormatters.compact(new Date(transactionCreatedAt))
    : t('ecm:documentRelationship.fallback.transaction.date');

  const billingAmountVariant = isTransactionPaidInForeignCurrency
    ? 'secondaryCurrency'
    : 'mainCurrency';

  return (
    <>
      {merchantLogoUrl && (
        <Avatar
          name={merchantName}
          img={merchantLogoUrl}
          alignSelf="center"
          justifySelf="center"
        />
      )}
      {!merchantLogoUrl && category && (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
          alignSelf="center"
          justifySelf="center"
        >
          <Image
            width="space24"
            opacity={0.5}
            src={mappedCategoriesIcons[category]}
            alt=""
          />
        </Flex>
      )}
      <Grid rowGap="space4" color="gray500">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          rowGap="space4"
        >
          <Flex alignItems="center" gap="space2">
            {!merchantLegalName &&
              t('ecm:documentRelationship.fallback.transaction.merchantName')}

            {merchantLegalName && (
              <TruncatedText
                color="gray800"
                fontWeight="500"
                maxWidth="20ch"
                overflowWrap="anywhere"
                whiteSpace="normal"
              >
                {merchantLegalName}
              </TruncatedText>
            )}
            {merchantName && merchantName !== merchantLegalName && (
              <TruncatedText
                maxWidth="12ch"
                overflowWrap="anywhere"
                whiteSpace="normal"
              >
                {`(${merchantName})`}
              </TruncatedText>
            )}
          </Flex>
          <Flex gap="space4">
            {showTransactionType && (
              <Tag variant="secondary" color="gray">
                {t(transaltionMap[type])}
              </Tag>
            )}
            {status ? <StatusTag status={status} /> : <FallbackStatusTag />}
          </Flex>
        </Flex>
        <Flex alignItems="start" justifyContent="space-between">
          <Flex gap="space12">{dateField}</Flex>
          <Flex
            gap="space8"
            textDecoration={textDecoration}
            color={isRefundType ? color : undefined}
          >
            {isTransactionPaidInForeignCurrency && (
              <AmountText
                value={transactionAmount}
                isRefundType={isRefundType}
                variant="mainCurrency"
              />
            )}
            {billingAmount && (
              <AmountText
                value={billingAmount}
                isRefundType={isRefundType}
                variant={billingAmountVariant}
              />
            )}
          </Flex>
        </Flex>
      </Grid>
    </>
  );
};

export const TransactionSummaryCard = ({
  transaction,
  menu,
  linkedDocumentStatus = DocumentStatus.New,
  ...rest
}: Omit<TransactionDetailsCardProps, 'as'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOnlyApprover } = useUserRoles();
  const user = useCurrentUser();

  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const { isOpen, tooltipProps, tooltipRef } = useTooltip({
    placement: 'bottom',
  });

  const {
    status,
    member,
    cardName,
    cardRefNum,
    localizedDeclineReason,
    invoiceAssociationStatus,
    cardId,
    cardType,
    cardBrand,
  } = transaction ?? {};

  const membershipId = member?.membershipId ?? undefined;
  const isTransactionOwner = membershipId === user?.id;

  const isTooltipHidden = isOnlyApprover && !isTransactionOwner;

  const isReversed = status === TransactionStatus.Reversed;
  const isDeclined = status === TransactionStatus.Declined;

  const isInvalidStatus = isReversed || isDeclined;

  const isTransactionAutomatched =
    invoiceAssociationStatus === InvoiceAssociationStatus.AutoMatched;

  const isAutomatchedInfoVisible =
    creditCardsAutomatedMatchingFF && isTransactionAutomatched;

  return (
    <>
      <Grid as="li">
        <DocumentSummaryCardContainer {...rest}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            style={{ marginInlineEnd: '-.5rem' }}
          >
            <Flex alignItems="center" gap="space8">
              <DocumentInfoTag>
                {t('transactionDetailsCard.category')}
              </DocumentInfoTag>
              {isAutomatchedInfoVisible && <AutomatchedInfo />}
            </Flex>
            {menu}
          </Flex>
          <Grid gap="space8" templateColumns=" auto 1fr" alignItems="center">
            <CreditCardImage cardType={cardType} cardBrand={cardBrand} />
            <CreditCardNumber
              cardName={cardName}
              cardRefNum={cardRefNum}
              member={member}
            />
            <TransactionSummary transaction={transaction} />
          </Grid>
          {isInvalidStatus && (
            <InvalidTransactionInfoBox
              status={status}
              localizedDeclineReason={localizedDeclineReason}
            />
          )}
        </DocumentSummaryCardContainer>
      </Grid>
      {isOpen && !isTooltipHidden && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <CreditCardLinksTooltip cardId={cardId} membershipId={membershipId} />
        </Tooltip>
      )}
    </>
  );
};

const AutomatchedInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'top' });

  return (
    <>
      <Card
        background="bluebg"
        padding="space4"
        boxShadow="noShadow"
        ref={triggerRef}
        {...triggerProps}
      >
        <Image alt="" src={sparkles} height="space20" width="space24" />
      </Card>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text>{t('transactionDetailsCard.automatchedInfo')}</Text>
        </Tooltip>
      )}
    </>
  );
};

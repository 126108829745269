import { Flex, Grid } from '@candisio/design-system';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import { SearchField } from 'components/SearchField/SearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';

interface ReimbursementTableToolbarProps {
  search: string;
  configurations: Configuration[];
  isLoading?: boolean;
  toolbarFilter?: ReactNode;
  onUpdateConfigurations: (configurations: Configuration[]) => void;
  onResetTableConfigurations: () => void;
  onSearch: (querySearch: string) => void;
}

export const ReimbursementTableToolbar = ({
  configurations,
  search,
  isLoading,
  toolbarFilter,
  onSearch,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: ReimbursementTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { showImprovedSearch, showSearchOverlay, showSearchOverlayPromo } =
    useReimbursement();

  return (
    <Grid
      templateColumns={
        showImprovedSearch
          ? 'minmax(12rem, 24rem) auto 1fr'
          : 'minmax(12rem, 24rem) 1fr'
      }
      borderTopRadius="basic"
      overflow="hidden"
      alignItems="center"
      background="white"
      borderBottom="1px solid gray200"
      padding="space12"
    >
      {showSearchOverlay || showSearchOverlayPromo ? (
        <AdvancedSearchField
          clearTooltip={t('archive:search.clearTooltip')}
          placeholder={t('archive:search.placeholder')}
          initialValue={search}
          onQueryChange={onSearch}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
      ) : (
        <SearchField
          clearTooltip={t('table.search.clearTooltip')}
          placeholder={t('table.search.placeholder')}
          initialValue={search}
          onChange={onSearch}
        />
      )}

      <Flex justifySelf="right" gap="space4" alignItems="center">
        {toolbarFilter && toolbarFilter}
        <ConfigurationsMenu
          isLoading={isLoading}
          configurationItems={configurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetTableConfigurations}
        />
      </Flex>
    </Grid>
  );
};

import { Modal, Tag } from '@candisio/design-system';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { useTranslation } from 'react-i18next';

interface SearchFilterUpsellModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SearchFilterUpsellModal({
  isOpen,
  onClose,
}: SearchFilterUpsellModalProps) {
  const [t] = useTranslation();
  const { promotionDetails } =
    usePromotionDetailsModalContent('documentManagement');

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        width="1000px"
        background="gray100"
        padding="0"
        titleInfo={
          <Tag color="purple" variant="secondary">
            {t('tag.addOn')}
          </Tag>
        }
        closeLabel={t('common.close')}
        style={{
          background: 'linear-gradient(90deg, #ffffff 60%, #f8f6f5 40%)',
        }}
      >
        <PromotionDetailsModalContent promotionDetails={promotionDetails} />
      </Modal>
    </>
  );
}

import {
  Box,
  Flex,
  Grid,
  Skeleton,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { StatusTag } from 'components/StatusTag';
import { DocumentType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import {
  ContractInfo,
  DocumentSummaryCardContainerImproved,
  DocumentSummaryCardProps,
  OverdueIcon,
  UserWithAccessInfoProps,
  fallbacksTransaltionMap,
} from './DocumentSummaryCard';
import { useEcmContractTypeOptions } from './Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from './Ecm/useEcmDocumentTypeItems';
import { FallbackStatusTag } from './utils';

const mainDocumentTemplate = `
"    type      type      type      type      type    status    status    status" 1.375rem
"    type      type      type      type      type altStatus altStatus altStatus" 1.375rem
"mainInfo  mainInfo  mainInfo  mainInfo  mainInfo  mainInfo  mainInfo    action" 1.0rem
" contact   contact   contact   contact   contact   contact   contact   contact" 1.0rem
"    date      date      date      date    amount    amount    amount    amount" 1.0rem
`;

export const MainDocumentSummaryCard = ({
  as = 'div',
  document,
  isLoading = false,
  action,
  ...rest
}: DocumentSummaryCardProps & {
  isLoading?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const {
    amount,
    createdBy,
    date,
    isOverdue,
    isSensitive,
    isEInvoice,
    eInvoice,
    name,
    notifyPerson,
    number,
    responsiblePerson,
    status,
    type,
    id,
    contractTypeId,
  } = document;

  const isContract = type === DocumentType.Contract;

  const usersWithAccess = useMemo(() => {
    return Array(createdBy)
      .concat(notifyPerson, responsiblePerson)
      .filter(Boolean);
  }, [createdBy, notifyPerson, responsiblePerson]);

  if (isLoading)
    return (
      <Grid as={as} key={id}>
        <LoadingMainDocumentSummaryCard {...rest} />
      </Grid>
    );

  const fallbackTranslation =
    type in fallbacksTransaltionMap
      ? fallbacksTransaltionMap[type]
      : fallbacksTransaltionMap[DocumentType.Other];

  const documentField: string = number ?? t(fallbackTranslation.documentNumber);

  const dateField = date
    ? DateFormatters.compact(new Date(date))
    : t(fallbackTranslation.date);

  const amountField = amount
    ? amountFormat(amount?.amount, amount?.currency, {
        convertToBasicMonetaryUnit:
          type !== DocumentType.Invoice && amount.precision === 2,
      })
    : t('documentRelationship.fallback.amount');

  const nameField: string =
    name ?? t('documentRelationship.fallback.contactName');

  const documentInfo =
    type === DocumentType.Contract && contractTypeId
      ? getContractTypeLabel(contractTypeId)
      : ecmDocumentTypeTranslationMap[type];

  return (
    <DocumentSummaryCardContainerImproved
      as="div"
      height="inherit"
      template={mainDocumentTemplate}
      autoFlow="row"
      autoColumns="1fr"
      alignItems="start"
      alignContent="space-between"
      color="gray500"
      rowGap="space2"
      paddingX="space12"
      paddingY="space12"
      {...rest}
    >
      <Flex
        gridArea="type"
        gap="space4"
        alignItems="center"
        lineHeight="1.375rem"
      >
        <Text fontWeight="semibold" color="gray800" fontSize="large">
          {documentInfo}
        </Text>
        {isEInvoice && (
          <EInvoiceInfoIcon
            isEInvoice={isEInvoice}
            eInvoice={eInvoice}
            showWarningDescription
          />
        )}
      </Flex>
      <Flex gridArea="status" justifySelf="end">
        {isContract && (
          <Box right="0" top="0px">
            <ContractInfo
              small
              isSensitive={isSensitive}
              users={usersWithAccess as UserWithAccessInfoProps['users']}
            />
          </Box>
        )}
        {status ? <StatusTag status={status} /> : <FallbackStatusTag />}
      </Flex>
      {isOverdue && <OverdueIcon gridArea="altStatus" padding="0" />}
      <Box gridArea="action" justifySelf="end" top="-space10" right="-space10">
        {action}
      </Box>

      <TruncatedText gridArea="mainInfo" charsAfterEllipsis={5}>
        {nameField}
      </TruncatedText>
      <Box gridArea="contact">
        <TruncatedText charsAfterEllipsis={10}>{documentField}</TruncatedText>
      </Box>
      <Text gridArea="date">{dateField}</Text>
      <Text
        gridArea="amount"
        justifySelf="end"
        fontWeight="600"
        color="gray800"
        alignSelf="end"
      >
        {amountField}
      </Text>
    </DocumentSummaryCardContainerImproved>
  );
};

const LoadingMainDocumentSummaryCard = (
  props: Partial<Omit<DocumentSummaryCardProps, 'as'>>
) => (
  <DocumentSummaryCardContainerImproved
    padding="space16"
    paddingBottom="space10"
    {...props}
  >
    <Flex
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="space8"
    >
      <Skeleton height="space24" width="25%" />
      <Skeleton height="space24" width="15%" />
    </Flex>
    <Flex direction="column" paddingTop="space10" rowGap="space5">
      <Flex alignItems="center" justifyContent="space-between">
        <Skeleton height="space12" width="65%" />
      </Flex>
      <Flex alignItems="start" justifyContent="start">
        <Skeleton height="space12" width="10rem" />
      </Flex>
      <Flex alignItems="start" justifyContent="space-between">
        <Skeleton height="space12" width="7rem" />
        <Skeleton height="space12" width="4rem" />
      </Flex>
    </Flex>
  </DocumentSummaryCardContainerImproved>
);

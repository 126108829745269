import { Grid } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { DatevConnectionType } from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useDatev } from 'orgConfig/datev';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import DatevApiIcon from '../images/DATEV-Cloud-Services.svg';
import { urlParams } from '../utils';
import { BdsInfoPanel } from './components/BdsInfoPanel/BdsInfoPanel';

type DatevHelpInformationProps = {
  displayBDSInfoPanel: boolean;
  displayFeedbackHelper: boolean;
};

export const DatevHelpInformation = ({
  displayBDSInfoPanel,
  displayFeedbackHelper,
}: DatevHelpInformationProps) => {
  const [t] = useTranslation();
  const [search] = useSearchParams();
  const { bdsConnected, bdsBought } = useDatev();
  const isBdsConnection = bdsBought && bdsConnected;
  const selectedDatevConnectionType =
    search.get(urlParams.datevConnectionType) ||
    (bdsBought ?? DatevConnectionType.DatevRewe);

  const [isBdsPromotionDismissed, setIsBdsPromotionDismissed] = useLocalStorage(
    'bds_promotion_dismissed',
    false
  );

  const displayDatevReweInfoPanel =
    isBdsConnection ||
    selectedDatevConnectionType === DatevConnectionType.DatevRewe;

  return (
    <Grid alignContent="start" gap="space32">
      {displayBDSInfoPanel && !isBdsPromotionDismissed && (
        <BdsInfoPanel closePromotion={() => setIsBdsPromotionDismissed(true)} />
      )}

      <InfoPanel
        logo={{
          alt: t('settings.datev.imageAltTexts.dcsImage'),
          src: DatevApiIcon,
        }}
        sections={t('settings.datev.connect.infoPanels.datev.sections', {
          returnObjects: true,
        })}
        externalLink={t(
          'settings.datev.connect.infoPanels.datev.externalLink',
          {
            returnObjects: true,
          }
        )}
      />

      {displayDatevReweInfoPanel ? (
        <InfoPanel
          title={t('settings.datev.connect.infoPanels.faq.bds.title')}
          sections={t('settings.datev.connect.infoPanels.faq.bds.sections', {
            returnObjects: true,
          })}
          externalLink={t(
            'settings.datev.connect.infoPanels.faq.bds.externalLink',
            {
              returnObjects: true,
            }
          )}
        />
      ) : (
        <InfoPanel
          title={t('settings.datev.connect.infoPanels.faq.rds.title')}
          sections={t('settings.datev.connect.infoPanels.faq.rds.sections', {
            returnObjects: true,
          })}
          externalLink={t(
            'settings.datev.connect.infoPanels.faq.rds.externalLink',
            {
              returnObjects: true,
            }
          )}
        />
      )}

      {displayFeedbackHelper && (
        <InfoPanel
          title={t('settings.datev.connect.infoPanels.feedback.title')}
          sections={t('settings.datev.connect.infoPanels.feedback.sections', {
            returnObjects: true,
          })}
          intercomLink={
            <IntercomLink
              translation={t(
                'settings.datev.connect.infoPanels.feedback.buttonTrigger.label'
              )}
            />
          }
        />
      )}
    </Grid>
  );
};

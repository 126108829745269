import { Button, Skeleton, Text } from '@candisio/design-system';
import { ProvisionsPromotionExportInfoCard } from 'components/ProductPromotions/Provisions/ProvisionsPromotionExportInfoCard';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import {
  DatevConnectionType,
  ExportableEntityType,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useDatevAvailableServices } from 'orgConfig/datev/datevAvailableServices';
import { useDatevExportConfig } from 'orgConfig/datev/datevExportConfig';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useParams } from 'react-router-dom';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ConfigureButton } from 'views/Integrations/Export/toolkit/components/ConfigureButton';
import { DatevFiscalYearReminderPanel } from 'views/Integrations/Export/toolkit/components/DatevFiscalYearReminderPanel';
import { DatevPanel } from 'views/Integrations/Export/toolkit/components/DatevPanel';
import { ExportConfigurationHint } from 'views/Integrations/Export/toolkit/components/ExportConfigurationHint';
import { useDatevSettings } from 'views/Integrations/Export/toolkit/hooks/useDatevSettings';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { useShowEntitlementPromo } from 'views/Integrations/Export/toolkit/hooks/useShowEntitlementPromo';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntity, Table } from 'views/Integrations/Export/types';
import { useCheckDatevSetup } from 'views/Settings/Integrations/DATEV/setup-checks';

const dxsoMissingPermissionLsKey = 'dxso_missing_permission_dismissed';

export const RdsExportButton = ({
  onExport,
  entities,
  hasExportableEntity,
  loading,
}: {
  onExport: () => void;
  entities: ExportEntity[];
  hasExportableEntity: boolean;
  loading?: boolean;
}) => {
  const [t] = useTranslation();
  const { datevSettings, loading: loadingDatevSettings } = useDatevSettings();
  const { isExporting } = useIsExporting();
  const { table } = useContext(ExportContext);
  const { exportProvisionsFF, isLoadingDatev: loadingDatev } = useDatev(); // BDS-checked

  const { hasInvalidGrant, loading: loadingDatevExportConfig } =
    useDatevExportConfig();

  const history = useHistory();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const { loadingSettings: loadingCheckDatevSetup } = useCheckDatevSetup();

  const { hasDxsoWithoutPermissions } = useDatevAvailableServices();

  const showEntitlementPromo = useShowEntitlementPromo();

  const isCashLedgerConfigured =
    !!datevSettings?.client?.isDxsoCashLedgerConfigured;

  const isFiscalYearConfigured = datevSettings?.isFiscalYearConfigured;

  const creditCardBookingAccount =
    datevSettings?.creditCardsLedger?.bookingAccount || '';

  const creditCardTransitAccount =
    datevSettings?.creditCardsLedger?.transitAccount || '';

  const creditCardTransactionExists = entities.some(hasTransaction);
  const cardSettlementsExists = entities.some(
    entity => entity.type === ExportableEntityType.CardSettlement
  );

  const hasError = !hasExportableEntity && isExportTriggered;

  const provisionsOpened =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  const onGoToDatevSettings = () => {
    history.push(`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`);
  };

  const isLoadingConfig =
    loadingDatevSettings ||
    loadingDatev ||
    loadingDatevExportConfig ||
    loadingCheckDatevSetup;

  const [dismissed, setDismissed] = useLocalStorage(
    `${dxsoMissingPermissionLsKey}-${organizationSlug}`,
    false
  );

  const isRdsConnected =
    datevSettings?.connectionType === DatevConnectionType.DatevDuo;

  const renderExportHint = () => {
    if (!hasDxsoWithoutPermissions || dismissed) {
      return <ProvisionsPromotionExportInfoCard />;
    }

    return (
      <ExportConfigurationHint
        variant="information"
        hint={t('export.exportForm.dxsoPermissionMissing.message')}
        linkText={t('export.exportForm.dxsoPermissionMissing.linkText')}
        onLinkClick={onGoToDatevSettings}
        dismissable
        setDismissed={setDismissed}
      />
    );
  };

  const renderExportButton = () => {
    if (hasInvalidGrant) {
      return (
        <ConfigureButton
          message={t('exports:exportButton.invalidGrant.message')}
          buttonLabel={t('exports:exportButton.invalidGrant.button')}
          loading={isExporting}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (
      (creditCardTransactionExists || cardSettlementsExists) &&
      !isCashLedgerConfigured
    ) {
      return (
        <ConfigureButton
          message={t(
            'export.exportForm.exportDatev.cashLedgerNotEnabledForTransactions'
          )}
          buttonLabel={t('export.exportForm.exportDatev.enableCashLedger')}
          loading={isExporting}
          onClick={onGoToDatevSettings}
        />
      );
    }

    if (
      isCashLedgerConfigured &&
      ((creditCardTransactionExists && !creditCardBookingAccount) ||
        (cardSettlementsExists && !creditCardTransitAccount))
    ) {
      return (
        <ConfigureButton
          message={t(
            'export.exportForm.exportDatev.misconfiguredCreditCardSettings'
          )}
          buttonLabel={t('export.exportForm.exportDatev.setupCreditCard')}
          loading={isExporting}
          variant="information"
          onClick={() =>
            history.push(
              `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
            )
          }
        />
      );
    }

    return (
      <>
        {renderExportHint()}
        <Button
          width="100%"
          data-cy="submit"
          loading={isExporting || loading}
          disabled={hasError || (!exportProvisionsFF && provisionsOpened)}
          onClick={() => {
            setIsExportTriggered(true);
            if (hasExportableEntity) {
              void onExport();
            }
          }}
        >
          {t('export.exportForm.exportDatev.export')}
        </Button>
      </>
    );
  };

  if (isLoadingConfig) {
    return (
      <ActionBar>
        <Skeleton width="100%" height="3rem" />
      </ActionBar>
    );
  }

  if (showEntitlementPromo) {
    return (
      <ActionBar>
        <UpsellPromo
          feature={FEATURE.PROVISIONS}
          utmSource={PROVISIONS_UTM_SOURCE.EXPORT_SIDEBAR}
        />
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  if (!datevSettings?.client) {
    return (
      <ActionBar>
        <Button
          onClick={onGoToDatevSettings}
          data-cy="submit"
          loading={loading}
        >
          {t('export.exportForm.exportDatev.notConnected.cta')}
        </Button>
      </ActionBar>
    );
  }

  return (
    <DatevPanel
      loading={isExporting}
      onExport={onExport}
      datevSettings={datevSettings}
      nonIncidentElement={
        <ActionBar>
          {hasError && (
            <Text color="red500" fontSize="xsmall">
              {t('export.exportForm.actionBar.noExportableDocuments')}
            </Text>
          )}
          {isCashLedgerConfigured &&
            isRdsConnected &&
            !isFiscalYearConfigured && <DatevFiscalYearReminderPanel />}

          {renderExportButton()}
        </ActionBar>
      }
    />
  );
};

import { Flex, TruncatedText } from '@candisio/design-system';
import { motion } from 'motion/react';

const MotionFlex = motion(Flex);

const motionTextVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

export const IconLabel = ({
  expanded,
  label,
  description,
  ariaLabel,
}: {
  expanded: boolean;
  label: string;
  description?: string;
  ariaLabel?: string;
}) => {
  return (
    <MotionFlex
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      initial="closed"
      animate={expanded ? 'open' : 'closed'}
      variants={motionTextVariants}
      transition={{ duration: 0.2 }}
      style={{ overflow: 'hidden' }}
    >
      <TruncatedText
        aria-label={ariaLabel ?? label}
        fontSize="basic"
        fontWeight="500"
        lineHeight="space20"
      >
        {label}
      </TruncatedText>
      {description && (
        <TruncatedText
          aria-label={description}
          fontSize="small"
          fontWeight="regular"
          lineHeight="space16"
        >
          {description}
        </TruncatedText>
      )}
    </MotionFlex>
  );
};

import { Flex, Popover, TextField, usePopover } from '@candisio/design-system';
import { AdvancedSearchFieldFilterOverlay } from 'components/AdvancedSearchFieldFilterOverlay/AdvancedSearchFieldFilterOverlay';
import { SearchFilterUpsellModal } from 'components/AdvancedSearchFieldFilterOverlay/SearchFilterUpsellModal';
import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { AdvancedSearchFieldClearButton } from './components/AdvancedSearchFieldClearButton';
import { FilterButton } from './components/AdvancedSearchFieldFilterButton';
import { AdvancedSearchFieldSearchIcon } from './components/AdvancedSearchFieldSearchIcon';

export interface AdvancedSearchFieldProps {
  autoFocus?: boolean;
  clearTooltip?: string;
  inputMaxLength?: number;
  width?: number;
  placeholder: string;
  initialValue?: string;
  readOnly?: boolean;
  onQueryChange: (filter: string) => void;
}
export const AdvancedSearchField = ({
  autoFocus,
  clearTooltip,
  inputMaxLength,
  width,
  placeholder,
  initialValue,
  readOnly = false,
  onQueryChange,
}: AdvancedSearchFieldProps) => {
  const [value, setValue] = useState(initialValue ?? '');
  const [t] = useTranslation();

  const {
    close,
    setOpen,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom' });

  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const openPromoModal = () => {
    close();
    setIsPromoModalOpen(true);
  };
  const closePromoModal = () => {
    setIsPromoModalOpen(false);
    setOpen(true);
  };

  const { showSearchOverlayPromo } = useEcm();

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (value === '') {
      searchParams.delete(searchTypeParameter);
      searchParams.delete(searchScopeParameter);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, value]);

  const handleOnChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      onQueryChange(newValue);
    },
    [onQueryChange]
  );

  return (
    <Flex alignItems="center" alignContent="center" position="relative">
      <TextField
        flex={1}
        aria-label={t('search.label')}
        readOnly={readOnly}
        input={{
          autoFocus,
          size: width,
          placeholder,
          readOnly,
          value,
          maxLength: inputMaxLength,
          style: { maxWidth: width ? 'auto' : '85%' },
          onChange: e => handleOnChange(e.target.value),
        }}
      />

      <FilterButton
        isOverlayOpen={isOpen}
        triggerProps={triggerProps}
        triggerRef={triggerRef}
      />

      {value.length === 0 && <AdvancedSearchFieldSearchIcon />}
      {value.length > 0 && (
        <AdvancedSearchFieldClearButton
          clearTooltip={clearTooltip}
          onClear={() => handleOnChange('')}
        />
      )}

      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} autoFocus restoreFocus>
          <AdvancedSearchFieldFilterOverlay
            onApply={close}
            onShowPromoModal={openPromoModal}
          />
        </Popover>
      )}
      {showSearchOverlayPromo && (
        <SearchFilterUpsellModal
          isOpen={isPromoModalOpen}
          onClose={closePromoModal}
        />
      )}
    </Flex>
  );
};

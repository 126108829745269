import { Box, Button, Flex, Grid, Tag, Text } from '@candisio/design-system';
import {
  LottieAnimation,
  documentPreviewUpsellDE,
  documentPreviewUpsellEN,
} from 'components/Lottie/Lottie';
import i18next from 'i18next';
import { motion } from 'motion/react';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentPreviewCTA } from './DocumentPreviewCTA';
import {
  DocumentPreviewEventLocations,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useEffect } from 'react';

interface DocumentPreviewUpsellProps {
  onClose: () => void;
  showContent: boolean;
  promotionLocation: DocumentPreviewEventLocations;
  documentId: string;
}

export function DocumentPreviewUpsell(props: DocumentPreviewUpsellProps) {
  const [t] = useTranslation();

  const { track } = useAnalytics();

  useEffect(() => {
    track(TrackingEvents.DOCUMENT_PREVIEW_OPENED, {
      document_id: props.documentId,
    });
  }, [props.documentId, track]);

  return (
    <Grid
      background="white"
      templateRows="auto minmax(0, 1fr)"
      height="100%"
      borderTop="1px solid gray250"
    >
      <Grid
        justifyContent="space-between"
        alignItems="center"
        autoFlow="column"
        padding="space16 space16 space16 space24"
        borderBottom="1px solid gray250"
      >
        <Grid>
          <Text fontSize="xlarge" fontWeight="semibold" lineHeight="paragraph">
            {t('documentPreview.title')}
          </Text>
        </Grid>
        <Grid autoFlow="column" justifyContent="center" alignItems="center">
          <Button variant="tertiary" icon="close" onClick={props.onClose} />
        </Grid>
      </Grid>
      {props.showContent && (
        <MotionFlex
          direction="column"
          height="100%"
          animate="open"
          exit="closed"
          initial="closed"
          variants={VARIANTS}
        >
          <Flex
            background="purple100"
            justifyContent="center"
            alignItems="center"
            paddingX="space32"
            paddingY="space64"
            height="386"
            flex="1"
          >
            <LottieAnimation
              width="100%"
              height="100%"
              options={lottieOptions}
            />
          </Flex>
          <Grid
            overflow="auto"
            justifyContent="start"
            padding="space32"
            gap="space16"
          >
            <Tag color="purple" variant="secondary" size="large">
              {t('tag.addOn')}
            </Tag>
            <Text
              color="gray700"
              fontWeight="regular"
              fontSize="xxlarge"
              whiteSpace="pre-line"
            >
              {t('documentPreview.upSell.title')}
            </Text>
            <Grid gap="space24">
              <Text color="gray600" fontWeight="regular" fontSize="basic">
                {t('documentPreview.upSell.description')}
              </Text>
              <DocumentPreviewCTA promotionLocation={props.promotionLocation} />
            </Grid>
            <Text color="gray500" fontSize="basic" whiteSpace="pre-line">
              <Trans t={t} i18nKey="documentPreview.upSell.footnote.text">
                <Box
                  as="a"
                  color="gray800"
                  target="_blank"
                  rel="noopener noreferer"
                  href={t('documentPreview.upSell.footnote.url')}
                  style={{ textDecoration: 'underline' }}
                />
              </Trans>
            </Text>
          </Grid>
        </MotionFlex>
      )}
    </Grid>
  );
}

const MotionFlex = motion(Flex);
const lottieAnimationMap: Record<string, any> = {
  de: documentPreviewUpsellDE,
  en: documentPreviewUpsellEN,
};

const lottieOptions = {
  loop: true,
  animationData: lottieAnimationMap[i18next.language],
};

const VARIANTS = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

import { Link } from '@candisio/design-system';
import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { useDocumentApprovalErrorMessages } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/use-document-approval-error-messages';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ArtistSocialInsuranceCode,
  DocumentWorkflowStepSubmitInput,
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  WorkflowStepResolutionTypes,
  useSubmitWorkflowStepMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { Routes } from 'models';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { documentQueries } from 'views/Inbox/DocumentProcessing/queries';
import {
  List,
  documentHistoryQuery,
  getRefetchListQueries,
} from 'views/queries';
import { mapCashDiscountToBookingInput } from 'views/utils/mapCashDiscountToPaymentCondition';

const invoiceApprovalAnalyticsEventProperties = ({
  documentId,
  values,
  isUserInLastActiveWorkflowStep,
}: {
  documentId: string;
  values: DocumentApprovalFormValues;
  isUserInLastActiveWorkflowStep: boolean;
}) => ({
  document_id: documentId,
  note: values.bookings[0].note,
  tax_code: values.bookings[0].taxCode,
  posting_text: values.bookings[0].postingText,
  accounts_payable: values.accountsPayableNumber,
  cost_center: values.bookings[0].costCenter.inputValue,
  cost_object: values.bookings[0].costObject.inputValue,
  extra_cost_info: values.bookings[0].extraCostInfo.inputValue,
  general_ledger_account: values.bookings[0].generalLedgerAccount.inputValue,
  social_artist_security: values.bookings[0].artistSocialInsuranceCode,
  is_fast_approval: false,
  is_last_step: isUserInLastActiveWorkflowStep,
});

type UseSubmitDataProps = {
  cycleDocument: () => void;
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >;
  bookings: DocumentApprovalFormValues['bookings'];
  isUserInLastActiveWorkflowStep: boolean;
};

export const useSubmitData = ({
  document,
  bookings,
  cycleDocument,
  isUserInLastActiveWorkflowStep,
}: UseSubmitDataProps) => {
  const [t] = useTranslation();
  const { success, error, dismiss } = useToastMessage();
  const counterQueries = useCounterQueries();
  const currentUser = useCurrentUser();
  const organizationSlug = useOrganizationId();
  const { shouldUseSapNetAmount, shouldUseSapProjectCodes } = useSap();

  const { track } = useAnalytics();
  const { shouldUseAccountingAreas } = useOtherIntegration();

  const [submitWorkflowStep] = useSubmitWorkflowStepMutation({
    // Only refetch the document if the mutation was successful
    // Someone may have changed the approval process and the user is no longer
    // an approver.
    // Still update the counters and list view query so navigation works
    refetchQueries: result =>
      result.data?.documentWorkflowStepSubmitUnionResponse?.__typename ===
        'Document' && !result.errors?.length
        ? [
            ...getRefetchListQueries(List.APPROVAL),
            ...counterQueries,
            {
              query: documentQueries.forDraftForm,
              variables: {
                id: document.id,
              },
            },
            {
              query: documentHistoryQuery,
              variables: {
                id: document.id,
              },
            },
          ]
        : [...getRefetchListQueries(List.APPROVAL), ...counterQueries],
  });

  const toSubmitData = (
    values: DocumentApprovalFormValues
  ): DocumentWorkflowStepSubmitInput => {
    const submitBookings = bookings?.map(booking => {
      const bookingAmount = booking.amount;
      const currentCashDiscount = values.cashDiscount;

      const paymentCondition = mapCashDiscountToBookingInput({
        bookingAmount: bookingAmount || undefined,
        cashDiscount: currentCashDiscount
          ? {
              amount: currentCashDiscount.amount || undefined,
              dueDate: currentCashDiscount.dueDate || undefined,
              percentage: currentCashDiscount.percentage || undefined,
            }
          : undefined,
        isSingleBooking: bookings?.length === 1,
      });

      return {
        id: booking.bookingId,
        amount: booking.amount,
        dueDate: booking.dueDate ? booking.dueDate.toISOString() : null,
        generalLedgerAccountId: booking.generalLedgerAccount?.value,
        costCenterId: booking.costCenter?.value,
        costObjectId: booking.costObject?.value,
        extraCostInfoId: booking.extraCostInfo?.value,
        bookingKeyId: booking.taxCode,
        note: booking.note,
        postingText: booking.postingText,
        vatRate: booking.vatRate,
        taxAmount: shouldUseSapNetAmount ? booking.taxAmount : undefined,
        netAmount: shouldUseSapNetAmount ? booking.netAmount : undefined,
        artistSocialInsuranceCode:
          booking.artistSocialInsuranceCode as ArtistSocialInsuranceCode,
        ...paymentCondition,
        quantity: booking.quantity ?? undefined,
        sapExpenseType: booking.sapExpenseType ?? undefined,
        unitPrice: booking.unitPrice ?? undefined,
        projectCodeId: shouldUseSapProjectCodes
          ? booking.projectCode?.value
          : undefined,
      };
    });

    const comment = values.comment;
    const rejected = values.rejected;

    return {
      comment: comment || '',
      // XXX This should not be sent, but should be derived on BE
      // From the X-user-id header
      approverId: currentUser?.id,
      bookings: submitBookings,
      resolution: rejected
        ? WorkflowStepResolutionTypes.Rejected
        : WorkflowStepResolutionTypes.Approved,
      stepId: document.currentWorkflowStep?.id ?? '',
      accountingAreaId:
        shouldUseAccountingAreas && values.accountingArea.value
          ? values.accountingArea.value
          : undefined,
      roundingAmount: shouldUseSapNetAmount ? values.roundingAmount : undefined,
    };
  };

  const navigate = useNavigate();
  const { getDocumentApprovalErrors } = useDocumentApprovalErrorMessages();

  const onSubmit = async (values: DocumentApprovalFormValues) => {
    const { data: result, errors } = await submitWorkflowStep({
      variables: { id: document.id, input: toSubmitData(values) },
    });

    if (
      result?.documentWorkflowStepSubmitUnionResponse?.__typename ===
      'DocumentSubmitWorkflowStepErrors'
    ) {
      const submitErrors = getDocumentApprovalErrors(
        result.documentWorkflowStepSubmitUnionResponse
      );

      return { status: 'error', submitErrors };
    }

    if (errors?.length) {
      error(t('document.approveDocument.errors.unexpected'));

      return { status: 'error' };
    }

    const key = values.rejected
      ? 'document.approveDocument.message.stepRejected'
      : 'document.approveDocument.message.stepApproved';

    const url = values.rejected
      ? `/${organizationSlug}${Routes.INBOX}/${document.id}`
      : `/${organizationSlug}${Routes.ARCHIVE}/${document.id}`;

    if (!values.rejected) {
      track(
        TrackingEvents.INVOICE_APPROVE_BTN_CLICKED,
        invoiceApprovalAnalyticsEventProperties({
          documentId: document.id,
          values,
          isUserInLastActiveWorkflowStep,
        })
      );
    }

    const name = document.documentFile?.name;

    const content = (
      <Trans i18nKey={key} values={{ name }}>
        Document
        <Link
          href={url}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            dismiss();
            navigate(url);
          }}
        >
          {{ name } as any}
        </Link>
        was approved.
      </Trans>
    );

    success(content, 7);

    cycleDocument();

    return { status: 'success' };
  };

  return { onSubmit };
};

import {
  Box,
  Card,
  Grid,
  ScrollBox,
  Separator,
  Text,
  useTheme,
} from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { useReimbursementErrors } from 'views/Reimbursement/hooks/useReimbursementErrorrs';

const mappedTypesToTranslationKeys: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE: 'reimbursementView.leftSection.reimbursementItems.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

export const ErrorSection = () => {
  const { colors, space } = useTheme();
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { bookingsErrorsSummary } = useReimbursementSplitBookingHelpers();
  const { invalidFieldCount, invalidFormCount } = bookingsErrorsSummary;

  const {
    hasNoIncludedExpenseError,
    hasNoReimbursementItemsError,
    hasReimbursementCaseErrors,
    reimbursementItemsErrors,
  } = useReimbursementErrors();

  const handleClick = (id: string) => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  const hasAnyErrors =
    !!reimbursementItemsErrors.length ||
    hasReimbursementCaseErrors ||
    hasNoReimbursementItemsError ||
    hasNoIncludedExpenseError ||
    !!invalidFormCount;

  if (!hasAnyErrors) {
    return null;
  }

  const isSeparatorVisible =
    reimbursementItemsErrors.length > 0 &&
    (hasReimbursementCaseErrors ||
      hasNoReimbursementItemsError ||
      hasNoIncludedExpenseError ||
      !!invalidFormCount);

  const itemsErrors = reimbursementItemsErrors.map((item, index) => (
    <li key={index} style={{ color: colors.red700, cursor: 'pointer' }}>
      <Text fontSize="basic" onClick={() => handleClick(item.id)}>
        {t('reimbursementView.rightSection.footerActions.errors', {
          count: item.errorsCount,
          expense: item.title
            ? item.title
            : t(mappedTypesToTranslationKeys[item.type]),
        })}
      </Text>
    </li>
  ));

  const reimbursementCaseError = hasReimbursementCaseErrors && (
    <li style={{ color: colors.red700 }}>
      <Text fontSize="basic">
        {t(
          'reimbursementView.rightSection.footerActions.expenseCollectionError'
        )}
      </Text>
    </li>
  );

  const noExpenseError = hasNoReimbursementItemsError && (
    <li style={{ color: colors.red700 }}>
      <Text fontSize="basic">
        {t('reimbursementView.rightSection.errorNoItem')}
      </Text>
    </li>
  );

  const noIncludedExpensesError = hasNoIncludedExpenseError && (
    <li style={{ color: colors.red700 }}>
      <Text fontSize="basic">
        {t('reimbursementView.rightSection.errorNoIncludedItems')}
      </Text>
    </li>
  );

  const itemBookingsError = !!invalidFormCount && (
    <li style={{ color: colors.red700 }}>
      <Text fontSize="basic">
        {t('reimbursementView.rightSection.invalidBookings', {
          count: invalidFieldCount,
        })}
      </Text>
    </li>
  );

  return (
    <Card background="redbg" paddingX="0px" paddingY="space8">
      <Grid gap="space8">
        <Box paddingLeft="space10">
          <Text fontWeight="semibold" fontSize="basic" color="red700">
            {t('reimbursementView.rightSection.footerActions.titleErrors')}
          </Text>
        </Box>
        <ScrollBox maxHeight="space128">
          <Grid
            gap="space4"
            as="ul"
            style={{ paddingLeft: space.space24, paddingRight: space.space24 }}
          >
            {noExpenseError}
            {noIncludedExpensesError}
            {reimbursementCaseError}
            {itemBookingsError}
            {isSeparatorVisible && <Separator color="red100" />}
            {itemsErrors}
          </Grid>
        </ScrollBox>
      </Grid>
    </Card>
  );
};

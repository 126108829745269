import { Button, Flex, useModal } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  ContactSupplierOpenedProperties,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useTranslation } from 'react-i18next';
import { ContactSupplierFormContainer } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/ContactSupplierFormContainer';

type ContactSupplierButtonProps = {
  documentId?: string;
  selectedContactEmail?: string;
  buttonLabel?: string;
  trackingEventLocation: ContactSupplierOpenedProperties['event_location'];
  eInvoiceErrorCount?: number;
};

export const ContactSupplierButton = ({
  documentId,
  selectedContactEmail,
  buttonLabel,
  trackingEventLocation,
  eInvoiceErrorCount,
}: ContactSupplierButtonProps) => {
  const [t] = useTranslation();

  const {
    modalProps,
    open: contactSupplierModalOpen,
    close: contactSupplierModalClose,
  } = useModal();

  const { track } = useAnalytics();

  return (
    <Flex>
      <Button
        size="small"
        variant="secondary"
        onClick={() => {
          track(TrackingEvents.CONTACT_SUPPLIER_OPENED, {
            event_location: trackingEventLocation,
            document_id: documentId ?? '',
            e_invoice_error_count: eInvoiceErrorCount ?? 0,
          });
          contactSupplierModalOpen();
        }}
      >
        {t(buttonLabel ?? 'common:contactSupplierFromCandis.button.label')}
      </Button>
      <ContactSupplierFormContainer
        documentId={documentId ?? ''}
        selectedContactEmail={selectedContactEmail}
        modalProps={modalProps}
        onCloseModal={contactSupplierModalClose}
      />
    </Flex>
  );
};

import { Box, Grid } from '@candisio/design-system';
import { motion } from 'motion/react';

const dotStyle = {
  width: '1.625rem',
  height: '1.625rem',
  borderRadius: '100%',
  backgroundColor: 'white',
  marginBottom: '40px',
};

const dotTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: 'easeInOut',
};

const dotVariant = {
  initial: {
    scale: 0.25,
    opacity: 0,
  },
  animate: {
    scale: 0.5,
    opacity: 1,
  },
};

const middleDotVariant = {
  initial: {
    scale: 0.5,
    opacity: 1,
  },
  animate: {
    scale: 0.25,
    opacity: 0,
  },
};

const containerVariant = {
  initial: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const MotionGrid = motion(Grid);
const MotionBox = motion(Box);

export const LoadingContainer = () => {
  return (
    <MotionGrid
      position="relative"
      border={0}
      width="180px"
      height="280px"
      initial="initial"
      animate="animate"
      background="gray800"
      paddingLeft="space7"
      autoFlow="column"
      placeItems="center"
      placeContent="center"
      borderRadius="medium"
      templateColumns="1fr auto 1fr"
      variants={containerVariant}
    >
      <MotionBox
        justifySelf="end"
        style={dotStyle}
        variants={dotVariant}
        transition={dotTransition}
      />
      <MotionBox
        style={dotStyle}
        variants={middleDotVariant}
        transition={dotTransition}
      />
      <MotionBox
        justifySelf="start"
        style={dotStyle}
        variants={dotVariant}
        transition={dotTransition}
      />
    </MotionGrid>
  );
};

import { Box, Grid } from '@candisio/design-system';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const ACTIONS_HEIGHT_BUFFER_PX = 20;
const ACTIONS_HEIGHT_FALLBACK_VALUE = 140;

const calculatePadding = (scrollPadding?: number) =>
  (scrollPadding ?? ACTIONS_HEIGHT_FALLBACK_VALUE) + ACTIONS_HEIGHT_BUFFER_PX;

/**
 * Fix focused controls hidden behind the sticky content
 * @link: https://tetralogical.com/blog/2023/06/08/focus-in-view/
 */
export const BoxWrapper = styled(Box)<{ ['data-scroll-padding']?: number }>`
  overflow: hidden;
  height: 100%;

  & * {
    scroll-padding: ${({ 'data-scroll-padding': scrollPadding }) =>
      calculatePadding(scrollPadding) + 'px'};
  }
`;

const SlideOutBox = styled(Box)`
  transition-timing-function: ease-in;
  transition: margin-left .3s, opacity .3s .1s;

  &[data-collapsed=true] {
    margin-left: -25cqw;
    opacity: 0;
  }
  
  &:not([data-collapsed=true]) {
    margin-left: 0;
    opacity: 1;
  }
`;

export const LeftSectionOuterWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isComparisonView } = useComparisonView();
  return (
    <SlideOutBox data-collapsed={isComparisonView}>
      <Grid
        alignContent="start"
        gap="space8"
        paddingTop="space32"
        height="100cqh"
      >
        {children}
      </Grid>
    </SlideOutBox>
  );
};

export const LeftSectionContentWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Grid
    gap="space8"
    height="100%"
    overflowY="auto"
    width="clamp(20cqw, 20rem, 25cqw)"
  >
    {children}
  </Grid>
);

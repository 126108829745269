import { Box } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { useRelatedDocumentFiles } from 'components/DocumentViewer/useRelatedDocumentFiles';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { ComponentProps, useCallback, useDeferredValue, useMemo } from 'react';

const DocumentLayout = (props: ComponentProps<typeof DocumentViewer>) => (
  <Box height="100%" overflowY="auto">
    <DocumentViewer hideAttachmentSection {...props} />
  </Box>
);

export const RelatedDocumentContainer = ({
  mainDocumentId,
}: {
  mainDocumentId: string;
}) => {
  const { documentFile, attachments } = useRelatedDocumentFiles(mainDocumentId);
  const { updateCurrentFileId, params } = useComparisonView();

  const selectedFile = useMemo(() => {
    if (!params?.currentFileId) return documentFile;
    if (params.currentFileId === documentFile?.id) return documentFile;

    return attachments.find(
      ({ mainDocumentFileId, id }) =>
        params.currentFileId === mainDocumentFileId ||
        params.currentFileId === id
    );
  }, [params?.currentFileId, attachments, documentFile]);

  const selected = useDeferredValue(selectedFile);

  const onSelectDocument = useCallback(
    (value: PDFDetails) => {
      if (!updateCurrentFileId) return;
      if (value === selected) return;

      updateCurrentFileId(value.mainDocumentFileId ?? value.id ?? '');
    },
    [updateCurrentFileId, selected]
  );

  return (
    <DocumentLayout
      documentFile={documentFile}
      attachments={attachments}
      selectedFile={selected}
      onSelectDocument={onSelectDocument}
    />
  );
};

import {
  File,
  HospitalityExpenseItem,
  Money,
  ReimbursementError,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isEmpty, isNil } from 'lodash';
import { DateFormatters } from 'utils/date_formatter';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';

type HospitalityExpenseData = Omit<HospitalityExpenseItem, 'bookings'>;

interface HospitalityExtractedData {
  expenseDate?: string | null;
  invoiceNumber?: string | null;
  location?: string | null;
  receiptAmount?: Money | null;
  tipAmount?: Money | null;
}

export interface HospitalityExpense {
  id: string;
  reason?: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  createdAt: string;
  files: File[];
  totalAmount: number | null;
  tipAmount: number | null;
  receiptAmount: number | null;
  location?: string;
  errors: ReimbursementError[];
  externalGuests?: string;
  internalGuests?: string;
  expenseDate?: string;
  invoiceNumber?: string;
  isExtractedDataAccepted: boolean;
  extractedData?: {
    invoiceNumber?: string;
    location?: string;
    expenseDate?: string;
    receiptAmount: number | null;
    tipAmount: number | null;
  };
}

export const formattedHospitalityExtractedData = (
  extractedData?: HospitalityExtractedData
) => {
  const converter = getDateConverted();

  if (!extractedData) return undefined;

  return {
    invoiceNumber: extractedData.invoiceNumber ?? undefined,
    location: extractedData.location ?? undefined,
    expenseDate: extractedData.expenseDate
      ? converter.dateTimeStringToDateString(extractedData.expenseDate)
      : undefined,
    receiptAmount: !isNil(extractedData.receiptAmount?.amount)
      ? formatFromCents(
          extractedData.receiptAmount.currency,
          extractedData.receiptAmount.amount
        )
      : null,
    tipAmount: !isNil(extractedData.tipAmount?.amount)
      ? formatFromCents(
          extractedData.tipAmount.currency,
          extractedData.tipAmount.amount
        )
      : null,
  };
};

export const mapToHospitalityExpense = (expense: HospitalityExpenseData) => {
  const converter = getDateConverted();

  const extractedData = expense.extractedData?.[0];

  return {
    id: expense.id,
    errors: expense.errors,
    status: expense.status,
    type: ReimbursementItemType.HospitalityExpense,
    reason: expense.title ?? undefined,
    invoiceNumber: expense.invoiceNumber ?? undefined,
    isExtractedDataAccepted: expense.extractedDataAccepted ?? false,
    location:
      expense.__typename === 'HospitalityExpenseItem' && expense.location
        ? expense.location
        : undefined,
    externalGuests:
      expense.__typename === 'HospitalityExpenseItem' &&
      expense.externalGuests?.[0]
        ? expense.externalGuests?.[0]
        : undefined,
    internalGuests:
      expense.__typename === 'HospitalityExpenseItem' &&
      expense.internalGuests?.[0]
        ? expense.internalGuests?.[0]
        : undefined,
    createdAt: DateFormatters.compact(new Date(expense.createdAt)),
    expenseDate: expense.expenseDate
      ? converter.dateTimeStringToDateString(expense.expenseDate)
      : undefined,
    totalAmount: !isNil(expense.totalAmount?.amount)
      ? formatFromCents(
          expense.totalAmount.currency,
          expense.totalAmount.amount
        )
      : null,
    tipAmount:
      expense.__typename === 'HospitalityExpenseItem' &&
      !isNil(expense.tipAmount?.amount)
        ? formatFromCents(expense.tipAmount.currency, expense.tipAmount.amount)
        : null,
    receiptAmount:
      expense.__typename === 'HospitalityExpenseItem' &&
      !isNil(expense.receiptAmount?.amount)
        ? formatFromCents(
            expense.receiptAmount?.currency,
            expense.receiptAmount.amount
          )
        : null,
    files: expense.files?.length
      ? expense.files.map(file => ({
          id: file.id,
          url: file.url,
          name: file.name,
        }))
      : [],
    extractedData: !isEmpty(extractedData)
      ? formattedHospitalityExtractedData(extractedData)
      : undefined,
  } satisfies HospitalityExpense;
};

import {
  Button,
  Grid,
  defaultTheme,
  usePopover,
} from '@candisio/design-system';
import { motion } from 'motion/react';
import { useTranslation } from 'react-i18next';
import { transition, variants } from '../const';

const MotionGrid = motion(Grid);
const searchInputWidth = '2.125rem' /* 34px */;

interface FilterButtonProps {
  isOverlayOpen: boolean;
  triggerProps: ReturnType<typeof usePopover>['triggerProps'];
  triggerRef: React.RefObject<HTMLButtonElement>;
}

export const FilterButton = ({
  isOverlayOpen,
  triggerProps,
  triggerRef,
}: FilterButtonProps) => {
  const [t] = useTranslation();

  return (
    <>
      <MotionGrid
        position="absolute"
        right={searchInputWidth}
        initial="hidden"
        animate="visible"
        transition={transition}
        variants={variants}
      >
        <Button
          aria-label={t('search.filter')}
          style={{ padding: defaultTheme.space.space4 }}
          color="blue"
          icon="filterOutlined"
          variant="tertiary"
          isPressed={isOverlayOpen}
          {...triggerProps}
          ref={triggerRef}
        />
      </MotionGrid>
    </>
  );
};

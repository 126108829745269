import { GoodsReceiptsTableColumnKeys } from 'views/Archive/GoodsReceipts/types';

export const goodsReceiptsTableColumns = [
  'contact',
  'amount',
  'receiptNumber',
  'supplierRefNumber',
  'deliveryDate',
  'status',
  'receiptLines',
] as GoodsReceiptsTableColumnKeys[];

export enum tableTabs {
  GoodsReceipts = 'goodsReceipts',
  PurchaseOrders = 'purchaseOrders',
}

import { PDFDetails } from 'components/DocumentViewer/utils';
import { useDocumentRelationshipsFilesQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useGlobalDocumentId } from 'hooks/useGlobalDocumentId';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

export const useRelatedDocumentFiles = (documentId: string) => {
  const globalDocumentId = useGlobalDocumentId(documentId);
  const { isComparisonView } = useComparisonView();

  const { data: relationsData } = useDocumentRelationshipsFilesQuery({
    variables: { input: { globalDocumentId } },
    skip: !globalDocumentId || !isComparisonView,
  });

  const relationships = relationsData?.documentRelationships.relationships;

  const result = useMemo(() => {
    // cursed: logical flaw
    /**
     * a document can be attached and linked to another document at the same
     * time hence we have to keep track of linked documents and omit linked
     * document from the attachments
     * ---------------
     * in current implementation "first write wins" whenever a document is an
     * attachment or a relationship it would be omitted on the next occurence as
     * a relationship or an attachment respectively
     */
    const uniqueIds = new Set<string>();
    const files: PDFDetails[] = [];

    (relationships ?? []).forEach(({ linkedDocument: link }) => {
      if (!link || link.__typename !== 'RelatedDocument' || !link.document)
        return;

      if (
        link.document.__typename === 'AggregatedEcmDocument' &&
        link.document.fileSource
      ) {
        const { url, name, id } = link.document.fileSource;
        if (uniqueIds.has(id ?? '')) return;

        uniqueIds.add(id ?? '');
        return files.push({ url, name, id: id ?? undefined });
      }

      let mainDocumentFileId: string | undefined;

      if (
        link.document.__typename === 'Document' &&
        link.document.documentFile
      ) {
        const { url, name, id } = link.document.documentFile;
        uniqueIds.add(id ?? '');

        mainDocumentFileId = id;
        files.push({ url, name, id });
      }

      if (
        link.document.__typename === 'Document' &&
        link.document.attachments
      ) {
        link.document.attachments.forEach(({ url, name, id }) => {
          if (uniqueIds.has(id)) return;

          uniqueIds.add(id);
          files.push({
            url,
            name,
            id,
            mainDocumentFileId,
          });
        });
      }
    });

    const [documentFile, ...attachments] = files;
    return { documentFile, attachments };
  }, [relationships]);

  return result;
};

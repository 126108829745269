import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { queryParameter } from 'components/Table/consts';
import {
  Query,
  ReimbursementCasesSortInput,
  ReimbursementCasesSortOrder,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';
import { mapToReimbursementSortInput } from 'views/Reimbursement/utils/mapToReimbursementSortInput';
import { mapToReimbursementsTableData } from 'views/Reimbursement/utils/mapToReimbursementsTableData';
import { useMapToReimbursementsFilterInput } from 'views/Reimbursement/utils/useMapToReimbursementsFilterInput';
import { inboxReimbursementsQuery } from '../queries';
import { availableFiltersAndSorts } from '../utils';

export const defaultReimbursementInboxSort: ReimbursementCasesSortInput = {
  createdAt: ReimbursementCasesSortOrder.Desc,
};

export const useInboxReimbursementsData = () => {
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<ReimbursementTableRow>({
      availableFilters: availableFiltersAndSorts,
    });

  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';
  const [search, setSearch] = useState(searchQueryParam);
  const { searchInputVariable } = useSearchInput();

  const mappedFilters = useMapToReimbursementsFilterInput(filters);

  const getVariables = (cursor?: string) => {
    return {
      limit: 20,
      sorts: sortBy.length
        ? mapToReimbursementSortInput(sortBy)
        : defaultReimbursementInboxSort,
      filters: mappedFilters,
      search: search.length ? search : undefined,
      searchInput: searchInputVariable,
      next: cursor,
    };
  };

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'inboxReimbursementCases'>
  >(
    inboxReimbursementsQuery,
    'inboxReimbursementCases',
    {
      variables: getVariables(),
    },
    { computeVariables: getVariables }
  );

  const reimbursementsEdges = data?.inboxReimbursementCases.edges ?? [];
  const pageInfo = data?.inboxReimbursementCases.pageInfo;
  const reimbursementsCount = pageInfo?.totalCount ?? 0;
  const isTableEmpty = reimbursementsEdges.length === 0 && !loading;
  const hasMoreData = pageInfo?.hasNextPage ?? false;
  const isTableFiltered = filters.length > 0;

  const reimbursements = reimbursementsEdges.map(mapToReimbursementsTableData);

  const handleDebounceSearch = useMemo(() => {
    return debounce((value: string) => {
      setSearch(value);
      updateSearchParam(queryParameter, value);
    }, DEFAULT_DEBOUNCE_TIME);
  }, [updateSearchParam]);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return {
    isLoadingReimbursements: loading,
    isTableFiltered,
    isTableEmpty,
    hasMoreData,
    reimbursements,
    reimbursementsCount,
    sortBy,
    filters,
    handleDebounceSearch,
    onFilter,
    onSort,
    onLoadMore,
  };
};

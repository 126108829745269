import { Box } from '@candisio/design-system';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpenseInvoiceInputField } from './ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { BooleanMap } from './utils/useExpensesFormActions';

interface InvoiceLeftSectionProps {
  formIndex: number;
  showInvoicePreview: boolean;
  invoice: ExpenseInvoice | undefined;
  isCreatingInvoice?: BooleanMap;
  canManageReimbursementItems: boolean;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  handleInvoiceUpdate: () => Promise<void>;

  isExcluded: boolean;
}

export const InvoiceLeftSection = ({
  formIndex,
  showInvoicePreview,
  invoice,
  isCreatingInvoice,
  canManageReimbursementItems,
  onInvoiceChange,
  handleInvoiceUpdate,
  isExcluded,
}: InvoiceLeftSectionProps) => {
  return (
    <Box>
      {showInvoicePreview && (
        <ExpenseInvoicePreviewContainer
          name={`expenses.${formIndex}.files`}
          invoice={invoice}
          height="700px"
          expenseType="general"
          createInvoicePending={isCreatingInvoice?.[formIndex]}
          isReadOnly={!canManageReimbursementItems}
          id={`invoice-picker-expenses.${formIndex}.files`}
          onChange={async file => await onInvoiceChange(file, formIndex)}
          onInvoiceUpdate={handleInvoiceUpdate}
          isExcluded={isExcluded}
        />
      )}
      {!showInvoicePreview && (
        <ExpenseInvoiceInputField
          name={`expenses.${formIndex}.files`}
          id={`invoice-input-field-expenses.${formIndex}.files`}
          onChange={async file => await onInvoiceChange(file, formIndex)}
          onInvoiceUpdate={handleInvoiceUpdate}
        />
      )}
    </Box>
  );
};

import {
  SplitBookingsFormSchemaOptions,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { IntegrationName, SapExpenseType } from 'generated-types/graphql.types';
import { getTranslationContext } from 'orgConfig';
import { validateTaxCode } from 'utils/validateTaxCode';
import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';

const formSchema = z.object({
  invoiceCorrection: z.boolean(),
  taxPresentation: z.enum([TaxPresentation.Gross, TaxPresentation.Net]),
  vatRate: z.number().min(0).max(99.99).step(0.01).nullish(),
  amount: z.number().superRefine((amount, ctx) => {
    if (amount === 0) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey: 'inputs.amount.nonZeroError',
        },
      });
    }
  }),
  netAmount: z.number().nullish(),
  taxAmount: z.number().nullish(),
  dueDate: z.string().nullish(),
  postingText: z.string().nullish(),
  note: z.string().nullish(),
  quantity: z.number().nullish(),

  // paginated select fields
  generalLedgerAccount: z
    .object({
      value: z.string().nullish(),
      inputValue: z.string().nullish(),
    })
    .nullish(),
  costCenter: z
    .object({
      value: z.string().nullish(),
      inputValue: z.string().nullish(),
    })
    .nullish(),
  costObject: z
    .object({
      value: z.string().nullish(),
      inputValue: z.string().nullish(),
    })
    .nullish(),
  extraCostInfo: z
    .object({
      value: z.string().nullish(),
      inputValue: z.string().nullish(),
    })
    .nullish(),
  taxCode: z.string().nullish(),
  artistSocialInsuranceCode: z.string().nullish(),
  sapExpenseType: z.nativeEnum(SapExpenseType).optional(),
  unitPrice: z.number().nullish(),
  projectCode: z
    .object({
      value: z.string().nullish(),
      inputValue: z.string().nullish(),
    })
    .nullish(),
});

const requiredTranslationKey = 'errors.fieldRequired';

const taxCodeSchema = ({
  availableTaxCodes,
  integration,
  documentDirection,
  mode,
  shouldRequireTaxCode,
  hasReversedTaxCodes,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ taxCode: true, mode: true })
    .superRefine(({ taxCode }, ctx) => {
      if (mode === 'approve') {
        if (shouldRequireTaxCode && !taxCode) {
          ctx.addIssue({
            code: 'custom',
            path: ['taxCode'],
            params: {
              translationKey: requiredTranslationKey,
            },
          });
        }
      }

      const currentTaxCode = availableTaxCodes?.find(
        bookingKey => taxCode === bookingKey.id
      );

      if (
        currentTaxCode &&
        hasReversedTaxCodes &&
        !currentTaxCode?.isAcquisitionReverse
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['taxCode'],
          params: {
            translationKey: 'inputs.bookingKey.notAcquisitionReverseError',
          },
        });
      }

      const simpleValidation = [
        IntegrationName.DatevAdjacent,
        IntegrationName.Other,
        IntegrationName.Sap,
      ];

      if (integration && simpleValidation.includes(integration)) {
        return;
      }

      if (!documentDirection) {
        return taxCode;
      }

      if (!currentTaxCode) {
        return taxCode;
      }

      const isCombinationValid = validateTaxCode(
        currentTaxCode.taxCode,
        documentDirection
      );

      if (!isCombinationValid) {
        ctx.addIssue({
          code: 'custom',
          path: ['taxCode'],
          params: {
            translationKey: 'errors.bookingKeyId',
          },
        });
      }
    });
};

const vatRateSchema = ({ integration }: SplitBookingsFormSchemaOptions) =>
  formSchema
    .pick({ taxPresentation: true, vatRate: true })
    .superRefine(({ taxPresentation, vatRate }, ctx) => {
      if (
        taxPresentation === TaxPresentation.Net &&
        vatRate == null &&
        integration !== IntegrationName.Sap
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['vatRate'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });

const dueDateSchema = ({ invoiceDate }: SplitBookingsFormSchemaOptions) => {
  return formSchema.pick({ dueDate: true }).superRefine(({ dueDate }, ctx) => {
    if (dueDate && invoiceDate && new Date(dueDate) < new Date(invoiceDate)) {
      ctx.addIssue({
        code: 'custom',
        path: ['dueDate'],
        params: {
          translationKey: 'errors.dueDate',
        },
      });
    }
  });
};

const generalLedgerAccountSchema = ({
  shouldRequireGeneralLedgerAccount,
  mode,
  integration,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ generalLedgerAccount: true, sapExpenseType: true })
    .superRefine(({ generalLedgerAccount, sapExpenseType }, ctx) => {
      if (mode === 'approve') {
        if (integration === IntegrationName.Sap && sapExpenseType) {
          return;
        }

        if (shouldRequireGeneralLedgerAccount && !generalLedgerAccount?.value) {
          ctx.addIssue({
            code: 'custom',
            path: ['generalLedgerAccount', 'value'],
            params: {
              translationKey: requiredTranslationKey,
            },
          });
        }
      }
    });
};

const costCenterSchema = ({
  integration,
  mode,
  hasCostCenters,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ costCenter: true })
    .superRefine(({ costCenter }, ctx) => {
      if (
        integration !== IntegrationName.Sap &&
        mode === 'approve' &&
        hasCostCenters &&
        !costCenter?.value
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['costCenter', 'value'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });
};

const costObjectSchema = ({
  integration,
  mode,
  hasCostObjects,
  hasCostCenters,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ costObject: true })
    .superRefine(({ costObject }, ctx) => {
      if (
        integration !== IntegrationName.Sap &&
        mode === 'approve' &&
        hasCostObjects &&
        !hasCostCenters &&
        !costObject?.value
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['costObject', 'value'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });
};

const quantitySchema = ({
  shouldRequireQuantity,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ quantity: true, sapExpenseType: true })
    .superRefine(({ quantity, sapExpenseType }, ctx) => {
      if (sapExpenseType || !shouldRequireQuantity) {
        return;
      }

      if (quantity == null) {
        ctx.addIssue({
          code: 'custom',
          path: ['quantity'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      } else if (quantity === 0) {
        ctx.addIssue({
          code: 'custom',
          path: ['quantity'],
          params: {
            translationKey: 'inputs.quantity.nonZeroError',
          },
        });
      }
    });
};

const netAmountSchema = ({
  shouldUseSapNetAmount,
  isFormReadOnly,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ netAmount: true })
    .superRefine(({ netAmount }, ctx) => {
      if (!shouldUseSapNetAmount || isFormReadOnly) {
        return;
      }

      if (!netAmount) {
        ctx.addIssue({
          code: 'custom',
          path: ['netAmount'],
          params: {
            translationKey: 'inputs.netAmount.nonZeroError',
          },
        });
      }
    });
};

const unitPriceSchema = ({
  shouldUseSapNetAmount,
  shouldRequireQuantity,
  isFormReadOnly,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .pick({ unitPrice: true, sapExpenseType: true })
    .superRefine(({ unitPrice, sapExpenseType }, ctx) => {
      if (
        !shouldUseSapNetAmount ||
        isFormReadOnly ||
        !shouldRequireQuantity ||
        sapExpenseType
      ) {
        return;
      }

      if (!unitPrice) {
        ctx.addIssue({
          code: 'custom',
          path: ['unitPrice'],
          params: {
            translationKey: 'inputs.unitPrice.nonZeroError',
          },
        });
      }
    });
};

export const splitBookingsSchema = ({
  availableTaxCodes,
  documentDirection,
  hasCostCenters,
  hasCostObjects,
  integration,
  mode,
  shouldRequireGeneralLedgerAccount,
  invoiceDate,
  shouldUseSapNetAmount,
  shouldRequireQuantity,
  shouldRequireTaxCode,
  isFormReadOnly,
  hasReversedTaxCodes,
}: SplitBookingsFormSchemaOptions) => {
  return formSchema
    .and(
      taxCodeSchema({
        availableTaxCodes,
        integration,
        documentDirection,
        shouldRequireTaxCode,
        mode,
        hasReversedTaxCodes,
      })
    )
    .and(vatRateSchema({ integration }))
    .and(
      dueDateSchema({
        invoiceDate,
      })
    )
    .and(
      generalLedgerAccountSchema({
        shouldRequireGeneralLedgerAccount,
        mode,
      })
    )
    .and(
      costCenterSchema({
        integration,
        mode,
        hasCostCenters,
      })
    )
    .and(
      costObjectSchema({
        integration,
        mode,
        hasCostObjects,
        hasCostCenters,
      })
    )
    .and(
      quantitySchema({
        shouldRequireQuantity,
      })
    )
    .and(
      netAmountSchema({
        shouldUseSapNetAmount,
        isFormReadOnly,
      })
    )
    .and(
      unitPriceSchema({
        shouldUseSapNetAmount,
        shouldRequireQuantity,
        isFormReadOnly,
      })
    );
};

export type SplitBookingsFormValues = z.infer<
  ReturnType<typeof splitBookingsSchema>
>;

type ExcludeKeys =
  | 'generalLedgerAccount'
  | 'generalLedgerAccount.inputValue'
  | 'costCenter'
  | 'costCenter.inputValue'
  | 'costObject'
  | 'costObject.inputValue'
  | 'extraCostInfo'
  | 'extraCostInfo.inputValue'
  | 'sapExpenseType'
  | 'projectCode'
  | 'projectCode.inputValue';

export const getSplitBookingsFormErrorMessages = (): ErrorMessages<
  ReturnType<typeof splitBookingsSchema>,
  ExcludeKeys
> => {
  return {
    quantity: {
      label: 'inputs.quantity.label',
    },
    taxPresentation: {
      label: 'inputs.taxPresentation.label',
    },
    invoiceCorrection: {
      label: 'inputs.invoiceCorrection.label',
    },
    amount: {
      label: 'inputs.amount.label',
    },
    vatRate: {
      label: 'inputs.vatRate.label',
    },
    dueDate: {
      label: 'inputs.invoiceDueDate.label',
    },
    postingText: {
      label: 'inputs.postingText.label',
    },
    note: {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.note.label_sap'
          : 'inputs.note.label',
    },

    // paginated select fields
    'costCenter.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.costCenter.label_sap'
          : 'inputs.costCenter.label',
    },
    'costObject.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.costObject.label_sap'
          : 'inputs.costObject.label',
    },
    'generalLedgerAccount.value': {
      label: 'inputs.generalLedger.label',
    },
    'extraCostInfo.value': {
      label: 'inputs.extraCostInfo.label',
    },

    // these will be fully fetched and therefore have a different schema
    taxCode: {
      label: 'inputs.bookingKey.label',
    },
    artistSocialInsuranceCode: {
      label: 'inputs.artistSocialInsuranceCode.label',
    },
    netAmount: {
      label: 'inputs.netAmount.label',
    },
    taxAmount: {
      label: 'inputs.taxAmount.label',
    },
    unitPrice: {
      label: 'inputs.unitPrice.label',
    },
    'projectCode.value': {
      label: 'inputs.projectCode.label',
    },
  };
};

import { Button, Grid, Image, Paragraph, Text } from '@candisio/design-system';
import { motion } from 'motion/react';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/index';
import { useTranslation } from 'react-i18next';
import intercomCookiePausedImageDE from './assets/intercom-cookie-paused-de.png';
import intercomCookiePausedImageEN from './assets/intercom-cookie-paused-en.png';

interface Props {
  intercom: boolean;
  setIntercomEnabled: (shouldIntercomBeEnabled: boolean) => void;
}

const MotionGrid = motion(Grid);
const MotionImage = motion(Image);

export const IntercomCookieBanner = ({
  intercom,
  setIntercomEnabled,
}: Props) => {
  const [tConsentManager] = useTranslation(LOCALE_NAME_SPACE.CONSENT_MANAGER);
  const currentUser = useCurrentUser();

  const transition = { duration: 0.3, easeInOut: [0.42, 0, 0.58, 1] };

  return (
    <Grid background="bluebg" borderRadius="medium">
      <MotionGrid
        gap="space12"
        justifyItems="start"
        padding="space16"
        variants={{
          hidden: { translateY: '30px' },
          show: { translateY: '0px', transition },
        }}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <Text as="h3" fontSize="xxlarge">
          {tConsentManager('intercomCookieBanner.title')}
        </Text>
        <Paragraph>{tConsentManager('intercomCookieBanner.text')}</Paragraph>
        <Button
          onClick={() => setIntercomEnabled(!intercom)}
          color="green"
          size="small"
        >
          {tConsentManager('intercomCookieBanner.actions.enable')}
        </Button>
      </MotionGrid>
      <MotionImage
        height="200px"
        position="absolute"
        top="-space20"
        right="space48"
        variants={{
          hidden: { scale: 0.8 },
          show: { scale: 1, transition },
        }}
        initial="hidden"
        animate="show"
        exit="hidden"
        alt={tConsentManager('intercomCookieBanner.actions.imageAlt')}
        src={
          currentUser?.locale === 'DE'
            ? intercomCookiePausedImageDE
            : intercomCookiePausedImageEN
        }
      />
    </Grid>
  );
};

import {
  Color,
  Filter,
  Table,
  TableInfiniteScrollProps,
  TableProps,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { DocumentTypeCell } from 'components/DocumentsTable/Cells/DocumentTypeCell';
import { InvoiceCorrectionCell } from 'components/DocumentsTable/Cells/InvoiceCorrectionCell';
import { UserWithStatusCell } from 'components/DocumentsTable/Cells/UserWithStatusCell';
import { TagsHeader } from 'components/DocumentsTable/TagsHeader';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { paginationFiltersHooks } from 'components/Table/Filters/FilterWithSearchAndPagination/hooks/paginationFiltersHook';
import {
  DocumentStatus,
  PaymentFrequencyType,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableState } from 'react-table';
import { sortByNumberProp } from 'utils/sorting';
import { useMemoizedState } from 'utils/useMemoizedState';
import { useExportContext } from 'views/Integrations/Export/Context';
import { View } from 'views/Integrations/Export/types';
import { paymentFrequencyTranslation } from 'views/Settings/CreditCards/CreditCardSettlements/utils';
import { DateRangeFilter } from '../Table/Filters/DateRangeFilter/DateRangeFilter';
import { TransactionStatusTag } from '../Transactions/TransactionStatusTag/TransactionStatusTag';
import { AmountCellDocument } from './Cells/AmountCell';
import { Cell } from './Cells/Cell';
import { CreditCardPayment } from './Cells/CreditCardPayment';
import { DateCell } from './Cells/DateCell';
import { DiscountDateWPercentageCell } from './Cells/DiscountDateWPercentageCell';
import { ExportStatusCell } from './Cells/ExportStatusCell/ExportStatusCell';
import { FileNameCell } from './Cells/FileNameCell';
import { FileSizeCell } from './Cells/FileSizeCell';
import { GLACell } from './Cells/GLACell';
import { IbanCell } from './Cells/IbanCell';
import { MultipleValueCell } from './Cells/MultipleValueCell';
import { OpenInDatevCell } from './Cells/OpenInDatevCell';
import { PaymentInfoCell } from './Cells/PaymentInfoCell';
import { SplitsCell } from './Cells/SplitsCell';
import { StatementCell } from './Cells/StatementCell';
import { StatusCell } from './Cells/StatusCell';
import { TagsCell } from './Cells/TagsCell';
import { Header } from './Header';
import {
  ColumnWidths,
  DocumentTableColumnKeys,
  DocumentsTableData,
} from './types';
import { createSortByFn } from './utils/createSortByFn';
import { includesArray } from './utils/includesArray';

export const defaultSortByFields: DocumentTableColumnKeys[] = [
  'transactionStatus',
  'exportStatus',
  'contact',
  'openInDatev',
  'settlementPaymentStatus',
  'netAmount',
  'provisionStatus',
  'status',
  'grossAmount',
  'createdAt',
  'isPayable',
  'paymentStatus',
  'invoiceDate',
  'dueDate',
  'discountDateWPercentage',
  'requestedAt',
];

export interface DocumentsTableProps<
  TableDataType extends DocumentsTableData = DocumentsTableData,
> extends Omit<TableProps<TableDataType>, 'columns'> {
  columnWidths?: ColumnWidths;
  columns?: DocumentTableColumnKeys[];
  defaultFilters?: TableState<TableDataType>['filters'];
  defaultSortBy?: TableState<TableDataType>['sortBy'];
  infiniteScrollOptions?: Omit<TableInfiniteScrollProps, 'scrollableTarget'>;
  isUsingSearchQuery?: boolean;
  manualFilters?: boolean;
  manualSortBy?: boolean;
  onAllRowsSelected?: (selectedRows: TableDataType[]) => void;
  onRowClick?: (row: DocumentsTableData) => void;
  onRangeChanged?: any;
  rowOverlay?: TableProps<TableDataType>['rowOverlay'];
  cellWrapper?: TableProps<TableDataType>['cellWrapper'];
  getCellStyles?: TableProps<TableDataType>['getCellStyles'];
  sortByFields?: DocumentTableColumnKeys[];
}

export const mapProvisionStatusToColors: Record<
  TransactionStatus | DocumentStatus,
  Color
> = {
  [TransactionStatus.Confirmed]: 'green',
  [TransactionStatus.Pending]: 'yellow',
  [TransactionStatus.Declined]: 'red',
  [TransactionStatus.Reversed]: 'gray',
  [DocumentStatus.Approved]: 'green',
  [DocumentStatus.Exported]: 'blue',
  [DocumentStatus.Archived]: 'gray',
  [DocumentStatus.Exporting]: 'gray',
  [DocumentStatus.New]: 'gray',
  [DocumentStatus.Open]: 'yellow',
  [DocumentStatus.Processing]: 'gray',
  [DocumentStatus.Rejected]: 'red',
};

export type DocumentsTableColums = {
  [K in DocumentTableColumnKeys]: Column<DocumentsTableData>;
};

export const DocumentsTable = ({
  columns: columnsProp = [],
  columnWidths: columnWidthsProp,
  customEmptyState,
  data,
  defaultEmptyStateContent,
  defaultFilters,
  defaultSortBy,
  filterOptions,
  infiniteScrollOptions,
  isLoading,
  isSingleSelect = false,
  isUsingSearchQuery = false,
  onEndReached,
  onFilter,
  onRangeChanged,
  onRowClick,
  cellWrapper: CellWrapper,
  onSort,
  overscan,
  selectionOptions,
  tableFooter,
  borderTopRadius,
  rowOverlay,
  shouldEnableSortByWithSearchQuery = false,
  sortByFields = defaultSortByFields,
  ...restProps
}: DocumentsTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  // Cache `columns` and `columnsWidth` prop values to prevent the table state
  // from getting reinitialized on each render. This way we don’t rely on
  // consumers of DocumentsTable memoizing the props before passing them in.
  const shownColumns = useMemoizedState(columnsProp);
  const columnWidths = useMemoizedState(columnWidthsProp);

  const defaultColumn = useMemo(
    (): Partial<Column<DocumentsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof DocumentsTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<DocumentsTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filterApply')}
                  resetFilterButton={t('filterReset')}
                  searchFieldPlaceholder={t('filterSearchPlaceholder')}
                  filterLabel={t('filterLabel')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (paginationFiltersHooks[column.id]) {
              return (
                <FilterWithSearchAndPagination
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      filter: includesArray,
      disableFilters: !filterOptions,
    }),
    [filterOptions, t]
  );

  const columns = useMemo(() => {
    const disableSortBy =
      isUsingSearchQuery && !shouldEnableSortByWithSearchQuery;

    const shouldSortByField = (field: DocumentTableColumnKeys) =>
      sortByFields.includes(field);

    const allColumns: DocumentsTableColums = {
      transactionStatus: {
        accessor: 'transactionStatus',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: ({ value }: { value: TransactionStatus }) => (
          <TransactionStatusTag status={value} />
        ),
        sortType: createSortByFn('values.transactionStatus'),
        width: columnWidths?.['transactionStatus'],
        disableSortBy: !shouldSortByField('transactionStatus'),
      },
      status: {
        accessor: 'status',
        Cell: StatusCell,
        disableSortBy: disableSortBy || !shouldSortByField('status'),
        sortType: createSortByFn('values.status.status'),
        width: columnWidths?.['status'],
      },
      exportStatus: {
        accessor: 'exportStatus',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: ExportStatusCell,
        sortType: createSortByFn('values.exportStatus.status'),
        width: columnWidths?.['exportStatus'],
        disableSortBy: !shouldSortByField('exportStatus'),
      },
      exportMethod: {
        accessor: 'exportMethod',
        disableSortBy: !shouldSortByField('exportMethod'),
        sortType: createSortByFn('values.exportStatus.status'),
        width: columnWidths?.['exportMethod'],
      },
      contact: {
        accessor: 'contact',
        width: columnWidths?.['contact'],
        Filter: filterOptions
          ? ({ column, handleUpdateIsFilterBeingUsed }) => {
              const filterOption =
                filterOptions[column.id as keyof DocumentsTableData];

              if (typeof filterOption === 'object' && 'data' in filterOption) {
                const isLoading = filterOption.isLoading;

                return (
                  <Filter<DocumentsTableData>
                    column={column}
                    options={filterOption.data}
                    isLoading={isLoading}
                    applyFilterButton={t('filterApply')}
                    resetFilterButton={t('filterReset')}
                    searchFieldPlaceholder={t('filterSearchPlaceholder')}
                    filterLabel={t('filterLabel')}
                    onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  />
                );
              }

              if (paginationFiltersHooks[column.id]) {
                return (
                  <FilterWithSearchAndPagination
                    column={column}
                    onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  />
                );
              }
            }
          : undefined,
        disableSortBy: disableSortBy || !shouldSortByField('contact'),
      },
      accountsPayableNumber: {
        accessor: 'accountsPayableNumber',
        disableSortBy: true,
        width: columnWidths?.['accountsPayableNumber'],
      },
      documentType: {
        accessor: 'documentType',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DocumentTypeCell,
        disableSortBy: !shouldSortByField('documentType'),
        sortType: createSortByFn('values.documentType'),
        width: columnWidths?.['documentType'],
      },
      isInvoiceCorrection: {
        accessor: 'isInvoiceCorrection',
        disableSortBy: !shouldSortByField('isInvoiceCorrection'),
        Cell: InvoiceCorrectionCell,
      },
      grossAmount: {
        accessor: 'grossAmount',
        Cell: AmountCellDocument<DocumentsTableData>,
        disableFilters: true,
        disableSortBy: disableSortBy || !shouldSortByField('grossAmount'),
        sortType: sortByNumberProp('values.grossAmount.amount'),
        ...amountCellProps,
      },
      paymentInfo: {
        accessor: 'paymentInfo',
        Cell: PaymentInfoCell,
        disableFilters: true,
        disableSortBy: !shouldSortByField('paymentInfo'),
        width: columnWidths?.['paymentInfo'],
      },
      iban: {
        accessor: 'iban',
        Cell: IbanCell,
        disableFilters: true,
        disableSortBy: !shouldSortByField('iban'),
        width: columnWidths?.['iban'],
      },
      invoiceNumber: {
        accessor: 'invoiceNumber',
        disableFilters: true,
        disableSortBy: !shouldSortByField('invoiceNumber'),
        width: columnWidths?.['invoiceNumber'],
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: Cell,
      },
      invoiceDate: {
        accessor: 'invoiceDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        sortType: 'datetime',
        disableSortBy: disableSortBy || !shouldSortByField('invoiceDate'),
        disableFilters: !filterOptions?.['invoiceDate'],
        width: columnWidths?.['invoiceDate'],
      },
      dueDate: {
        accessor: 'dueDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        sortType: createSortByFn('values.dueDate[0]'),
        disableFilters: !filterOptions?.['dueDate'],
        width: columnWidths?.['dueDate'],
        disableSortBy: disableSortBy || !shouldSortByField('dueDate'),
      },
      tags: {
        accessor: 'tags',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: TagsCell,
        disableSortBy: !shouldSortByField('tags'),
        disableFilters: !filterOptions?.['tags'],
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Header: TagsHeader,
        width: 260,
      },
      deliveryDate: {
        accessor: 'deliveryDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        width: columnWidths?.['deliveryDate'],
        disableFilters: !filterOptions?.['deliveryDate'],
        disableSortBy: !shouldSortByField('deliveryDate'),
      },
      createdAt: {
        accessor: 'createdAt',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        width: columnWidths?.['createdAt'],
        disableFilters: !filterOptions?.['createdAt'],
        sortType: 'datetime',
        disableSortBy: disableSortBy || !shouldSortByField('createdAt'),
      },
      requester: {
        accessor: 'requester',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        disableSortBy: !shouldSortByField('requester'),
        width: columnWidths?.['requester'],
      },
      approvers: {
        accessor: 'approvers',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        disableSortBy: !shouldSortByField('approvers'),
        width: columnWidths?.['approvers'],
      },
      fileName: {
        accessor: 'fileName',
        Cell: FileNameCell,
        disableFilters: true,
        disableSortBy: !shouldSortByField('fileName'),
        width: columnWidths?.['fileName'],
      },
      fileSize: {
        accessor: 'fileSize',
        Cell: FileSizeCell,
        disableFilters: true,
        disableSortBy: !shouldSortByField('fileSize'),
        width: columnWidths?.['fileSize'],
      },
      openInDatev: {
        accessor: 'openInDatev',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: OpenInDatevCell,
        disableFilters: true,
        disableSortBy: !shouldSortByField('openInDatev'),
        width: columnWidths?.['openInDatev'],
      },
      settlementPaymentStatus: {
        accessor: 'settlementPaymentStatus',
        Cell: ({ value }) => {
          // TODO: find out a way to distinct Not Ready settlements
          // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
          const { view } = useExportContext();
          const isPaid = value === 'PAID';
          const isAwaitingPdf = isPaid && view === View.NOT_READY;

          let readable = isPaid ? t('isPaid.paid') : t('isPaid.notPaid');

          if (isAwaitingPdf) {
            readable = t('isPaid.awaitingPdf');
          }

          const color: Color = isAwaitingPdf ? 'yellow' : 'gray';

          return (
            <Tag color={color} variant="secondary">
              {readable}
            </Tag>
          );
        },
        sortType: createSortByFn('values.settlementPaymentStatus'),
        width: columnWidths?.['settlementPaymentStatus'],
        disableSortBy: !shouldSortByField('settlementPaymentStatus'),
      },
      period: {
        accessor: 'period',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: ({ value }: { value: PaymentFrequencyType }) => {
          // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
          const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

          return <Text>{t(paymentFrequencyTranslation[value])}</Text>;
        },
        disableFilters: true,
        disableSortBy: !shouldSortByField('period'),
        width: columnWidths?.['period'],
      },
      accountingArea: {
        accessor: 'accountingArea',
        disableSortBy: !shouldSortByField('accountingArea'),
        width: columnWidths?.['accountingArea'],
        Cell: ({ value }) =>
          value ? (
            <TruncatedText>{value}</TruncatedText>
          ) : (
            <Text>{t('emptyCellPlaceholder')}</Text>
          ),
      },
      generalLedgerAccount: {
        accessor: 'generalLedgerAccount',
        disableSortBy: !shouldSortByField('generalLedgerAccount'),
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: GLACell,
        width: columnWidths?.['generalLedgerAccount'],
      },
      account: {
        accessor: 'account',
        disableSortBy: !shouldSortByField('account'),
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        width: columnWidths?.['account'],
      },
      netAmount: {
        accessor: 'netAmount',
        Cell: AmountCellDocument<DocumentsTableData>,
        disableFilters: true,
        sortType: sortByNumberProp('values.netAmount.amount'),
        ...amountCellProps,
        disableSortBy: !shouldSortByField('netAmount'),
      },
      provisionStatus: {
        accessor: 'provisionStatus',
        Cell: ({ value }) =>
          value ? (
            <Tag
              color={mapProvisionStatusToColors[value]}
              variant="secondary"
              width="fit-content"
            >
              <TruncatedText>
                {t(`statuses.${value.toLowerCase()}`)}
              </TruncatedText>
            </Tag>
          ) : null,
        sortType: createSortByFn('values.provisionStatus'),
        width: columnWidths?.['provisionStatus'],
        disableSortBy: !shouldSortByField('provisionStatus'),
      },
      costCenter: {
        accessor: 'costCenter',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('costCenter'),
        width: columnWidths?.['costCenter'],
      },
      costObject: {
        accessor: 'costObject',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('costObject'),
        width: columnWidths?.['costObject'],
      },
      extraCostInfo: {
        accessor: 'extraCostInfo',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('extraCostInfo'),
        width: columnWidths?.['extraCostInfo'],
      },
      artistSocialInsuranceCode: {
        accessor: 'artistSocialInsuranceCode',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('artistSocialInsuranceCode'),
        width: columnWidths?.['artistSocialInsuranceCode'],
      },
      note: {
        accessor: 'note',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('note'),
        width: columnWidths?.['note'],
        disableFilters: true,
      },
      postingText: {
        accessor: 'postingText',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: SplitsCell,
        disableSortBy: !shouldSortByField('postingText'),
        width: columnWidths?.['postingText'],
      },
      isPayable: {
        accessor: 'isPayable',
        disableSortBy: disableSortBy || !shouldSortByField('isPayable'),
        Cell: ({ value }) =>
          value ? (
            <Tag color="green" variant="secondary" width="fit-content">
              <TruncatedText>{t('isPayable.payable')}</TruncatedText>
            </Tag>
          ) : (
            <Text>{t('emptyCellPlaceholder')}</Text>
          ),
        width: columnWidths?.['isPayable'],
      },
      paymentStatus: {
        accessor: 'paymentStatus',
        disableSortBy: disableSortBy || !shouldSortByField('paymentStatus'),
        Cell: ({ value }) =>
          value === 'PaidDocumentState' ? (
            <Tag color="green" variant="secondary" width="fit-content">
              <TruncatedText>{t('isPaid.paid')}</TruncatedText>
            </Tag>
          ) : (
            <Text>{t('emptyCellPlaceholder')}</Text>
          ),
        width: columnWidths?.['paymentStatus'],
      },
      paidAt: {
        accessor: 'paidAt',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        disableSortBy: !shouldSortByField('paidAt'),
        width: columnWidths?.['paidAt'],
      },
      discountDateWPercentage: {
        accessor: 'discountDateWPercentage',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DiscountDateWPercentageCell,
        disableSortBy:
          disableSortBy || !shouldSortByField('discountDateWPercentage'),
        width: columnWidths?.['discountDateWPercentage'],
        disableFilters: !filterOptions?.['discountDateWPercentage'],
      },
      requestedAt: {
        accessor: 'requestedAt',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        disableSortBy: disableSortBy || !shouldSortByField('requestedAt'),
        width: columnWidths?.['requestedAt'],
      },
      creditCardPayment: {
        accessor: 'creditCardPayment',
        Cell: CreditCardPayment,
        width: columnWidths?.['creditCardPayment'],
        disableSortBy: !shouldSortByField('creditCardPayment'),
      },
      statement: {
        accessor: 'statement',
        Cell: StatementCell,
        disableSortBy: !shouldSortByField('statement'),
        width: columnWidths?.['statement'],
      },
      purchaseOrderNumber: {
        accessor: 'purchaseOrderNumber',
        disableSortBy: !shouldSortByField('purchaseOrderNumber'),
        disableFilters: true,
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: Cell,
        width: columnWidths?.['purchaseOrderNumber'],
      },
      sapPurchaseOrderNumber: {
        accessor: 'sapPurchaseOrderNumber',
        disableSortBy: !shouldSortByField('sapPurchaseOrderNumber'),
        disableFilters: true,
        Cell: MultipleValueCell,
        width: columnWidths?.['sapPurchaseOrderNumber'],
      },
      goodsReceiptsCount: {
        accessor: 'goodsReceiptsCount',
        disableSortBy: !shouldSortByField('goodsReceiptsCount'),
        disableFilters: true,
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell,
        width: columnWidths?.['goodsReceiptsCount'],
      },
    };

    if (!shownColumns.length) {
      return Object.values(allColumns);
    }

    return columnsProp.map(accessor => allColumns[accessor]);
  }, [
    columnWidths,
    isUsingSearchQuery,
    shouldEnableSortByWithSearchQuery,
    filterOptions,
    shownColumns.length,
    columnsProp,
    t,
    sortByFields,
  ]);

  return (
    <Table<DocumentsTableData>
      columns={columns}
      customEmptyState={customEmptyState}
      cellWrapper={CellWrapper}
      data={data}
      defaultColumn={defaultColumn}
      defaultEmptyStateContent={defaultEmptyStateContent}
      filterOptions={filterOptions}
      initialState={{
        filters: defaultFilters ?? [],
        sortBy: defaultSortBy ?? [],
      }}
      isLoading={isLoading}
      isSingleSelect={isSingleSelect}
      onEndReached={onEndReached}
      onFilter={onFilter}
      onRangeChanged={onRangeChanged}
      borderTopRadius={borderTopRadius}
      onRowClick={
        onRowClick
          ? row => {
              onRowClick(row.original);
            }
          : undefined
      }
      onRowSelected={selectionOptions?.onSelectionRowChanged}
      onSort={onSort}
      overscan={overscan}
      selectedRowIds={selectionOptions?.selectedRowsIds}
      selectionOptions={selectionOptions}
      tableFooter={tableFooter}
      rowOverlay={rowOverlay}
      {...restProps}
    />
  );
};

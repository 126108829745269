import { Card, Grid } from '@candisio/design-system';
import {
  Locale,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ErrorSection } from './ErrorSection';
import { ReimbursementApproverActions } from './ReimbursementApproverActions';
import { FastApproveReturn } from './FooterActionBox';
import {
  MIN_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH_OTHER,
} from './ReviewStatusFooterActionBoxDeprecated';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { useSubmitReimbursmentWorkflowStep } from 'views/Reimbursement/hooks/useSubmitReimbursmentWorkflowStep';
import { ReimbursementWorkflow } from 'views/Reimbursement/hooks/useWorkflowForReimbursementCaseById';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { WorkflowVisualization } from '../WorkflowVisualization/WorkflowVisualization';
import { RejectReimbursmentCommentForm } from '../RejectReimbursement/RejectReimbursmentCommentForm';
import { FooterHeader } from './FooterHeader';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

export type ApprovingFooterMode = 'view' | 'edit';

export const TRANSLATION = {
  TITLE: 'reimbursementView.rightSection.footerActions.title',
  EDIT: 'reimbursementView.rightSection.approvedFooterAction.edit',
  CANCEL_EDIT: 'reimbursementView.rightSection.approvedFooterAction.cancelEdit',
  REJECT_ERROR:
    'reimbursementView.rightSection.approvingFooterAction.rejectError',
  REJECT_SUCCESS:
    'reimbursementView.rightSection.approvingFooterAction.rejectSuccess',
  APPROVE_ERROR:
    'reimbursementView.rightSection.approvingFooterAction.approveError',
  APPROVE_SUCCESS:
    'reimbursementView.rightSection.approvingFooterAction.approveSuccess',
} as const;
interface ApprovingStatusFooterActionBoxProps {
  reimbursement?: Reimbursement;
  createdById?: string;
  isFastApprovePending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onCycleReimbursments: () => void;
  workflow?: ReimbursementWorkflow;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
}

const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ApprovingStatusFooterActionBox = ({
  createdById,
  reimbursement,
  isFastApprovePending,
  onFastApprove,
  getExpenses,
  onCycleReimbursments,
  workflow,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
}: ApprovingStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [approvingFooterMode, setApprovingFooterMode] =
    useState<ApprovingFooterMode>('view');
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();
  const {
    triggerAllValidations,
    setApprovalMode,
    lastModifiedExpense,
    setHasReimbursementErrors,
  } = useReimbursementFormsContext();
  const { triggerBookingsValidation } = useReimbursementSplitBookingHelpers();

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);
  const { error } = useToastMessage();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  const { hasReimbursementErrors, setReimbursementUIConfig } =
    useReimbursementFormsContext();
  const { hasBookingsErrors } = useReimbursementSplitBookingHelpers();

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });
  const { isWorkflowStepPending, submitWorkflowStep } =
    useSubmitReimbursmentWorkflowStep();

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const handleModeToggle = () => {
    const newMode = approvingFooterMode === 'view' ? 'edit' : 'view';
    setApprovingFooterMode(newMode);
    setReimbursementUIConfig(prevConfig => ({
      ...prevConfig,
      isReimbursementFormEditable: newMode === 'edit',
    }));
  };

  const isErrorSectionVisible = hasReimbursementErrors || hasBookingsErrors;

  const updateExpenseItems = async () => {
    if (!lastModifiedExpense) return;

    const expense = getExpenses('expenses')[lastModifiedExpense];
    const updateFn = {
      general: updateGeneralExpense,
      hospitality: updateHospitalityExpense,
    }[expense?.expenseType];

    if (updateFn) await updateFn(expense);
  };

  const rejectReimbursement = async (comment: string) => {
    await updateExpenseItems();
    if (!workflow?.currentStepId) return;
    const submitResponse = await submitWorkflowStep({
      resolution: WorkflowStepResolutionTypes.Rejected,
      stepId: workflow.currentStepId,
      comment,
    });

    if (submitResponse === 'error') {
      error(t(TRANSLATION.REJECT_ERROR));

      return;
    }

    successMessageWithDeepLink({
      key: TRANSLATION.REJECT_SUCCESS,
    });
    onCycleReimbursments();
  };

  const approveReimbursement = async () => {
    setApprovalMode('approve');
    await updateExpenseItems();

    if (!workflow?.currentStepId) return;

    const submitResponse = await submitWorkflowStep({
      resolution: WorkflowStepResolutionTypes.Approved,
      stepId: workflow.currentStepId,
    });

    if (submitResponse === 'error') {
      triggerAllValidations();
      triggerBookingsValidation();

      setHasReimbursementErrors(true);

      error(t(TRANSLATION.APPROVE_ERROR));

      return;
    }

    setHasReimbursementErrors(false);
    successMessageWithDeepLink({
      key: TRANSLATION.APPROVE_SUCCESS,
    });
    onCycleReimbursments();
  };

  const isUpdatePending =
    isUpdateGeneralExpensePending || isUpdateHospitalityExpensePending;

  const isSubmitting = isUpdatePending || isWorkflowStepPending;

  const isCurrentUserReimbursementApprover =
    currentUserId && workflow?.currentStepApproverIds?.includes(currentUserId);

  if (isCommentFormVisible) {
    return (
      <RejectReimbursmentCommentForm
        onSetIsFormVisible={setIsCommentFormVisible}
        onRejectReimbursment={rejectReimbursement}
        isRejectPending={isSubmitting}
        onToggleEditMode={handleModeToggle}
        reimbursement={reimbursement}
        reimbursementItems={reimbursementItems}
        refetchReimbursement={refetchReimbursement}
        refetchReimbursementItems={refetchReimbursementItems}
      />
    );
  }

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      {approvingFooterMode === 'view' && (
        <Grid gap="space8">
          <FooterHeader
            onToggleEditMode={handleModeToggle}
            showEditAction={!isOnlyApprover}
            reimbursement={reimbursement}
            reimbursementItems={reimbursementItems}
            refetchReimbursement={refetchReimbursement}
            refetchReimbursementItems={refetchReimbursementItems}
          />
          {isErrorSectionVisible && <ErrorSection />}
          {isCurrentUserReimbursementApprover && (
            <ReimbursementApproverActions
              onSetIsCommentFormVisible={setIsCommentFormVisible}
              isCommentFormVisible={isCommentFormVisible}
              isSubmitPending={isSubmitting}
              onApproveReimbursement={approveReimbursement}
            />
          )}
          {workflow && <WorkflowVisualization workflow={workflow} />}
        </Grid>
      )}
      {/* {approvingFooterMode === 'edit' && (
        <Grid gap="space8">
          <Grid columns={2}>
            <Text
              color="gray500"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              {t(TRANSLATION.TITLE)}
            </Text>
            {isErrorSectionVisible && <ErrorSection />}
            <Button
              variant="tertiary"
              justifySelf="end"
              size="small"
              onClick={handleModeToggle}
            >
              {t(TRANSLATION.CANCEL_EDIT)}
            </Button>
          </Grid>
          <Grid gap="space8">
            {canRequestApproval && (
              <RequestApprovalAction
                reimbursement={reimbursement}
                onCycleReimbursments={onCycleReimbursments}
                getExpenses={getExpenses}
              />
            )}
            <Grid
              gap="space8"
              templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}
            >
              {canFastApprove && (
                <ApproveNowAction
                  btnVariant="secondary"
                  onFastApprove={onFastApprove}
                  getExpenses={getExpenses}
                  isFastApprovePending={isFastApprovePending}
                />
              )}
              <Button
                color="blue"
                variant="secondary"
                onClick={() => setIsSendBackFormVisible(true)}
              >
                <TruncatedText>{t(backTextKey)}</TruncatedText>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )} */}
    </Card>
  );
};

import { Button, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface ReimbursementApproverActionsProps {
  isSubmitPending: boolean;
  onApproveReimbursement: () => Promise<void>;
  isCommentFormVisible: boolean;
  onSetIsCommentFormVisible: Dispatch<SetStateAction<boolean>>;
}

export const ReimbursementApproverActions = ({
  isSubmitPending,
  onApproveReimbursement,
  onSetIsCommentFormVisible,
}: ReimbursementApproverActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid columns={2} gap="space10">
      <Button
        color="blue"
        onClick={onApproveReimbursement}
        loading={isSubmitPending}
      >
        {t('reimbursementView.rightSection.approvedFooterAction.approve')}
      </Button>
      <Button
        color="blue"
        variant="secondary"
        onClick={() => onSetIsCommentFormVisible(true)}
      >
        {t('reimbursementView.rightSection.approvedFooterAction.reject')}
      </Button>
    </Grid>
  );
};

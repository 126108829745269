import {
  Box,
  Button,
  Grid,
  Paragraph,
  Text,
  usePopover,
} from '@candisio/design-system';
import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ElementRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConfirm } from 'views/Inbox/DocumentProcessing/components/ActionConfirm';
import { PopoverDescription, popoverDescription } from './utils';

export const TransactionDetailsCardContextMenu = ({
  documentStatus,
  onUnlinkTransaction,
  isUnlinkPending,
}: {
  documentStatus: DocumentStatus | EcmDocumentStatus;
  onUnlinkTransaction: () => Promise<void>;
  isUnlinkPending?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const { showDocumentRelationsImproved } = useEcm();

  const {
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom' });

  const handleConfirm = async () => {
    await onUnlinkTransaction();
    closePopover();
  };

  const fallbackDescription =
    popoverDescription[documentStatus as keyof PopoverDescription];

  return (
    <Box
      position="absolute"
      top={0}
      right="space8"
      data-cy="transaction-context-menu"
    >
      <Button
        icon={showDocumentRelationsImproved ? 'unlinkOutlined' : 'unlink'}
        label={t('transactionDetailsCardContextMenu.tooltip')}
        variant="tertiary"
        size="small"
        {...triggerProps}
        ref={triggerRef}
      />

      <UnlinkActionConfirm
        ref={popoverRef}
        popoverProps={popoverProps}
        isActionPending={isUnlinkPending}
        closePopover={closePopover}
        handleConfirm={handleConfirm}
        fallbackDescription={fallbackDescription}
      />
    </Box>
  );
};

export const UnlinkActionConfirm = forwardRef<
  ElementRef<typeof ActionConfirm>,
  Omit<ComponentProps<typeof ActionConfirm>, 'informationText'> & {
    fallbackDescription?: string;
  }
>(
  (
    {
      fallbackDescription = '',
      popoverProps,
      isActionPending,
      closePopover,
      handleConfirm,
    },
    ref
  ) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
    const { isOnlyApprover } = useUserRoles();

    const descriptionKey = isOnlyApprover
      ? 'transactionDetailsCardContextMenu.approver.description'
      : fallbackDescription;

    const translations = {
      title: t('transactionDetailsCardContextMenu.title'),
      description: t(descriptionKey),
      cancelLabel: t('transactionDetailsCardContextMenu.cancelCTALabel'),
      confirmLabel: t('transactionDetailsCardContextMenu.confirmCTALabel'),
    } as const;

    const informationText = (
      <Grid gap="space8">
        <Text fontWeight="bold">{translations.title}</Text>
        <Paragraph>{translations.description}</Paragraph>
      </Grid>
    );

    return (
      <ActionConfirm
        ref={ref}
        popoverProps={popoverProps}
        isActionPending={isActionPending}
        closePopover={closePopover}
        handleConfirm={handleConfirm}
        informationText={informationText}
        cancelLabel={translations.cancelLabel}
        confirmLabel={translations.confirmLabel}
        maxWidth="450px"
      />
    );
  }
);

import { Grid, Popover, usePopover } from '@candisio/design-system';
import { NavIconProps } from '../hooks/useNavigationIcons';
import { MenuApprovals } from './MenuItems/MenuApprovals/MenuApprovals';
import { MenuCreditCards } from './MenuItems/MenuCreditCards/MenuCreditCards';
import { MenuExport } from './MenuItems/MenuExport/MenuExport';
import { MenuInbox } from './MenuItems/MenuInbox/MenuInbox';
import { MenuPayments } from './MenuItems/MenuPayments/MenuPayments';
import { MenuTravel } from './MenuItems/MenuTravel/MenuTravel';
import { NavigationIcon } from './NavigationIcon';
import { MenuDivider } from './MenuItems/MenuDivider/MenuDivider';
import { RefObject } from 'react';

interface NavigationIconsProps {
  hiddenIcons: NavIconProps[];
  icons: NavIconProps[];
  isNavigationOpen: boolean;
  itemRef: RefObject<HTMLDivElement>;
}

const iconComponentsMap: Partial<
  Record<NavIconProps['icon'], (props: NavIconProps) => JSX.Element | null>
> = {
  menuInbox: MenuInbox,
  menuApprovals: MenuApprovals,
  menuPayments: MenuPayments,
  menuExport: MenuExport,
  menuTravel: MenuTravel,
  menuCreditCards: MenuCreditCards,
};

const renderIconComponent = (
  icon: NavIconProps,
  isNavigationOpen: boolean,
  onClick?: () => void
) => {
  const IconComponent = iconComponentsMap[icon.icon] || NavigationIcon;

  return (
    <IconComponent
      {...icon}
      data-cy={`nav-item-${icon.label?.toLowerCase()}`}
      expanded={isNavigationOpen}
      onClick={onClick}
    />
  );
};

const dividerPositions = ['menuExport'];

export const OverflowNavigationIcons = ({
  hiddenIcons,
  icons,
  isNavigationOpen,
  itemRef,
}: NavigationIconsProps) => {
  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  return (
    <>
      {icons.map((icon, index) => (
        <div key={`${icon.icon}-${index}`} ref={itemRef}>
          {renderIconComponent(icon, isNavigationOpen)}
          {dividerPositions.includes(icon.icon) && <MenuDivider />}
        </div>
      ))}

      {hiddenIcons.length > 0 && (
        <>
          <NavigationIcon
            icon="meatballs"
            {...triggerProps}
            ref={triggerRef}
            expanded={isNavigationOpen}
            aria-label="overflowedMenuButton"
          />
          {isOpen && (
            <Popover
              {...popoverProps}
              ref={popoverRef}
              autoFocus={false}
              restoreFocus={false}
            >
              {hiddenIcons.map((icon, index) => (
                <Grid gap="space8" key={index}>
                  {renderIconComponent(icon, true, close)}
                </Grid>
              ))}
            </Popover>
          )}
        </>
      )}
    </>
  );
};

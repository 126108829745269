import {
  CustomEmptyStateProps,
  Grid,
  Paragraph,
  SelectionOptions,
  useTheme,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { Maybe } from 'generated-types/resolvers-types';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { compiledRoutes } from 'models';
import { AnimatePresence } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrdersEmptyState } from 'views/Archive/PurchaseOrders/PurchaseOrdersEmptyState';
import {
  PURCHASE_ORDER_FILTER,
  usePurchaseOrderListFilters,
} from 'views/Archive/PurchaseOrders/usePurchaseOrderListFilters';
import { purchaseOrderTableColumns } from 'views/Archive/PurchaseOrders/utils';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';

import { getFiltersWithContact } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/utils';
import { PurchaseOrdersTable } from 'components/PurchaseOrders/Table/PurchaseOrdersTable';
import {
  ColumnWidths,
  PurchaseOrdersTableData,
  ViewUsingPruchaseOrders,
} from 'components/PurchaseOrders/Table/types';
import { usePurchaseOrdersData } from 'components/PurchaseOrders/Table/usePurchaseOrdersData';
import { purchaseOrderToTableData } from 'components/PurchaseOrders/Table/util';
import { useLinkPurchaseOrderToDocument } from 'components/PurchaseOrders/hooks/useLinkPurchaseOrderToDocument';
import { LinkingFooterSource } from './LinkingFooter';

export interface PurchaseOrderListProps {
  documentId: string;
  searchValue: string;
  documentAmount?: number | null;
  accountsPayableNumber?: Maybe<string>;
  closeModal: () => void;
  onResetSearch: () => void;
}

const isRowSelectable = (
  row: PurchaseOrdersTableData,
  linkedPurchaseOrderCurrency: DocumentCurrency,
  alreadyLinkedPurchaseOrderNumbers: string[]
) => {
  return !(
    row.goodsReceiptCount?.open === 0 ||
    (linkedPurchaseOrderCurrency &&
      row.grossAmount?.currency !== linkedPurchaseOrderCurrency) ||
    alreadyLinkedPurchaseOrderNumbers?.includes(row?.orderNumber as string)
  );
};

const getDisabledReasonKey = (
  row: PurchaseOrdersTableData,
  linkedPurchaseOrderCurrency: DocumentCurrency,
  alreadyLinkedPurchaseOrderNumbers: string[]
) => {
  if (!row.goodsReceiptCount?.total) {
    return 'cantSelectPurchaseOrderWithNoGoodsReceipt';
  } else if (
    linkedPurchaseOrderCurrency &&
    row.grossAmount?.currency !== linkedPurchaseOrderCurrency
  ) {
    return 'cantSelectPurchaseOrderWithDifferentCurrency';
  } else if (
    alreadyLinkedPurchaseOrderNumbers?.includes(row?.orderNumber as string)
  ) {
    return 'cantSelectPurchaseOrderAlreadyLinked';
  } else if (!row.goodsReceiptCount?.open) {
    return 'cantSelectPurchaseOrderWithNoOpenGoodsReceipt';
  }
  return '';
};

export const PurchaseOrderList = ({
  documentId = '',
  documentAmount,
  accountsPayableNumber,
  searchValue,
  onResetSearch,
  closeModal,
}: PurchaseOrderListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { space } = useTheme();
  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<PurchaseOrdersTableData>();

  const filtersWithContact = getFiltersWithContact({
    filters,
    accountsPayableNumber,
  });

  const {
    purchaseOrderList,
    isLoadingPurchaseOrderList,
    onLoadMore,
    isTableEmpty,
  } = usePurchaseOrdersData({
    documentAmount,
    routeType: ViewUsingPruchaseOrders.ARCHIVE,
    filters: filtersWithContact,
    sortBy,
    searchValue,
  });

  const orgId = useOrganizationId();
  const { purchaseOrders: linkedPurchaseOrders } =
    useSapPurchaseOrders(documentId);

  const linkedPurchaseOrderCurrency = linkedPurchaseOrders?.[0]?.amount
    .currency as DocumentCurrency;

  const alreadyLinkedPurchaseOrderNumbers = useMemo(
    () =>
      (linkedPurchaseOrders ?? [])
        .map(po => po?.orderNumber)
        .filter(Boolean) as string[],
    [linkedPurchaseOrders]
  );

  const { filterOptions } = usePurchaseOrderListFilters([
    PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive,
  ]);

  const { setShouldResetForm } = useBookingsFormContext();
  const { linkPurchaseOrder, isLinkingLoading } =
    useLinkPurchaseOrderToDocument({
      documentId,
      onCompleted: () => {
        setShouldResetForm?.(true);
      },
    });

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrdersTableData | null>(null);

  const handleLinkPurchaseOrderToDocument = async () => {
    if (!selectedPurchaseOrder) {
      return;
    }

    await linkPurchaseOrder(
      selectedPurchaseOrder.id,
      selectedPurchaseOrder?.orderNumber ?? ''
    );

    closeModal();
  };

  const selectionOptions: SelectionOptions<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        selectCheckboxProps: row => {
          if (
            !isRowSelectable(
              row,
              linkedPurchaseOrderCurrency,
              alreadyLinkedPurchaseOrderNumbers
            )
          ) {
            return {
              isDisabled: true,
              disabledReason: t(
                getDisabledReasonKey(
                  row,
                  linkedPurchaseOrderCurrency,
                  alreadyLinkedPurchaseOrderNumbers
                )
              ),
            };
          }

          return { isVisible: true };
        },
        onSelectionRowChanged: purchaseOrders => {
          setSelectedPurchaseOrder(purchaseOrders[0]);
        },
        selectedRowsIds: selectedPurchaseOrder
          ? [selectedPurchaseOrder.id]
          : [],
      };
    }, [
      alreadyLinkedPurchaseOrderNumbers,
      linkedPurchaseOrderCurrency,
      selectedPurchaseOrder,
      t,
    ]);

  const arePurchaseOrdersSelected = Boolean(selectedPurchaseOrder);

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <PurchaseOrdersEmptyState
        isTableFiltered={false}
        arePurchaseOrdersSelected={arePurchaseOrdersSelected}
        isUsingSearchQuery={!!searchValue}
        resetFilters={resetFilters}
        resetSearch={onResetSearch}
      />
    ) : null;

  const columnWidths: ColumnWidths = {
    connectedInvoices: space.space256,
    lineItems: space.space256,
    goodsReceiptCount: space.space200,
  };

  const tableData = useMemo(
    () => purchaseOrderToTableData(purchaseOrderList),
    [purchaseOrderList]
  );
  const visibleColumns = useMemo(
    () =>
      accountsPayableNumber
        ? purchaseOrderTableColumns.filter(col => col !== 'contact')
        : purchaseOrderTableColumns,
    [accountsPayableNumber]
  );

  return (
    <>
      <PurchaseOrdersTable
        isLoading={isLoadingPurchaseOrderList}
        documentAmount={documentAmount}
        columns={visibleColumns}
        onEndReached={onLoadMore}
        onFilter={onFilter}
        onSort={onSort}
        customEmptyState={emptyState}
        data={tableData}
        filterOptions={filterOptions}
        columnWidths={columnWidths}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        selectionOptions={selectionOptions}
        borderTopRadius="none"
        tableFooter={
          !isTableEmpty &&
          !isLoadingPurchaseOrderList && (
            <Grid placeContent="center" padding="space24">
              <Paragraph maxWidth="80%" textAlign="center">
                <Trans
                  i18nKey="addPurchaseOrderModal.footerText"
                  ns={LOCALE_NAME_SPACE.PURCHASE_ORDERS}
                >
                  Can't find the purchase order? Then please check the selected
                  contact. You can also see the latest orders from
                  <RouterLink
                    to={`/${orgId}${compiledRoutes.allPurchaseOrdersImportHistoryRoute}`}
                  >
                    SAP Import History
                  </RouterLink>
                </Trans>
              </Paragraph>
            </Grid>
          )
        }
      />
      <LinkingFooterSource>
        <AnimatePresence>
          {selectedPurchaseOrder && (
            <AssociateCard
              onAssociate={handleLinkPurchaseOrderToDocument}
              footerText={t(
                'addPurchaseOrderModal.footer.footerWhenDocumentSelected'
              )}
              ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
              flex="none"
              width="100%"
              position="absolute"
              bottom={0}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: '50%', opacity: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              loading={isLinkingLoading}
            />
          )}
        </AnimatePresence>
      </LinkingFooterSource>
    </>
  );
};

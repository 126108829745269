import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useSearchInput } from './useSearchInput';

export function useSearchFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchInput = useSearchInput();

  const [searchType, setSearchType] = useState(searchInput.searchType);
  const [searchScope, setSearchScope] = useState(searchInput.searchScope);

  const applySearchInputFilters = () => {
    searchParams.set(searchTypeParameter, searchType);
    searchParams.set(searchScopeParameter, searchScope);
    setSearchParams(searchParams, { replace: true });
  };

  return {
    searchType,
    searchScope,
    setSearchType,
    setSearchScope,
    applySearchInputFilters,
  };
}

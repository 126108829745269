import {
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { StandardHTMLAttributes } from '@candisio/design-system/src/types';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TableHeaderWithTooltip = () => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const [t] = useTranslation();

  return (
    <Flex gap="space4" alignItems="center">
      {t('settings.datev.connect.documentTypes.bookingAccount.title')}
      <Icon
        {...(triggerProps as Partial<StandardHTMLAttributes<SVGSVGElement>>)}
        ref={triggerRef}
        icon="infoCircle"
        size="space16"
        color="gray500"
      />
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef} width="space256">
          <Text>
            <Trans i18nKey="settings.datev.connect.documentTypes.bookingAccount.columnHeaderInfo">
              This only works with RDS 1.0 enabled.
              <Link
                external
                href={t(
                  'settings.datev.connect.documentTypes.bookingAccount.columnHeaderInfoLink'
                )}
              >
                Learn More
              </Link>
            </Trans>
          </Text>
        </Tooltip>
      )}
    </Flex>
  );
};

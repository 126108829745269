import {
  DirectDocumentAccess,
  useModifyDocumentAccessMutation,
} from 'generated-types/graphql.types';
import {
  ManageAccessFormValues,
  ManageAccessSubmitValues,
} from '../../ManageAccess/manageAccessFormSchema';

interface UseSubmitAccessChanges {
  globalDocumentId: string;
  initialData: ManageAccessFormValues['memberships'];
  onCloseDrawer: () => void;
  setSubmitting: (value: boolean) => void;
}

const hashInitialData = (
  data: ManageAccessFormValues['memberships'][number]
): string[] => {
  return [
    // Exists in initial data
    `${data.subjectType}:${data.id}`,
    // Hash with access
    `${data.subjectType}:${data.id}:${data.directAccess}`,
  ];
};

const hashSubmitData = (data: ManageAccessSubmitValues['changes'][number]) => {
  return {
    existsHash: `${data.subject.type}:${data.subject.id}`,
    accessHash: `${data.subject.type}:${data.subject.id}:${data.access}`,
  };
};

export const useSubmitAccessChanges = ({
  globalDocumentId,
  initialData,
  onCloseDrawer,
  setSubmitting,
}: UseSubmitAccessChanges) => {
  const [modifyDocumentAccess, { loading }] = useModifyDocumentAccessMutation();
  const initialDataHashes = new Set<string>(
    initialData.flatMap(hashInitialData)
  );

  const handleSubmit = async (values: ManageAccessSubmitValues) => {
    setSubmitting(true);
    try {
      const changes = values.changes.filter(change => {
        const { existsHash, accessHash } = hashSubmitData(change);
        // If their access is set to NONE but they never had access to begin with,
        // then there was no change made.
        if (change.access === DirectDocumentAccess.None) {
          return initialDataHashes.has(existsHash);
        }

        return !initialDataHashes.has(accessHash);
      });

      if (changes.length === 0) {
        return;
      }

      await modifyDocumentAccess({
        variables: {
          input: {
            globalDocumentId,
            changes,
          },
        },
      });
    } finally {
      onCloseDrawer();
      setSubmitting(false);
    }
  };

  return { handleSubmit, submitting: loading };
};

import { Grid, Icon, Text } from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Team } from '../../toolkit/hooks/useGetTeams';
import { animationVariant, transition } from '../../toolkit/utils/animations';
import { SuccessModalLayout } from '../components';
import { CreditCardSetupCard } from '../components/CreditCardSetupCard';
import { TeamDetailsInfoCard } from '../components/TeamDetailsInfoCard';

const MotionGrid = motion(Grid);

interface SuccessModalProps {
  team: Team;
  isOpen: boolean;
  onClose: () => void;
  hasSetupCreditCards: boolean;
}

export const SuccessModal = ({
  team,
  isOpen,
  onClose,
  hasSetupCreditCards,
}: SuccessModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const title = hasSetupCreditCards
    ? t('teams.createTeam.successModal.leftSideTitleSetup')
    : t('teams.createTeam.successModal.leftSideTitle');

  return (
    <SuccessModalLayout
      onClose={onClose}
      isOpen={isOpen}
      leftSide={
        <MotionGrid
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={animationVariant}
          transition={transition}
          templateRows="1fr auto"
          padding="0 space48 0 space32"
        >
          <Text fontSize="xxlarge" textAlign="center">
            {title}
          </Text>
          <TeamDetailsInfoCard team={team} />
        </MotionGrid>
      }
    >
      {hasSetupCreditCards ? (
        <Grid
          background="gray0"
          placeItems="center"
          placeContent="start"
          autoFlow="column"
          gap="space8"
          padding="space16"
        >
          <Icon icon="checkCircle" color="green500" size="space24" />
          <Text fontSize="xlarge">
            {t('teams.createTeam.successModal.setupSuccessMsg')}
          </Text>
        </Grid>
      ) : (
        <CreditCardSetupCard />
      )}
    </SuccessModalLayout>
  );
};

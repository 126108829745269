import { Flex, Grid, TruncatedText } from '@candisio/design-system';
import { DocumentAccessButton } from 'components/DocumentAccess/DocumentAccessButton';
import { DocumentKebabMenu } from 'components/Menu/DocumentKebabMenu/DocumentKebabMenu';
import {
  DocumentStatus as DocumentStatusBackend,
  DocumentType,
  EcmDocumentStatus,
  useGetDatevLocationParamsForDocumentQuery,
  useGetDocumentForSidebarQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { DocumentStatusFrontend } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { buildDatevDocumentLink } from 'utils/build_datev_link_for_document';

type DocumentStatus =
  | DocumentStatusBackend
  | DocumentStatusFrontend
  | EcmDocumentStatus;

export interface ProcessSidebarHeaderProps {
  documentId?: string;
  globalDocumentId?: string;
  /** Sets the title based on the document status */
  documentStatus?: DocumentStatus;
  documentType: DocumentType;
  isSensitiveDocument: boolean;
}

export const ProcessSidebarHeader = ({
  documentStatus,
  documentId,
  globalDocumentId,
  documentType,
  isSensitiveDocument,
}: ProcessSidebarHeaderProps) => {
  const [t] = useTranslation();
  const { showEcm } = useEcm();

  const [displayDocumentAccessDrawerFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.displayDocumentAccessDrawer,
  ]);

  const { data, loading } = useGetDocumentForSidebarQuery({
    variables: {
      id: documentId || '',
    },
    skip: !documentId,
  });

  const documentUrl = data?.getDocument?.documentFile?.url;
  const datevGuid = data?.getDocument?.datevGuid?.value;

  const { data: datevLocationParams, loading: fetchingDatevLocationParams } =
    useGetDatevLocationParamsForDocumentQuery({
      variables: {
        documentId: documentId || '',
      },
      skip: !documentId || !datevGuid,
    });

  const { clientNumber, consultantNumber } =
    datevLocationParams?.getLatestDatevExportOfDocument || {};

  const openInDatevLink =
    buildDatevDocumentLink({
      clientNumber,
      consultantNumber,
      documentExportGuid: datevGuid,
    }) ?? undefined;

  const documentStatusToHeadingMap: Record<
    DocumentStatus,
    { title: string; color?: string }
  > = {
    [DocumentStatusBackend.Approved]: {
      title: t('document.details.approved.title'),
      color: 'green500',
    },
    [DocumentStatusBackend.Exported]: {
      title: t('document.details.exported.title'),
    },
    [DocumentStatusBackend.Exporting]: {
      title: t('document.details.exporting.title'),
    },
    [DocumentStatusBackend.New]: {
      title: t('document.details.new.title'),
    },
    [DocumentStatusBackend.Open]: {
      title: t('document.details.open.title'),
    },
    [DocumentStatusBackend.Processing]: {
      title: t('document.details.processing.title'),
    },
    [DocumentStatusBackend.Rejected]: {
      title: t('document.details.rejected.title'),
      color: 'red500',
    },
    [DocumentStatusBackend.Archived]: {
      title: t('document.details.archived.title'),
    },
    [DocumentStatusFrontend.AwaitingApproval]: {
      title: t('document.details.awaitingApproval.title'),
    },
    [DocumentStatusFrontend.ApprovedAndPaid]: {
      title: t('document.details.approvedAndPaid.title'),
      color: 'green500',
    },
    [DocumentStatusFrontend.Draft]: {
      title: t('document.details.draft.title'),
    },
    [DocumentStatusFrontend.ExportedAndPaid]: {
      title: t('document.details.exportedAndPaid.title'),
    },
    [DocumentStatusFrontend.Stored]: {
      title: t('document.details.stored.title'),
    },
  };

  let headingText = '';
  if (documentStatus) {
    headingText = documentStatusToHeadingMap[documentStatus].title;

    // Override the title of the new document status when ECM is enabled for the organization.
    // 'New request' > 'New document'
    if (documentStatus === DocumentStatusBackend.New && showEcm) {
      headingText = t('ecm:processingSidebar.title');
    }
  }

  const headingColor =
    documentStatus && documentStatusToHeadingMap[documentStatus].color;

  const showKebabMenu = documentId && !loading && !fetchingDatevLocationParams;

  const showDocumentAccessButton =
    globalDocumentId && !loading && displayDocumentAccessDrawerFF;

  return (
    <Grid templateColumns="1fr min-content" alignItems="baseline">
      <TruncatedText
        as="h1"
        lineHeight="normal"
        fontWeight="regular"
        color={headingColor ?? 'gray800'}
      >
        {headingText}
      </TruncatedText>
      <Flex gap="space2">
        {showDocumentAccessButton ? (
          <DocumentAccessButton
            globalDocumentId={globalDocumentId || ''}
            documentType={documentType}
            isSensitiveDocument={isSensitiveDocument}
          />
        ) : null}
        {showKebabMenu ? (
          <DocumentKebabMenu
            datevGuid={datevGuid}
            documentUrl={documentUrl}
            openInDatevLink={openInDatevLink}
          />
        ) : null}
      </Flex>
    </Grid>
  );
};

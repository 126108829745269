import {
  DirectDocumentAccess,
  DocumentAccessSubjectType,
} from 'generated-types/graphql.types';
import { z } from 'zod';

export const manageAccessFormSchema = z
  .object({
    search: z.string(),
    memberships: z.array(
      z
        .object({
          id: z.string(),
          name: z.string(),
          avatarUrl: z.string().optional(),
          subjectType: z.nativeEnum(DocumentAccessSubjectType),
          directAccess: z.nativeEnum(DirectDocumentAccess),
        })
        .transform(value => ({
          access: value.directAccess,
          subject: {
            id: value.id,
            type: value.subjectType,
          },
        }))
    ),
  })
  .transform(value => ({ changes: value.memberships }));

export type ManageAccessFormValues = z.input<typeof manageAccessFormSchema>;
export type ManageAccessSubmitValues = z.output<typeof manageAccessFormSchema>;

const TABLE_ROW_AVG_HEIGHT_PX = 43;
const MAX_ROWS = 40;
const MIN_ROWS = 1;

export const getTableCanvasDistanceFromTop = () => {
  const FALLBACK_DISTANCE_FROM_TOP = 100;
  const tableWrapper = document.querySelectorAll('[role="table"]');
  const el = tableWrapper?.length && tableWrapper[0];

  return (el && el.getBoundingClientRect().top) || FALLBACK_DISTANCE_FROM_TOP;
};

export const getApproximateNumberOfRowForTable = () => {
  const tableDistanceFromTop = getTableCanvasDistanceFromTop();

  const visibleRows = Math.floor(
    (window.innerHeight - tableDistanceFromTop) / TABLE_ROW_AVG_HEIGHT_PX
  );

  return Math.min(Math.max(MIN_ROWS, visibleRows), MAX_ROWS);
};

import { Button, defaultTheme } from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export function DocumentTypesViewDocumentLink({ link }: { link: string }) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <MotionButton
      as="a"
      target="_blank"
      href={link}
      variant="tertiary"
      icon="newTab"
      iconPosition="left"
      color="blue"
      tabIndex="-1"
      opacity="var(--link-opacity)"
      pointerEvents=" var(--link-pointer)"
      transform="scale(var(--link-scale))"
      transition={{
        opacity: { duration: 0.3 },
        transform: { duration: 0.01 },
      }}
      whileHover={{ color: defaultTheme.colors.blue500 }}
      size="small"
    >
      {t('documentTypes.list.viewDocuments')}
    </MotionButton>
  );
}

const MotionButton = motion(Button);

import { queryParameter } from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import debounce from 'lodash/debounce';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { mapToArchiveInvoiceDocumentsTableData } from 'views/utils/mapToArchiveInvoiceDocumentsTableData';
import { useArtistSocialInsuranceCodeTranslationsMap } from 'views/utils/useArtistSocialInsuranceCodeTranslationsMap';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { getGqlSearchFromUrl } from '../../../../components/DocumentsTable/Filters/filters';
import { archiveInvoiceDocumentsTableQuery } from '../../Documents/queries';
import { useComputeQueryFilters } from './useComputeQueryFilters';

export interface ArchiveDocumentsDataParams {
  previewDocumentId?: string | null;
}

export const useArchiveDocumentsData = ({
  previewDocumentId,
}: ArchiveDocumentsDataParams) => {
  const searchQueryFromUrl = getGqlSearchFromUrl(window.location.search);
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const searchQuery = searchParams.get(queryParameter) ?? '';

  const [debounceSearchValue, setDebounceSearchValue] = useState(
    searchQueryFromUrl?.query
  );

  const includePurchaseOrderData = useIncludePurchaseOrderData();
  const { limit, filters, dateRangeFilters, sort, searchInput } =
    useComputeQueryFilters();

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'archiveInvoiceDocuments'>
  >(archiveInvoiceDocumentsTableQuery, 'archiveInvoiceDocuments', {
    variables: {
      limit,
      filters: { ...filters, ...dateRangeFilters },
      sort,
      query: debounceSearchValue ?? '',
      withPurchaseOrderData: includePurchaseOrderData,
      searchInput,
    },
  });

  const documentsList = data && data.archiveInvoiceDocuments;

  const documentEdges = documentsList?.edges || [];
  const documentsCount = documentsList?.pageInfo?.totalCount;
  const hasMoreData = documentsList?.pageInfo?.hasNextPage ?? false;
  const isTableEmpty = documentEdges.length === 0 && !loading;
  const endCursor = documentsList?.pageInfo?.endCursor;
  const artistSocialInsuranceCodeTranslations =
    useArtistSocialInsuranceCodeTranslationsMap();
  const documentsTableData = mapToArchiveInvoiceDocumentsTableData(
    documentEdges,
    artistSocialInsuranceCodeTranslations,
    previewDocumentId
  );

  const selectedDocumentsCount =
    data?.archiveInvoiceDocuments?.pageInfo?.totalCount ??
    data?.archiveInvoiceDocuments?.edges?.length ??
    0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(
      (value: string) => {
        setDebounceSearchValue(value);
        updateSearchParam(queryParameter, value);
      },
      DEFAULT_DEBOUNCE_TIME,
      {
        trailing: true,
      }
    );
  }, [updateSearchParam]);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return {
    documentsCount,
    documentsTableData,
    endCursor,
    handleDebounceSearch,
    hasMoreData,
    isTableEmpty,
    loading,
    onLoadMore,
    selectedDocumentsCount,
    searchQuery,
    isUsingSearchQuery: false,
  };
};

import { Box, Grid } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import { DocumentTypeContextProvider } from 'views/DocumentDetails/components/DocumentTypeContext';
import { DocumentProcessingContextProvider, useInboxContext } from '../Context';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';
import { RelatedDocumentContainer } from 'components/DocumentViewer/RelatedDocumentContainer';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

const DocumentProcessingComponent = () => {
  const {
    documentId,
    globalDocumentId,
    mainDocumentFile,
    isDocumentFileLoading,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  const { isComparisonView } = useComparisonView();

  return (
    <DocumentTypeContextProvider key={documentId} initiallyAnInvoice>
      <DetailsLayout
        key={documentId}
        leftSection={<LeftSection />}
        middleSection={
          <Grid
            height="100%"
            overflowY="auto"
            paddingTop="space32"
            columnGap={isComparisonView ? 'space16' : '0'}
            templateColumns={isComparisonView ? '1fr 1fr' : '0fr 1fr'}
          >
            <RelatedDocumentContainer mainDocumentId={globalDocumentId} />
            <Box height="100%" overflowY="auto">
              <DocumentViewer
                documentId={documentId}
                documentFile={mainDocumentFile}
                selectedFile={selectedPdf}
                attachments={attachments}
                onSelectDocument={setSelectedPdf}
                canAttachFiles={attachPermissions}
                isLoadingPdf={isDocumentFileLoading}
                hideAttachmentSection={isComparisonView}
              />
            </Box>
          </Grid>
        }
        rightSection={<RightSection />}
      />
    </DocumentTypeContextProvider>
  );
};

export const DocumentProcessing = () => (
  <DocumentProcessingContextProvider>
    <DocumentProcessingComponent />
  </DocumentProcessingContextProvider>
);

import { Flex, Grid } from '@candisio/design-system';
import { ProcessingFormTaxCodeField } from 'components/Form/ProcessingForm/ProcessingFormTaxCodeField';
import { useAmountFieldsTooltip } from 'components/Form/ProcessingForm/hooks/useAmountFieldsTooltip';
import { useHasReverseChargeTaxCode } from 'containers/SplitBookings/toolkit/hooks/useHasReverseChargeTaxCode';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useTranslation } from 'react-i18next';
import { useShowInvoiceCorrectionField } from 'views/utils/useShouldShowField';
import { useBookingsFieldArrayContext } from './BookingsFieldArrayContext';
import { ProcessingFormFieldOptions } from './ProcessingForm';
import { ProcessingFormAmountField } from './ProcessingFormAmountField';
import { ProcessingFormComboBoxField } from './ProcessingFormComboBoxField';
import { ProcessingFormInvoiceCorrectionField } from './ProcessingFormInvoiceCorrectionField';
import { RoundingAmountWarningMessage } from './RoundingAmountWarningMessage';
import { ProcessingFormValues } from './processingFormSchema';

const FLEX = '1 0 6.1rem';

export interface ProcessingFormAmountFieldsProps {
  /** Individual field options */
  fieldOptions?: ProcessingFormFieldOptions;
  /** Display fields as read only */
  readOnly?: boolean;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
  watchedBookings?: ProcessingFormValues['bookings'];
  showSplitList?: boolean;
}

/**
 * Displays sap amount-related fields in the processing form:
 * - invoice correction
 * - gross amount
 * - currency
 * - VAT rate
 *
 * VAT rate is only displayed when there is a single booking.
 */
export const ProcessingFormAmountFieldsWithFF = ({
  fieldOptions,
  readOnly,
  watchedBookings,
  showSplitList,
  ...restProps
}: ProcessingFormAmountFieldsProps) => {
  const [t] = useTranslation();

  const bookingsFieldArray = useBookingsFieldArrayContext();
  const splits = bookingsFieldArray?.fields ?? [];

  const showInvoiceCorrectionField = useShowInvoiceCorrectionField();
  const integration = useIntegrationSettings();

  const tooltips = useAmountFieldsTooltip(splits, watchedBookings);
  const firstSplitId = splits[0]?.id;

  const isAcquisitionReverse = useHasReverseChargeTaxCode(
    watchedBookings ?? []
  );

  const disableProcessingFormTaxAmount = isAcquisitionReverse;

  return (
    <Grid gap="space16">
      {showInvoiceCorrectionField && (
        <ProcessingFormInvoiceCorrectionField
          name="grossAmount"
          label={t('document.requestApproval.inputs.invoiceCorrection.label')}
          readOnly={readOnly}
          {...restProps}
        />
      )}
      <Flex columnGap="space8" rowGap="space16" wrap="wrap">
        {showSplitList ? (
          <ProcessingFormAmountField
            flex={FLEX}
            name="netAmount"
            label={t('document.requestApproval.inputs.netAmount.label')}
            placeholder={t(
              'document.requestApproval.inputs.netAmount.placeholder'
            )}
            readOnly
            info={tooltips.netAmount}
            {...restProps}
          />
        ) : (
          <ProcessingFormAmountField
            key={`${firstSplitId}-netAmount`}
            flex={FLEX}
            name="bookings.0.netAmount"
            label={t('document.requestApproval.inputs.netAmount.label')}
            placeholder={t(
              'document.requestApproval.inputs.netAmount.placeholder'
            )}
            readOnly={readOnly}
            info={readOnly ? tooltips.netAmount : undefined}
            {...restProps}
          />
        )}

        <ProcessingFormComboBoxField
          flex={FLEX}
          name="currency"
          label={t('document.requestApproval.inputs.currency.label')}
          placeholder={t(
            'document.requestApproval.inputs.currency.placeholder'
          )}
          clearable={false}
          {...fieldOptions?.currency}
          readOnly={readOnly || fieldOptions?.currency?.readOnly}
          {...restProps}
        />
      </Flex>
      <Flex columnGap="space8" rowGap="space16" wrap="wrap">
        {showSplitList ? (
          <ProcessingFormAmountField
            flex={FLEX}
            name="taxAmount"
            label={t('document.requestApproval.inputs.taxAmount.label')}
            placeholder={t(
              'document.requestApproval.inputs.taxAmount.placeholder'
            )}
            readOnly
            info={tooltips.taxAmount}
            {...restProps}
          />
        ) : (
          <ProcessingFormAmountField
            key={`${firstSplitId}-taxAmount`}
            flex={FLEX}
            name="bookings.0.taxAmount"
            label={t('document.requestApproval.inputs.taxAmount.label')}
            placeholder={t(
              'document.requestApproval.inputs.taxAmount.placeholder'
            )}
            readOnly={readOnly || disableProcessingFormTaxAmount}
            info={tooltips.taxAmount}
            {...restProps}
          />
        )}

        {!showSplitList && !fieldOptions?.taxCode?.hidden ? (
          <ProcessingFormTaxCodeField
            flex={FLEX}
            name="bookings.0.taxCode"
            label={t('document.requestApproval.inputs.bookingKey.label')}
            placeholder={t(
              'document.requestApproval.inputs.bookingKey.placeholder'
            )}
            integration={integration}
            typeFieldItems={fieldOptions?.type?.items}
            {...fieldOptions?.taxCode?.props}
            readOnly={readOnly || fieldOptions?.taxCode?.props?.readOnly}
            {...restProps}
          />
        ) : null}
      </Flex>
      <Grid gap="space4">
        <Flex columnGap="space8" rowGap="space16" wrap="wrap">
          <ProcessingFormAmountField
            flex={FLEX}
            name="grossAmount"
            label={t('document.requestApproval.inputs.amount.label')}
            placeholder={t(
              'document.requestApproval.inputs.amount.placeholder'
            )}
            readOnly={readOnly}
            {...restProps}
          />

          <ProcessingFormAmountField
            flex={FLEX}
            name="roundingAmount"
            label={t('document.requestApproval.inputs.roundingAmount.label')}
            placeholder={t(
              'document.requestApproval.inputs.roundingAmount.placeholder'
            )}
            readOnly
            info={tooltips.roundingAmount}
            {...restProps}
          />
        </Flex>
        <RoundingAmountWarningMessage />
      </Grid>
    </Grid>
  );
};

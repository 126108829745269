import { Flex, Grid, Paragraph } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DocumentAccessCardSkeleton } from '../DocumentAccessCardSkeleton';
import { useInitialDirectAccess } from '../toolkit/hooks/useInitialDirectAccess';
import { useSubmitAccessChanges } from '../toolkit/hooks/useSubmitAccessChanges';
import { useTeamMembersFieldItems } from '../toolkit/hooks/useTeamMembersFieldOptions';
import { ManageAccessForm } from './ManageAccessForm';

interface ManageAccessContainerProps {
  formId: string;
  globalDocumentId: string;
  readOnly?: boolean;
  onCloseDrawer: () => void;
  setSubmitting: (isSubmitting: boolean) => void;
}

export const ManageAccessContainer = ({
  formId,
  globalDocumentId,
  readOnly,
  onCloseDrawer,
  setSubmitting,
}: ManageAccessContainerProps) => {
  const [t] = useTranslation();

  const { memberships, membershipsWithIrrevocableAccess, loading } =
    useInitialDirectAccess({
      globalDocumentId,
    });

  const { handleSubmit } = useSubmitAccessChanges({
    globalDocumentId,
    initialData: memberships,
    onCloseDrawer,
    setSubmitting,
  });

  const teamMembersFieldOptions = useTeamMembersFieldItems({
    excludeFromSuggestions: membershipsWithIrrevocableAccess,
  });

  if (loading) {
    return <DocumentAccessCardSkeleton />;
  }

  return (
    <Flex direction="column" gap="space16">
      <Grid
        width="100%"
        gap="space16"
        background="graybg"
        padding="space16"
        borderRadius="small"
      >
        <ManageAccessForm
          readOnly={readOnly}
          formId={formId}
          onSubmit={handleSubmit}
          teamMembersFieldOptions={teamMembersFieldOptions}
          initialValues={{
            search: '',
            memberships,
          }}
        />
      </Grid>
      <Paragraph fontWeight="400" color="gray600" whiteSpace="pre-wrap">
        {t('documentAccess.membership.access.viewers.description')}
      </Paragraph>
    </Flex>
  );
};

import {
  CustomEmptyStateProps,
  SelectionOptions,
} from '@candisio/design-system';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { AnimatePresence } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsTable } from 'views/Archive/GoodsReceipts/GoodsReceiptsTable/GoodsReceiptsTable';
import { useGetGoodsReceiptsTableFilterOptions } from 'views/Archive/GoodsReceipts/hooks/useGetTableFilterOptions';
import { GoodsReceiptsTableData } from 'views/Archive/GoodsReceipts/types';
import { LinkingFooterSource } from './LinkingFooter';
import { goodsReceiptsTableColumns } from './consts';
import { GoodsReceiptsEmptyState } from 'views/Archive/GoodsReceipts/GoodsReceiptsTable/EmptyState';
import { goodsReceiptsTableAvailableFilters } from 'views/Archive/GoodsReceipts/utils';

// TODO: TPX-1273 Review footer card translations
type GoodsReceiptListProps = {
  accountsPayableNumber?: string | null;
  searchValue: string;
  onResetSearch: () => void;
};

export const GoodsReceiptsList = ({
  accountsPayableNumber,
  searchValue,
  onResetSearch,
}: GoodsReceiptListProps) => {
  const [selectedGoodsReceipts, setSelectedGoodsReceipts] = useState<
    GoodsReceiptsTableData[]
  >([]);
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const filterOptions = useGetGoodsReceiptsTableFilterOptions();
  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<GoodsReceiptsTableData>();
  const selectionOptions: SelectionOptions<GoodsReceiptsTableData> =
    useMemo(() => {
      return {
        selectCheckboxProps: _row => {
          return { isVisible: true };
        },
        onSelectionRowChanged: goodsReceipts => {
          setSelectedGoodsReceipts(goodsReceipts);
        },
        canBeSelected: _row => {
          return true;
        },
        selectedRowsIds: selectedGoodsReceipts.map(({ id }) => id),
      };
    }, [selectedGoodsReceipts]);

  const visibleColumns = useMemo(
    () =>
      accountsPayableNumber
        ? goodsReceiptsTableColumns.filter(col => col !== 'contact')
        : goodsReceiptsTableColumns,
    [accountsPayableNumber]
  );
  // TODO: TPX-1273 Add once backend implement this mutation
  const handleGoodsReceiptsLinkToDocument = () => {};
  const emptyState = ({ resetFilters }: CustomEmptyStateProps) => (
    <GoodsReceiptsEmptyState
      isTableFiltered={Boolean(filters.length)}
      isUsingSearchQuery={Boolean(searchValue)}
      resetFilters={resetFilters}
      resetSearch={onResetSearch}
    />
  );

  const defaultFilters = useMemo(() => {
    if (accountsPayableNumber) {
      return [
        ...filters,
        {
          id: goodsReceiptsTableAvailableFilters.contact,
          value: [accountsPayableNumber],
        },
      ];
    }
    return filters;
  }, [filters, accountsPayableNumber]);

  return (
    <>
      <GoodsReceiptsTable
        columns={visibleColumns}
        filterOptions={filterOptions}
        defaultFilters={defaultFilters}
        defaultSortBy={sortBy}
        searchValue={searchValue}
        onFilter={onFilter}
        onSort={onSort}
        selectionOptions={selectionOptions}
        customEmptyState={emptyState}
        showFooterText
      />
      <LinkingFooterSource>
        <AnimatePresence>
          {selectedGoodsReceipts.length > 0 && (
            <AssociateCard
              onAssociate={handleGoodsReceiptsLinkToDocument}
              footerText={t(
                'addPurchaseOrderModal.footer.footerWhenDocumentSelected'
              )}
              ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
              flex="none"
              width="100%"
              position="absolute"
              bottom={0}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: '50%', opacity: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              loading={false}
            />
          )}
        </AnimatePresence>
      </LinkingFooterSource>
    </>
  );
};

import { Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { HospitalityExpenseInvoiceData } from './HospitalityExpenseInvoiceData';
import { extractedDataHospitalitySummary } from './utils/extractedDataSummary';
import { HospitalityFieldNames } from './utils/generateHospitalityFormFieldNames';
import {
  ExtractionStatus,
  ExtractionStatusType,
} from './utils/invoiceDataExtractionHelpers';

interface ExtractedHospitalityExpenseInvoiceDataProps {
  fields: HospitalityFieldNames;
  isExcluded: boolean;
  formIndex: number;
  isExpenseFormEditable: boolean;
  extractedData?: ReturnType<typeof extractedDataHospitalitySummary>;
  extractionStatus: ExtractionStatusType;
  clearableField: string | undefined;
  onUpdateTotalAmount: (index: number) => void;
  onCleareableField: (field?: string) => () => void;
  onUpdateHospitality: (index: number) => () => Promise<void>;
  onSetValue: UseFormSetValue<ExpensesFormOutput>;
}
export const ExtractedHospitalityExpenseInvoiceData = ({
  fields,
  isExcluded,
  formIndex,
  extractionStatus,
  extractedData,
  onSetValue,
  onUpdateTotalAmount,
  onUpdateHospitality,
  clearableField,
  onCleareableField,
  isExpenseFormEditable,
}: ExtractedHospitalityExpenseInvoiceDataProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const handleEditClick = () => {
    onSetValue(fields.isExtractedDataAccepted, false);

    void onUpdateHospitality(formIndex)();
  };

  if (extractionStatus === ExtractionStatus.FULL_EXTRACTION) {
    return (
      <HospitalityExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={isExpenseFormEditable && !isExcluded}
      />
    );
  }

  return (
    <Grid gap="space8">
      <Grid gap="space16">
        {!extractedData?.location && (
          <HookFormTextField
            name={fields.location}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.location.placeholder'
            )}
            clearable={clearableField === fields.location}
            onFocus={onCleareableField(fields.location)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        )}
        {!extractedData?.expenseDate && (
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.date.label'
            )}
            clearable={clearableField === fields.expenseDate}
            onFocus={onCleareableField(fields.expenseDate)}
            onBlur={onUpdateHospitality(formIndex)}
          />
        )}

        {!extractedData?.invoiceNumber && (
          <HookFormTextField
            name={fields.invoiceNumber}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.placeholder'
            )}
            clearable={clearableField === fields.invoiceNumber}
            onFocus={onCleareableField(fields.invoiceNumber)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        )}
        {!extractedData?.receiptAmount && (
          <HookFormAmountField
            name={fields.receiptAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.receiptAmount}
            onFocus={onCleareableField(fields.receiptAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
        )}

        {!extractedData?.tipAmount && (
          <HookFormAmountField
            name={fields.tipAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.tipAmount}
            onFocus={onCleareableField(fields.tipAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
        )}

        {!extractedData?.totalAmount && (
          <HookFormAmountField
            name={fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
            )}
            readOnly
            currency="EUR"
            minValue={0}
          />
        )}
      </Grid>
      <HospitalityExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={isExpenseFormEditable && !isExcluded}
      />
    </Grid>
  );
};

import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { CompletedReimbursementListContainer } from './Lists/CompletedReImbursmentListContainer';
import { CreateReimbursementCardContainer } from './Lists/CreateReimbursementCardContainer';
import { OpenReimbursementListContainer } from './Lists/OpenReimbursementListContainer';
import { ReimbursementsDashboardPromotion } from './ReimbursementsDashboardPromotion';
import { ReimbursementDashboardLayout } from './components/ReimbursementDashboardLayout';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { SwimlaneGroup } from 'components/Swimlane/SwimlaneGroup';
import {
  DRAFT_STATUSES,
  OPEN_STATUSES,
  useGetArchiveReimbursementCases,
  useGetReimbursementCasesSummary,
} from './hooks/useReimbursementDashboardInsights';
import { Heading, Spinner } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import styles from './ReimbursementDashboard.module.css';
import { ReimbursementClosedSwimlane } from './components/Dashboard/ReimbursementClosedSwimlane';
import { ReimbursementDraftSwimlane } from './components/Dashboard/ReimbursementDraftSwimlane';
import { ReimbursementOpenSwimlane } from './components/Dashboard/ReimbursementOpenSwimlane';
import { ReimbursementSummarySwimlane } from './components/Dashboard/ReimbursementSummarySwimlane';

export const ReimbursementDashboard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { variant } = useReimbursementsPromotions();
  const dashboardRedesignExpensesFF = useCandisFeatureFlags(
    FEATURE_FLAGS.dashboardRedesignExpenses
  );

  const { data, loading: summaryLoading } = useGetReimbursementCasesSummary();

  const { draft, open, closed } = data?.reimbursementCasesSummary || {};

  const { draftReimbursementCases, openReimbursementCases, loading } =
    useGetArchiveReimbursementCases({
      filters: { statuses: [...DRAFT_STATUSES, ...OPEN_STATUSES] },
    });

  const isPromotionForReimbursementsVisible = variant !== 'noPromotion';

  if (isPromotionForReimbursementsVisible) {
    return <ReimbursementsDashboardPromotion />;
  }

  if (loading || summaryLoading) {
    return <Spinner size="space64" color="gray400" />;
  }

  if (dashboardRedesignExpensesFF) {
    return (
      <div className={styles.layout}>
        <Heading as="h1">{t('dashboard.header')}</Heading>
        <SwimlaneGroup>
          <ReimbursementSummarySwimlane
            reimbursementCasesSummary={data?.reimbursementCasesSummary}
          />
          <ReimbursementDraftSwimlane
            draftReimbursementCases={draftReimbursementCases}
            totalCount={draft?.totalCount}
          />
          <ReimbursementOpenSwimlane
            openReimbursementCases={openReimbursementCases}
            totalCount={open?.totalCount}
          />
          <ReimbursementClosedSwimlane totalCount={closed?.last30DaysCount} />
        </SwimlaneGroup>
      </div>
    );
  }

  return (
    <ReimbursementDashboardLayout>
      <CreateReimbursementCardContainer />
      <OpenReimbursementListContainer />
      <CompletedReimbursementListContainer />
    </ReimbursementDashboardLayout>
  );
};

import { Flex, Grid, Text } from '@candisio/design-system';
import { TRANSLATION } from './ApprovingStatusFooterActionBox';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { AutoSaveStatusContainer } from './AutoSaveStatusContainer';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';

interface FooterHeaderProps {
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
  isSavePending?: boolean;
  reimbursement?: Reimbursement;
  showEditAction?: boolean;
  onToggleEditMode?: () => void;
}

export const FooterHeader = ({
  isSavePending,
  reimbursement,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
  showEditAction,
  onToggleEditMode,
}: FooterHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  return (
    <Grid columns={2}>
      <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
        {t(TRANSLATION.TITLE)}
      </Text>
      <Flex justifySelf="end">
        {/* {showEditAction && (
          <Button
            justifySelf="end"
            size="small"
            variant="tertiary"
            onClick={onToggleEditMode}
          >
            {t(TRANSLATION.EDIT)}
          </Button>
        )} */}
        <AutoSaveStatusContainer
          isSavePending={isSavePending}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
          refetchReimbursement={refetchReimbursement}
          refetchReimbursementItems={refetchReimbursementItems}
        />
      </Flex>
    </Grid>
  );
};

import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import type { SearchScope, SearchType } from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useDefaultSearchInput } from './useDefaultSearchInput';

export function useSearchInput() {
  const [searchParams] = useSearchParams();
  const { showSearchOverlay } = useEcm();
  const { DEFAULT_SEARCH_TYPE, DEFAULT_SEARCH_SCOPE } = useDefaultSearchInput();

  if (!showSearchOverlay) {
    return {
      searchType: DEFAULT_SEARCH_TYPE,
      searchScope: DEFAULT_SEARCH_SCOPE,
      searchInputVariable: undefined,
    };
  }

  const searchType =
    searchParams.get(searchTypeParameter) || DEFAULT_SEARCH_TYPE;

  const searchScope =
    searchParams.get(searchScopeParameter) || DEFAULT_SEARCH_SCOPE;

  return {
    searchType,
    searchScope,
    searchInputVariable: {
      searchType: searchType as SearchType,
      searchScope: searchScope as SearchScope,
    },
  };
}

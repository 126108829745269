import {
  Box,
  Grid,
  Icon,
  ScrollBox,
  Spinner,
  Text,
} from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { WideButton } from 'components/WideButton';
import {
  DocumentHistoryComponent,
  DocumentSummarySection,
} from 'containers/document-summary/DocumentSummarySection';
import { DocumentType, EcmDocumentStatus } from 'generated-types/graphql.types';
import { useEcmDocumentFile } from 'hooks/useEcmDocumentFile';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import {
  ExpandingDrawer,
  MAX_DRAWER_WIDTH,
} from 'views/DocumentDetails/ExpandingDrawer';
import { DocumentTypeContextProvider } from 'views/DocumentDetails/components/DocumentTypeContext';
import { useDrawerState } from 'views/DocumentDetails/useDrawerState';
import { DocumentRelationsSegment } from 'views/Inbox/DocumentProcessing/components/DocumentRelationsSegment';
import {
  DocumentSummaryCard,
  DocumentSummaryCardListContainer,
} from 'views/Inbox/DocumentProcessing/components/DocumentSummaryCard';
import { StorageFormContainer } from 'views/Inbox/DocumentProcessing/components/Ecm/StorageFormContainer';
import { useStorageFormInitialData } from 'views/Inbox/DocumentProcessing/components/Ecm/useStorageFormInitialData';
import { MainDocumentSummaryCard } from 'views/Inbox/DocumentProcessing/components/MainDocumentSummaryCard';
import {
  RelatedDocument,
  useDocumentRelations,
} from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentRelations';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';
import { RelatedDocumentContainer } from 'components/DocumentViewer/RelatedDocumentContainer';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

/**
 * Error messages are defined and come from BE
 * TODO: either localise errors in BE or introduce status code
 * to reliably show localised error in FE
 */
const GET_DOCUMENT_FILE_INVALID_ID_ERROR = 'Invalid ecm document id';
const GET_DOCUMENT_FILE_ACCESS_DENIED_ERROR =
  'Access denied due to insufficient permissions';

interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  documentId: string;
}
const possibleRoutes = [
  Routes.ECM_DOCUMENTS,
  Routes.ECM_CONTRACTS,
  Routes.ECM_SENSITIVE_CONTRACTS,
  Routes.INBOX_CONTRACTS,
  Routes.INBOX_OTHER_DOCUMENTS,
] as const;

export const EcmDocument = () => {
  const [t] = useTranslation();
  const { error: errorToast } = useToastMessage();
  const { organizationSlug, documentId } = useParams<RouteParams>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    globalDocumentId,
    defaultValues,
    documentStatus,
    loading: isDocumentDataLoading,
    isDocumentReadOnly,
  } = useStorageFormInitialData({ documentId });
  const { error, documentFile, isDocumentFileLoading } = useEcmDocumentFile({
    documentId,
  });

  const {
    amount,
    currency,
    contact,
    documentDate,
    documentNumber,
    documentType,
    documentSubCategory,
    documentName,
  } = defaultValues;

  const document = {
    id: documentId,
    amount:
      amount && currency
        ? {
            amount: amount,
            currency: currency,
            // We pass '0' to prevent conversion to basic monetary value
            // that happens in `DocumentSummaryCardList`
            precision: 0,
          }
        : null,
    name: contact?.inputValue ?? null,
    date: documentDate ?? null,
    number:
      (documentType === DocumentType.Contract
        ? documentName
        : documentNumber) ?? null,
    status: documentStatus ?? null,
    type: documentType ?? DocumentType.Other,
    contractTypeId: documentSubCategory,
  };

  const pathname = useLocation().pathname;

  const currentRoute =
    possibleRoutes.find(route => pathname.includes(route)) ??
    Routes.ECM_DOCUMENTS;

  const {
    linkBackToList,
    navigationLoading,
    nextDocumentLink,
    prevDocumentLink,
  } = useFetchDocNavigationData({
    cursor: searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR),
    organizationSlug,
    route: currentRoute,
  });

  useEffect(() => {
    if (isDocumentFileLoading || !error?.message) return;

    // If the document cannot be found, show error message
    // and redirect back to Archive view
    if (error?.message === GET_DOCUMENT_FILE_INVALID_ID_ERROR) {
      errorToast(t('ecm:invalidDocumentIdError'));
    } else if (error?.message === GET_DOCUMENT_FILE_ACCESS_DENIED_ERROR) {
      errorToast(t('ecm:insufficientPermissionsError'));
    }

    navigate(linkBackToList);
  }, [
    isDocumentFileLoading,
    error?.message,
    errorToast,
    linkBackToList,
    navigate,
    t,
  ]);

  // If there's no next document and no previous document, return to list
  const cycleDocument = useCallback(() => {
    if (nextDocumentLink) {
      navigate(nextDocumentLink);
    } else if (prevDocumentLink) {
      navigate(prevDocumentLink);
    } else {
      navigate(linkBackToList);
    }
  }, [linkBackToList, nextDocumentLink, prevDocumentLink, navigate]);

  const goBackToList = useCallback(() => {
    navigate(linkBackToList);
  }, [linkBackToList, navigate]);

  const gotoNextDocument = useCallback(() => {
    if (nextDocumentLink) navigate(nextDocumentLink);
  }, [nextDocumentLink, navigate]);

  const gotoPreviousDocument = useCallback(() => {
    if (prevDocumentLink) navigate(prevDocumentLink);
  }, [prevDocumentLink, navigate]);

  const { showDocumentRelationsImproved } = useEcm();
  const { isComparisonView } = useComparisonView();

  const relationsTitle = t('document.tabs.relationships.title');

  return (
    <DetailsLayout
      key={documentId}
      leftSection={
        <>
          <LeftSectionOuterWrapper>
            <ListNavigator
              loading={navigationLoading}
              backToListText={t('document.backToDocumentList')}
              arrowLeftTooltip={t('document.previousDocument')}
              arrowRightTooltip={t('document.nextDocument')}
              onBackToList={goBackToList}
              onNext={nextDocumentLink ? gotoNextDocument : undefined}
              onPrev={prevDocumentLink ? gotoPreviousDocument : undefined}
            />
            <LeftSectionContentWrapper>
              {/*
            // cursed: Chrome browser renders scrollbars outside component's border
            // we add static ScrollBox to reserve same gutter space as for
            // scrollable DocumentSummarySection 
            */}
              <ScrollBox scrollDirection="none" scrollbarGutter="stable">
                <EcmDocumentSummary
                  globalDocumentId={globalDocumentId}
                  isDocumentDataLoading={isDocumentDataLoading}
                  document={document}
                />
              </ScrollBox>
              <ScrollBox scrollDirection="y" scrollbarGutter="stable">
                <Grid gap="space8">
                  {!showDocumentRelationsImproved && (
                    <DocumentSummarySection
                      globalDocumentId={globalDocumentId}
                      documentId={documentId}
                      documentName={documentFile.name}
                      documentStatus={documentStatus}
                      readOnly={isDocumentReadOnly}
                    />
                  )}
                  <DocumentHistoryComponent
                    isEcmDocument
                    documentId={documentId}
                    isSensitiveDocument={defaultValues.isSensitive}
                  />
                </Grid>
              </ScrollBox>
            </LeftSectionContentWrapper>
          </LeftSectionOuterWrapper>
          {showDocumentRelationsImproved && (
            <Grid maxWidth={MAX_DRAWER_WIDTH} height="100cqh" overflow="hidden">
              <ExpandingDrawer drawer="RELATIONSHIPS" title={relationsTitle}>
                <DocumentRelationsSegment globalDocumentId={globalDocumentId} />
              </ExpandingDrawer>
            </Grid>
          )}
        </>
      }
      middleSection={
        <Grid
          height="100%"
          overflowY="auto"
          paddingTop="space32"
          columnGap={isComparisonView ? 'space16' : '0'}
          templateColumns={isComparisonView ? '1fr 1fr' : '0fr 1fr'}
        >
          <RelatedDocumentContainer mainDocumentId={globalDocumentId} />
          <Box height="100%" overflowY="auto">
            <DocumentViewer
              documentId={documentId}
              documentFile={documentFile}
              isLoadingPdf={isDocumentFileLoading}
            />
          </Box>
        </Grid>
      }
      rightSection={
        <DocumentTypeContextProvider
          key={documentId}
          initiallyAnInvoice={false}
        >
          <ProcessSidebar
            documentStatus={documentStatus}
            globalDocumentId={globalDocumentId}
            documentType={defaultValues.documentType}
            isSensitiveDocument={defaultValues.isSensitive}
          >
            <StorageFormContainer
              documentId={documentId}
              onDeleteDocument={cycleDocument}
              onStoreDocument={
                documentStatus === EcmDocumentStatus.New
                  ? cycleDocument
                  : undefined
              }
            />
          </ProcessSidebar>
        </DocumentTypeContextProvider>
      }
    />
  );
};

const EcmDocumentSummary = ({
  globalDocumentId,
  isDocumentDataLoading,
  document,
}: {
  globalDocumentId: string;
  isDocumentDataLoading: boolean;
  document: RelatedDocument;
}) => {
  const [t] = useTranslation();

  const { showDocumentRelationsImproved } = useEcm();

  const {
    isLoading: isLoadingRelations,
    hasError,
    relatedDocuments,
  } = useDocumentRelations(globalDocumentId);
  const canShowRelationsCounter = !isLoadingRelations && !hasError;

  const {
    toggleDrawer: toggleRelationsDrawer,
    isDrawerOpen: isRelationsDrawerOpen,
  } = useDrawerState('RELATIONSHIPS');

  const relationsTitle = t('document.tabs.relationships.title');

  const SummaryCard = showDocumentRelationsImproved
    ? MainDocumentSummaryCard
    : DocumentSummaryCard;

  return (
    <DocumentSummaryCardListContainer borderRadius="medium" background="gray0">
      <SummaryCard
        as="div"
        borderRadius="inherit"
        borderTopRadius={showDocumentRelationsImproved ? 'medium' : 'inherit'}
        isLoading={isDocumentDataLoading}
        document={document}
      />
      {showDocumentRelationsImproved && (
        <Grid padding="space12" paddingTop="0" gap="space8">
          <WideButton
            onClick={toggleRelationsDrawer}
            isPressed={isRelationsDrawerOpen}
            icon={
              <Icon
                icon="linkOutlined"
                size="space18"
                style={{ marginTop: '-1px' }}
              />
            }
          >
            <Text>{relationsTitle}</Text>
            {isLoadingRelations && <Spinner />}
            {canShowRelationsCounter && (
              <Text
                fontSize="small"
                style={{ fontVariantNumeric: 'tabular-nums' }}
              >
                {relatedDocuments.length}
              </Text>
            )}
          </WideButton>
        </Grid>
      )}
    </DocumentSummaryCardListContainer>
  );
};

import { useQuery } from '@apollo/client';
import {
  Grid,
  Icon,
  Popover,
  ScrollBox,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { Query } from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { OrganizationsListView } from '../../Header/components/MultiOrganizationOverview/OrganizationsListView';
import { OrganizationAvatar } from './OrganizationAvatar/OrganizationAvatar';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { getOrganizationsQuery } from '../../Header/components/MultiOrganizationOverview/getOrganizationsQuery';

interface OrganizationSwitcherProps {
  isNavigationOpen: boolean;
}

export const OrganizationSwitcher = ({
  isNavigationOpen,
}: OrganizationSwitcherProps) => {
  const organization = useFullOrganization();
  const organizationName = organization?.name;
  const organizationsQuery = useQuery<Pick<Query, 'getOrganizations'>>(
    getOrganizationsQuery
  );

  const organizations = organizationsQuery.data?.getOrganizations || [];
  const hasOnlyOneOrganization = organizations.length === 1;

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  const isActive = isOpen && !hasOnlyOneOrganization;

  return (
    <>
      <Grid
        {...triggerProps}
        ref={triggerRef}
        background={isActive ? 'bluebg' : 'transparent'}
        border="none"
        borderRadius="medium"
        cursor={hasOnlyOneOrganization ? 'default' : 'pointer'}
        alignItems="center"
        alignContent="center"
        templateColumns="1fr 2fr"
        focus={
          hasOnlyOneOrganization
            ? {
                background: colors.bluebg,
              }
            : undefined
        }
        transition="background 0.2s"
        padding="space24 space12"
        width={isNavigationOpen ? '90%' : '64px'}
        height="space64"
        gap={isNavigationOpen ? 'space8' : '0'}
      >
        <OrganizationAvatar
          organizationName={organizationName}
          showTooltip={!isNavigationOpen && !isOpen}
        />
        {isNavigationOpen && (
          <Grid
            gap="space8"
            alignItems="center"
            templateColumns="1fr auto"
            width="176px"
          >
            <TruncatedText
              fontSize="basic"
              fontWeight="500"
              color="gray800"
              lineClamp={2}
            >
              {organizationName ?? ''}
            </TruncatedText>

            {!hasOnlyOneOrganization && (
              <Icon
                icon={isOpen ? 'caretUp' : 'caretDown'}
                size="space20"
                color="gray600"
              />
            )}
          </Grid>
        )}
      </Grid>

      {isOpen && !hasOnlyOneOrganization && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="0"
          paddingY="0"
          width="440px"
        >
          <ScrollBox maxHeight="inherit" padding="0">
            <OrganizationsListView handleCurrentOrganization={close} />
          </ScrollBox>
        </Popover>
      )}
    </>
  );
};

import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useApprovalData } from '../Invoices/hooks/useApprovalData';
import { Tab } from '../Tabs/AppprovalsTabs';
import { ApprovalsHeader } from './ApprovalsHeader';

interface ApprovalsHeaderContainerProps {
  activeTab: Tab;
}

export const ApprovalsHeaderContainer = ({
  activeTab,
}: ApprovalsHeaderContainerProps) => {
  const { approvalDocumentsBasicsQueryResult } = useApprovalData();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { searchParams } = useMutateSearchParams();
  const navigate = useNavigate();

  const firstDocToApprove = approvalDocumentsBasicsQueryResult?.edges?.[0];

  const onClick = () => {
    const cursor = firstDocToApprove?.cursor;
    if (!cursor) {
      return;
    }
    searchParams.set('cursor', cursor);
    const startApprovingLink = `/${organizationSlug}${Routes.APPROVALS}/${firstDocToApprove?.node.id}?${searchParams.toString()}`;
    navigate(startApprovingLink);
  };

  return (
    <ApprovalsHeader
      isButtonDisabled={!firstDocToApprove || activeTab === 'Reimbursements'}
      onClick={onClick}
    />
  );
};

// TODO: TPX-1206 Remove this file once schema is updated
import {
  ImportHistoryDetailsData,
  ProjectCodeImportHistoryItemProps,
} from './types';

export const mockErrorData: ImportHistoryDetailsData[] = [
  {
    id: 'err1',
    code: '123',
    name: 'Project 1',
    error: 'Error message',
    sapDocEntryId: '123',
  },
  {
    id: 'err2',
    code: '456',
    name: 'Project 2',
    error: 'Error message',
    sapDocEntryId: '456',
  },
];

export const historyItems: ProjectCodeImportHistoryItemProps[] = [
  {
    id: '1',
    username: 'system',
    createdAt: '2025-01-20T02:03:02.539Z',
  },
];

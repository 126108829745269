import {
  useApprovalReimbursementCasesCountQuery,
  useCountOpenApprovalsQuery,
} from 'generated-types/graphql.types';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const MenuApprovals = (iconProps: NavIconProps) => {
  const user = useCurrentUser();
  const { canUseReimbursement } = useReimbursement();
  const expenseApprovalWorkflowFF = useCandisFeatureFlags(
    FEATURE_FLAGS.expenseApprovalWorkflow
  );
  const { data: approvalCount, loading: approvalCountLoading } =
    useCountOpenApprovalsQuery({
      fetchPolicy: 'cache-first',
    });

  const {
    data: assignedReimbursementsToMe,
    loading: isAssignedReimbursementsToMeLoading,
  } = useApprovalReimbursementCasesCountQuery({
    variables: { filters: { approversIds: [user?.id as string] } },
    skip: !user?.id || !canUseReimbursement || !expenseApprovalWorkflowFF,
  });

  const totalCount =
    (approvalCount?.countOpenApprovals.count ?? 0) +
    (assignedReimbursementsToMe?.approvalReimbursementCasesCount.totalCount ??
      0);

  const isCountLoading =
    isAssignedReimbursementsToMeLoading || approvalCountLoading;

  return (
    <NavigationIcon
      {...iconProps}
      count={totalCount}
      countLoading={isCountLoading}
    />
  );
};

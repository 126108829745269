import {
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useCanModifyAccess = ({
  isSensitiveDocument,
}: {
  isSensitiveDocument: boolean;
}) => {
  const modifyDocumentAccessFF = useCandisFeatureFlags(
    FEATURE_FLAGS.modifyDocumentAccess
  );

  const entitlement = useEntitlementsFlag();

  return (
    modifyDocumentAccessFF && !isSensitiveDocument && entitlement !== 'LEGACY'
  );
};

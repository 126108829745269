import { Button, Skeleton, Text } from '@candisio/design-system';
import { ProvisionsPromotionExportInfoCard } from 'components/ProductPromotions/Provisions/ProvisionsPromotionExportInfoCard';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import {
  ExportableEntityType,
  useGetAvailableMonthsQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useParams } from 'react-router-dom';
import { ExportContext } from 'views/Integrations/Export/Context';
import {
  csvExportTypesDatev,
  csvExportTypesNonDatev,
} from 'views/Integrations/Export/consts';
import { ConfigureButton } from 'views/Integrations/Export/toolkit/components/ConfigureButton';
import { useDatevSettings } from 'views/Integrations/Export/toolkit/hooks/useDatevSettings';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { useShowEntitlementPromo } from 'views/Integrations/Export/toolkit/hooks/useShowEntitlementPromo';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntity, Table } from 'views/Integrations/Export/types';
import { useCheckDatevSetup } from 'views/Settings/Integrations/DATEV/setup-checks';

export const DatevCsvExportButton = ({
  onExport,
  hasExportableEntity,
  entities,
  loading,
}: {
  onExport: () => void;
  hasExportableEntity: boolean;
  entities: ExportEntity[];
  loading?: boolean;
}) => {
  const state = useContext(ExportContext);
  const { exportType, table } = state;
  const [t] = useTranslation();
  const history = useHistory();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const { datevSettings, loading: loadingDatevSettings } = useDatevSettings();
  const { onlyUnsupportedDatevDocumentsAvailable } =
    useExportDataGigaFactory(state);

  const { loadingSettings } = useCheckDatevSetup();

  const showEntitlementPromo = useShowEntitlementPromo();

  const {
    isLoadingDatev: loadingDatev,
    exportProvisionsFF,
    bdsConnected,
  } = useDatev(); // BDS-checked

  const { isExporting } = useIsExporting();

  const { loading: loadingAvailableMonthsData } = useGetAvailableMonthsQuery({
    variables: {
      originalExportId: state.exportId,
    },
    fetchPolicy: 'no-cache',
  });

  const onGoToCreditCardSettings = () => {
    history.push(
      `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
    );
  };

  const creditCardTransactionExists = entities.some(hasTransaction);
  const cardSettlementsExists = entities.some(
    entity => entity.type === ExportableEntityType.CardSettlement
  );

  const { bookingAccount, transitAccount } =
    datevSettings?.creditCardsLedger || {};

  const shouldShowUnsupportedError = () =>
    exportType &&
    (csvExportTypesDatev.includes(
      exportType as (typeof csvExportTypesDatev)[number]
    ) ||
      csvExportTypesNonDatev.includes(
        exportType as (typeof csvExportTypesNonDatev)[number]
      )) &&
    onlyUnsupportedDatevDocumentsAvailable;

  const hasError =
    (!hasExportableEntity && isExportTriggered) || shouldShowUnsupportedError();

  const { otherAssetsAccountNumber, provisionAccountNumber } =
    datevSettings?.provisionsLedger || {};

  const provisionsExists = entities.some(
    entity =>
      entity.type === ExportableEntityType.Provision ||
      entity.type === ExportableEntityType.ProvisionReversal
  );

  const provisionsOpened =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  const renderExportButton = () => {
    if (
      (creditCardTransactionExists && !bookingAccount) ||
      (cardSettlementsExists && !transitAccount)
    ) {
      return (
        <ConfigureButton
          message={t('export.exportForm.exportDatev.creditCardAccountMissing')}
          buttonLabel={t('export.exportForm.exportDatev.enterAccountNumbers')}
          loading={isExporting}
          onClick={onGoToCreditCardSettings}
        />
      );
    }

    if (
      bdsConnected &&
      exportProvisionsFF &&
      provisionsExists &&
      (!provisionAccountNumber || !otherAssetsAccountNumber)
    ) {
      return (
        <ConfigureButton
          variant="information"
          message={t('export.exportForm.exportDatevBds.missingAccountNumber')}
          buttonLabel={t(
            'export.exportForm.exportDatevBds.missingAccountNumberButton'
          )}
          loading={isExporting}
          onClick={() =>
            history.push(
              `/${organizationSlug}${Routes.SETTINGS}${Routes.PROVISIONS}`
            )
          }
        />
      );
    }

    return (
      <>
        <ProvisionsPromotionExportInfoCard />
        <Button
          onClick={() => {
            setIsExportTriggered(true);
            if (hasExportableEntity) {
              void onExport();
            }
          }}
          disabled={hasError || (!exportProvisionsFF && provisionsOpened)}
          type="submit"
          loading={loading}
          data-cy="submit"
        >
          {t('export.exportForm.actionBar.export')}
        </Button>
      </>
    );
  };

  const isLoadingConfig =
    loadingDatevSettings || loadingSettings || loadingDatev;

  if (isLoadingConfig) {
    return (
      <ActionBar>
        <Skeleton width="100%" height="3rem" />
      </ActionBar>
    );
  }

  if (loadingAvailableMonthsData) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.loading')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (isExporting) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.exporting')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (showEntitlementPromo) {
    return (
      <ActionBar>
        <UpsellPromo
          feature={FEATURE.PROVISIONS}
          utmSource={PROVISIONS_UTM_SOURCE.EXPORT_SIDEBAR}
        />
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      {hasError && (
        <Text color="red500" fontSize="xsmall">
          {t('export.exportForm.actionBar.noExportableDocuments')}
        </Text>
      )}
      {renderExportButton()}
    </ActionBar>
  );
};

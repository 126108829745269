import { IconProps } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type NavIconProps = {
  icon: IconProps['icon'];
  label?: string;
  route?: string;
  count?: number;
  countLoading?: boolean;
  showMenuIcon?: boolean;
  onClick?: () => void;
  pulseIndicator?: ReactNode;
  expanded?: boolean;
};

type NavIconPropsReturnType = {
  initialOverflowIcons: NavIconProps[];
};

export const useNavigationIcons = (): NavIconPropsReturnType => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const currentUser = useCurrentUser();
  const { isAdmin, isAccountant, isRequester, hasRoles } = useUserRoles();
  const organization = useFullOrganization();
  const { ft, show } = useFeatureToggle();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const showCreditCardMenuItem =
    !!organization?.creditCardsDetails && showAllExtraFeatures;

  const { canUseReimbursement } = useReimbursement();

  const isExpenseIconVisible = (canUseReimbursement || isAdmin) && hasRoles;

  const shouldShowPaymentMenuIcon =
    show(ft.SEPA_XML) &&
    (isAccountant || isAdmin) &&
    organization?.payment?.isActive;

  const initialOverflowIcons: NavIconProps[] = useMemo(
    () => [
      {
        icon: 'menuInbox',
        label: t('menuLabels.inbox'),
        route: Routes.INBOX,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuApprovals',
        label: t('menuLabels.approvals'),
        route: `${Routes.APPROVALS}?approvers=${currentUser?.id}`,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuPayments',
        label: t('menuLabels.payments'),
        route: Routes.PAYMENTS,
        showMenuIcon: shouldShowPaymentMenuIcon,
      },
      {
        icon: 'menuExport',
        label: t('menuLabels.export'),
        showMenuIcon: isAccountant || isAdmin,
      },
      {
        icon: 'menuArchive',
        label: t('menuLabels.archive'),
        route: Routes.ARCHIVE,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuTravel',
        label: t('menuLabels.travelAndExpense'),
        route: Routes.REIMBURSEMENTS_DASHBOARD,
        showMenuIcon: isExpenseIconVisible,
      },
      {
        icon: 'menuCreditCards',
        label: t('menuLabels.creditCards'),
        showMenuIcon: showCreditCardMenuItem && hasRoles,
      },
      {
        icon: 'menuContactsAlt',
        label: t('menuLabels.contacts'),
        route: Routes.CONTACTS,
        showMenuIcon: isAccountant || isAdmin || isRequester,
      },
    ],
    [
      currentUser?.id,
      hasRoles,
      isAccountant,
      isAdmin,
      isRequester,
      t,
      shouldShowPaymentMenuIcon,
      isExpenseIconVisible,
      showCreditCardMenuItem,
    ]
  );

  return {
    initialOverflowIcons: initialOverflowIcons.filter(
      icon => icon.showMenuIcon
    ),
  };
};

import { Button, Grid } from '@candisio/design-system';
import { PdfThumbnailViewer } from 'components/DocumentViewer/PdfViewer/PdfThumbnailViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { MouseEvent } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

const StyledGrid = styled(Grid)`
  isolation: isolate;

  & > * {
    grid-area: 1 / 1;
    border-radius: inherit;
  }
`;

export interface DocumentThumbnailProps {
  handleClick: () => void;
  documentFile: PDFDetails;
}

export const DocumentThumbnail = ({
  documentFile,
  handleClick,
}: DocumentThumbnailProps) => {
  return (
    <StyledGrid
      background="gray50"
      paddingX="0"
      paddingY="0"
      height="inherit"
      width="inherit"
      borderRadius="small"
      border="1px solid gray200"
    >
      <PdfThumbnailViewer documentFile={documentFile} />
      <Grid
        opacity="0"
        cursor="pointer"
        hover={{ background: 'graybg', opacity: '1' }}
        onPointerDown={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          handleClick();
        }}
      >
        <Button
          alignSelf="center"
          justifySelf="center"
          icon="newTab"
          variant="tertiary"
          onClick={handleClick}
        />
      </Grid>
    </StyledGrid>
  );
};

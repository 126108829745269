import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { ReimbursementCasesSummary } from 'generated-types/graphql.types';

export const ReimbursementSummarySwimlane = ({
  reimbursementCasesSummary,
}: { reimbursementCasesSummary?: ReimbursementCasesSummary }) => {
  const { draft, open, closed } = reimbursementCasesSummary || {};

  return (
    <Swimlane>
      <SwimlaneTiles contentTileCount={3}>
        <SwimlaneHeader icon="add" label="Reimbursement status" />
        <SwimlaneTile asChild variant="gray">
          <a href="#draft">{`${draft?.totalCount || 0} draft expenses`}</a>
        </SwimlaneTile>
        <SwimlaneTile asChild variant="gray">
          <a href="#open">{`${open?.totalCount || 0} open expenses`}</a>
        </SwimlaneTile>
        <SwimlaneTile asChild variant="gray">
          <a href="#closed">{`${closed?.last30DaysCount || 0} closed expenses`}</a>
        </SwimlaneTile>
      </SwimlaneTiles>
    </Swimlane>
  );
};

import {
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { BookingValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { BookingEntryKeys } from '../../utils/generateSplitBookingsFormFieldNames';

export type ReimbursementStatusProps = {
  status?: ReimbursementCaseStatus;
  itemStatus: ReimbursementItemStatus;
  isCurrentUserCaseApprover: boolean;
};

export interface ActionRules {
  canAddAndDeleteSplits: boolean;
  canAcceptAllSplits: boolean;
}

export interface FieldRule {
  isReadOnly: (
    props: ReimbursementStatusProps,
    booking: BookingValues
  ) => boolean;
}

export type FieldRules = {
  [K in BookingEntryKeys]: FieldRule;
};

const READONLY_STATUSES = new Set([
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.PartiallyExported,
]);

const isReadOnlyStatus = ({ status }: ReimbursementStatusProps) =>
  status ? READONLY_STATUSES.has(status) : true;

const isFullyReadOnlyStatus = (status: ReimbursementCaseStatus) =>
  [
    ReimbursementCaseStatus.Approved,
    ReimbursementCaseStatus.Exported,
    ReimbursementCaseStatus.Archived,
    ReimbursementCaseStatus.Exporting,
  ].includes(status);

/**
 * Creates a rule to determine field read-only state based on:
 * - Case status (Approved, Exported, etc.)
 * - Field value during Approving status
 * - Export status for partial exports
 */

const createConditionalReadOnlyRule =
  (
    hasFieldValue: (booking: BookingValues) => boolean
  ): FieldRule['isReadOnly'] =>
  ({ status, itemStatus, isCurrentUserCaseApprover }, booking) => {
    if (!status) return true;

    const isFullyReadOnly = isFullyReadOnlyStatus(status);

    const isValidForApproval =
      status === ReimbursementCaseStatus.Approving &&
      // If not a case approver, all fields should be readonly (return true)
      (!isCurrentUserCaseApprover ||
        // If is a case approver, check if field has value
        hasFieldValue(booking));

    const isItemExportBlocked =
      status === ReimbursementCaseStatus.PartiallyExported &&
      itemStatus !== ReimbursementItemStatus.ReadyToExport;

    return isFullyReadOnly || isValidForApproval || isItemExportBlocked;
  };

const fieldRules: FieldRules = {
  splitAmount: { isReadOnly: isReadOnlyStatus },
  taxPresentation: { isReadOnly: isReadOnlyStatus },
  vatRate: { isReadOnly: isReadOnlyStatus },
  taxCode: {
    isReadOnly: createConditionalReadOnlyRule(booking => !!booking.taxCode),
  },
  artistSocialInsuranceCode: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking.artistSocialInsuranceCode
    ),
  },
  costCenter: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking.costCenter?.value
    ),
  },
  costObject: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking.costObject?.value
    ),
  },
  extraCostInfo: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking.extraCostInfo?.value
    ),
  },
  generalLedgerAccount: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking.generalLedgerAccount?.value
    ),
  },
  note: {
    isReadOnly: createConditionalReadOnlyRule(booking => !!booking.note),
  },
  postingText: {
    isReadOnly: createConditionalReadOnlyRule(booking => !!booking.postingText),
  },
};

export type BookingFieldRules = {
  [K in BookingEntryKeys]: { isReadOnly: boolean };
};

interface GetFieldRulesProps {
  isCurrentUserCaseApprover: boolean;
  status?: ReimbursementCaseStatus;
  itemStatus: ReimbursementItemStatus;
  bookings: BookingValues[];
}

export const getFieldRules = ({
  status,
  itemStatus,
  bookings,
  isCurrentUserCaseApprover,
}: GetFieldRulesProps): BookingFieldRules[] => {
  if (!status) return [];

  const rules = (bookings ?? []).map(booking =>
    Object.entries(fieldRules).reduce((acc, [key, rule]) => {
      acc[key as BookingEntryKeys] = {
        isReadOnly: rule.isReadOnly(
          { status, itemStatus, isCurrentUserCaseApprover },
          booking
        ),
      };
      return acc;
    }, {} as BookingFieldRules)
  );

  return rules;
};

export const getActionRules = (
  fieldRules: BookingFieldRules[]
): ActionRules => {
  const hasAnyReadOnlyField = fieldRules.some(rule =>
    Object.values(rule).some(field => field.isReadOnly)
  );

  const hasAnyEditableField = fieldRules.some(rule =>
    Object.values(rule).some(field => !field.isReadOnly)
  );

  return {
    canAddAndDeleteSplits: !hasAnyReadOnlyField,
    canAcceptAllSplits: hasAnyEditableField,
  };
};

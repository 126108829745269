import {
  Card,
  Flex,
  Icon,
  IconKey,
  Theme,
  TruncatedText,
} from '@candisio/design-system';

interface InfoCardProps {
  value: number;
  text: string;
  color: keyof Theme['colors'];
  icon?: IconKey;
  onClick: (() => void) | undefined;
  minWidth?: string;
}

export const InfoCard = ({
  color,
  icon,
  text,
  value,
  minWidth,
  onClick,
}: InfoCardProps) => {
  return (
    <Card
      background="gray50"
      border="1px solid gray250"
      paddingY="space8"
      paddingX="space12"
      onClick={onClick}
      minWidth={minWidth}
      hover={{ background: onClick ? 'gray200' : 'none' }}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <Flex alignItems="start" direction="column">
        <Flex alignItems="center" gap="space4">
          {icon ? <Icon icon={icon} color={color} size="space14" /> : null}
          <TruncatedText color={color} fontSize="xlarge">
            {String(value)}
          </TruncatedText>
        </Flex>
        <TruncatedText color={color} fontSize="basic">
          {text}
        </TruncatedText>
      </Flex>
    </Card>
  );
};

import { Flex, Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteReimbursementItemAction } from './DeleteReimbursementItem';
import { ExpenseFormWrapper } from './ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from './ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { ReadonlyHospitalityExpenseFormDeprecated } from './ReadonlyHospitalityExpenseFormDeprecated';
import { ReimbursementSwitchField } from './ReimbursementsSwitchField';
import { generateFieldNames } from './utils/generateHospitalityFormFieldNames';
import { BooleanMap } from './utils/useExpensesFormActions';
import { FieldsOptions } from './utils/useFieldsOptions';

interface HospitalityExpenseFormDeprecatedProps {
  isReadOnly: boolean;
  isPromotion?: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateHospitality: (index: number) => () => Promise<void>;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  onUpdateTotalAmount: (index: number) => void;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  isCreatingInvoice: BooleanMap;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
  fieldOptions: FieldsOptions;
}

export const HospitalityExpenseFormDeprecated = ({
  isReadOnly,
  reimbursementStatus,
  onRemoveForm,
  onCleareableField,
  onUpdateHospitality,
  onInvoiceChange,
  onUpdateTotalAmount,
  formIndex,
  clearableField,
  formMethods,
  isCreatingInvoice,
  expenseId,
  isDeletingForm,
  canManageReimbursementItems,
  isPromotion = false,
  fieldOptions,
}: HospitalityExpenseFormDeprecatedProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { watch, getValues } = formMethods;
  const { isExcluded: isExcludedFieldOptions } = fieldOptions;

  const fields = generateFieldNames(formIndex);

  const invoices = watch(fields.files);
  const isExcluded = watch(fields.isExpenseExcluded);

  const invoice = invoices?.[0];

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const itemStatus = getValues(fields.itemStatus);
  const showInvoicePreview =
    (isCreatingInvoice[formIndex] ?? false) || !!invoice;
  const isExcludedInfoVisible =
    isExcluded && isExcludedFieldOptions.showInfoIsExcluded;

  if (isReadOnly) {
    return (
      <ReadonlyHospitalityExpenseFormDeprecated
        expenseId={expenseId}
        reason={reason}
        invoice={invoice}
        formIndex={formIndex}
        totalAmount={totalAmount}
        reimbursementStatus={reimbursementStatus}
        itemStatus={itemStatus}
        isExcluded={isExcluded}
      />
    );
  }

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="hospitality"
      reason={reason}
      isExcluded={isExcluded}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
    >
      <Grid as="form" gap="space16">
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={isPromotion ? '' : fields.reason}
            readOnly={isExcluded}
            maxLength={60}
            label={t(
              'reimbursementView.middleSection.form.hospitality.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.reason.placeholder'
            )}
            clearable={clearableField === fields.reason}
            onFocus={onCleareableField(fields.reason)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly={isPromotion || isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.date.label'
            )}
            clearable={clearableField === fields.expenseDate}
            onFocus={onCleareableField(fields.expenseDate)}
            onBlur={onUpdateHospitality(formIndex)}
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={isPromotion ? '' : fields.location}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.location.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.location.placeholder'
            )}
            clearable={clearableField === fields.location}
            onFocus={onCleareableField(fields.location)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
          <HookFormTextField
            name={isPromotion ? '' : fields.invoiceNumber}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.invoiceNumber.placeholder'
            )}
            clearable={clearableField === fields.invoiceNumber}
            onFocus={onCleareableField(fields.invoiceNumber)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        </Grid>
        <Grid columns={3} gap="space8">
          <HookFormAmountField
            name={isPromotion ? '' : fields.receiptAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.receiptAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.receiptAmount}
            onFocus={onCleareableField(fields.receiptAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
          <HookFormAmountField
            name={isPromotion ? '' : fields.tipAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.tipAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.tipAmount}
            onFocus={onCleareableField(fields.tipAmount)}
            onBlur={() => {
              onUpdateTotalAmount(formIndex);
              onUpdateHospitality(formIndex)();
            }}
          />
          <HookFormAmountField
            name={isPromotion ? '' : fields.totalAmount}
            label={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.totalAmount.placeholder'
            )}
            readOnly
            currency="EUR"
            minValue={0}
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={isPromotion ? '' : fields.internalGuests}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.internalGuests.placeholder'
            )}
            infoTooltip={{
              message: t(
                'reimbursementView.middleSection.form.hospitality.internalGuests.tooltip'
              ),
            }}
            clearable={clearableField === fields.internalGuests}
            onFocus={onCleareableField(fields.internalGuests)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
          <HookFormTextField
            name={isPromotion ? '' : fields.externalGuests}
            label={
              /**
               * The other field aligned to this one has infoTooltip which makes the alignement impossible
               * thus the need of putting the label into a flex
               */
              <Flex height="space16" alignItems="center">
                <Text>
                  {t(
                    'reimbursementView.middleSection.form.hospitality.externalGuests.label'
                  )}
                </Text>
              </Flex>
            }
            placeholder={t(
              'reimbursementView.middleSection.form.hospitality.externalGuests.placeholder'
            )}
            clearable={clearableField === fields.externalGuests}
            readOnly={isExcluded}
            onFocus={onCleareableField(fields.externalGuests)}
            inputProps={{
              onBlur: onUpdateHospitality(formIndex),
            }}
          />
        </Grid>

        {showInvoicePreview && (
          <ExpenseInvoicePreviewContainer
            name={fields.files}
            invoice={invoice}
            expenseType="hospitality"
            isExcluded={isExcluded}
            createInvoicePending={isCreatingInvoice[formIndex]}
            id={`invoice-picker-${fields.files}`}
            onChange={async file => await onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateHospitality(formIndex)}
          />
        )}
        {!showInvoicePreview && (
          <ExpenseInvoiceInputField
            name={fields.files}
            id={`invoice-input-field-${fields.files}`}
            onChange={async file => await onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateHospitality(formIndex)}
            isPromotion={isPromotion}
          />
        )}
        {isExcludedFieldOptions.isVisible && (
          <ReimbursementSwitchField
            label={t(
              'reimbursementView.middleSection.form.hospitality.excludeItem.label'
            )}
            isReadOnly={isExcludedFieldOptions.isReadOnly}
            name={fields.isExpenseExcluded}
            onChange={onUpdateHospitality(formIndex)}
          />
        )}
        {(isExcludedInfoVisible || canManageReimbursementItems) && (
          <Flex justifySelf="end" alignItems="center" gap="space4">
            {isExcludedInfoVisible && (
              <Text fontSize="basic">
                {t(
                  'reimbursementView.middleSection.form.hospitality.expenseIsExcludedInfo'
                )}
              </Text>
            )}
            {canManageReimbursementItems && (
              <DeleteReimbursementItemAction
                loading={isDeletingForm[formIndex]}
                onDelete={onRemoveForm(formIndex, expenseId)}
              />
            )}
          </Flex>
        )}
      </Grid>
    </ExpenseFormWrapper>
  );
};

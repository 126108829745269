import { Grid, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteReimbursementItemAction } from './DeleteReimbursementItem';
import { ReimbursementSwitchField } from './ReimbursementsSwitchField';
import { BooleanMap } from './utils/useExpensesFormActions';

interface ExpenseFooterProps {
  isExcludedFieldOptions: {
    isVisible: boolean;
    isReadOnly: boolean;
    showInfoIsExcluded: boolean;
  };
  formIndex: number;
  onUpdateExpense: (index: number) => () => Promise<void>;
  isExcludedInfoVisible: boolean;
  canManageReimbursementItems: boolean;
  isDeletingForm: BooleanMap;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  expenseId: string;
  onTriggerValidation: UseFormTrigger<ExpensesFormOutput>;
}

export const ExpenseFooter = ({
  formIndex,
  isExcludedFieldOptions,
  onUpdateExpense,
  canManageReimbursementItems,
  isExcludedInfoVisible,
  isDeletingForm,
  onRemoveForm,
  expenseId,
  onTriggerValidation,
}: ExpenseFooterProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const {
    formMethods: { clearErrors },
  } = useReimbursementSplitBookingHelpers();

  return (
    <Grid alignContent="start">
      {isExcludedFieldOptions.isVisible && (
        <ReimbursementSwitchField
          isReadOnly={isExcludedFieldOptions.isReadOnly}
          name={`expenses.${formIndex}.isExpenseExcluded`}
          onChange={() => {
            onUpdateExpense(formIndex)();
            clearErrors(`reimbursementItemBookings.${formIndex}`);
            void onTriggerValidation(`expenses.${formIndex}`);
          }}
          label={t(
            'reimbursementView.middleSection.form.general.excludeItem.label'
          )}
        />
      )}

      {(isExcludedInfoVisible || canManageReimbursementItems) && (
        <Grid alignItems="center" autoFlow="column">
          {isExcludedInfoVisible && (
            <Text fontSize="basic" justifySelf="start">
              {t(
                'reimbursementView.middleSection.form.general.expenseIsExcludedInfo'
              )}
            </Text>
          )}
          {canManageReimbursementItems && (
            <DeleteReimbursementItemAction
              loading={isDeletingForm[formIndex]}
              onDelete={onRemoveForm(formIndex, expenseId)}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

import { Grid, ScrollBox } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { FormProvider } from 'react-hook-form';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useCreateExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useReimbursementMenuButtonActions } from 'views/Reimbursement/hooks/useReimbursementMenuButtonActions';
import { useVisibleReimbursementItemTracker } from 'views/Reimbursement/toolkit/useVisibleReimbursementItemTracker';
import { CreateExpenseActionMenuButton } from '../CreateExpenseActionMenuButton';
import { GeneralExpensesForm } from './GeneralExpenseForm';
import { HospitalityExpenseForm } from './HospitalityExpenseForm';
import { useExpenseFormsHelpers } from './utils/useExpenseFormsHelpers';
import { useExpensesFormActions } from './utils/useExpensesFormActions';
import { useFieldsOptions } from './utils/useFieldsOptions';

interface ExpensesFormsContainerProps {
  status?: ReimbursementCaseStatus;
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
}

export const ExpensesFormsContainer = ({
  status,
  formHelpers,
}: ExpensesFormsContainerProps) => {
  const { createExpenseInvoice } = useCreateExpenseInvoice();
  const { reimbursementUIConfig, itemsContainerRef } =
    useReimbursementFormsContext();
  const fieldOptions = useFieldsOptions();

  const { isExpenseFormEditable, canManageReimbursementItems } =
    reimbursementUIConfig;

  const { forms, onRemoveForm, formMethods } = formHelpers;

  const {
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
    isCreatingExpenseItem,
  } = useReimbursementMenuButtonActions({ formHelpers });

  const {
    clearableField,
    handleClearableField,
    handleDeleteForm,
    handleInvoiceChange,
    handleUpdateHospitality,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
    isCreatingInvoice,
    isDeletingForm,
    isExtractingInvoiceData,
    extractAndProcessGeneralInvoice,
    extractAndProcessHospitalityInvoice,
  } = useExpensesFormActions({
    createExpenseInvoice,
    formMethods,
    onRemoveForm,
  });

  useVisibleReimbursementItemTracker();

  return (
    <ScrollBox scrollDirection="y" ref={itemsContainerRef}>
      <Grid gap="space16">
        <FormProvider {...formMethods}>
          <Grid gap="space24">
            {forms.map((form, index) => {
              if (form.expenseType === 'hospitality') {
                return (
                  <HospitalityExpenseForm
                    key={form.expenseId}
                    expenseId={form.expenseId}
                    formIndex={index}
                    fieldOptions={fieldOptions}
                    reimbursementStatus={status}
                    isExpenseFormEditable={isExpenseFormEditable}
                    clearableField={clearableField}
                    isCreatingInvoice={isCreatingInvoice}
                    isExtractingInvoiceData={isExtractingInvoiceData}
                    isDeletingForm={isDeletingForm}
                    onUpdateHospitality={handleUpdateHospitality}
                    formMethods={formMethods}
                    onRemoveForm={handleDeleteForm}
                    onInvoiceChange={handleInvoiceChange}
                    onCleareableField={handleClearableField}
                    onUpdateTotalAmount={handleUpdateTotalAmount}
                    canManageReimbursementItems={canManageReimbursementItems}
                    onHospitalityInvoiceDataExtraction={
                      extractAndProcessHospitalityInvoice
                    }
                  />
                );
              }

              return (
                <GeneralExpensesForm
                  key={form.expenseId}
                  expenseId={form.expenseId}
                  formIndex={index}
                  reimbursementStatus={status}
                  isExpenseFormEditable={isExpenseFormEditable}
                  clearableField={clearableField}
                  isExtractingInvoiceData={isExtractingInvoiceData}
                  isCreatingInvoice={isCreatingInvoice}
                  isDeletingForm={isDeletingForm}
                  onUpdateExpense={handleUpdateGeneralExpense}
                  fieldOptions={fieldOptions}
                  formMethods={formMethods}
                  onRemoveForm={handleDeleteForm}
                  onInvoiceChange={handleInvoiceChange}
                  onCleareableField={handleClearableField}
                  canManageReimbursementItems={canManageReimbursementItems}
                  onGeneralInvoiceDataExtraction={
                    extractAndProcessGeneralInvoice
                  }
                />
              );
            })}
          </Grid>
        </FormProvider>

        {canManageReimbursementItems && (
          <Grid
            background="gray50"
            placeItems="center"
            borderRadius="medium"
            padding="space16"
          >
            <CreateExpenseActionMenuButton
              count={forms.length}
              creatingExpense={isCreatingExpenseItem}
              onGeneralExpenseClick={handleCreateGeneralExpense}
              onHospitalityExpenseClick={handleCreateHospitalityExpense}
            />
          </Grid>
        )}
      </Grid>
    </ScrollBox>
  );
};

import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { DocumentType } from 'generated-types/graphql.types';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';
import {
  ProcessSidebarHeader,
  ProcessSidebarHeaderProps,
} from './Header/ProcessSidebarHeader';

interface ProcessSidebarProps {
  actions?: ReactNode;
  children: ReactNode;
  documentId?: string;
  globalDocumentId?: string;
  id?: string;
  switcher?: ReactNode;
  /** Sets the title based on the document status */
  documentStatus?: ProcessSidebarHeaderProps['documentStatus'];

  documentType?: ProcessSidebarHeaderProps['documentType'];
  isSensitiveDocument?: boolean;
}

export const ProcessSidebar = ({
  actions,
  children,
  documentId,
  globalDocumentId,
  id,
  switcher,
  documentStatus,
  documentType,
  isSensitiveDocument,
}: ProcessSidebarProps) => {
  const cardRef = useStickyCardRefContext();

  return (
    <ScrollBox
      scrollDirection="y"
      scrollbarGutter="stable"
      height="100%"
      id={id}
      paddingTop="space32"
      paddingLeft="space8"
      paddingRight="space3"
      position="static"
    >
      <Grid
        gap="space8"
        templateRows="auto 1fr"
        height="100%"
        position="static"
      >
        <Grid gap={!!switcher ? 'space8' : 'space16'} paddingX="space8">
          <ProcessSidebarHeader
            documentId={documentId}
            documentStatus={documentStatus}
            globalDocumentId={globalDocumentId}
            documentType={documentType ?? DocumentType.Invoice}
            isSensitiveDocument={isSensitiveDocument ?? false}
          />
          {switcher && <Box>{switcher}</Box>}
        </Grid>

        <Box position="static">{children}</Box>

        {actions && (
          <Box
            alignSelf="end"
            bottom="0"
            position="sticky"
            zIndex="2"
            ref={cardRef}
          >
            {actions}
          </Box>
        )}
      </Grid>
    </ScrollBox>
  );
};

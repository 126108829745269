import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { compiledRoutes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { createSearchParams } from 'react-router-dom-v5-compat';
import { renderIcon } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/OrganizationDetails';
import { REIMBURSEMENT_TABLE_FILTERS } from 'views/Reimbursement/utils/useMapToReimbursementsFilterInput';
import { useOrganizationReimbursementInsightsData } from './useOrganizationReimbursementInsightsData';

interface ReimbursementTask {
  total: number;
  path: string;
  icon: JSX.Element;
}

interface ReimbursementTasks {
  inbox: ReimbursementTask;
  monitoring: ReimbursementTask;
  approving: ReimbursementTask;
  exporting: ReimbursementTask;
}

const EMPTY_PATH = '';

export const useOrganizationReimbursementTasks = () => {
  const orgId = useOrganizationId();

  const {
    toApproveTotal,
    toReviewTotal,
    toCheckTotal,
    toExportTotal,
    reimbursementMembershipId,
  } = useOrganizationReimbursementInsightsData();

  const inboxTaskParams = createSearchParams({
    [REIMBURSEMENT_TABLE_FILTERS.status]: [
      ReimbursementCaseStatus.Check,
      ReimbursementCaseStatus.Review,
    ],
  });

  const ReimbursementIcon = renderIcon('menuTravel');

  const reimbursementTasks: ReimbursementTasks = {
    inbox: {
      total: toCheckTotal + toReviewTotal,
      icon: ReimbursementIcon,
      path: `/${orgId}${
        compiledRoutes.inboxReimbursementsRoute
      }?${inboxTaskParams.toString()}`,
    },
    //for these paths, we don't have a specific route yet. To be updated in the future.
    monitoring: {
      total: toApproveTotal,
      icon: ReimbursementIcon,
      path: EMPTY_PATH,
    },
    approving: {
      total: toApproveTotal,
      icon: ReimbursementIcon,
      path: EMPTY_PATH,
    },
    exporting: {
      total: toExportTotal,
      icon: ReimbursementIcon,
      path: EMPTY_PATH,
    },
  };

  return { reimbursementTasks, reimbursementMembershipId };
};

import { ScrollBox, Separator } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { useSap } from 'orgConfig/sap';
import { ReactNode } from 'react';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchIndicator } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchIndicator';
import { AddNewPurchaseOrderButton } from '../PurchaseOrderSection/LinkingModal/AddNewPurchaseOrderButton';
import { useGetMatchStateStyles } from './hooks/useGetMatchStateStyles';

interface ThreeWayMatchIndicatorProps {
  children: ReactNode;
}

export function ThreeWayMatchWrapper({
  children,
}: ThreeWayMatchIndicatorProps) {
  const {
    newData,
    documentId,
    contactName,
    documentAmount,
    accountsPayableNumber,
    isPurchaseOrderEditable,
  } = useThreeWayMatchContext();
  const { shouldUseSapGoodsReceipts } = useSap();
  const variant = getThreeWayMatchGeneralState(newData);
  const getMatchingStyles = useGetMatchStateStyles();
  const { leftSectionColor } = getMatchingStyles(variant);

  return (
    <CollapsibleCard
      data-cy="three-way-match-wrapper"
      id="three-way-match-wrapper"
      borderRadius="medium"
      background={leftSectionColor}
      header={<ThreeWayMatchIndicator />}
      headerProps={{
        background: leftSectionColor,
        hoverColor: leftSectionColor,
      }}
      defaultOpen
    >
      <ScrollBox
        padding="0"
        borderRadius={variant === 'default' ? 'none' : 'medium'}
        scrollDirection="y"
        maxHeight="400px"
        background="gray0"
        style={
          variant !== 'default'
            ? {
                margin: '0 .25rem',
              }
            : {}
        }
        boxShadow="noShadow"
      >
        {children}
      </ScrollBox>
      {variant === 'default' && shouldUseSapGoodsReceipts && (
        <Separator color="gray200" />
      )}
      {(variant !== 'default' || shouldUseSapGoodsReceipts) && (
        <AddNewPurchaseOrderButton
          documentId={documentId ?? ''}
          contactName={contactName}
          documentAmount={documentAmount}
          accountsPayableNumber={accountsPayableNumber}
          disabled={!isPurchaseOrderEditable}
          variant={variant}
        />
      )}
    </CollapsibleCard>
  );
}

import {
  Box,
  Button,
  Grid,
  Icon,
  MenuItem,
  Modal,
  Paragraph,
  ScrollBox,
  Tag,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { AttachmentsTableContainer } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTable';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { Filename } from 'components/Filename/Filename';
import { Attachment } from 'hooks/useAttachments/useAttachments';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import {
  ComponentProps,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';
import { ActionConfirm } from './ActionConfirm';
import { ActionsMenu } from './ActionsMenu';
import { useDeleteAction } from './hooks/useDeleteAction';
import { useDetachAction } from './hooks/useDetachAction';
import { useFilesSelected } from './useFilesSelected';

export type AttachmentsSegmentProps = {
  attachments: Attachment[];
  selectedPdf: PDFDetails;
  documentFile: PDFDetails;
  documentId: string;
  attachPermissions: CanAddAttachments;
  onAttachmentClick: (..._args: any) => void;
};

export const AttachmentsSegment = ({
  attachments,
  selectedPdf,
  documentId,
  documentFile,
  attachPermissions,
  onAttachmentClick,
}: AttachmentsSegmentProps) => {
  const [t] = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const { onAttachFiles, isAttachingFiles } = useFilesSelected(
    attachments,
    documentFile,
    documentId
  );

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;

  const canOnlyAttachFromDisk =
    attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const isAttachActionDisabled =
    !attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onUploadFileClick = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = () => {
    closeModal();
    onUploadFileClick();
  };

  const onFilesChanged = () => {
    if (!fileUploadRef.current?.files) return;

    onAttachFiles(Array.from(fileUploadRef.current.files));
    fileUploadRef.current.value = '';
  };

  const canAttachFromCandis = !canOnlyAttachFromDisk;

  const isEmpty = attachments.length === 0;

  return (
    <ScrollBox scrollDirection="y" scrollbarGutter="stable" paddingX="space8">
      <Grid
        as="ul"
        rowGap="space8"
        listStyle="none"
        alignContent="start"
        justifyItems="stretch"
        borderRadius="medium"
        height="100%"
        padding="0"
        {...(isEmpty && {
          padding: 'space16 space12',
          background: 'gray50',
        })}
      >
        {isEmpty && <AttachmentsEmptyState />}
        {attachments.map(attachment => (
          <AttachmentItem
            key={attachment.id}
            borderRadius="medium"
            paddingY="space8"
            paddingX="space20 space12"
            isActive={selectedPdf.id === attachment.id}
            attachment={attachment}
            onAction={onAttachmentClick}
          />
        ))}
        <Grid
          position="sticky"
          bottom="0"
          paddingTop="space16"
          justifyItems="stretch"
          gap="space8"
        >
          <Button
            onClick={onUploadFileClick}
            loading={isAttachingFiles}
            disabled={isAttachActionDisabled}
            label={disabledLabel}
            variant="primary"
            color="blue"
            icon="plus"
          >
            <TruncatedText>
              {t('document.attachments.attachPdfFromDisk')}
            </TruncatedText>
          </Button>
          {canAttachFromCandis && (
            <Button
              onClick={openModal}
              loading={isAttachingFiles}
              disabled={isAttachActionDisabled}
              label={disabledLabel}
              variant="secondary"
              color="blue"
              icon="plus"
            >
              <TruncatedText>
                {t('document.attachments.attachPdfFromCandis')}
              </TruncatedText>
            </Button>
          )}
        </Grid>
      </Grid>

      <input
        type="file"
        accept="application/pdf"
        ref={fileUploadRef}
        onChange={onFilesChanged}
        disabled={!canAttach}
        multiple
        hidden
      />

      <Modal
        background="gray200"
        closeLabel={t('common.close')}
        width="80vw"
        isOpen={isModalOpen}
        onClose={closeModal}
        padding="0"
        scrollDirection="none"
        title={t('document.attachments.attachPdfFromCandis')}
      >
        <AttachmentsTableContainer
          documentId={documentId}
          onDocumentsAttached={closeModal}
          onUploadFileClick={handleFileUpload}
        />
      </Modal>
    </ScrollBox>
  );
};
const AttachmentsEmptyState = () => {
  const [t] = useTranslation();
  return (
    <>
      <Grid gap="space16" paddingBottom="space16" justifySelf="start">
        <Tag color="blue" callout>
          {t('tag.new')}
        </Tag>
        <Paragraph fontSize="basic" width="11rem">
          {t('document.attachments.newViewInfo')}
        </Paragraph>
      </Grid>
      <LoadingAttachment />
      <LoadingAttachment />
    </>
  );
};
const LoadingAttachment = () => (
  <AttachmentItemWrapper
    background="gray200"
    borderRadius="medium"
    paddingY="space16"
    paddingLeft="space20"
    paddingRight="space12"
  >
    <AttachmentItemLayout>
      <AttachmentIcon />
      <Box
        background="gray300"
        borderRadius="full"
        height="space16"
        width="space128"
      />
      <Box justifySelf="end" paddingRight="space12">
        <Box
          background="gray300"
          borderRadius="full"
          height="space16"
          width="space12"
        />
      </Box>
    </AttachmentItemLayout>
  </AttachmentItemWrapper>
);

const AttachmentIcon = memo(() => (
  <Icon icon="attachment" size="space16" color="gray500" minWidth="space16" />
));

const translationMap = {
  delete: 'document.compact.history.documentAttachment.remove.warning',
  detach: 'document.compact.history.documentAttachment.detach.warning',
  '': '',
} as const;

type allowedState = 'delete' | 'detach' | '';

export const AttachmentItemWrapper = ({
  children,
  isActive,
  onPointerDown,
  background = 'gray50',
  ...rest
}: ComponentProps<typeof Box>) => (
  <Box
    as="li"
    role="row"
    width="100%"
    paddingY="space5"
    paddingLeft="space16"
    paddingRight="space8"
    background={isActive ? 'gray250' : background}
    {...(onPointerDown && {
      onPointerDown,
      cursor: 'pointer',
      hover: { background: 'gray250' },
    })}
    {...rest}
  >
    {children}
  </Box>
);

export const AttachmentItemLayout = ({
  children,
}: ComponentProps<typeof Grid>) => (
  <Grid
    role="gridcell"
    gap="space4"
    autoFlow="column"
    alignItems="center"
    templateColumns="auto auto 1fr"
  >
    {children}
  </Grid>
);

export const AttachmentItem = ({
  isActive = false,
  attachment,
  onAction,
  ...rest
}: ComponentProps<typeof AttachmentItemWrapper> & {
  isActive?: boolean;
  attachment: Attachment;
  onAction: (attachment: any) => void;
}) => {
  const [t] = useTranslation();

  const [isDetachPending, setIsDetachPending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const [openedConfirm, setOpenedConfirm] = useState<allowedState>('');

  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const handleDetach = useDetachAction(attachment, setIsDetachPending);
  const handleDelete = useDeleteAction(attachment, setIsDeletePending);

  const actionsMap = useMemo(
    () => ({
      delete: handleDelete,
      detach: handleDetach,
      '': Promise.resolve,
    }),
    [handleDelete, handleDetach]
  );

  const handleClick = () => onAction(attachment);

  const menuItems: MenuItem[] = [
    attachment.isRemovable && {
      id: 'remove',
      label: t('document.tabs.attachments.deleteAttachment'),
      onAction: () => {
        setOpenedConfirm('delete');
        openPopover();
      },
    },
    attachment.isDetachable && {
      id: 'detach',
      label: t('document.tabs.attachments.detachAttachment'),
      onAction: () => {
        setOpenedConfirm('detach');
        openPopover();
      },
    },
  ].filter(Boolean) as MenuItem[];

  const warningText = t(translationMap[openedConfirm]);

  const actionMenuLabel = t('documentContextMenu.openMenu');
  const isActionPending = isDetachPending || isDeletePending;

  return (
    <>
      <AttachmentItemWrapper
        onPointerDown={handleClick}
        isActive={isActive}
        aria-describedby={attachment.id + '-item'}
        {...rest}
      >
        <AttachmentItemLayout>
          {/* // TODO: Replace with PdfThumbnailViewer */}
          <AttachmentIcon />
          <Filename
            fontSize="small"
            color="gray800"
            fontWeight="semibold"
            id={attachment.id + '-item'}
          >
            {attachment.name}
          </Filename>
          <Box justifySelf="end" paddingRight="space2">
            <ActionsMenu
              label={actionMenuLabel}
              isActionPending={isActionPending}
              items={menuItems}
              ref={triggerRef}
              size="small"
            />
          </Box>
        </AttachmentItemLayout>
      </AttachmentItemWrapper>
      <ActionConfirm
        ref={popoverRef}
        informationText={warningText}
        popoverProps={popoverProps}
        isActionPending={isActionPending}
        closePopover={closePopover}
        handleConfirm={actionsMap[openedConfirm]}
      />
    </>
  );
};

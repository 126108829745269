import {
  Box,
  Button,
  Card,
  Grid,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { recurringPaymentsId } from 'views/CreditCards/CreditCardsInsights/RecurringPayments/RecurringPaymentsWidget';

interface RecurringPaymentsProps {
  loading: boolean;
  convertedVendors?: number;
  vendorsToConvert?: number;
  isCreditCardsInUse: boolean;
}

export const RecurringPayments = ({
  convertedVendors,
  vendorsToConvert,
  loading,
  isCreditCardsInUse,
}: RecurringPaymentsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const orgId = useOrganizationId();
  const totalVendors = (convertedVendors ?? 0) + (vendorsToConvert ?? 0);

  const recurringPaymentsLink = `/${orgId}${Routes.CREDIT_CARDS_DASHBOARD}#${recurringPaymentsId}`;

  if (loading) {
    return (
      <Card borderTopRadius="none" background="gray100">
        <Grid gap="space12">
          <Skeleton height="space16" width="space32" />
          <Skeleton width="100%" height="space16" />
        </Grid>
      </Card>
    );
  }

  if (vendorsToConvert === 0 || !isCreditCardsInUse) {
    return null;
  }

  return (
    <Card borderTopRadius="none" background="gray100" paddingY="space8">
      <Grid gap="space4">
        <Text fontWeight="semibold" fontSize="basic">
          {t('recurringPayments.title')}
        </Text>

        <Grid gap="space16" alignItems="center" templateColumns="1fr auto">
          <Grid
            alignItems="center"
            gap="space8"
            templateColumns="auto 1fr auto"
          >
            <Text fontSize="basic">{t('recurringPayments.progress')}</Text>
            <Box
              background="gray250"
              borderRadius="medium"
              width="100%"
              height="space8"
            >
              <Box
                maxWidth="100%"
                borderRadius="medium"
                height="100%"
                width={`${((convertedVendors ?? 0) / totalVendors) * 100}%`}
                background="blue300"
              />
            </Box>
            <Text fontSize="basic">
              {t('recurringPayments.progression', {
                convertedVendors: convertedVendors ?? 0,
                totalVendors,
              })}
            </Text>
          </Grid>
          <Button
            as="a"
            variant="tertiary"
            size="small"
            href={recurringPaymentsLink}
          >
            {t('recurringPayments.buttonLink')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

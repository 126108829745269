import {
  Badge,
  Box,
  Button,
  Popover,
  Skeleton,
  usePopover,
} from '@candisio/design-system';
import { NotificationSummaryContainer } from 'containers/notifications/NotificationSummaryContainer';
import { useInAppNotificationsCountData } from 'containers/notifications/hooks/useInAppNotificationsCountData';

export const EventNotifications = () => {
  const {
    open,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    close,
  } = usePopover({
    placement: 'top',
  });

  const { count, loadingCount } = useInAppNotificationsCountData();

  if (loadingCount && !isOpen) {
    return <Skeleton height="2rem" width="2rem" />;
  }

  const hasUnreadNotifications = count > 0;

  const handleOpen = () => {
    open();
  };

  return (
    <Box>
      <Box>
        <Button
          variant="tertiary"
          onClick={handleOpen}
          {...triggerProps}
          ref={triggerRef}
          opacity={hasUnreadNotifications ? 1 : 0.5}
          style={{ rotate: hasUnreadNotifications ? '-15deg' : 'none' }}
          icon={hasUnreadNotifications ? 'megaphoneFilled' : 'megaphone'}
        />
        {hasUnreadNotifications && (
          <Box position="absolute" top={0} left={28}>
            <Badge aria-label="Inbox-menu-item-count" color="darkGray">
              {count}
            </Badge>
          </Box>
        )}
      </Box>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="500px"
          overflow="hidden"
          padding="0"
          height="65%"
        >
          <NotificationSummaryContainer onClose={close} />
        </Popover>
      )}
    </Box>
  );
};

import { Paragraph } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useDocumentAccessLevelsData } from './toolkit/hooks/useDocumentAccessLevelsData';
import { useTranslation } from 'react-i18next';

interface AccessByRoleCardContainerProps {
  globalDocumentId?: string;
}

export const ViewersAccessCardContainer = ({
  globalDocumentId,
}: AccessByRoleCardContainerProps) => {
  const { accessByViewersData, loading } = useDocumentAccessLevelsData({
    globalDocumentId,
  });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessByViewersData}
      loading={loading}
      description={
        <Paragraph fontWeight="400" color="gray600" whiteSpace="pre-wrap">
          {t('common:documentAccess.membership.access.viewers.description')}
        </Paragraph>
      }
    />
  );
};

import {
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isEmpty, isNil } from 'lodash';
import { ExpenseSchema } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { GeneralExpense } from 'views/Reimbursement/utils/mapToGeneralExpense';
import { HospitalityExpense } from 'views/Reimbursement/utils/mapToHospitalityExpense';

const toDefaultValues = (
  item: HospitalityExpense | GeneralExpense
): ExpenseSchema => {
  const converter = getDateConverted();

  if (item.type === ReimbursementItemType.HospitalityExpense) {
    return {
      expenseId: item.id,
      reason: item.reason ?? '',
      expenseType: 'hospitality',
      itemStatus: item.status,
      invoiceNumber: item.invoiceNumber ?? '',
      isExtractedDataAccepted: item.isExtractedDataAccepted,
      isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
      expenseDate: item.expenseDate
        ? converter.dateTimeStringToDateString(item.expenseDate)
        : '',
      totalAmount: !isNil(item.totalAmount) ? item.totalAmount : null,
      receiptAmount:
        'receiptAmount' in item && !isNil(item.receiptAmount)
          ? item.receiptAmount
          : null,
      location: 'location' in item && item.location ? item.location : '',
      tipAmount:
        'tipAmount' in item && !isNil(item.tipAmount) ? item.tipAmount : null,
      internalGuests:
        'internalGuests' in item && item.internalGuests
          ? item.internalGuests
          : null,
      externalGuests:
        'externalGuests' in item && item.externalGuests
          ? item.externalGuests
          : null,
      files:
        item.files?.map(file => ({
          id: file.id ?? '',
          name: file.name ?? '',
          url: file.url ?? '',
        })) ?? [],
      extractedData: !isEmpty(item.extractedData)
        ? {
            invoiceNumber: item.extractedData?.invoiceNumber ?? '',
            location:
              'location' in item.extractedData
                ? (item.extractedData.location ?? '')
                : '',
            expenseDate: item.extractedData?.expenseDate
              ? converter.dateTimeStringToDateString(
                  item.extractedData.expenseDate
                )
              : '',
            receiptAmount:
              'receiptAmount' in item.extractedData
                ? item.extractedData.receiptAmount || null
                : null,
            tipAmount:
              'tipAmount' in item.extractedData
                ? item.extractedData.tipAmount || null
                : null,
          }
        : undefined,
    };
  }

  return {
    expenseId: item.id,
    reason: item.reason ?? '',
    expenseType: 'general',
    itemStatus: item.status,
    isExtractedDataAccepted: item.isExtractedDataAccepted,
    isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
    totalAmount: item.totalAmount ?? null,
    invoiceNumber: item.invoiceNumber ?? '',
    expenseDate: item.expenseDate
      ? converter.dateTimeStringToDateString(item.expenseDate)
      : '',
    files: (item.files ?? []).map(file => ({
      id: file.id ?? '',
      name: file.name ?? '',
      url: file.url ?? '',
    })),
    extractedData: !isEmpty(item.extractedData)
      ? {
          invoiceNumber: item.extractedData?.invoiceNumber ?? '',
          expenseDate: item.extractedData?.expenseDate
            ? converter.dateTimeStringToDateString(
                item.extractedData.expenseDate
              )
            : '',
          totalAmount:
            'totalAmount' in item.extractedData
              ? item.extractedData.totalAmount || null
              : null,
        }
      : undefined,
  };
};

interface UseExpensesFormDefaultValuesParams {
  expenses: (GeneralExpense | HospitalityExpense)[];
}

export const useExpensesFormDefaultValues = ({
  expenses,
}: UseExpensesFormDefaultValuesParams) => {
  const defaultValues = {
    expenses: expenses.map(toDefaultValues),
  };

  return defaultValues;
};

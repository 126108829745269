import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Paragraph,
  ScrollBox,
  Tag,
  TruncatedText,
} from '@candisio/design-system';
import { Routes } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { DocumentRelationItem } from './DocumentRelations';
import {
  DocumentSummaryCardContainerImproved,
  ErrorDocumentSummaryCardList,
  HelpCenterLink,
} from './DocumentSummaryCard';
import { useDocumentRelations } from './hooks/useDocumentRelations';

type DocumentRelationsDrawerProps = {
  globalDocumentId: string;
  readOnly?: boolean;
};

export const DocumentRelationsSegment = ({
  globalDocumentId,
  readOnly = false,
}: DocumentRelationsDrawerProps) => {
  const [t] = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const goToManageRelationships = useCallback(() => {
    navigate(location.pathname + Routes.RELATIONSHIPS);
  }, [location.pathname, navigate]);

  const {
    isLoading: isLoadingRelations,
    isEmpty: hasNoRelations,
    hasError,
    relatedDocuments,
    isLinkingDocumentsPending,
    isUnlinkingDocumentsPending,
    handleUnlinkDocuments,
  } = useDocumentRelations(globalDocumentId);

  const isEmpty = hasNoRelations;
  const isLoading = isLoadingRelations;

  const showError = !isLoading && hasError;
  const showEmptyList = !isLoading && !hasError && isEmpty;
  const showLoadingItem = isLoadingRelations || isLinkingDocumentsPending;

  return (
    <ScrollBox scrollDirection="y" scrollbarGutter="stable" paddingX="space8">
      <Grid
        as="ul"
        rowGap="space8"
        listStyle="none"
        alignContent="start"
        justifyItems="stretch"
        borderRadius="medium"
        height="100%"
        padding="0"
        {...(showEmptyList && {
          padding: 'space16 space12',
          background: 'gray50',
        })}
      >
        {showError && <ErrorDocumentSummaryCardList key="error-item" />}
        {showEmptyList && <EmptyRelationshipsList key="empty-item" />}
        {relatedDocuments.map(relatedDocument => (
          <DocumentRelationItem
            readOnly={readOnly}
            key={relatedDocument.relationId}
            documentName={relatedDocument.name ?? ''}
            handleUnlinkDocuments={handleUnlinkDocuments}
            isUnlinkingDocumentsPending={isUnlinkingDocumentsPending}
            {...relatedDocument}
          />
        ))}
        {showLoadingItem && <LoadingRelatonshipItem />}
        <Grid
          position="sticky"
          bottom="0"
          paddingTop="space16"
          justifyItems="stretch"
          gap="space8"
          background={
            showEmptyList
              ? undefined
              : 'linear-gradient(to top, gray200 80%, transparent 95% 100%)'
          }
        >
          <Button
            disabled={readOnly}
            onClick={goToManageRelationships}
            variant={showEmptyList ? 'primary' : 'secondary'}
            color="blue"
            icon="plus"
          >
            <TruncatedText>
              {t('document.tabs.relationships.manageRelationships')}
            </TruncatedText>
          </Button>
          {showEmptyList && <HelpCenterLink />}
        </Grid>
      </Grid>
    </ScrollBox>
  );
};

const EmptyRelationshipsList = () => {
  const [t] = useTranslation();
  return (
    <>
      <Grid gap="space16" paddingBottom="space16" justifySelf="start">
        <Tag color="blue" callout>
          {t('tag.new')}
        </Tag>
        <Paragraph fontSize="basic" width="12rem">
          {t('document.tabs.relationships.newViewInfo')}
        </Paragraph>
      </Grid>
      <LoadingRelatonshipItem />
      <LoadingRelatonshipItem />
    </>
  );
};

const LoadingRelatonshipItem = () => (
  <DocumentSummaryCardContainerImproved background="gray200" width="100%">
    <Grid
      padding="0"
      autoColumns="4rem 1fr"
      gap="space8"
      autoFlow="column"
      color="gray500"
    >
      <Grid
        background="gray300"
        border="1px soldi gray250"
        borderRadius="small"
        justifyContent="center"
        alignItems="center"
        style={{ aspectRatio: Math.SQRT1_2 + '' }}
      >
        <Flex borderRadius="full" background="gray200" padding="space8">
          <Icon icon="search" size="space16" color="gray800" />
        </Flex>
      </Grid>
      <Grid alignContent="space-between">
        <Grid gap="space8">
          <Box
            width="space96"
            height="space12"
            background="gray400"
            borderRadius="full"
          />
          <Box
            width="space128"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
          <Box
            width="space80"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
        </Grid>
        <Grid alignSelf="end" justifyContent="space-between" autoFlow="column">
          <Box
            width="space64"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
          <Box
            width="space32"
            height="space12"
            background="gray400"
            borderRadius="full"
          />
        </Grid>
      </Grid>
    </Grid>
  </DocumentSummaryCardContainerImproved>
);

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { DocumentTypesInfoPanel } from './components/DocumentTypesInfoPanel';
import { DocumentTypesList } from './components/DocumentTypesList';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Box } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useGetCustomPromotionDetails } from 'components/ProductPromotions/hooks/useGetCustomPromotionDetails';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';

export const DocumentTypes = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { showEcm } = useEcm();

  const documentTypesSettingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentTypesSetting
  );

  const { documentManagementPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'documentManagement',
    DOCUMENT_MANAGEMENT_UTM_SOURCE.SETTINGS_TAGS
  );

  const showDocumentTypes = showEcm && documentTypesSettingFF;

  return (
    <SettingsLayout
      title={t('documentTypes.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      {showDocumentTypes ? (
        <DocumentTypesList />
      ) : (
        <Box height="24rem">
          <PromotionsProvider promotions={[documentManagementPromotion]}>
            <PromotionCard
              promotion={documentManagementPromotion}
              closable={false}
              customPromotionDetails={customPromotionDetails}
            />
          </PromotionsProvider>
        </Box>
      )}
      <DocumentTypesInfoPanel />
    </SettingsLayout>
  );
};

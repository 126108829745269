import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridProps,
  Icon,
  Image,
  InfoPanel,
  Link,
  ScrollBox,
  Skeleton,
  Text,
  Tooltip,
  TruncatedText,
  defaultTheme,
  useTooltip,
  type BoxProps,
} from '@candisio/design-system';
import emptyStateImage from 'assets/dms/document-relations-empty-state.png';
import promoImage from 'assets/dms/document-relations-promo.png';
import { ClockIcon } from 'components/Icons/DefaultIcons';
import { StatusTag } from 'components/StatusTag';
import { DocumentThumbnailContainer } from 'containers/DocumentPreview/DocumentThumbnailContainer';
import {
  DocumentStatus,
  DocumentType,
  EcmDocumentContractStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, Fragment, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { DateFormatters } from 'utils/date_formatter';
import { amountFormat } from 'utils/format';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { CurrentDocumentIndicator } from './CurrentDocumentIndicator';
import { useEcmContractTypeOptions } from './Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from './Ecm/useEcmDocumentTypeItems';
import { Person, RelatedDocument } from './hooks/useDocumentRelations';
import { DocumentInfoTag, FallbackStatusTag } from './utils';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { getContractStatus } from 'components/EcmDocumentsTable/hooks/useEcmContractsData';

const StyledGrid = styled(Grid)`
  list-style: none;
  padding-inline-start: 0;
  margin: 0;

  display: grid;

  /* each immediate child that has a sibling */
  /*
    parent
    -x  child1
    -x    grandchild
    ->  child2
    -x    grandchild
    ->  child3
    -x    grandchild
  */
  > * + * {
    border-block-start: 1px solid ${defaultTheme.colors.gray200};
  }
`;

export interface DocumentSummaryCardProps extends ComponentProps<typeof Grid> {
  document: RelatedDocument;
  onAction?: () => void;
  as?: keyof JSX.IntrinsicElements;
  action?: ReactNode;
}

export const LoadingDocumentSummaryCard = (
  props: Partial<Omit<DocumentSummaryCardProps, 'as'>>
) => (
  <DocumentSummaryCardContainer {...props}>
    <Flex alignItems="center" justifyContent="start" paddingTop="space5">
      <Skeleton height="space24" width="25%" />
    </Flex>
    <Flex
      direction="column"
      paddingTop="space4"
      rowGap="space5"
      borderBottom="1px solid transparent"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Skeleton height="space24" width="65%" />
        <Skeleton height="space24" width="15%" />
      </Flex>
      <Flex
        wrap="wrap"
        columnGap="space16"
        alignItems="start"
        justifyContent="space-between"
      >
        <Skeleton flex="3 2 10rem" height="space16" width="100%" />
        <Skeleton flex="1 0 3rem" height="space16" width="100%" />
      </Flex>
      <Flex alignItems="start" justifyContent="start">
        <Skeleton flex="0 0 10rem" height="space14" width="80%" />
      </Flex>
    </Flex>
  </DocumentSummaryCardContainer>
);

type FallbackTranslations = {
  documentNumber: string;
  date: string;
};

// Uses LOCALE_NAME_SPACE.ECM
export const fallbacksTransaltionMap: Record<
  DocumentType,
  FallbackTranslations
> = {
  [DocumentType.Contract]: {
    documentNumber: 'documentRelationship.fallback.contract.documentNumber',
    date: 'documentRelationship.fallback.contract.date',
  },
  [DocumentType.DeliveryNote]: {
    documentNumber:
      'documentRelationship.fallback.delivery_note.documentNumber',
    date: 'documentRelationship.fallback.delivery_note.date',
  },
  [DocumentType.Invoice]: {
    documentNumber: 'documentRelationship.fallback.invoice.documentNumber',
    date: 'documentRelationship.fallback.invoice.date',
  },
  [DocumentType.Offer]: {
    documentNumber: 'documentRelationship.fallback.offer.documentNumber',
    date: 'documentRelationship.fallback.offer.date',
  },
  [DocumentType.OrderConfirmation]: {
    documentNumber:
      'documentRelationship.fallback.order_confirmation.documentNumber',
    date: 'documentRelationship.fallback.order_confirmation.date',
  },
  [DocumentType.Other]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.AccountStatement]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.FeeNotice]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.Guarantee]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.Reminder]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.RemittanceAdvise]: {
    documentNumber: 'documentRelationship.fallback.other.documentNumber',
    date: 'documentRelationship.fallback.other.date',
  },
  [DocumentType.PurchaseOrder]: {
    documentNumber:
      'documentRelationship.fallback.purchase_order.documentNumber',
    date: 'documentRelationship.fallback.purchase_order.date',
  },
  [DocumentType.ExpenseReimbursementItem]: {
    documentNumber: '',
    date: '',
  },
  [DocumentType.TravelReimbursementItem]: {
    documentNumber: '',
    date: '',
  },
} as const;

export const DocumentSummaryCardContainer = ({
  onAction,
  background = 'gray0',
  ...rest
}: GridProps & { onAction?: () => void }) => (
  <Grid
    fontSize="basic"
    paddingX="space16"
    paddingY="space14"
    gap="space12"
    background={background}
    {...(onAction && {
      onPointerDown: onAction,
      cursor: 'pointer',
      hover: { background: 'gray100' },
    })}
    {...rest}
  />
);

export const DocumentSummaryCard = ({
  as = 'li',
  document,
  isLoading = false,
  action,
  ...rest
}: DocumentSummaryCardProps & {
  isLoading?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const {
    amount,
    createdBy,
    date,
    isOverdue,
    isSensitive,
    isEInvoice,
    eInvoice,
    name,
    notifyPerson,
    number,
    responsiblePerson,
    status,
    type,
    id,
    contractTypeId,
  } = document;

  const isContract = type === DocumentType.Contract;

  const usersWithAccess = useMemo(() => {
    return Array(createdBy)
      .concat(notifyPerson, responsiblePerson)
      .filter(Boolean);
  }, [createdBy, notifyPerson, responsiblePerson]);

  if (isLoading)
    return (
      <Grid as={as} key={id}>
        <LoadingDocumentSummaryCard {...rest} />
      </Grid>
    );

  const fallbackTranslation =
    type in fallbacksTransaltionMap
      ? fallbacksTransaltionMap[type]
      : fallbacksTransaltionMap[DocumentType.Other];

  const documentField: string = number ?? t(fallbackTranslation.documentNumber);

  const dateField = date
    ? DateFormatters.compact(new Date(date))
    : t(fallbackTranslation.date);

  const amountField = amount
    ? amountFormat(amount?.amount, amount?.currency, {
        convertToBasicMonetaryUnit:
          type !== DocumentType.Invoice && amount.precision === 2,
      })
    : t('documentRelationship.fallback.amount');

  const nameField: string =
    name ?? t('documentRelationship.fallback.contactName');

  const documentInfo =
    type === DocumentType.Contract && contractTypeId
      ? getContractTypeLabel(contractTypeId)
      : ecmDocumentTypeTranslationMap[type];

  return (
    <Grid as={as} borderRadius="inherit">
      <DocumentSummaryCardContainer {...rest}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{ marginInlineEnd: '-.5rem' }}
        >
          <Flex gap="space4">
            <Flex gap="space4" alignItems="center">
              <DocumentInfoTag>{documentInfo}</DocumentInfoTag>
              <EInvoiceInfoIcon
                isEInvoice={isEInvoice}
                eInvoice={eInvoice}
                showWarningDescription={true}
              />
            </Flex>
            {isContract && (
              <ContractInfo
                isSensitive={isSensitive}
                users={usersWithAccess as UserWithAccessInfoProps['users']}
              />
            )}
          </Flex>
          {action}
        </Flex>
        <Flex direction="column" rowGap="space4">
          <Flex
            columnGap="space12"
            alignItems="center"
            justifyContent="space-between"
          >
            <TruncatedText
              charsAfterEllipsis={5}
              flex="0 2 14rem"
              maxWidth="14rem"
              fontWeight="500"
            >
              {documentField}
            </TruncatedText>
            <Flex alignItems="center">
              {isOverdue && <OverdueIcon />}
              {status ? <StatusTag status={status} /> : <FallbackStatusTag />}
            </Flex>
          </Flex>
          <Flex
            color="gray500"
            columnGap="space16"
            alignItems="start"
            justifyContent="space-between"
          >
            <Text>{dateField}</Text>
            <Text flex="0 0 auto">{amountField}</Text>
          </Flex>
          <Flex color="gray500" columnGap="space16" alignItems="start">
            <TruncatedText charsAfterEllipsis={5}>{nameField}</TruncatedText>
          </Flex>
        </Flex>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

const importantStatuses = Array<
  DocumentStatus | EcmDocumentStatus | EcmDocumentContractStatus
>(
  DocumentStatus.New,
  DocumentStatus.Open,
  DocumentStatus.Rejected,
  EcmDocumentStatus.New,
  EcmDocumentStatus.Open,
  EcmDocumentStatus.Processing,
  EcmDocumentContractStatus.Active,
  EcmDocumentContractStatus.Inactive
);

export const DocumentSummaryCardContainerImproved = ({
  onAction,
  isSelected = false,
  background = 'gray0',
  ...rest
}: GridProps & { onAction?: () => void; isSelected?: boolean }) => (
  <Grid
    paddingX="space10"
    paddingY="space10"
    gap="space8"
    background={background}
    borderRadius="medium"
    {...(onAction && {
      onPointerDown: onAction,
      cursor: 'pointer',
      hover: { boxShadow: 'elevatedShadow2' },
    })}
    {...(isSelected && { boxShadow: 'elevatedShadow2' })}
    {...rest}
  />
);

export const DocumentSummaryCardImproved = ({
  as = 'li',
  document,
  isLoading = false,
  action: unlink,
  onPreviewClick,
  ...rest
}: DocumentSummaryCardProps & {
  onPreviewClick?: () => void;
  isLoading?: boolean;
}) => {
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const { isComparisonView, params } = useComparisonView();

  const {
    id,
    type,
    status,
    name,
    number,
    date,
    amount,
    isSensitive,
    createdBy,
    notifyPerson,
    contractTypeId,
    responsiblePerson,
    notes,
    documentFileId,
    eInvoice,
    isEInvoice,
    isOverdue,
    endDate,
    startDate,
    terminationReminderDate,
  } = document;

  const isContract = type === DocumentType.Contract;

  let contractStatus;
  if (isContract) {
    contractStatus = getContractStatus({
      isSensitive: Boolean(isSensitive),
      terminationReminderDate: terminationReminderDate
        ? new Date(terminationReminderDate)
        : undefined,
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
    });
  }

  // @ts-expect-error - filter(Boolean) is not yet working as expected in TS@5.3.3
  const usersWithAccess: Person[] = useMemo(() => {
    return Array<Person | undefined>()
      .concat(createdBy, notifyPerson, responsiblePerson)
      .filter(Boolean);
  }, [createdBy, notifyPerson, responsiblePerson]);

  const showStatus = !!status && importantStatuses.includes(status);

  const documentInfo =
    isContract && contractTypeId
      ? getContractTypeLabel(contractTypeId)
      : ecmDocumentTypeTranslationMap[type];

  const dateField = date && DateFormatters.compact(new Date(date));

  const amountField =
    amount &&
    amountFormat(amount?.amount, amount?.currency, {
      convertToBasicMonetaryUnit:
        type !== DocumentType.Invoice && amount.precision === 2,
    });

  const isSelected =
    isComparisonView && params.currentFileId === documentFileId;

  return (
    <DocumentSummaryCardContainerImproved {...rest} isSelected={isSelected}>
      <CurrentDocumentIndicator isCurrentDocument={isSelected} />
      <Grid
        padding="0"
        autoColumns="4rem 1fr"
        gap="space8"
        autoFlow="column"
        color="gray500"
        fontSize="small"
      >
        <DocumentThumbnailContainer
          documentId={id}
          handleClick={onPreviewClick}
        />
        <Grid height="inherit" templateRows="auto 1fr auto">
          <Grid
            autoFlow="column"
            alignItems="center"
            templateColumns="auto auto"
            templateRows="auto auto"
            justifyContent="space-between"
          >
            {showStatus && (
              <Box>
                {status && (
                  <StatusTag
                    status={contractStatus ? contractStatus.status : status}
                    size="xsmall"
                  />
                )}
              </Box>
            )}

            <Flex
              gridColumn="2"
              justifyContent="end"
              gap="space4"
              // cursed: have to add negative margin in order to align with type and status
              style={{ marginTop: '-4px' }}
            >
              {isContract && (
                <Box position="relative" right="0" top="0">
                  <ContractInfo
                    small
                    users={usersWithAccess}
                    isSensitive={isSensitive}
                  />
                </Box>
              )}
              <Box>{unlink}</Box>
            </Flex>

            <Flex
              gridRow={showStatus ? '2' : '1'}
              gridColumn="1"
              gap="space2"
              alignItems="center"
            >
              <Text fontWeight="bold" color="gray800" lineHeight="1.4">
                {documentInfo}
              </Text>
              {isEInvoice && (
                <EInvoiceInfoIcon
                  isEInvoice={isEInvoice}
                  eInvoice={eInvoice}
                  showWarningDescription
                />
              )}
              {isOverdue && <OverdueIcon paddingY="0" paddingX="0" />}
            </Flex>
          </Grid>
          <Flex direction="column">
            <TruncatedText lineHeight="1.4" lineClamp={1}>
              {number ?? ''}
            </TruncatedText>
            <TruncatedText lineHeight="1.4" lineClamp={1}>
              {name ?? ''}
            </TruncatedText>
          </Flex>
          <Grid autoFlow="column" justifyContent="space-between">
            <Box lineHeight="1.4">{dateField}</Box>
            <Text fontWeight="bold" color="gray800" lineHeight="1.4">
              {amountField}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      {notes && (
        <InfoPanel size="small">
          <Text color="gray700" fontSize="small" lineHeight="1.4">
            {notes}
          </Text>
        </InfoPanel>
      )}
    </DocumentSummaryCardContainerImproved>
  );
};

export const DocumentSummaryCardListContainer = (props: GridProps) => (
  <Grid rowGap="1px" borderRadius="inherit" height="100%" {...props} />
);

export const DocumentSummaryCardList = ({
  children,
  background = 'gray0',
}: ComponentProps<typeof Grid>) => {
  return (
    <ScrollBox
      scrollDirection="y"
      scrollbarGutter="stable"
      background={background}
    >
      <StyledGrid as="ul" role="grid">
        {children}
      </StyledGrid>
    </ScrollBox>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  white-space: nowrap;

  > svg {
    top: 0;
    bottom: 0;
  }
`;

export const EmptyDocumentSummaryCardList = ({
  canAddTransaction = false,
}: {
  canAddTransaction?: boolean;
}) => {
  const [t] = useTranslation();

  let traslationContext: 'onlyDocuments' | undefined;
  if (!canAddTransaction) traslationContext = 'onlyDocuments';

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer height="150px" padding="space8">
        <Grid
          gap="space16"
          alignItems="center"
          autoFlow="column"
          background="bluebg"
          padding="space16 space20"
          borderRadius="medium"
        >
          <Image
            alt=""
            src={emptyStateImage}
            width="space80"
            height="space80"
          />
          <Grid autoFlow="row">
            <Text textAlign="start" fontSize="basic" color="gray600">
              {t('document.tabs.relationships.emptyList', {
                context: traslationContext,
              })}
            </Text>
            <HelpCenterLink />
          </Grid>
        </Grid>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

const StyledText = styled(Text)`
  @media screen and (width < 1481px) {
    display: none;
  }
`;

export const PromoDocumentSummaryCardList = () => {
  const [t] = useTranslation();
  const { isInUse } = useCreditCardsSetup();

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer height="150px" padding="space8">
        <Grid
          gap="space16"
          alignItems="center"
          autoFlow="column"
          background="purplebg"
          padding="space16 space20"
          borderRadius="medium"
        >
          <Image alt="" src={promoImage} width="space80" height="space80" />
          <Grid autoFlow="row">
            <StyledText fontWeight="semibold">
              {t('document.tabs.relationships.promo.title')}
            </StyledText>
            <Text textAlign="start" fontSize="basic" color="gray600">
              {t('document.tabs.relationships.promo.text', {
                context: isInUse && 'withTransactions',
              })}
            </Text>
          </Grid>
        </Grid>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

export const ErrorDocumentSummaryCardList = () => {
  const [t] = useTranslation();

  return (
    <Grid role="none">
      <DocumentSummaryCardContainer paddingY="space24">
        <Flex rowGap="space16" direction="column" alignItems="center">
          <Icon icon="warning" size="3rem" color="red400" />
          <Text textAlign="center" fontSize="basic">
            {t('document.tabs.relationships.error')}
          </Text>
        </Flex>
      </DocumentSummaryCardContainer>
    </Grid>
  );
};

export type UserWithAccessInfoProps = {
  users: Person[];
};

export const ContractInfo = ({
  isSensitive = false,
  small = false,
  users,
}: UserWithAccessInfoProps & {
  small?: boolean;
  isSensitive?: boolean;
}) =>
  isSensitive ? (
    <SensitiveContractInfo users={users} small={small} />
  ) : (
    <RegularContractInfo small={small} />
  );

const RegularContractInfo = ({ small = false }: { small: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const { isOpen, tooltipProps, tooltipRef, triggerRef, triggerProps } =
    useTooltip();

  if (small)
    return (
      <>
        <Button
          ref={triggerRef}
          {...triggerProps}
          variant="tertiary"
          size="xsmall"
          icon="infoOutline"
          disabled
          style={{ cursor: 'default' }}
        />

        {isOpen && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            <Text>{t('documentRelationship.contract.infoTooltip')}</Text>
          </Tooltip>
        )}
      </>
    );

  return (
    <>
      <DocumentInfoTag
        ref={triggerRef}
        {...triggerProps}
        width="fit-content"
        paddingX="space2"
        paddingY="space2"
        height="space24"
      >
        <Icon icon="infoOutline" color="gray500" size="space20" />
      </DocumentInfoTag>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps} maxWidth="360px">
          <Text>{t('documentRelationship.contract.infoTooltip')}</Text>
        </Tooltip>
      )}
    </>
  );
};

const SensitiveContractInfo = ({
  users,
  small = false,
}: UserWithAccessInfoProps & { small: boolean }) => {
  const { isOpen, tooltipProps, tooltipRef, triggerRef, triggerProps } =
    useTooltip();

  if (small)
    return (
      <>
        <Button
          ref={triggerRef}
          {...triggerProps}
          variant="tertiary"
          size="xsmall"
          icon="lockFilled"
          disabled
          style={{ cursor: 'default' }}
        />

        {isOpen && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            <UserWithAccessInfo users={users} />
          </Tooltip>
        )}
      </>
    );

  return (
    <>
      <DocumentInfoTag
        ref={triggerRef}
        {...triggerProps}
        paddingX="space5"
        paddingY="space4"
        height="space30"
      >
        <Icon icon="lockFilled" color="gray500" size="space20" />
      </DocumentInfoTag>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps}>
          <UserWithAccessInfo users={users} />
        </Tooltip>
      )}
    </>
  );
};

const UserWithAccessInfo = ({ users }: UserWithAccessInfoProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
      <Text gridColumnStart={1} gridColumnEnd={3}>
        {t('documentRelationship.contract.infoSensitiveTooltip')}
      </Text>

      {users.map(user => (
        <Fragment key={user.name}>
          <Avatar img={user.avatarUrl ?? undefined} name={user.name} />
          <Text>{user.name}</Text>
        </Fragment>
      ))}
    </Grid>
  );
};

export const OverdueIcon = (rest?: BoxProps) => {
  const [t] = useTranslation();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'top' });

  return (
    <>
      <Grid
        {...triggerProps}
        ref={triggerRef}
        paddingY="space2"
        paddingX="space8"
        {...rest}
      >
        <ClockIcon size="space16" />
      </Grid>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text>{t('common.overdue')}</Text>
        </Tooltip>
      )}
    </>
  );
};

export const HelpCenterLink = () => {
  const [t] = useTranslation();

  const helpCenterLink = t('document.tabs.relationships.helpCenterArticle');
  const helpCenterLabel = t(
    'document.tabs.relationships.helpCenterArticle.label'
  );

  return (
    <StyledLink
      external
      href={helpCenterLink}
      fontWeight="regular"
      justifySelf="center"
      padding="space12"
    >
      {helpCenterLabel}
    </StyledLink>
  );
};

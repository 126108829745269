import { User } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { isOnlyApprover } from 'utils/authorization';
import { useCreateCommentOnReimbursement } from 'views/Reimbursement/hooks/useCreateCommentOnReimbursement';
import { useGetReimbursementById } from 'views/Reimbursement/hooks/useGetReimbursementById';
import { useOrganizationMemberships } from 'views/Reimbursement/hooks/useOrganizationMemberships';
import { useReimbursementHistory } from 'views/Reimbursement/hooks/useReimbursementHistory';
import { HistoryAndCommentSection } from './HistoryAndCommentSection';

const DISABLED_MENTIONS_STATUSES = [
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Check,
];

export const HistoryAndCommentSectionContainer = () => {
  const [_t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { reimbursement } = useGetReimbursementById({ reimbursementId });
  const { timeline, loading } = useReimbursementHistory({
    id: reimbursementId,
  });

  const { onCreateComment, isCreatingComment } =
    useCreateCommentOnReimbursement();
  const { organizationMemberships, loading: loadingMemberships } =
    useOrganizationMemberships({
      status: 'Active',
    });

  const reimbursementCreatedById = reimbursement?.createdByMembership.id;
  const status = reimbursement?.status;

  const cannotMentionUsers = status
    ? DISABLED_MENTIONS_STATUSES.includes(status)
    : false;

  const mentionableMemberships = organizationMemberships.filter(
    member =>
      !isOnlyApprover(member as User) || member.id === reimbursementCreatedById
  );

  const memberships = cannotMentionUsers ? [] : mentionableMemberships;

  return (
    <HistoryAndCommentSection
      memberships={memberships}
      timeline={timeline}
      isCreateCommentPending={isCreatingComment}
      isTimelineLoading={loading}
      isLoadingMemberships={loadingMemberships}
      onCreateComment={onCreateComment}
    />
  );
};

import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  getLinkToInvoice,
  getLinkToInvoiceOrECMDocument,
} from 'containers/notifications/utils/getLinkToDocument';
import {
  InAppNotificationSubscription,
  InAppNotificationType,
} from 'generated-types/graphql.types';
import { ComponentProps, FunctionComponent } from 'react';
import {
  CardRequestApprovedInAppNotification,
  CardRequestApprovedInAppNotificationIcon,
  CardRequestApprovedInAppNotificationToast,
} from './events/CardRequestApprovedInAppNotification';
import {
  CardRequestCreatedInAppNotification,
  CardRequestCreatedInAppNotificationIcon,
  CardRequestCreatedInAppNotificationToast,
} from './events/CardRequestCreatedInAppNotification';
import {
  CardRequestRejectedInAppNotification,
  CardRequestRejectedInAppNotificationIcon,
  CardRequestRejectedInAppNotificationToast,
} from './events/CardRequestRejectedInAppNotification';
import {
  DocumentApprovalRequestedInAppNotification,
  DocumentApprovalRequestedInAppNotificationIcon,
  DocumentApprovalRequestedInAppNotificationToast,
} from './events/DocumentApprovalRequestedInAppNotification';
import {
  DocumentCommentCreatedInAppNotification,
  DocumentCommentCreatedInAppNotificationIcon,
  DocumentCommentCreatedInAppNotificationToast,
} from './events/DocumentCommentCreatedInAppNotification';
import {
  DocumentRejectedInAppNotification,
  DocumentRejectedInAppNotificationIcon,
  DocumentRejectedInAppNotificationToast,
} from './events/DocumentRejectedInAppNotification';
import {
  TransactionCommentCreatedInAppNotification,
  TransactionCommentCreatedInAppNotificationIcon,
  TransactionCommentCreatedInAppNotificationToast,
} from './events/TransactionCommentCreatedInAppNotification';
import {
  TransactionDeclinedInAppNotification,
  TransactionDeclinedInAppNotificationIcon,
  TransactionDeclinedInAppNotificationToast,
} from './events/TransactionDeclinedInAppNotification';
import { getLinkToApprovedCardRequest } from './utils/getLinkToApprovedCardRequest';
import { getLinkToCardRequest } from './utils/getLinkToCardRequest';
import { getLinkToTransaction } from './utils/getLinkToDeclinedTransaction';
import { getLinkToWallet } from './utils/getLinkToWallet';

export type NotificationType = NonNullable<
  NonNullable<InAppNotificationSubscription['inAppNotification']>['__typename']
>;

export type NotificationProps<T extends NotificationType> = Extract<
  NonNullable<NonNullable<InAppNotificationSubscription['inAppNotification']>>,
  { __typename: T }
>;

type Link = ComponentProps<typeof RouterLink>['to'];

export interface NotificationInfo {
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  content: FunctionComponent<any>;
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  icon: FunctionComponent<any>;
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  toast: FunctionComponent<any>;
  getLinkTo: (params: any) => { link: Link };
}

const typeToNotificationInfo: { [key in NotificationType]?: NotificationInfo } =
  {
    CardRequestApprovedInAppNotification: {
      content: CardRequestApprovedInAppNotification,
      icon: CardRequestApprovedInAppNotificationIcon,
      toast: CardRequestApprovedInAppNotificationToast,
      getLinkTo: getLinkToApprovedCardRequest,
    },
    CardRequestCreatedInAppNotification: {
      content: CardRequestCreatedInAppNotification,
      icon: CardRequestCreatedInAppNotificationIcon,
      toast: CardRequestCreatedInAppNotificationToast,
      getLinkTo: getLinkToCardRequest,
    },
    CardRequestRejectedInAppNotification: {
      content: CardRequestRejectedInAppNotification,
      icon: CardRequestRejectedInAppNotificationIcon,
      toast: CardRequestRejectedInAppNotificationToast,
      getLinkTo: getLinkToWallet,
    },
    DocumentCommentCreatedInAppNotification: {
      content: DocumentCommentCreatedInAppNotification,
      icon: DocumentCommentCreatedInAppNotificationIcon,
      toast: DocumentCommentCreatedInAppNotificationToast,
      getLinkTo: getLinkToInvoiceOrECMDocument,
    },
    DocumentApprovalRequestedInAppNotification: {
      content: DocumentApprovalRequestedInAppNotification,
      icon: DocumentApprovalRequestedInAppNotificationIcon,
      toast: DocumentApprovalRequestedInAppNotificationToast,
      getLinkTo: getLinkToInvoice,
    },
    DocumentRejectedInAppNotification: {
      content: DocumentRejectedInAppNotification,
      icon: DocumentRejectedInAppNotificationIcon,
      toast: DocumentRejectedInAppNotificationToast,
      getLinkTo: getLinkToInvoice,
    },
    TransactionCommentCreatedInAppNotification: {
      content: TransactionCommentCreatedInAppNotification,
      icon: TransactionCommentCreatedInAppNotificationIcon,
      toast: TransactionCommentCreatedInAppNotificationToast,
      getLinkTo: getLinkToTransaction,
    },
    TransactionDeclinedInAppNotification: {
      icon: TransactionDeclinedInAppNotificationIcon,
      toast: TransactionDeclinedInAppNotificationToast,
      content: TransactionDeclinedInAppNotification,
      getLinkTo: getLinkToTransaction,
    },
  };

export const getNotificationInfo = (notificationType: NotificationType) => {
  const notificationInfo = typeToNotificationInfo[notificationType];

  return notificationInfo;
};

export const getNotificationTypes = () => {
  const notificationTypes = Object.keys(typeToNotificationInfo);

  return notificationTypes as InAppNotificationType[];
};

import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  TabPanel,
  Tabs,
} from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { motion } from 'motion/react';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { BatchFastApprove } from 'views/AppContainer/components/Header/components/QaUtils/BatchFastApprove/BatchFastApprove';
import { GraphiQL } from 'views/AppContainer/components/Header/components/QaUtils/GraphiQL/GraphiQL';
import { auditorEmails } from 'views/AppContainer/components/Header/components/QaUtils/utils';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { BatchMockTransaction } from './BatchMockTransaction';
import { FeatureFlags } from './OrgConfig/containers/FeatureFlags';
import { Overview } from './OrgConfig/containers/Overview';

const MotionIcon = motion(Icon);

enum QA_TAB {
  APPROVE = 'APPROVE',
  FFS = 'FFS',
  TRANSACTION = 'TRANSACTION',
  GRAPHI_QL = 'GRAPHI_QL',
}

export const QaUtils = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.QA_UTILS);
  const user = useCurrentUser();
  const navigate = useNavigate();

  const [tab, setTab] = useState<QA_TAB>(QA_TAB.FFS);

  const defaultTabs = [
    {
      key: QA_TAB.FFS,
      title: 'Org Config',
      children: (
        <Flex justifyContent="space-between" gap="space24">
          <Overview />
          <FeatureFlags />
        </Flex>
      ),
    },
    {
      key: QA_TAB.APPROVE,
      title: 'Batch Fast Approve',
      children: <BatchFastApprove />,
    },
    {
      key: QA_TAB.TRANSACTION,
      title: 'Batch Mock Transactions',
      children: <BatchMockTransaction />,
    },
    {
      key: QA_TAB.GRAPHI_QL,
      title: 'GraphiQL',
      children: <GraphiQL />,
    },
  ];

  const auditorTabs = [
    {
      key: QA_TAB.TRANSACTION,
      title: 'Batch Mock Transactions',
      children: <BatchMockTransaction />,
    },
    {
      key: QA_TAB.GRAPHI_QL,
      title: 'GraphiQL',
      children: <GraphiQL />,
    },
  ];

  const applyAuditorTabs = user?.email && auditorEmails.includes(user?.email);

  const { tabPanelProps, tabsProps } = useTabs({
    selectedKey: tab,
    items: !user?.email ? [] : applyAuditorTabs ? auditorTabs : defaultTabs,
    onSelectionChange: key => {
      setTab(key as QA_TAB);
    },
  });

  const { key: tabPanelKey, ...restTabPanelProps } = tabPanelProps;

  const visible = window.location.hash === '#qa-utils';

  const closeModal = () => {
    navigate({ hash: '', search: window.location.search });
  };

  const openModal = () => {
    navigate({ hash: 'qa-utils', search: window.location.search });
  };

  return (
    <Flex>
      <Button onClick={openModal} size="small" variant="secondary">
        <Flex gap="space4">
          {t('title')}
          <MotionIcon
            icon="caretDown"
            size="space16"
            animate={visible ? 'open' : 'closed'}
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 },
            }}
            transition={{
              rotate: { duration: 0.25 },
              default: { ease: 'easeInOut' },
            }}
          />
        </Flex>
      </Button>
      <Modal
        onClose={closeModal}
        isOpen={visible}
        closeLabel={t('common:common.close')}
        title="QA-Utils"
        width="70vw"
        height="90vh"
      >
        <Box borderBottom="1px solid gray300" width="100%">
          <Tabs {...tabsProps} size="small" />
        </Box>
        <TabPanel key={tabPanelKey} {...restTabPanelProps} overflow="hidden" />
      </Modal>
    </Flex>
  );
};

import { Item, SelectField, Text } from '@candisio/design-system';
import { SearchType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useDefaultSearchInput } from './useDefaultSearchInput';

type SearchFilterTypeSelectProps =
  | {
      query: string;
      value: string;
      onChange: (value: string) => void;
      onFocus?: () => void;
      disabled?: boolean;
    }
  | {
      query?: string;
      value?: string;
      onChange?: (value: string) => void;
      onFocus: () => void;
      disabled: true;
    };

export function SearchTypeSelect({
  query,
  value,
  onChange,
  onFocus,
  disabled,
}: SearchFilterTypeSelectProps) {
  const { DEFAULT_SEARCH_TYPE } = useDefaultSearchInput();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const items = [
    {
      id: SearchType.PrefixMatch,
      name: t('searchFilter.popover.fields.searchType.prefixSearch'),
    },
    {
      id: SearchType.ExactMatch,
      name: t('searchFilter.popover.fields.searchType.exactSearch'),
    },
  ];

  return (
    <SelectField
      label={t('searchFilter.popover.fields.searchType.label')}
      aria-label={t('searchFilter.popover.fields.searchType.placeholder')}
      disabled={disabled}
      onFocus={onFocus}
      select={{
        items,
        placeholder: t('searchFilter.popover.fields.searchType.placeholder'),
        children: item => {
          return (
            <Item key={item.id} textValue={item.name}>
              {item.name}
              {item.id === DEFAULT_SEARCH_TYPE && (
                <>
                  {!!query && <>&nbsp;‘{query}’</>}
                  <Text color="gray500">
                    &nbsp;({t('searchFilter.popover.fields.default')})
                  </Text>
                </>
              )}
            </Item>
          );
        },
        defaultSelectedKey: value || DEFAULT_SEARCH_TYPE,
        onSelectionChange: v => onChange?.(v as SearchType),
      }}
    />
  );
}

import { Spinner } from '@candisio/design-system';
import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { faker } from 'utils/faker';
import { useGetClosedReimbursementCasesFromLast30days } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';

export const ReimbursementClosedSwimlane = ({
  totalCount,
}: { totalCount?: number }) => {
  const { closedReimbursementCases, loading } =
    useGetClosedReimbursementCasesFromLast30days();

  if (loading) {
    return <Spinner size="space20" color="gray400" />;
  }

  const hasActionTile = false;
  const hasPromoTile = true;

  if (!closedReimbursementCases?.length) {
    return null;
  }

  return (
    <Swimlane>
      <SwimlaneTiles
        contentTileCount={closedReimbursementCases.length}
        hasActionTile={hasActionTile}
        hasPromoTile={hasPromoTile}
      >
        {({
          contentTileVisibleCount,
          isActionTileVisible,
          isPromoTileVisible,
          isShowAllTileVisible,
        }) => {
          return (
            <>
              <SwimlaneHeader icon="add" label="Reimbursement Closed">
                <span>{`${contentTileVisibleCount} of ${totalCount}`}</span>
              </SwimlaneHeader>
              {isActionTileVisible && (
                <SwimlaneTile asChild variant="red" className="col-span-2">
                  <a href="#attention">Attention!</a>
                </SwimlaneTile>
              )}
              {Array.from({ length: contentTileVisibleCount }, (_, index) => (
                <SwimlaneTile
                  asChild
                  key={`${closedReimbursementCases?.[index]?.id}-${faker.string()}`}
                >
                  <a href="/">{closedReimbursementCases?.[index]?.title}</a>
                </SwimlaneTile>
              ))}

              {isShowAllTileVisible && <SwimlaneTileShowAll to="/" />}
              {isPromoTileVisible && (
                <SwimlaneTile asChild variant="blue" className="col-span-2">
                  <a href="#promo">Promo</a>
                </SwimlaneTile>
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

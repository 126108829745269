import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

export const useEcm = () => {
  const [
    contractsCustomSubtypesFF,
    documentRelationsFF,
    documentRelationsImprovementFF,
    ecmContractManagementFF,
    ecmDocumentsDownloadFF,
    ecmSensitiveDocumentsFF,
    enterpriseContentManagementFF,
    archiveDocumentPreviewFF,
    searchOverlayFF,
    consistentSortAndFilteringFF,
    enableNewIndexInInboxViewFF,
    enableNewIndexInApprovalViewFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.contractsCustomSubtypes,
    FEATURE_FLAGS.documentRelations,
    FEATURE_FLAGS.documentRelationsImprovement,
    FEATURE_FLAGS.ecmContractManagement,
    FEATURE_FLAGS.ecmDocumentsDownload,
    FEATURE_FLAGS.ecmSensitiveDocuments,
    FEATURE_FLAGS.enterpriseContentManagement,
    FEATURE_FLAGS.archiveDocumentPreview,
    FEATURE_FLAGS.searchOverlay,
    FEATURE_FLAGS.consistentSortAndFiltering,
    FEATURE_FLAGS.enableNewIndexInInboxView,
    FEATURE_FLAGS.enableNewIndexInApprovalView,
  ]);

  const { isAccountant, isAdmin, isRequester } = useUserRoles();
  const entitlements = useEntitlementsFlag();
  const { ft, promo, show, hide } = useFeatureToggle();

  const canAccessEcm = isAdmin || isRequester || isAccountant;
  const ecmAvailable = entitlements !== Entitlements.LEGACY;
  if (ecmAvailable) {
    return {
      showEcm: true,
      isDocumentRelationsAvailable: !hide(ft.DOCUMENT_RELATIONS),
      showContractCustomSubCategories:
        contractsCustomSubtypesFF && show(ft.CONTRACTS),
      showDocumentRelations: show(ft.DOCUMENT_RELATIONS),
      showDocumentRelationsImproved:
        show(ft.DOCUMENT_RELATIONS) && documentRelationsImprovementFF,
      showEcmContractManagement: show(ft.CONTRACTS),
      showEcmDocumentsDownload: show(ft.ECM),
      showEcmSensitiveDocuments: show(ft.CONTRACTS),
      showDocumentTags: show(ft.TAGS),
      showImprovedSearch: show(ft.FULL_TEXT_SEARCH) && !searchOverlayFF,
      showDocumentPreview:
        archiveDocumentPreviewFF && show(ft.DOCUMENT_PREVIEW),
      showDocumentPreviewPromo:
        archiveDocumentPreviewFF && promo(ft.DOCUMENT_PREVIEW),
      canAccessEcm,
      showSearchOverlay: searchOverlayFF && show(ft.SEARCH_OVERLAY),
      showSearchOverlayPromo: searchOverlayFF && promo(ft.SEARCH_OVERLAY),
      showConsistentSortAndFiltering: consistentSortAndFilteringFF,
      useNewInboxQuery:
        enableNewIndexInInboxViewFF && consistentSortAndFilteringFF,
      useNewApprovalQuery:
        enableNewIndexInApprovalViewFF && consistentSortAndFilteringFF,
    };
  }

  return {
    showEcm: enterpriseContentManagementFF,
    isDocumentRelationsAvailable:
      enterpriseContentManagementFF && documentRelationsFF,
    showContractCustomSubCategories:
      enterpriseContentManagementFF &&
      ecmContractManagementFF &&
      contractsCustomSubtypesFF,
    showDocumentRelations: enterpriseContentManagementFF && documentRelationsFF,
    showDocumentRelationsImproved:
      enterpriseContentManagementFF &&
      documentRelationsFF &&
      documentRelationsImprovementFF,
    showEcmContractManagement:
      enterpriseContentManagementFF && ecmContractManagementFF,
    showEcmDocumentsDownload:
      enterpriseContentManagementFF && ecmDocumentsDownloadFF,
    showEcmSensitiveDocuments:
      enterpriseContentManagementFF &&
      ecmContractManagementFF &&
      ecmSensitiveDocumentsFF,
    showDocumentTags: enterpriseContentManagementFF,
    showImprovedSearch: enterpriseContentManagementFF && !searchOverlayFF,
    showDocumentPreview:
      archiveDocumentPreviewFF && enterpriseContentManagementFF,
    showDocumentPreviewPromo:
      archiveDocumentPreviewFF && !enterpriseContentManagementFF,
    canAccessEcm,
    showSearchOverlay: searchOverlayFF && enterpriseContentManagementFF,
    showSearchOverlayPromo:
      searchOverlayFF &&
      !enterpriseContentManagementFF &&
      promo(ft.SEARCH_OVERLAY),
    showConsistentSortAndFiltering:
      enterpriseContentManagementFF && consistentSortAndFilteringFF,
    useNewInboxQuery:
      enterpriseContentManagementFF &&
      enableNewIndexInInboxViewFF &&
      consistentSortAndFilteringFF,
    useNewApprovalQuery:
      enterpriseContentManagementFF &&
      enableNewIndexInApprovalViewFF &&
      consistentSortAndFilteringFF,
  };
};

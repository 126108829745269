import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  defaultUIConfig,
  UIConfigProps,
} from '../hooks/useReimbursementUIConfig';
import {
  ApprovalFieldData,
  ApprovalMode,
  FormRegistration,
  initialApprovalData,
  ReimbursementFormsContext,
} from './ReimbursementFormsContext';

interface ReimbursementFormsProviderProps {
  children: ReactNode;
  initialUIConfig?: UIConfigProps;
}

export const ReimbursementFormsProvider = ({
  children,
  initialUIConfig,
}: ReimbursementFormsProviderProps) => {
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const formsRef = useRef<Record<string, FormRegistration>>({});
  const [approvalMode, setApprovalMode] =
    useState<ApprovalMode>('requestApproval');

  const [isReimbursementUpdatePending, setIsReimbursementUpdatePending] =
    useState<boolean>(false);
  const [lastModifiedExpense, setLastModifiedExpense] = useState<null | number>(
    null
  );

  const [visibleItemId, setVisibleItemId] = useState<string | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [hasReimbursementErrors, setHasReimbursementErrors] =
    useState<boolean>(false);

  const [approvalData, setApprovalData] =
    useState<ApprovalFieldData>(initialApprovalData);

  const uiConfig = initialUIConfig || defaultUIConfig;

  const [reimbursementUIConfig, setReimbursementUIConfig] =
    useState<UIConfigProps>(uiConfig);

  const registerForm = (formKey: string, registration: FormRegistration) => {
    formsRef.current = {
      ...formsRef.current,
      [formKey]: registration,
    };
  };

  const updateLastModifiedExpense = useCallback((expenseFormIndex: number) => {
    setLastModifiedExpense(expenseFormIndex);
  }, []);

  const triggerAllValidations = useCallback(() => {
    Object.values(formsRef.current).forEach(form => form.trigger?.());
  }, []);

  const handleSetSelectedItemId = useCallback((id: string | null) => {
    setSelectedItemId(id);
  }, []);

  const handleIsReimbursementUpdatePending = useCallback((val: boolean) => {
    setIsReimbursementUpdatePending(val);
  }, []);

  const updateHasReimbursementErrors = useCallback((val: boolean) => {
    setHasReimbursementErrors(val);
  }, []);

  const updateApprovalMode = useCallback((approval: ApprovalMode) => {
    setApprovalMode(approval);
  }, []);

  const updateApprovalData = useCallback((values: ApprovalFieldData) => {
    setApprovalData(values);
  }, []);

  useEffect(() => {
    setReimbursementUIConfig(uiConfig);
  }, [uiConfig]);

  return (
    <ReimbursementFormsContext.Provider
      value={{
        lastModifiedExpense,
        updateLastModifiedExpense,
        registerForm,
        triggerAllValidations,
        reimbursementUIConfig,
        setReimbursementUIConfig,
        visibleItemId,
        setVisibleItemId,
        itemsContainerRef,
        itemRefs,
        selectedItemId,
        setSelectedItemId: handleSetSelectedItemId,
        isReimbursementUpdatePending,
        handleIsReimbursementUpdatePending,
        hasReimbursementErrors,
        setHasReimbursementErrors: updateHasReimbursementErrors,
        approvalMode,
        setApprovalMode: updateApprovalMode,
        approvalData,
        setApprovalData: updateApprovalData,
      }}
    >
      {children}
    </ReimbursementFormsContext.Provider>
  );
};

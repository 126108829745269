import { useQuery } from '@apollo/client';
import {
  ArchiveReimbursementCasesListItem,
  ArchiveReimbursementCasesQuery,
  GetReimbursementCasesSummaryQuery,
  ReimbursementCaseStatus,
  ReimbursementCasesFilterInput,
  ReimbursementCasesSortOrder,
  useArchiveReimbursementCasesQuery,
} from 'generated-types/graphql.types';
import moment from 'moment';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { DEFAULT_TIMEZONE } from 'providers/OrganizationProvider';
import { useRef } from 'react';
import { reimbursementCasesSummaryQuery } from '../toolkit/queries';

export const useGetReimbursementCasesSummary = () => {
  const { data, loading, error } = useQuery<GetReimbursementCasesSummaryQuery>(
    reimbursementCasesSummaryQuery
  );
  return { data, loading, error };
};

export const DRAFT_STATUSES = [ReimbursementCaseStatus.Draft];

export const OPEN_STATUSES = [
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Check,
  ReimbursementCaseStatus.Review,
];

export const CLOSED_STATUSES = [
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Rejected,
  ReimbursementCaseStatus.Approved,
];

export type ArchiveReimbursementCase = Pick<
  ArchiveReimbursementCasesListItem,
  | 'id'
  | 'title'
  | 'createdAt'
  | 'isPaid'
  | 'paidAt'
  | 'firstSubmittedForReviewAt'
  | 'status'
> & {
  __typename?: 'ArchiveReimbursementCasesListItem';
};

const filterCasesByStatus = (
  cases: ArchiveReimbursementCasesQuery['archiveReimbursementCases']['edges'],
  statuses: ReimbursementCaseStatus[]
) => {
  return cases
    ?.map(edge => edge.node)
    .filter(caseItem => statuses.includes(caseItem.status));
};

/**
 * Fetches reimbursement cases with optional filters.
 */
export const useGetArchiveReimbursementCases = ({
  filters,
  sort = ReimbursementCasesSortOrder.Desc,
  limit = 6,
}: {
  filters?: ReimbursementCasesFilterInput;
  sort?: ReimbursementCasesSortOrder;
  limit?: number;
}) => {
  const { id: userId } = useCurrentUser() || {};

  const { data, loading, error } = useArchiveReimbursementCasesQuery({
    variables: {
      limit,
      filters: {
        ...filters,
        // targetMembershipIds: [userId ?? ''],
      },
      sorts: {
        createdAt: sort,
      },
    },
    skip: !userId,
  });

  const archiveReimbursementCases = data?.archiveReimbursementCases?.edges;

  return {
    draftReimbursementCases: filterCasesByStatus(
      archiveReimbursementCases,
      DRAFT_STATUSES
    ),
    openReimbursementCases: filterCasesByStatus(
      archiveReimbursementCases,
      OPEN_STATUSES
    ),
    closedReimbursementCases: filterCasesByStatus(
      archiveReimbursementCases,
      CLOSED_STATUSES
    ),
    loading,
    error,
  };
};

/**
 * Fetches reimbursement cases that are closed in the last 30 days for dashboard.
 */
const MS_IN_DAY = 24 * 60 * 60 * 1000;
export const useGetClosedReimbursementCasesFromLast30days = () => {
  const fromDate = useRef(
    moment
      .tz(new Date(Date.now() - 30 * MS_IN_DAY), DEFAULT_TIMEZONE)
      .toISOString()
  ).current;

  const toDate = useRef(
    moment.tz(new Date(), DEFAULT_TIMEZONE).toISOString()
  ).current;

  return useGetArchiveReimbursementCases({
    filters: {
      statuses: CLOSED_STATUSES,
      updatedAt: { fromDate, toDate },
    },
  });
};

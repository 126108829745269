import { Button, Flex, Icon, Link } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import {
  Promotion,
  PromotionDetail,
  PromotionModalId,
} from 'components/NewPromotions/types';
import { ReactNode, useState } from 'react';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
import qrCode from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/media/user-profile-menu-QR-code.jpg';
import { PromotionDetailsModalContent } from '../PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from '../PromotionHeader/PromotionHeader';
import { PromotionModal } from '../PromotionModal/PromotionModal';

export const PromotionCTA = ({
  promotion,
  customModalContent,
  customPromotionDetails,
}: {
  promotion: Promotion;
  customModalContent?: ReactNode;
  customPromotionDetails?: PromotionDetail;
}) => {
  const { ctaButton, link, label, id } = promotion;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pauseAutoPlayCard, resumeAutoPlayCard } = usePromotionsContext();

  const { promotionDetails } = usePromotionDetailsModalContent(
    promotion.id as PromotionModalId
  );

  const onClickCtaButton = () => {
    if (ctaButton?.url) return;

    setIsModalOpen(true);
    pauseAutoPlayCard?.();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resumeAutoPlayCard?.();
  };

  const renderModal = () => {
    if (promotion.id === 'mobileApp') {
      return (
        <MobileAppPromotionModal
          qrCode={qrCode}
          visible={isModalOpen}
          onCancel={closeModal}
        />
      );
    }

    return (
      <PromotionModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        titleInfo={<PromotionLabel label={label} />}
        data-cy={`promotionModal-${id}`}
      >
        <PromotionDetailsModalContent
          promotionDetails={customPromotionDetails ?? promotionDetails}
        />
      </PromotionModal>
    );
  };

  return (
    <>
      <Flex
        direction="column"
        justifyContent="end"
        alignItems="center"
        gap="space8"
      >
        {ctaButton && (
          <Button
            color="gray"
            variant="secondary"
            width="100%"
            onClick={onClickCtaButton}
          >
            {ctaButton.text}
          </Button>
        )}
        <Flex>
          {link?.url && (
            <Link
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              color={link.color ?? 'purple'}
              fontSize="basic"
              fontWeight="500"
              href={link.url}
            >
              <Flex
                paddingX="space16"
                alignItems="center"
                width="100%"
                gap="space4"
              >
                {link.text}
                {link.externalIcon && <Icon icon="newTab" />}
              </Flex>
            </Link>
          )}
        </Flex>
      </Flex>
      {customModalContent ? customModalContent : renderModal()}
    </>
  );
};

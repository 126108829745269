import { useModal } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { BaselineSearchPromotion } from './BaselineSearchPromotion';
import { useBaselinePromotionSeenState } from './useBaselinePromotionSeenState';

export const BaselineSearchPromotionContainer = () => {
  const [consistentSortAndFilteringFF, searchOverlayFF] = useCandisFeatureFlags(
    [FEATURE_FLAGS.consistentSortAndFiltering, FEATURE_FLAGS.searchOverlay]
  );
  const { baselineSearchPromotionSeen } = useBaselinePromotionSeenState();

  if (!consistentSortAndFilteringFF && !searchOverlayFF) {
    return null;
  }

  if (baselineSearchPromotionSeen) {
    return null;
  }

  return <BaselineSearchPromotionModal />;
};

const BaselineSearchPromotionModal = () => {
  const { isOpen, close } = useModal({ defaultOpen: true });
  const { setPromotionIsSeen } = useBaselinePromotionSeenState();

  const handleClose = () => {
    setPromotionIsSeen();
    close();
  };
  return <BaselineSearchPromotion isOpen={isOpen} close={handleClose} />;
};

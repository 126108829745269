import { Box } from '@candisio/design-system';
import {
  HookFormRadioGroupField,
  HookFormRadioGroupFieldProps,
} from 'components/HookFormFields/HookFormRadioGroupField';
import { ContactType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';
import { ContactFormValues } from '../contactFormSchema';

/** A HookFormRadioGroupField for picking the contact type */
export const ContactTypeField = (
  props?: Omit<
    HookFormRadioGroupFieldProps<ContactFormValues>,
    'name' | 'options'
  > & { isEmployeeContactTypeHidden: boolean }
) => {
  const [t] = useTranslation();
  const { canUseReimbursement } = useReimbursement();
  const employeeContactTypeFF = useCandisFeatureFlags(
    FEATURE_FLAGS.employeeContactType
  );

  const isEmployeeContactTypeVisible =
    canUseReimbursement &&
    employeeContactTypeFF &&
    !props?.isEmployeeContactTypeHidden;

  const excludedOptions = isEmployeeContactTypeVisible
    ? [ContactType.NotSpecified]
    : [ContactType.NotSpecified, 'EMPLOYEE'];

  return (
    <Box fontSize="basic" paddingX="space8">
      <HookFormRadioGroupField<ContactFormValues>
        {...props}
        name="contactType"
        label={t('settings.contacts.details.edit.contactType.label')}
        excludeOptions={excludedOptions}
        options={[
          {
            value: ContactType.Company,
            children: t(
              'settings.contacts.details.edit.contactType.options.company'
            ),
            isDisabled: false,
          },
          {
            value: ContactType.Individual,
            children: t(
              'settings.contacts.details.edit.contactType.options.naturalPerson'
            ),
            isDisabled: false,
          },
          {
            value: 'EMPLOYEE',
            children: t(
              'settings.contacts.details.edit.contactType.options.employee'
            ),
            isDisabled: false,
          },
          {
            value: ContactType.NotSpecified,
            children: t(
              'settings.contacts.details.edit.contactType.options.unknown'
            ),
            isDisabled: true,
          },
        ]}
        orientation="horizontal"
      />
    </Box>
  );
};

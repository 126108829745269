import { Button, Card, Grid } from '@candisio/design-system';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import {
  CommentFormOutput,
  commentFormErrorMessages,
  commentFormSchema,
} from '../SendBackToEmploy/utils';
import { useUserRoles } from 'hooks/useUserRoles';
import { ReactNode } from 'react';
import { FooterHeader } from '../FooterActions/FooterHeader';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';

interface RejectReimbursmentCommentFormProps {
  isRejectPending: boolean;
  onToggleEditMode: () => void;
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
  isSavePending?: boolean;
  reimbursement?: Reimbursement;
  showEditAction?: boolean;
  onSetIsFormVisible: (isVisible: boolean) => void;
  onRejectReimbursment: (
    comment: CommentFormOutput['comment']
  ) => Promise<void>;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
}

interface CommentCardProps {
  isRejectPending: boolean;
  header: ReactNode;
  onClose: () => void;
}

const CommentCard = ({
  isRejectPending,
  header,
  onClose,
}: CommentCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { fieldState } = useController({ name: 'comment' });

  const isFieldInvalid = !!fieldState.error;
  return (
    <Card
      corners="top"
      boxShadow="elevatedShadow3"
      padding="space20"
      border={isFieldInvalid ? '2px solid red' : 'none'}
    >
      <Grid gap="space8">
        {header}
        <HookFormTextareaField
          autoFocus
          name="comment"
          aria-label={t('reimbursementView.rightSection.form.comment')}
          placeholder={t(
            'reimbursementView.rightSection.approvingFooterAction.reject.placeholder'
          )}
        />
        <Grid autoFlow="column" gap="space4">
          <Button
            onClick={onClose}
            variant="tertiary"
            disabled={isRejectPending}
          >
            {t(
              'reimbursementView.rightSection.approvingFooterAction.reject.discard'
            )}
          </Button>
          <Button
            color="red"
            type="submit"
            variant="secondary"
            loading={isRejectPending}
            disabled={isRejectPending}
          >
            {t(
              'reimbursementView.rightSection.approvingFooterAction.reject.accept'
            )}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export const RejectReimbursmentCommentForm = ({
  onSetIsFormVisible,
  onRejectReimbursment,
  isRejectPending,
  onToggleEditMode,
  reimbursement,
  reimbursementItems,
  ...props
}: RejectReimbursmentCommentFormProps) => {
  const { isOnlyApprover } = useUserRoles();

  const form = useForm<CommentFormOutput>({
    defaultValues: { comment: undefined },
    reValidateMode: 'onSubmit',
    resolver: zodResolver({
      errorMessages: commentFormErrorMessages,
      zodSchema: commentFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const handleCloseForm = () => {
    onSetIsFormVisible(false);
  };

  const rejectReimbursement = async ({ comment }: CommentFormOutput) => {
    await onRejectReimbursment(comment);
    handleCloseForm();
  };

  const toggleEditMode = () => {
    onToggleEditMode();
    handleCloseForm();
  };

  return (
    <FormProvider {...form}>
      <Grid as="form" onSubmit={form.handleSubmit(rejectReimbursement)}>
        <CommentCard
          onClose={handleCloseForm}
          isRejectPending={isRejectPending}
          header={
            <FooterHeader
              showEditAction={!isOnlyApprover}
              onToggleEditMode={toggleEditMode}
              reimbursement={reimbursement}
              reimbursementItems={reimbursementItems}
              {...props}
            />
          }
        />
      </Grid>
    </FormProvider>
  );
};

import { Box, Grid, Image } from '@candisio/design-system';
import noAvatar from 'assets/icon-avatar-empty.svg';
import {
  HookFormUserFieldItem,
  HookFormUsersField,
} from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { Maybe } from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion(Grid);

interface ApproversFieldProps {
  approverDropdownOptions: HookFormUserFieldItem[];
  stepIndex: number;
  isArchived?: Maybe<boolean>;
  showConditions?: boolean | null;
}

export const ApproversField = ({
  approverDropdownOptions,
  stepIndex,
  isArchived,
  showConditions,
}: ApproversFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <MotionGrid
      padding="space24 space18"
      borderRadius={showConditions ? '0 0 medium medium' : 'medium'}
      background="gray200"
      gap="space18"
      templateColumns="auto 1fr"
      alignItems="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.8 }}
    >
      <Box paddingTop="space16">
        <Image alt="Avatar icon" height="space30" src={noAvatar} />
      </Box>
      <HookFormUsersField
        label={t('workflows.details.edit.user.label')}
        name={`steps[${stepIndex}].approvers`}
        defaultItems={approverDropdownOptions}
        readOnly={!!isArchived}
      />
    </MotionGrid>
  );
};

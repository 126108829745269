import { Button, Flex, Link, defaultTheme } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { queryParameter } from 'components/Table/consts';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useDeferredValue, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { SearchOverlayUpsell } from './SearchFilterUpsell';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { useSearchFilter } from './useSearchFilter';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';

interface AdvancedSearchFieldFilterOverlayProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlay({
  onApply,
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayProps) {
  const [search] = useSearchParams();
  const query = useDeferredValue(search.get(queryParameter) ?? '');
  const { showSearchOverlayPromo } = useEcm();
  const {
    searchType,
    searchScope,
    setSearchType,
    setSearchScope,
    applySearchInputFilters,
  } = useSearchFilter();

  const { track } = useAnalytics();
  useEffect(() => {
    track(TrackingEvents.FILTER_BTN_CLICKED, {
      event_location: 'global_search',
    });
  }, [track]);

  const applyFilters = () => {
    applySearchInputFilters();
    onApply();
    track(TrackingEvents.FILTER_APPLY_BTN_CLICKED, {
      event_location: 'global_search',
    });
  };

  const handleOnFocus = () => {
    if (!showSearchOverlayPromo) {
      return;
    }

    onShowPromoModal();
  };

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect
          query={query}
          value={searchType}
          onChange={setSearchType}
          onFocus={handleOnFocus}
        />
        <SearchScopeSelect
          value={searchScope}
          onChange={setSearchScope}
          onFocus={handleOnFocus}
        />
      </Flex>
      {showSearchOverlayPromo ? (
        <SearchOverlayUpsell openModal={onShowPromoModal} />
      ) : (
        <FilterActions applyFilters={applyFilters} />
      )}
    </Flex>
  );
}

function FilterActions({ applyFilters }: { applyFilters: () => void }) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Flex gap="space20" justifyContent="space-between">
      <Flex gap="space20" alignItems="center">
        <Link
          href={t('searchFilter.popover.help.url')}
          fontSize="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('searchFilter.popover.help.text')}
        </Link>
        <IntercomLink
          id="search-filter-overlay-intercom-link"
          variant="noIcon"
          style={{
            fontSize: defaultTheme.fontSizes.small,
            lineHeight: defaultTheme.lineHeights.body,
          }}
        >
          {t('searchFilter.popover.feedback.text')}
        </IntercomLink>
      </Flex>

      <Button size="small" color="blue" onClick={applyFilters}>
        {t('searchFilter.popover.actions.apply')}
      </Button>
    </Flex>
  );
}

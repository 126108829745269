import { GQLErrorTypes } from 'gql';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import Joi from 'joi';
import { checkVAT, countries } from 'jsvat';
import { isNil } from 'lodash';
import { i18n } from 'providers/LocaleProvider';
import {
  SPECIAL_CHARACTERS_REGEX,
  TAX_NUMBER_REGEX,
} from 'views/Contacts/consts';

// TODO: we can remove hasSpecialCharacters when this PR is merged: https://github.com/se-panfilov/jsvat/pull/133 and the jsvat package is upgraded
export const hasSpecialCharacters = (vatId: string) => {
  return Boolean(vatId.match(SPECIAL_CHARACTERS_REGEX));
};

export const isValidVatId = (vatId: string) => {
  const hasSpecialChars = hasSpecialCharacters(vatId);

  // TODO - change to checkVAT(vatId, countries, false); when this PR is merged: https://github.com/se-panfilov/jsvat/pull/133 and the jsvat package is upgraded
  return !hasSpecialChars && checkVAT(vatId, countries).isValid;
};

export const validateIBAN = (
  iban: string,
  { strict }: { strict?: boolean } = {}
) => {
  let newIBAN;
  if (strict !== true) {
    newIBAN = electronicFormatIBAN(iban);
  }

  return isValidIBAN(newIBAN || iban);
};

export const isValidTaxNumberForGermany = (
  countryISOCode: string | null | undefined,
  taxNumber: string | null | undefined
) => {
  if (
    taxNumber &&
    (countryISOCode === GERMAN_COUNTRY_ISO_CODE || isNil(countryISOCode))
  ) {
    return TAX_NUMBER_REGEX.test(taxNumber);
  } else {
    return true;
  }
};

// German postal codes are 5 digits long, so we should only allow 5 digits /^[0-9]{5}$/.
// but we start with light validation which is coming from Datev
export const GERMAN_POSTAL_CODE_REGEX = /^[0-9]*$/;
export const GERMAN_COUNTRY_ISO_CODE = 'DE';

export const isValidPostalCodeForGermany = (
  countryISOCode: string | null | undefined,
  postalCode: string | null | undefined
) => {
  if (
    postalCode &&
    (countryISOCode === GERMAN_COUNTRY_ISO_CODE || isNil(countryISOCode))
  ) {
    return GERMAN_POSTAL_CODE_REGEX.test(postalCode);
  } else {
    return true;
  }
};

export const ibanIncludesBan = (iban?: string, ban?: string) => {
  if (!iban || !ban) {
    return true;
  }

  const sanitizedBAN = ban.replace(/\s/g, '');

  const sanitizedIBAN = iban.replace(/\s/g, '');

  // iban should include ban
  return sanitizedIBAN.includes(sanitizedBAN);
};

export const isFunction = (value: any): value is (...args: any[]) => any =>
  typeof value === 'function';

const isFormatError = (value: any): value is FormatError =>
  value instanceof FormatError;

export type TransformObject<T, K> = { [P in keyof T]: K };

export enum FormErrorKey {
  ANY_INVALID = 'any.invalid',
}

export const joiError = ({
  field,
  type,
  context = {},
}: {
  field: string;
  type: string;
  context?: any;
}) => ({
  details: [
    {
      message: `${field} contains an invalid value`,
      path: [field],
      type,
      context,
    },
  ],
});

/** TODO: extends types */
const ErrorKeys = [
  'alternatives.all',
  'alternatives.any',
  'alternatives.match',
  'alternatives.one',
  'alternatives.types ',
  'any.custom',
  'any.default',
  'any.duplicate',
  'any.empty',
  'any.failover',
  'any.invalid',
  'any.only',
  'any.ref',
  'any.required',
  'array.min',
  'date.base',
  'date.greater',
  'date.less',
  'date.max',
  'date.min',
  'number.base',
  'number.greater',
  'number.integer',
  'number.less',
  'number.max',
  'number.min',
  'number.precision',
  'string.email',
  'string.empty',
  'string.invalid',
  'string.invalidIBAN',
  'string.invalidVAT',
  'string.max',
  'string.pattern.base',
  'string.pattern.name',
  'string.regex.base',
] as const;

type ErrorTypes = (typeof ErrorKeys)[number];

type ErrorConfig = {
  root?: boolean;
  translationNs?: string;
};

export class FormatError {
  constructor(
    private _path: string,
    private _config?: ErrorConfig
  ) {}

  setConfig(config?: ErrorConfig) {
    this._config = config;

    return this;
  }

  format = ({ label, limit, ...context }: Joi.Context) => {
    const formattedLimit =
      typeof limit === 'number'
        ? Intl.NumberFormat(i18n.language).format(limit)
        : limit;

    return i18n.t(this._path, {
      ns: this._config?.translationNs || 'common',
      label,
      limit: formattedLimit,
      ...context,
    });
  };
}

export const FormattedErrors = {
  REQUIRED: new FormatError('validation.fields.required'),
  INVALID: new FormatError('validation.fields.valid'),
};

const _defaultFormattedErrors: Partial<Record<ErrorTypes, FormatError>> = {
  'any.empty': FormattedErrors.REQUIRED,
  'any.invalid': FormattedErrors.INVALID,
  'any.required': FormattedErrors.REQUIRED,
  'string.empty': FormattedErrors.REQUIRED,
};

export type JoiFormatError = (
  e: Joi.Context,
  path?: string[]
) => string | undefined;

export type ErrorMessageRecord = {
  [ErrorSubProperty in ErrorTypes]?: ErrorMessageProvider;
};

export function isErrorMessageRecord(obj: any): obj is ErrorMessageRecord {
  return (
    typeof obj === 'object' &&
    Object.keys(obj).every(key => ErrorKeys.includes(key as ErrorTypes)) &&
    Object.values(obj).every(
      value =>
        typeof value === 'string' || isFunction(value) || isFormatError(value)
    )
  );
}

type ErrorMessageProvider = FormatError | string | JoiFormatError;

/**
 * An object whose keys are either:
 * - a key of `FormData`
 * - a Joi error type (e.g. `number.max`)
 * - any other string
 * and whose values are:
 * - an error message provider
 * - an error message record
 * - a nested `FormError` (to support nested form fields)
 */
export type FormError<FormData extends object = {}> = {
  [FieldName in keyof FormData | ErrorTypes | (string & {})]?:
    | ErrorMessageProvider
    | ErrorMessageRecord
    | FormError<FormData>;
};

export const validationErrorsExtractor = (error: any) => {
  const { graphQLErrors } = error;

  if (graphQLErrors?.length) {
    const { extensions } = graphQLErrors[0];
    const { code, exception } = extensions;

    if (code === GQLErrorTypes.VALIDATION_ERROR) {
      const validationErrors = exception.validationErrors[0];
      const errorsList = Object.keys(validationErrors).reduce(
        (result: any, fieldName: any) => {
          result[fieldName] = validationErrors[fieldName].message;

          return result;
        },
        {}
      );

      return errorsList;
    }
  }

  return null;
};

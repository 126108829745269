import { Box, Button, Flex, Grid, Text } from '@candisio/design-system';
import { LottieAnimation, successData } from 'components/Lottie/Lottie';
import { TransactionDetailsDeprecated } from 'components/Transactions/TransactionDetailsCard/TransactionDetails';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import { CreditCardDetailsAndActions } from 'containers/credit-cards/CreditCardSettings/CreditCardDetailsAndActions/CreditCardDetailsAndActions';
import {
  SortDirection,
  useArchiveCardIssuerTransactionsQuery,
} from 'generated-types/graphql.types';
import { CardIssuerTransactionSortField } from 'generated-types/resolvers-types';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { Routes } from 'models';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { useFullOrganization } from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { TRANSACTION_FILTER } from 'views/Inbox/Transactions/useTransactionListFilters';
import { DrawerMode } from '../CreditCardDrawer';
import { Loader } from '../components/Loader';

export type HandleClickProp = {
  id: string;
  cursor: string | undefined;
};
interface TransactionsTabProps {
  cardId: string;
  onClose: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const TransactionsTab = ({
  cardId,
  onClose,
  onSetMode,
}: TransactionsTabProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const org = useFullOrganization();

  const { cardholderId } = useCardholderId();

  const { inboxTransactionCount } = useInboxTransactionCount({
    cardholderIds: cardholderId ? [cardholderId] : undefined,
    cardIds: [cardId],
    skip: !cardholderId,
  });

  const { data: totalTransactions, loading } =
    useArchiveCardIssuerTransactionsQuery({
      variables: {
        input: {
          limit: 5,
        },
        filters: {
          cardIds: [cardId],
        },
        sortBy: {
          direction: SortDirection.Desc,
          field: CardIssuerTransactionSortField.TransactionCreatedAt,
        },
      },
      // because of the `limit`, ensure that (filtered) results in Archive
      // transactions are NOT cached/affected by this query
      fetchPolicy: 'no-cache',
      skip: !cardId,
    });

  const latestTransactions =
    totalTransactions?.archiveCardIssuerTransactions.edges;

  const organizationSlug = org?.slug;

  const onShowAllTransactions = () => {
    navigate({
      pathname: `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onShowMissingInvoices = () => {
    navigate({
      pathname: `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const handleClick = ({ id, cursor }: HandleClickProp) => {
    navigate(
      `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${id}?cursor=${cursor}`
    );
  };

  return (
    <Flex
      paddingY="space16"
      flexGrow={1}
      gap="space20"
      paddingX="space24"
      paddingTop="space24"
      paddingBottom={0}
      direction="column"
    >
      <CreditCardDetailsAndActions
        cardId={cardId}
        onClose={onClose}
        onSetMode={onSetMode}
      />
      <Grid alignContent="start" gap="space16">
        <Text fontSize="large" fontWeight="500">
          {t('credit-cards:drawer.latestTransactions')}
        </Text>

        <Flex direction="column" gap="space8">
          {loading ? (
            <Loader />
          ) : latestTransactions?.length ? (
            latestTransactions.map((transaction, i) => (
              <Box
                key={i}
                style={{ cursor: 'pointer' }}
                hover={{ background: 'bluebg' }}
                onClick={() =>
                  handleClick({
                    id: transaction?.node?.id,
                    cursor: transaction?.cursor,
                  })
                }
                background="gray100"
                borderRadius="medium"
                padding="space16"
              >
                <TransactionDetailsDeprecated
                  transaction={dataToTransactionDetails(transaction.node)}
                />
              </Box>
            ))
          ) : (
            <Box
              background="gray100"
              borderRadius="medium"
              height="space256"
              textAlign="center"
            >
              <LottieAnimation options={{ animationData: successData }} />

              <Text fontSize="large" fontWeight="500">
                {t('credit-cards:drawer.noTransactions')}
              </Text>
            </Box>
          )}
        </Flex>
      </Grid>

      {(latestTransactions?.length ?? 0) > 0 && (
        <Flex
          position="sticky"
          bottom={0}
          gap="space16"
          justifyContent="end"
          background="white"
          paddingY="space16"
          style={{ marginTop: 'auto' }}
        >
          {inboxTransactionCount > 0 && (
            <Button
              color="red"
              variant="secondary"
              icon="arrowRight"
              iconPosition="right"
              onClick={onShowMissingInvoices}
            >
              {t('credit-cards:drawer.transactionsMissingInvoice', {
                count: inboxTransactionCount,
              })}
            </Button>
          )}

          <Button
            variant="secondary"
            icon="arrowRight"
            iconPosition="right"
            onClick={onShowAllTransactions}
          >
            {t('credit-cards:drawer.transactions')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

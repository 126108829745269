import gql from 'graphql-tag';
import { builtInRoleData, customRoleData } from '../Roles/toolkit/queries';

export const getTeamMembersQuery = gql`
  query teamMembers($name: String) {
    teamMembers(input: { name: $name }) {
      id
      avatarUrl
      name
      firstName
      lastName
      email
      locale
      roles
      membershipRoles {
        __typename
        id
        name
        description
        isArchived
        isBuiltinRole
      }
      username
      createdTimestamp
      status
    }
  }
`;

export const getRolesForUserQuery = gql`
  query getRolesForUserQuery(
    $input: RolesPageBasedPaginationInput!
    $queries: RolesQueryInput
    $filters: RolesFiltersInput
    $sortBy: RolesSortInput
  ) {
    roles(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        __typename
        id
        description
        isArchived
        ...BuiltInRoleData
        ...CustomRoleData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
  ${builtInRoleData}
  ${customRoleData}
`;

export const getPendingInvitesQuery = gql`
  query pendingInvites {
    organizationMemberships(filters: { emailVerified: false }) {
      id
      name
      firstName
      lastName
      email
      locale
      roles
      membershipRoles {
        id
        name
        isBuiltinRole
        isLegacyRole
      }
      username
      invitedTimestamp
      createdTimestamp
      status
    }
  }
`;

export const costCenterCountForApprover = gql`
  query costCenterCountForApprover($membershipId: String!) {
    costCenterCountForApprover(membershipId: $membershipId)
  }
`;

export const costCenterArchivedCountForApprover = gql`
  query costCenterArchivedCountForApprover($membershipId: String!) {
    costCenterArchivedCountForApprover(membershipId: $membershipId)
  }
`;

export const workflowTemplatesCountForApprover = gql`
  query getCountOfWorkflowTemplatesByApproverId($approverId: String!) {
    getCountOfWorkflowTemplatesByApproverId(approverId: $approverId) {
      active
      archived
      all
    }
  }
`;

export const countPendingDocumentsByMembership = gql`
  query countPendingDocumentsByMembership($membershipId: String!) {
    countPendingDocumentsByMembership(membershipId: $membershipId) {
      count
    }
  }
`;

export const creditCardInvolvementsByMembershipId = gql`
  query creditCardInvolvements($membershipId: String!) {
    creditCardInvolvements(input: { membershipId: $membershipId }) {
      cards {
        total
      }
      cardholder {
        id
      }
    }
  }
`;

export const userInvolvementForEcmDocuments = gql`
  query getUserInvolvementForEcmDocuments($membershipId: String!) {
    getUserInvolvementForEcmDocuments(membershipId: $membershipId) {
      responsiblePerson {
        count
      }
      notifyPerson {
        count
      }
    }
  }
`;

export const getAllUsersEmailsQuery = gql`
  query getAllUsersEmails(
    $filters: OrganizationMembershipsFiltersInput
    $sortBy: MemberSortInput
  ) {
    organizationMemberships(filters: $filters, sortBy: $sortBy) {
      email
    }
  }
`;

export const getApproversQuery = gql`
  query approvers($name: String) {
    approvers(name: $name) {
      id
      name
      roles
      avatarUrl
    }
  }
`;

export const organizationMembershipCountByStatusQuery = gql`
  query organizationMembershipCountByStatus {
    organizationMembershipCountByStatus {
      active
      inActive
      pending
    }
  }
`;

export const teamFieldOptionsQuery = gql`
  query teamFieldOptions(
    $input: TeamsPageBasedPaginationInput!
    $queries: TeamQueryInput
    $filters: TeamsFilterInput
    $sortBy: TeamsSortInput
  ) {
    teams(
      input: $input
      queries: $queries
      filters: $filters
      sortBy: $sortBy
    ) {
      records {
        id
        name
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const selectedTeamFieldOptionsQuery = gql`
  query selectedTeamsOptions(
    $input: TeamsPageBasedPaginationInput!
    $queries: TeamQueryInput
    $filters: TeamsFilterInput
    $sortBy: TeamsSortInput
  ) {
    teams(input: $input, queries: $queries, filters: $filters, sortBy: $sortBy)
      @connection(
        key: "selectedTeamsOptions"
        filter: ["filters", "sort", "queries"]
      ) {
      records {
        id
        name
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const teamInvolvementsByMembershipIdQuery = gql`
  query teamInvolvementsByMembershipId(
    $input: TeamsPageBasedPaginationInput!
    $filters: TeamsFilterInput
    $sortBy: TeamsSortInput
  ) {
    teamInvolvementsByMembershipId: teams(
      input: $input
      filters: $filters
      sortBy: $sortBy
    )
      @connection(
        key: "teamInvolvementsByMembershipId"
        filter: ["filters", "sort"]
      ) {
      records {
        id
        name
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }
`;

export const getAccountPayableNumberByMembershipId = gql`
  query getAccountPayableNumberByMembershipId($membershipId: String!) {
    contactByEmployeeMembershipId(membershipId: $membershipId) {
      id
      name {
        value
      }
      accountsPayableNumber
    }
  }
`;

export const getContactEmployeeTypeByMembershipId = gql`
  query getContactEmployeeTypeByMembershipId($membershipId: String!) {
    contactByEmployeeMembershipId(membershipId: $membershipId) {
      id
      name {
        value
      }
      employeeMembership {
        id
      }
    }
  }
`;

export const getNextFreeAccountsPayableNumberForEmployeesQuery = gql`
  query getNextFreeAccountsPayableNumberForEmployees($input: String!) {
    getNextFreeAccountsPayableNumberForEmployees(input: $input)
  }
`;

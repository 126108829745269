import { useEcm } from 'orgConfig/ecm/useEcm';
import { AdvancedSearchFieldFilterOverlayPromo } from './AdvancedSearchFieldFilterOverlayPromo';
import { AdvancedSearchFieldFilterOverlay } from './AdvancedSearchFieldFilterOverlay';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { AdvancedSearchFieldFilterOverlayBase } from './AdvancedSearchFieldFilterOverlayBase';

interface AdvancedSearchFieldFilterOverlayContainerProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlayContainer({
  onApply,
  onShowPromoModal,
}: AdvancedSearchFieldFilterOverlayContainerProps) {
  const { showSearchOverlayPromo } = useEcm();
  const currentEntitlement = useEntitlementsFlag();

  if (currentEntitlement === Entitlements.BASE) {
    return (
      <AdvancedSearchFieldFilterOverlayBase
        onApply={onApply}
        onShowPromoModal={onShowPromoModal}
      />
    );
  }

  if (showSearchOverlayPromo) {
    return (
      <AdvancedSearchFieldFilterOverlayPromo
        onShowPromoModal={onShowPromoModal}
      />
    );
  }

  return (
    <AdvancedSearchFieldFilterOverlay
      onShowPromoModal={onShowPromoModal}
      onApply={onApply}
    />
  );
}

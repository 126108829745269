import {
  Query,
  ReimbursementCasesFilterInput,
  ReimbursementCasesSortInput,
  ReimbursementCasesSortOrder,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { openReimbursementsCursorBasedQuery } from '../toolkit/queries';
import { useFormattedReimbursement } from './useFormattedReimbursement';

export const defaultReimbursementDashboardSort: ReimbursementCasesSortInput = {
  createdAt: ReimbursementCasesSortOrder.Desc,
};

interface UseOpenReimbursementsCursorBasedDataProps {
  filters?: ReimbursementCasesFilterInput;
}

export const useOpenReimbursementsCursorBasedData = ({
  filters,
}: UseOpenReimbursementsCursorBasedDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const toReimbursement = useFormattedReimbursement();

  const getVariables = (cursor?: string) => {
    return {
      limit: 20,
      filters,
      sorts: defaultReimbursementDashboardSort,
      next: cursor,
    };
  };

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'archiveReimbursementCases'>
  >(
    openReimbursementsCursorBasedQuery,
    'archiveReimbursementCases',
    {
      variables: getVariables(),
      skip: !canUseReimbursement,
    },
    { computeVariables: getVariables }
  );

  const { pageInfo, edges } = data?.archiveReimbursementCases ?? {};

  const totalReimbursements = pageInfo?.totalCount ?? 0;

  const reimbursementList = (edges ?? []).map(edge => ({
    ...toReimbursement({
      cursor: edge.cursor,
      ...edge.node,
    }),
  }));

  return {
    loading,
    loadMore: onLoadMore,
    reimbursementList,
    totalReimbursements,
  };
};

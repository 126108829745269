import { Flex, Grid, Text } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteReimbursementItemAction } from '../DeleteReimbursementItem';
import { ExpenseFormWrapper } from '../ExpenseFormWrapper';
import { ExpenseInvoiceInputField } from '../ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from '../ExpenseInvoicePreviewContainer';
import { ReimbursementSwitchField } from '../ReimbursementsSwitchField';
import { generateGeneralExpenseFormFieldNames } from '../utils/generateGeneralExpenseFormFieldNames';
import { BooleanMap } from '../utils/useExpensesFormActions';
import { FieldsOptions } from '../utils/useFieldsOptions';
import { ReadonlyGeneralExpensesFormDeprecated } from './ReadonlyGeneralExpensesFormDeprecated';

interface GeneralExpensesFormDeprecatedProps {
  isReadOnly: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateExpense: (index: number) => () => Promise<void>;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  isCreatingInvoice: BooleanMap;
  fieldOptions: FieldsOptions;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
}
export const GeneralExpensesFormDeprecated = ({
  isReadOnly,
  reimbursementStatus,
  clearableField,
  expenseId,
  formIndex,
  formMethods,
  isCreatingInvoice,
  isDeletingForm,
  fieldOptions,
  onCleareableField,
  onInvoiceChange,
  onUpdateExpense,
  onRemoveForm,
  canManageReimbursementItems,
}: GeneralExpensesFormDeprecatedProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { watch, getValues } = formMethods;
  const { isExcluded: isExcludedFieldOptions } = fieldOptions;

  const fields = generateGeneralExpenseFormFieldNames(formIndex);

  const invoices = watch(fields.files);

  const invoice = invoices?.[0];
  const isExcluded = watch(fields.isExpenseExcluded);

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const itemStatus = getValues(fields.itemStatus);
  const showInvoicePreview =
    (isCreatingInvoice[formIndex] ?? false) || !!invoice;

  const isExcludedInfoVisible =
    isExcluded && isExcludedFieldOptions.showInfoIsExcluded;

  if (isReadOnly) {
    return (
      <ReadonlyGeneralExpensesFormDeprecated
        expenseId={expenseId}
        reason={reason}
        invoice={invoice}
        formIndex={formIndex}
        totalAmount={totalAmount}
        reimbursementStatus={reimbursementStatus}
        itemStatus={itemStatus}
        isExcluded={isExcluded}
      />
    );
  }

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="general"
      reason={reason}
      isExcluded={isExcluded}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
    >
      <Grid as="form" gap="space16">
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.reason}
            maxLength={60}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            ariaLabel={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.reason.placeholder'
            )}
            clearable={clearableField === fields.reason}
            onFocus={onCleareableField(fields.reason)}
            inputProps={{
              onBlur: onUpdateExpense(formIndex),
            }}
          />
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly={isExcluded}
            label={t('reimbursementView.middleSection.form.general.date.label')}
            onBlur={onUpdateExpense(formIndex)}
            clearable={clearableField === fields.expenseDate}
            onFocus={onCleareableField(fields.expenseDate)}
          />
        </Grid>
        <Grid columns={2} gap="space8">
          <HookFormTextField
            name={fields.invoiceNumber}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.placeholder'
            )}
            clearable={clearableField === fields.invoiceNumber}
            onFocus={onCleareableField(fields.invoiceNumber)}
            inputProps={{
              onBlur: onUpdateExpense(formIndex),
            }}
          />
          <HookFormAmountField
            name={fields.totalAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.general.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.totalAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.totalAmount}
            onFocus={onCleareableField(fields.totalAmount)}
            onBlur={onUpdateExpense(formIndex)}
          />
        </Grid>
        {showInvoicePreview && (
          <ExpenseInvoicePreviewContainer
            name={fields.files}
            invoice={invoice}
            expenseType="general"
            createInvoicePending={isCreatingInvoice[formIndex]}
            id={`invoice-picker-${fields.files}`}
            onChange={async file => await onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateExpense(formIndex)}
            isExcluded={isExcluded}
          />
        )}
        {!showInvoicePreview && (
          <ExpenseInvoiceInputField
            name={fields.files}
            id={`invoice-input-field-${fields.files}`}
            onChange={async file => await onInvoiceChange(file, formIndex)}
            onInvoiceUpdate={onUpdateExpense(formIndex)}
          />
        )}
        {isExcludedFieldOptions.isVisible && (
          <ReimbursementSwitchField
            isReadOnly={isExcludedFieldOptions.isReadOnly}
            name={fields.isExpenseExcluded}
            onChange={onUpdateExpense(formIndex)}
            label={t(
              'reimbursementView.middleSection.form.general.excludeItem.label'
            )}
          />
        )}

        {(isExcludedInfoVisible || canManageReimbursementItems) && (
          <Flex justifySelf="end" alignItems="center" gap="space4">
            {isExcludedInfoVisible && (
              <Text fontSize="basic">
                {t(
                  'reimbursementView.middleSection.form.general.expenseIsExcludedInfo'
                )}
              </Text>
            )}
            {canManageReimbursementItems && (
              <DeleteReimbursementItemAction
                loading={isDeletingForm[formIndex]}
                onDelete={onRemoveForm(formIndex, expenseId)}
              />
            )}
          </Flex>
        )}
      </Grid>
    </ExpenseFormWrapper>
  );
};

import {
  ComboBoxFieldOptions,
  PaginatedComboBoxFieldOptions,
} from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import {
  ArtistSocialInsuranceCode,
  BookingKey,
  IntegrationName,
  Maybe,
  PurchaseOrderType,
  SapExpenseType,
} from 'generated-types/graphql.types';
import { FieldSources } from 'models';
import { FormLabelValue } from 'types/FormLabelValue';

export enum TaxPresentation {
  Gross = 'Gross',
  Net = 'Net',
}
export interface CommonFieldOptions {
  readOnly?: boolean;
}

export type Split = {
  id?: string | null;
  amount?: Maybe<number>;
  bookingKeyId?: Maybe<FormLabelValue>;
  bookingKey?: Maybe<FormLabelValue>;
  costCenterId?: Maybe<FormLabelValue>;
  costObjectId?: Maybe<FormLabelValue>;
  extraCostInfoId?: Maybe<FormLabelValue>;
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  dueDate?: Maybe<Date>;
  generalLedgerAccountId?: Maybe<FormLabelValue>;
  note?: Maybe<string>;
  postingText?: Maybe<string>;
  taxPresentation?: TaxPresentation;
  vatRate?: Maybe<number>;
  quantity?: Maybe<number>;
  taxAmount?: Maybe<number>;
  netAmount?: Maybe<number>;
  sapExpenseType?: SapExpenseType;
  unitPrice?: Maybe<number>;
  projectCodeId?: Maybe<FormLabelValue>;
};

export type SmartFields = {
  costCenter?: {
    source: FieldSources;
    confidence: number;
    id: string;
    readableName: string;
  };
  costObject?: {
    source: FieldSources;
    confidence: number;
    id: string;
    readableName: string;
  };
  generalLedgerAccount?: {
    source: FieldSources;
    confidence: number;
    id: string;
    readableName: string;
  };
  bookingKey?: {
    source: FieldSources;
    confidence: number;
    id: string;
    readableName: string;
  };
};

export enum SplitBookingFields {
  grossAmount = 'grossAmount',
  currency = 'currency',
  amount = 'amount',
  generalLedgerAccountId = 'generalLedgerAccountId',
  bookingKeyId = 'bookingKeyId',
  costCenterId = 'costCenterId',
  costObjectId = 'costObjectId',
  extraCostInfoId = 'extraCostInfoId',
  artistSocialInsuranceCode = 'artistSocialInsuranceCode',
  dueDate = 'dueDate',
  note = 'note',
  postingText = 'postingText',
  vatRate = 'vatRate',
  quantity = 'quantity',
  netAmount = 'netAmount',
  taxAmount = 'taxAmount',
  taxPresentation = 'taxPresentation',
  unitPrice = 'unitPrice',
  projectCodeId = 'projectCode',
}
export type SplitDS = {
  id?: string | null;
  amount?: Maybe<number>;
  taxAmount?: Maybe<number>;
  netAmount?: Maybe<number>;
  artistSocialInsuranceCode?: Maybe<ArtistSocialInsuranceCode>;
  dueDate?: Maybe<string>;
  note?: Maybe<string>;
  postingText?: Maybe<string>;
  taxPresentation?: TaxPresentation;
  vatRate?: Maybe<number>;
  quantity?: Maybe<number>;
  taxCode?: Maybe<string>;
  generalLedgerAccount?: { value?: Maybe<string>; inputValue?: Maybe<string> };
  costCenter?: { value?: Maybe<string>; inputValue?: Maybe<string> };
  costObject?: { value?: Maybe<string>; inputValue?: Maybe<string> };
  extraCostInfo?: { value?: Maybe<string>; inputValue?: Maybe<string> };
  sapExpenseType?: SapExpenseType; // freight and packaging
  unitPrice?: Maybe<number>;
  projectCode?: { value?: Maybe<string>; inputValue?: Maybe<string> };
};
export type IsFieldEditable = (
  field: SplitBookingFields,
  bookingIndex: number
) => boolean;

export type ShouldShowField = (
  field: SplitBookingFields,
  bookingIndex: number
) => boolean | undefined;
export interface AcceptedValuesDS {
  bookings: SplitDS[];
  amount?: Maybe<number>;
  currency?: Maybe<string>;
}
export type SplitBookingsFormPropsDS = {
  purchaseOrderType?: PurchaseOrderType;
  documentDirection?: string;
  initialOpenIndex?: number;
  showSubmitErrors?: boolean;
  initialValues: {
    grossAmount: Maybe<number>;
    currency: Maybe<string>;
    invoiceDate: Maybe<string>;
    bookings?: SplitDS[];
    roundingAmount?: Maybe<number>;
  };
  isFieldEditable: IsFieldEditable;
  mode: ProcessingFormValues['mode'];
  onAcceptBookings?: (values: AcceptedValuesDS) => void;
  onDiscardBookings?: () => void;
  readOnly?: boolean;
  shouldShowField: ShouldShowField;
  isGoodsPurchaseOrderLinked?: boolean;
};

export interface SplitBookingsFormFieldOptions {
  /** Options for artist social insurance code field */
  artistSocialInsuranceCode?: {
    hidden?: boolean;
    props?: ComboBoxFieldOptions;
  };
  /** Options for cost center field */
  costCenter?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for cost object field */
  costObject?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for extra cost info field */
  extraCostInfo?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  accountingArea?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for general ledger account field */
  generalLedgerAccount?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for tax code field */
  taxCode?: {
    hidden?: boolean;
    props?: ComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
  /** Options for posting text field */
  postingText?: {
    hidden?: boolean;
    props?: CommonFieldOptions;
  };
  isLoading?: boolean;
  /** Options for quantity field */
  quantity?: {
    hidden?: boolean;
  };
  /** Options for project code field */
  projectCode?: {
    hidden?: boolean;
    props?: PaginatedComboBoxFieldOptions<ProcessingFormAccountingDataFieldItem>;
  };
}

export interface SplitBookingsFormSchemaOptions {
  availableTaxCodes?: Array<
    Pick<BookingKey, 'id' | 'taxCode' | 'isAcquisitionReverse'>
  >;
  documentDirection?: string;
  hasCostCenters?: boolean;
  hasCostObjects?: boolean;
  isFormReadOnly?: boolean;
  integration?: IntegrationName;
  mode?: ProcessingFormValues['mode'];
  shouldUseSapPurchaseOrder?: boolean;
  shouldUseSapNetAmount?: boolean;
  shouldRequireGeneralLedgerAccount?: boolean;
  shouldRequireTaxCode?: boolean;
  invoiceDate?: Maybe<string>;
  shouldRequireQuantity?: boolean;
  hasReversedTaxCodes?: boolean;
}

import { ReimbursementsPromotion } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotion';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { ReimbursementTableColumnKeys } from 'views/Reimbursement/toolkit/types';

const tableColumns: ReimbursementTableColumnKeys[] = [
  'status',
  'requesterOfTheReimbursement',
  'requester',
  'approvers',
  'grossAmount',
  'title',
  'createdAt',
  'approvalRequestedAt',
];

export const ApprovalReimbursementsPromotion = () => {
  return (
    <ReimbursementsPromotion
      tableColumns={tableColumns}
      status={ReimbursementCaseStatus.Approved}
    />
  );
};

import { Grid, Tag, Text, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { useReimbursementTags } from '../hooks/useReimbursementTags';

interface ReimbursementItemProps extends Reimbursement {
  onClick: (id: string, cursor: string | undefined) => void;
}

export const ReimbursementItem = ({
  id,
  status,
  createdAt,
  title,
  totalAmount,
  cursor,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const tags = useReimbursementTags();

  return (
    <Grid
      onClick={() => onClick(id, cursor)}
      gap="space24"
      cursor="pointer"
      alignItems="center"
      padding="space16"
      borderBottom="1px solid gray200"
      templateColumns="100px 1fr auto"
    >
      <Tag variant="secondary" color={tags[status].color}>
        <TruncatedText maxWidth="12ch">{tags[status].text}</TruncatedText>
      </Tag>
      <Grid templateRows="auto auto" gap="space2">
        <TruncatedText fontSize="basic" fontWeight="semibold">
          {title ?? t('dashboard.list.reimbursementItemPlaceholder')}
        </TruncatedText>
        <TruncatedText color="gray500">
          {t('dashboard.list.createdAt', { createdAt })}
        </TruncatedText>
      </Grid>
      <Text fontSize="basic" fontWeight="semibold">
        {totalAmount}
      </Text>
    </Grid>
  );
};

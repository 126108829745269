import { Slot, SlotProps } from '@candis/utils';
import clsx from 'clsx';
import { HTMLProps } from 'react';
import styles from './SwimlaneTile.module.css';

type SwimlaneTileProps = HTMLProps<HTMLDivElement> &
  SlotProps & {
    cols?: 1 | 2;
    variant?: 'blue' | 'gray' | 'purple' | 'red' | 'white' | 'yellow';
  };

export const SwimlaneTile = ({
  asChild,
  cols = 1,
  className,
  variant = 'white',
  ...otherProps
}: SwimlaneTileProps) => {
  const Child = asChild ? Slot : 'div';
  const classes = clsx(styles.tile, className, {
    [styles['tile--blue']]: variant === 'blue',
    [styles['tile--gray']]: variant === 'gray',
    [styles['tile--purple']]: variant === 'purple',
    [styles['tile--red']]: variant === 'red',
    [styles['tile--white']]: variant === 'white',
    [styles['tile--yellow']]: variant === 'yellow',
  });

  return <Child className={classes} {...otherProps} />;
};

import {
  ReimbursementCasesSortInput,
  ReimbursementCasesSortOrder,
} from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from '../toolkit/types';

type SortableColumns = Extract<
  ReimbursementTableColumnKeys,
  | 'status'
  | 'createdAt'
  | 'paidAt'
  | 'grossAmount'
  | 'requesterOfTheReimbursement'
  | 'title'
  | 'firstSubmittedForReviewAt'
  | 'lastApprovedByMembership'
  | 'approvalRequestedAt'
>;

const mappedColumnAccessorsToSortInput: Record<
  SortableColumns,
  keyof ReimbursementCasesSortInput
> = {
  status: 'status',
  createdAt: 'createdAt',
  grossAmount: 'totalAmount',
  paidAt: 'paidAt',
  requesterOfTheReimbursement: 'targetMembershipName',
  title: 'title',
  firstSubmittedForReviewAt: 'firstSubmittedToReviewAt',
  lastApprovedByMembership: 'lastApprovedByName',
  approvalRequestedAt: 'lastSubmittedToApprovalAt',
};

export const mapToReimbursementSortInput = (
  sortBy: SortingRule<ReimbursementTableRow>[]
): ReimbursementCasesSortInput | undefined => {
  if (sortBy.length < 1) {
    return undefined;
  }

  const [firstSort] = sortBy;

  const { id, desc } = firstSort;

  const fieldId = mappedColumnAccessorsToSortInput[id as SortableColumns];

  return {
    [fieldId]: desc
      ? ReimbursementCasesSortOrder.Desc
      : ReimbursementCasesSortOrder.Asc,
  };
};

import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { getContractsData } from 'containers/Entitlements/components/Upsell/dummyData';
import { noop } from 'lodash';
import { useGetEcmContractsTableConfigs } from 'views/Archive/EcmContracts/hooks/useGetEcmContractsTableConfigs';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { TabView } from 'views/Inbox/models';
import { PromotionWrapper, TableWrapper, Wrapper } from '../Wrapper';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Locale } from 'generated-types/resolvers-types';

const columnIds: (keyof EcmDocumentsTableData)[] = [
  'contractStatus',
  'contact',
  'contractType',
  'documentName',
  'costCenter',
  'responsiblePerson',
];

export const ContractsPromotionArchive = () => {
  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_CONTRACTS}>
      <ContractsPromotion />
    </ArchiveViewLayout>
  );
};

export const ContractsPromotion = () => {
  const { contractManagementPromotion } = usePromotionContent();
  const { configurationsTable } = useGetEcmContractsTableConfigs({
    filters: [],
    sortBy: [],
  });
  const user = useCurrentUser();

  return (
    <Wrapper>
      <TableWrapper>
        <EcmDocumentsTable
          context="contracts"
          columns={columnIds}
          configurationsTable={configurationsTable}
          data={getContractsData(user?.locale ?? Locale.En)}
          selectedDocumentsCount={0}
          onSearchChange={noop}
          onRowClick={noop}
          onFilter={noop}
          onEndReached={noop}
          onUpdateConfigurations={noop}
          onSort={noop}
          onResetTableConfigurations={noop}
        />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[contractManagementPromotion]}>
          <PromotionCard
            promotion={contractManagementPromotion}
            closable={false}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { RelatedDocumentContainer } from 'components/DocumentViewer/RelatedDocumentContainer';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { TransactionDetailsCardContextMenu } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCardContextMenu';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import {
  DocumentHistoryComponent,
  DocumentSummarySection,
} from 'containers/document-summary/DocumentSummarySection';
import {
  DocumentStatus,
  EcmDocumentStatus,
  Money,
  useGetDocumentForDraftQuery,
  useUnlinkTransactionFromDocumentMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useAttachments } from 'hooks/useAttachments/useAttachments';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSap } from 'orgConfig/sap';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useLocation, useParams } from 'react-router-dom';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { AttachmentsSegment } from 'views/Inbox/DocumentProcessing/components/AttachmentsSegment';
import { DocumentRelationsSegment } from 'views/Inbox/DocumentProcessing/components/DocumentRelationsSegment';
import { DocumentSummaryInformation } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryInformation';
import { DocumentSummaryInformationDeprecated } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryInformationDeprecated';
import { getDocumentQuery } from 'views/Inbox/DocumentProcessing/queries';
import { usePrefetchPdfData } from 'views/Inbox/DocumentProcessing/util/usePrefetchPdfData';
import { getCardIssuerTransactionById } from 'views/TransactionAssociation/gql';
import { documentHistoryQuery } from 'views/queries';
import { userCanApprove, userIsMonitoring } from '../Approvals/Invoices/utils';
import { ExpandingDrawer, MAX_DRAWER_WIDTH } from './ExpandingDrawer';
import { Approved } from './components/Approved/Approved';
import { Archived } from './components/Archived/Archived';
import { DocumentTypeContextProvider } from './components/DocumentTypeContext';
import { Exported } from './components/Exported/Exported';
import { Monitoring } from './components/Monitoring';
import { New } from './components/New';
import { Open } from './components/Open';
import { PurchaseOrderSectionContainer } from './components/PurchaseOrderSection/PurchaseOrderSectionContainer';
import { Rejected } from './components/Rejected';
import { TransactionSection } from './components/TransactionSection';

interface RouteParams {
  [AppRouteParams.organizationSlug]: string;
  documentId: string;
  type: Routes;
}

const editableStatuses = [
  DocumentStatus.New,
  DocumentStatus.Open,
  DocumentStatus.Approved,
  DocumentStatus.Rejected,
] as const;

export const DocumentDetails = () => {
  const [t] = useTranslation();
  const showGqlError = useShowError();
  const { success, error } = useToastMessage();

  const currentUser = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const { isOnlyApprover } = useUserRoles();
  const { shouldUseSapPurchaseOrder } = useSap();
  const { showDocumentRelations, showDocumentRelationsImproved } = useEcm();

  const { isComparisonView } = useComparisonView();

  const DocumentSummaryInformationComponent = showDocumentRelationsImproved
    ? DocumentSummaryInformation
    : DocumentSummaryInformationDeprecated;

  const { organizationSlug, documentId } = useParams<RouteParams>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [contactItem, setContactItem] =
    useState<ProcessingFormContactFieldItem>();

  const { data, loading } = useGetDocumentForDraftQuery({
    variables: {
      id: documentId,
    },
  });

  const document = data?.getDocument;
  const documentStatus = document?.status ?? EcmDocumentStatus.New;
  const documentAmount = document?.amount?.value ?? undefined;
  const documentCurrency = document?.currency?.value ?? undefined;
  const transactions = data?.getDocument?.transactions;
  const transaction = transactions?.[0];
  const transactionId = transaction?.id;
  const purchaseOrderId = data?.getDocument?.purchaseOrderData?.purchaseOrderId;
  const contactName =
    data?.getDocument?.contact?.value.name?.value ??
    data?.getDocument?.extractedContact?.name?.value;

  const accountsPayableNumber =
    data?.getDocument?.contact?.value?.accountsPayableNumber;

  const globalDocumentId = data?.getDocument?.globalDocumentId ?? '';

  const {
    documentFile: mainDocumentFile,
    documentAttachments,
    isDocumentFileLoading,
  } = useDocumentFile({ documentId });

  const toggleEditMode = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}/:${DocumentProcessingRouteParams.documentId}`,
      { organizationSlug, documentId }
    );

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('isArchived', '1');

    navigate({ pathname, search: newSearchParams.toString() });
  };

  const currentDocumentCursor = searchParams.get('cursor');
  const isInvoice = searchParams.get('isInvoice');

  const {
    prevDocumentId,
    prevDocumentLink,
    nextDocumentId,
    nextDocumentLink,
    linkBackToList,
    navigationLoading,
  } = useFetchDocNavigationData({
    cursor: currentDocumentCursor,
    route: isInvoice ? Routes.ECM_DOCUMENTS : Routes.ARCHIVE,
    organizationSlug,
  });

  const gotoPreviousDocument = () => {
    if (prevDocumentLink) {
      navigate(prevDocumentLink);
    }
  };

  const gotoNextDocument = () => {
    if (nextDocumentLink) {
      navigate(nextDocumentLink);
    }
  };

  const redirectTo = (url: string) => {
    navigate(url);
  };

  // If there's no next document and no previous document mark as all Done!
  const cycleDocument = () => {
    if (nextDocumentLink) {
      gotoNextDocument();
    } else if (prevDocumentLink) {
      gotoPreviousDocument();
    } else {
      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}`,
        { organizationSlug }
      );

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('isArchived', '1');

      navigate({
        pathname,
        search: newSearchParams.toString(),
      });
    }
  };

  usePrefetchPdfData({ nextDocumentId, prevDocumentId });

  const handleGetContactItem = useCallback(
    (value?: ProcessingFormContactFieldItem) => {
      setContactItem(value);
    },
    []
  );

  const componentArgs = {
    redirectTo,
    organizationSlug,
    loading,
    document,
    prevId: prevDocumentId,
    nextId: nextDocumentId,
    toggleEditMode,
    gotoNextDocument,
    gotoPreviousDocument,
    cycleDocument,
    purchaseOrderId,
    contactItem,
    onGetContactItem: handleGetContactItem,
  };

  let Content = (_props: any): JSX.Element | null => null;

  if (document) {
    if (document.status === DocumentStatus.Open) {
      const isMonitoringView =
        userIsMonitoring(document, currentUser) ||
        !userCanApprove(document, currentUser);

      Content = isMonitoringView ? Monitoring : Open;
    }

    if (document.status === DocumentStatus.New) Content = New;
    if (document.status === DocumentStatus.Approved) Content = Approved;
    if (document.status === DocumentStatus.Exported) Content = Exported;
    if (document.status === DocumentStatus.Rejected) Content = Rejected;
    if (document.status === DocumentStatus.Archived) Content = Archived;
  }

  const { attachPermissions, attachments, selectedPdf, setSelectedPdf } =
    useAttachments({
      documentFile: mainDocumentFile,
      documentId,
      documentAttachments,
    });

  const [
    unlinkTransactionFromDocumentMutation,
    { loading: isUnlinkingLoading },
  ] = useUnlinkTransactionFromDocumentMutation();

  const counterQueries = useCounterQueries();

  const unlinkTransaction = async () => {
    try {
      if (!transaction?.id) {
        return;
      }

      const { data, errors } = await unlinkTransactionFromDocumentMutation({
        variables: { transactionId: transaction.id, documentId },
        refetchQueries: [
          { query: getDocumentQuery, variables: { id: documentId } },
          { query: documentHistoryQuery, variables: { id: documentId } },
          {
            query: getCardIssuerTransactionById,
            variables: { id: transaction.id },
          },
          ...counterQueries,
        ],
      });

      if (data?.unlinkTransactionFromDocument) {
        success(
          t('transactions:transactionDetailsCardContextMenu.successMessage')
        );
      } else if (errors?.length) {
        error(t('transactions:transactionDetailsCardContextMenu.errorMessage'));
      }
    } catch (err) {
      showGqlError(err as GQLError);

      return validationErrorsExtractor(err);
    }
  };

  const { state } = useLocation<{ from: string; search: string }>();

  const goBackToList = () => {
    if (state?.from) {
      return navigate({
        pathname: state.from,
        search: state.search,
      });
    }

    navigate(linkBackToList);
  };

  const isDocumentOpen = document?.status === DocumentStatus.Open;

  const hasDocumentAmount =
    documentCurrency !== undefined && documentAmount !== undefined;

  const amount = useMemo(
    () =>
      !hasDocumentAmount
        ? null
        : ({
            __typename: 'Money',
            amount: documentAmount,
            currency: documentCurrency,
            precision: 2,
          } satisfies Money),
    [documentAmount, documentCurrency, hasDocumentAmount]
  );

  const showTransactionSection =
    !showDocumentRelations && creditCardsSetup.isInUse;

  const showPurchaseOrderSection = shouldUseSapPurchaseOrder && !transactionId;

  const attachmentsTitle = t('document.tabs.oldAttachments.title');
  const relationsTitle = t('document.tabs.relationships.title');

  const isTransactionEditable: boolean =
    !!document?.status && editableStatuses.includes(document.status as any);

  const canAddTransaction =
    creditCardsSetup.isInUse && isTransactionEditable && !transaction;

  const hasContextMenu =
    isTransactionEditable && !isOnlyApprover && !!document?.status;

  const transactionSection = (
    <TransactionSection
      loading={loading}
      documentId={documentId}
      transaction={dataToTransactionDetails(transaction)}
      canAddTransaction={canAddTransaction}
      contextMenu={
        hasContextMenu && (
          <TransactionDetailsCardContextMenu
            documentStatus={document.status}
            onUnlinkTransaction={unlinkTransaction}
            isUnlinkPending={isUnlinkingLoading}
          />
        )
      }
    />
  );

  return (
    <DocumentTypeContextProvider key={documentId} initiallyAnInvoice>
      <DetailsLayout
        key={documentId}
        leftSection={
          <>
            <LeftSectionOuterWrapper>
              <ListNavigator
                backToListText={t('document.backToDocumentList')}
                arrowLeftTooltip={t('document.previousDocument')}
                arrowRightTooltip={t('document.nextDocument')}
                onBackToList={goBackToList}
                onNext={nextDocumentLink ? gotoNextDocument : undefined}
                onPrev={prevDocumentLink ? gotoPreviousDocument : undefined}
                loading={navigationLoading}
              />
              <LeftSectionContentWrapper>
                {/*
                  // cursed: Chrome browser renders scrollbars outside component's border
                  // we add static ScrollBox to reserve same gutter space as for
                  // scrollable DocumentSummarySection
                */}
                <ScrollBox scrollDirection="none" scrollbarGutter="stable">
                  <DocumentSummaryInformationComponent
                    documentId={documentId}
                    isEInvoice={Boolean(document?.isEInvoice)}
                    eInvoiceValidationErrors={
                      document?.eInvoiceValidationErrors
                    }
                    eInvoice={document?.eInvoice}
                    amount={amount}
                    invoiceDate={document?.invoiceDate?.value}
                    invoiceNumber={document?.invoiceNumber?.value}
                    documentStatus={document?.status}
                    contactName={contactName}
                    selectedContactEmail={contactItem?.email}
                    isLoading={isDocumentFileLoading}
                    documentFile={mainDocumentFile}
                    attachments={attachments}
                    attachPermissions={attachPermissions}
                    onAttachmentClick={setSelectedPdf}
                    selectedPdf={selectedPdf}
                    transactionSection={transactionSection}
                  />
                </ScrollBox>
                <ScrollBox scrollDirection="y" scrollbarGutter="stable">
                  <Grid gap="space8">
                    {showPurchaseOrderSection && (
                      <PurchaseOrderSectionContainer
                        loading={loading}
                        documentId={documentId}
                        documentStatus={document?.status}
                        documentAmount={document?.amount?.value}
                        contactName={
                          isDocumentOpen ? contactName : contactItem?.children
                        }
                        accountsPayableNumber={
                          isDocumentOpen
                            ? accountsPayableNumber
                            : contactItem?.accountsPayableNumber
                        }
                      />
                    )}
                    {showTransactionSection && transactionSection}
                    {!showDocumentRelationsImproved && (
                      <DocumentSummarySection
                        globalDocumentId={globalDocumentId}
                        documentId={documentId}
                        documentName={mainDocumentFile.name}
                        canAddTransaction={canAddTransaction}
                        documentStatus={documentStatus}
                      />
                    )}
                    <DocumentHistoryComponent documentId={documentId} />
                  </Grid>
                </ScrollBox>
              </LeftSectionContentWrapper>
            </LeftSectionOuterWrapper>
            {showDocumentRelationsImproved && (
              <Grid
                maxWidth={MAX_DRAWER_WIDTH}
                height="100cqh"
                overflow="hidden"
              >
                <ExpandingDrawer
                  key="ATTACHMENTS"
                  drawer="ATTACHMENTS"
                  title={attachmentsTitle}
                >
                  <AttachmentsSegment
                    documentId={documentId}
                    documentFile={mainDocumentFile}
                    attachments={attachments}
                    attachPermissions={attachPermissions}
                    onAttachmentClick={setSelectedPdf}
                    selectedPdf={selectedPdf}
                  />
                </ExpandingDrawer>
                <ExpandingDrawer
                  key="RELATIONSHIPS"
                  drawer="RELATIONSHIPS"
                  title={relationsTitle}
                >
                  <DocumentRelationsSegment
                    globalDocumentId={globalDocumentId}
                  />
                </ExpandingDrawer>
              </Grid>
            )}
          </>
        }
        middleSection={
          <Grid
            height="100%"
            overflowY="auto"
            paddingTop="space32"
            columnGap={isComparisonView ? 'space16' : '0'}
            templateColumns={isComparisonView ? '1fr 1fr' : '0fr 1fr'}
          >
            <RelatedDocumentContainer mainDocumentId={globalDocumentId} />
            <Box height="100%" overflowY="auto">
              <DocumentViewer
                documentId={documentId}
                documentFile={mainDocumentFile}
                selectedFile={selectedPdf}
                attachments={attachments}
                onSelectDocument={setSelectedPdf}
                canAttachFiles={attachPermissions}
                isLoadingPdf={isDocumentFileLoading}
                hideAttachmentSection={isComparisonView}
              />
            </Box>
          </Grid>
        }
        rightSection={<Content key={documentId} {...componentArgs} />}
      />
    </DocumentTypeContextProvider>
  );
};

import gql from 'graphql-tag';

export const archiveReimbursementsQuery = gql`
  query archiveReimbursementCases(
    $search: String
    $sorts: ReimbursementCasesSortInput
    $filters: ReimbursementCasesFilterInput
    $limit: Int
    $next: String
    $searchInput: SearchInput
  ) {
    archiveReimbursementCases(
      search: $search
      sorts: $sorts
      filters: $filters
      limit: $limit
      next: $next
      searchInput: $searchInput
    ) {
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
      edges {
        cursor
        node {
          id
          createdAt
          isPaid
          contact {
            iban
          }
          paidAt
          firstSubmittedForReviewAt
          title
          status
          lastApprovedByMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          targetMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
    }
  }
  
`;

import { Button, Card, Grid, TruncatedText } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { SendBackToEmployeeForm } from '../SendBackToEmploy/SendBackToEmployeeForm';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { ApproveNowAction } from './ApproveNowAction';
import { ErrorSection } from './ErrorSection';
import { FastApproveReturn } from './FooterActionBox';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { FooterHeader } from './FooterHeader';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';

interface ReviewStatusFooterActionBoxDeprecatedProps {
  createdById?: string;
  isFastApprovePending: boolean;
  isRequestAmendmentPending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  reimbursement?: Reimbursement;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
}

export const MIN_COLUMN_WIDTH = 151;
export const MIN_COLUMN_WIDTH_OTHER = 191;
const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ReviewStatusFooterActionBoxDeprecated = ({
  createdById,
  isFastApprovePending,
  isRequestAmendmentPending,
  onRequestAmendment,
  onFastApprove,
  getExpenses,
  reimbursement,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
}: ReviewStatusFooterActionBoxDeprecatedProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);
  const { hasReimbursementErrors } = useReimbursementFormsContext();
  const { hasBookingsErrors } = useReimbursementSplitBookingHelpers();

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyApprover } = useUserRoles();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;

  if (isOnlyApprover) return null;

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const isErrorSectionVisible = hasReimbursementErrors || hasBookingsErrors;

  if (isCommentFormVisible) {
    return (
      <SendBackToEmployeeForm
        onSetIsFormVisible={setIsCommentFormVisible}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
      />
    );
  }

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <FooterHeader
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
          refetchReimbursement={refetchReimbursement}
          refetchReimbursementItems={refetchReimbursementItems}
        />
        {isErrorSectionVisible && <ErrorSection />}
        <Grid gap="space8">
          {canFastApprove && (
            <ApproveNowAction
              onFastApprove={onFastApprove}
              getExpenses={getExpenses}
              isFastApprovePending={isFastApprovePending}
            />
          )}
          <Grid
            gap="space8"
            templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}
          >
            <Button
              color="blue"
              variant="secondary"
              onClick={() => setIsCommentFormVisible(true)}
            >
              <TruncatedText>{t(backTextKey)}</TruncatedText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

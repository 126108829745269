export const SETTINGS_ROUTES = {
  ACCOUNTING_AREAS: 'accounting_areas',
  CANDIS_API: 'candis_api',
  CONTRACT_SUBCATEGORIES: 'contract_subcategories',
  DOCUMENT_TYPES: 'document_types',
  MAIL: 'mail/:mailId?',
  MOBILE_APPLICATION: 'mobile_application',
  PAYMENT_CONDITIONS: 'payment_conditions',
  PAYMENT_CONDITIONS_IMPORT_HISTORY: 'payment_conditions/import_history',
  ROLES: 'roles',
  SINGLE_SIGN_ON: 'single-sign-on',
  TAGS: 'tags',
  TEAMS: 'teams',
  PROJECT_CODES: 'project_codes',
  PROJECT_CODES_IMPORT_HISTORY: 'project_codes/import_history',
} as const;

import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ContractSubCategoriesInfoPanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <InfoPanel
      title={t('contractCustomSubCategories.infoPanel.title')}
      intercomLink={
        <IntercomLink id="settings-contract-types-intercom-link">
          {t('contractCustomSubCategories.infoPanel.feedbackButton')}
        </IntercomLink>
      }
      sections={t('contractCustomSubCategories.infoPanel.sections', {
        returnObjects: true,
      })}
    />
  );
};

import { Box } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

export const ProvisionsPromotionSettings = () => {
  const { provisionsPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'datevSelfBooker',
    PROVISIONS_UTM_SOURCE.SETTINGS
  );

  return (
    <Box height="24rem">
      <PromotionsProvider promotions={[provisionsPromotion]}>
        <PromotionCard
          promotion={provisionsPromotion}
          closable={false}
          customPromotionDetails={customPromotionDetails}
        />
      </PromotionsProvider>
    </Box>
  );
};

import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  EcmDocumentsTable,
  EcmDocumentsTableProps,
  defaultSortByFields,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { DocumentPreviewForExpenseContainer } from 'containers/DocumentPreviewForExpense/DocumentPreviewForExpenseContainer';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { useEcmDocumentsDataWithExpenses } from 'containers/document-relationships/useEcmDocumentsDataWithExpenses';
import { DocumentType } from 'generated-types/graphql.types';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { useCallback, useMemo, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { generatePath } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

type Props = RouteComponentProps<{ organizationSlug: string }>;

interface PreviewDocument {
  id: string;
  type: string | undefined;
  reimbursementCaseId?: string;
}

export const EcmDocuments = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const {
    showDocumentPreview,
    showDocumentPreviewPromo,
    showConsistentSortAndFiltering,
  } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const [previewDocument, setPreviewDocument] = useState<
    PreviewDocument | undefined
  >(undefined);

  const previewDocumentId = previewDocument?.id ?? null;

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsDataWithExpenses({
    filterParamsSource: 'url',
    previewDocumentId,
  });

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = data.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (
        tableRow === undefined ||
        tableRow.documentType === DocumentType.Invoice
      ) {
        return 'invoice';
      }

      return 'ecmDocument';
    },
    [data]
  );

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocument({
                      id: isEcmDocument ? data.id : (data.invoiceId ?? ''),
                      type: data.documentType,
                      reimbursementCaseId: data.reimbursementCaseId,
                    });
                  }}
                  isSelected={
                    data.id === previewDocumentId ||
                    data.invoiceId === previewDocumentId
                  }
                />
              );
            }
          : undefined,
      [showPreview, getTableRowDocumentType, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
      documentType,
      reimbursementCaseId,
    }: {
      documentId: string | null;
      cursor: string | null;
      documentType: string | undefined;
      reimbursementCaseId?: string;
    }): void => {
      if (!documentId) return;

      let pathname = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}`;
      const params = new URLSearchParams();

      if (cursor) params.set('cursor', cursor);

      if (documentType === 'EXPENSE_REIMBURSEMENT_ITEM') {
        params.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
        pathname = `/${organizationSlug}${Routes.ECM_DOCUMENTS}${Routes.REIMBURSEMENTS}/${reimbursementCaseId}/${documentId}`;
      }

      if (isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `${pathname}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocument(undefined);
  }, []);

  const cursor =
    data.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
          promotionLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
        />
      );
    }

    if (showDocumentPreview) {
      const isExpenseReimbursementItem =
        previewDocument?.type === 'EXPENSE_REIMBURSEMENT_ITEM';
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          closePreviewDrawer={closePreview}
          documentPreviewView={
            isExpenseReimbursementItem
              ? DocumentPreviewForExpenseContainer
              : undefined
          }
          openDocument={() =>
            openDocument({
              documentId: previewDocumentId,
              cursor,
              documentType: previewDocument?.type,
              reimbursementCaseId: previewDocument?.reimbursementCaseId,
            })
          }
          documentId={previewDocumentId}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    previewDocument,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({
      id,
      cursor,
      documentType,
      invoiceId,
      reimbursementCaseId,
    }: EcmDocumentsTableData) => {
      if (!organizationSlug) return '';

      const isInvoice = documentType === DocumentType.Invoice;

      const documentId = invoiceId && isInvoice ? invoiceId : id;

      const isReimbursementItem = !!reimbursementCaseId;

      let pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
        { organizationSlug, documentId }
      );

      if (isInvoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      if (cursor) searchParams.set('cursor', cursor);

      if (isReimbursementItem) {
        searchParams.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
        pathname = generatePath(
          `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}${Routes.REIMBURSEMENTS}/:reimbursementId/:reimbursementItemId`,
          {
            organizationSlug,
            reimbursementId: reimbursementCaseId,
            reimbursementItemId: id,
          }
        );
      }

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => {
    const linkProps = {
      state: {
        from: `/${organizationSlug}${Routes.ECM_DOCUMENTS}`,
        search: searchParams.toString(),
      },
    };

    return getCellWrapperLink(getPath, linkProps);
  }, [getPath, organizationSlug, searchParams]);

  const cellStyle = () => ({ padding: 'unset' });

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    showConsistentSortAndFiltering
      ? [...defaultSortByFields, 'contact', 'documentNumber', 'documentName']
      : defaultSortByFields;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      <EcmDocumentsTable
        context="documents"
        columns={availableDocumentColumnIds}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={totalCount}
        defaultSortBy={sortBy}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        onSearchChange={handleSearchChange}
        onUpdateConfigurations={handleUpdateConfigurations}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
        sortByFields={sortByFields}
      />
    </ArchiveViewLayout>
  );
};

import { Box } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { CONTRACT_UTM_SOURCE } from '../constants/utmSource';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';
import { ContractSubCategoriesInfoPanel } from 'views/Settings/ContractSubCategories/ContractSubCategoriesInfoPanel';

export const ContractSubCategoriesPromotionSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { contractSubCategoriesPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'contractManagement',
    CONTRACT_UTM_SOURCE.SETTINGS_CONTRACT_TYPES
  );

  return (
    <SettingsLayout
      title={t('contractCustomSubCategories.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <Box height="24rem">
        <PromotionCard
          promotion={contractSubCategoriesPromotion}
          customPromotionDetails={customPromotionDetails}
          closable={false}
        />
      </Box>
      <ContractSubCategoriesInfoPanel />
    </SettingsLayout>
  );
};

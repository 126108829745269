import { Button } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useSubmitReimbursementForApproval } from 'views/Reimbursement/hooks/useSubmitReimbursementForApproval';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';

const TRANSLATION = {
  ERROR:
    'reimbursementView.rightSection.reviewfooterAction.requestApproveToast.error',
  SUCCESS:
    'reimbursementView.rightSection.reviewfooterAction.requestApproveToast.success',
  REQUEST: 'reimbursementView.rightSection.reviewfooterAction.request',
};
interface RequestApprovalActionProps {
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
}

export const RequestApprovalAction = ({
  getExpenses,
  reimbursement,
  onCycleReimbursments,
}: RequestApprovalActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { error } = useToastMessage();
  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const {
    triggerAllValidations,
    setApprovalMode,
    lastModifiedExpense,
    setHasReimbursementErrors,
    approvalData,
  } = useReimbursementFormsContext();

  const { triggerBookingsValidation } = useReimbursementSplitBookingHelpers();
  const { submitForApproval, isSubmitForApprovalPending } =
    useSubmitReimbursementForApproval();

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });

  const updateExpenseItems = async () => {
    if (!lastModifiedExpense) return;

    const expense = getExpenses('expenses')[lastModifiedExpense];
    const updateFn = {
      general: updateGeneralExpense,
      hospitality: updateHospitalityExpense,
    }[expense?.expenseType];

    if (updateFn) await updateFn(expense);
  };

  const handleClick = async () => {
    setApprovalMode('requestApproval');
    updateExpenseItems();

    const submitResponse = await submitForApproval(approvalData);

    if (submitResponse === 'error') {
      triggerAllValidations();
      triggerBookingsValidation();

      setHasReimbursementErrors(true);

      error(t(TRANSLATION.ERROR));

      return;
    }

    setHasReimbursementErrors(false);
    successMessageWithDeepLink({
      key: TRANSLATION.SUCCESS,
    });
    onCycleReimbursments();
  };

  const isLoading =
    isUpdateHospitalityExpensePending ||
    isUpdateGeneralExpensePending ||
    isSubmitForApprovalPending;

  return (
    <Button
      color="blue"
      onClick={handleClick}
      loading={isLoading}
      disabled={isLoading}
    >
      {t(TRANSLATION.REQUEST)}
    </Button>
  );
};

import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import {
  GetDocumentForDraftQuery,
  GetDocumentQuery,
  useDocumentWorkflowStatusQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { isDocWithMultipleBookings } from 'utils/document-with-bookings';
import { useUserInActiveStep } from 'utils/isUserInActiveFirstWFStep';
import { useLinkedPurchaseOrder } from 'views/Inbox/DocumentProcessing/useLinkedPurchaseOrder';

type UseApprovalStateProps = {
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >;
  bookings: DocumentApprovalFormValues['bookings'];
};

export const useApprovalState = ({
  bookings,
  document,
}: UseApprovalStateProps) => {
  const { isQuantityRequired: isGoodsPurchaseOrderLinked } =
    useLinkedPurchaseOrder(document?.id);
  const currentUser = useCurrentUser();

  const showBookingsWithSapPurchaseOrder =
    isGoodsPurchaseOrderLinked && !isNil(bookings);

  const showFormField =
    (showBookingsWithSapPurchaseOrder || (bookings && bookings.length > 1)) ??
    false;

  const { data: documentWorkflow } = useDocumentWorkflowStatusQuery({
    variables: { id: document.id },
  });

  const { isUserInActiveFirstWFStep, isUserInActiveLastWFStep } =
    useUserInActiveStep();

  const isUserInFirstActiveWorkflowStep = isUserInActiveFirstWFStep(
    documentWorkflow?.getDocument,
    currentUser?.id
  );

  const isUserInLastActiveWorkflowStep = isUserInActiveLastWFStep(
    documentWorkflow?.getDocument,
    currentUser?.id
  );

  const isPrevBookingsMultipleSplits = isDocWithMultipleBookings(document);
  const isPrevBookingsWithSapPurchaseOrder =
    isGoodsPurchaseOrderLinked && !isNil(document.bookings);

  const hasPrevBookingsSplits =
    isPrevBookingsWithSapPurchaseOrder || isPrevBookingsMultipleSplits;

  const isCurrentBookingsMultipleSplits = bookings.length > 1;
  const bookingsHaveSameVatRate = bookings
    .map(b => b.vatRate)
    .every((vatRate, _i, vatRates) => vatRate === vatRates[0]);

  const showVatField =
    !isGoodsPurchaseOrderLinked &&
    (!isCurrentBookingsMultipleSplits || bookingsHaveSameVatRate);

  return {
    showFormField,
    isUserInFirstActiveWorkflowStep,
    isUserInLastActiveWorkflowStep,
    hasPrevBookingsSplits,
    showVatField,
  };
};

import { Flex, MenuItem, Tag, Text, useModal } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { BDSPromotionModal } from 'components/ProductPromotions/Provisions/BDSPromotionModal';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useExportContactsGenericMutation,
  useExportContactsMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useOtherIntegration } from 'orgConfig/other';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { CONTACT_ROUTE_HASH } from 'views/Contacts/types';
import { useShowExtendAccountNumbersEntry } from 'views/utils/useShowExtendAccountNumbersEntry';
import { useSap } from '../../../orgConfig/sap';

export interface ContactKebabMenuProps {
  organizationSlug?: string;
}

export const ContactKebabMenu = ({
  organizationSlug,
}: ContactKebabMenuProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const history = useHistory();
  const [isContactsExportEnabled] = useCandisFeatureFlags([
    FEATURE_FLAGS.contactsExport,
  ]);

  const showExtendAccountNumbersEntry = useShowExtendAccountNumbersEntry();

  const { bdsBought } = useDatev(); // BDS-checked

  const { shouldUseSapContacts } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();

  const showBdsPromotionModal = !bdsBought;

  const { open, isOpen, close } = useModal();

  // TODO: below should be changed to use exportContactsDatev mutation
  const [exportContacts, { loading: exportingContacts }] =
    useExportContactsMutation();

  const [exportContactsGeneric, { loading: exportingGenericContacts }] =
    useExportContactsGenericMutation();

  const { isAdmin, isAccountant } = useUserRoles();
  const canExportContacts =
    isContactsExportEnabled && (isAdmin || isAccountant);

  const canExportGenericContacts = isAdmin;

  const items: MenuItem[] = [];

  if (!shouldUseSapContacts && !shouldUseCoreDataApi) {
    items.push({
      id: 'import',
      label: t('settings.contacts.contextMenu.import'),
      onAction: () => {
        history.push({ hash: CONTACT_ROUTE_HASH.import });
      },
    });
  }

  if (!shouldUseSapContacts) {
    items.push({
      id: 'history',
      label: t('settings.contacts.contextMenu.history'),
      onAction: () => {
        history.push(`/${organizationSlug}${Routes.CONTACT_IMPORT_HISTORY}`);
      },
    });
  }

  if (canExportContacts && !shouldUseSapContacts && !shouldUseCoreDataApi) {
    items.push({
      id: 'export',
      disabled: exportingContacts,
      label: t('settings.contacts.contextMenu.export.label'),
      onAction: async () => {
        const { data } = await exportContacts();
        if (data?.exportContacts.url) {
          window.open(
            data?.exportContacts.url,
            '_blank',
            'noopener noreferrer'
          );
          success(t('settings.contacts.contextMenu.export.success'));
        } else {
          error(t('settings.contacts.contextMenu.export.error'));
        }
      },
    });
  }

  if (canExportGenericContacts) {
    items.push({
      id: 'generic-export',
      disabled: exportingGenericContacts,
      label: t('settings.contacts.contextMenu.exportGeneric.label'),
      onAction: async () => {
        const { data } = await exportContactsGeneric();
        if (data?.exportContactsGeneric?.url) {
          window.open(
            data.exportContactsGeneric.url,
            '_blank',
            'noopener noreferrer'
          );
          success(t('settings.contacts.contextMenu.exportGeneric.success'));
        } else {
          error(t('settings.contacts.contextMenu.exportGeneric.error'));
        }
      },
    });
  }

  if (showBdsPromotionModal && !shouldUseSapContacts && !shouldUseCoreDataApi) {
    items.push({
      id: 'exportRewe',
      label: t('settings.contacts.contextMenu.exportRewe.label'),
      renderItem: (item: MenuItem) => (
        <Flex alignItems="center" gap="space10">
          {item.label}
          <Tag color="purple" variant="secondary">
            {t('tag.addOn')}
          </Tag>
        </Flex>
      ),
      onAction: () => {
        open();
      },
    });
  }

  if (showExtendAccountNumbersEntry) {
    items.push({
      id: 'extend-account-numbers',
      label: t('settings.contacts.contextMenu.extendAccountNumbers.label'),
      renderItem: (item: MenuItem) => <Text>{item.label}</Text>,
      closeOnSelect: false,
    });
  }

  return (
    <>
      {items.length > 0 && (
        <KebabMenu
          items={items}
          label={t('settings.contacts.contextMenu.tooltip')}
        />
      )}
      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.CONTACTS}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};

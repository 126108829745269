import { Routes } from 'models';
import {
  Entity,
  SEARCH_PARAM,
  Sidebar,
  Table,
  View,
} from 'views/Integrations/Export/types';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';

export const generateExportUrlPath = (
  search: string,
  organizationSlug: string
) => {
  const searchParams = new URLSearchParams(search);

  const paramsToDelete = [
    REIMBURSEMENT_URL_PARAM.CURSOR,
    REIMBURSEMENT_URL_PARAM.VIEW,
  ];
  paramsToDelete.forEach(param => {
    searchParams.delete(param);
  });

  const newParams = {
    [SEARCH_PARAM.VIEW]: View.READY,
    [SEARCH_PARAM.ENTITY]: Entity.REIMBURSEMENT_ITEMS,
    [SEARCH_PARAM.TABLE]: Table.APPROVALS,
    [SEARCH_PARAM.SIDEBAR]: Sidebar.INIT_NEW_EXPORT,
    [SEARCH_PARAM.TRIGGER_DOWNLOAD]: 'false',
  };

  Object.entries(newParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  return `/${organizationSlug}${Routes.EXPORTS}?${searchParams.toString()}`;
};

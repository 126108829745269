import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';

export const archiveReimbursementsTableId =
  'archive-reimbursement-table-configurations';

interface Params {
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
}

export const defaultColumnsConfigurations: Configuration[] = [
  {
    id: 'status',
    isVisible: true,
    label: 'reimbursement:table.columns.status',
    isFixed: true,
  },
  {
    id: 'requesterOfTheReimbursement',
    isVisible: true,
    label: 'reimbursement:table.columns.requesterOfTheReimbursement',
    isFixed: true,
  },
  {
    id: 'title',
    isVisible: true,
    label: 'reimbursement:table.columns.title',
  },
  {
    id: 'grossAmount',
    isVisible: true,
    label: 'reimbursement:table.columns.grossAmount',
  },
  {
    id: 'createdAt',
    isVisible: true,
    label: 'reimbursement:table.columns.createdAt',
  },
  {
    id: 'firstSubmittedForReviewAt',
    isVisible: true,
    label: 'reimbursement:table.columns.firstSubmittedForReviewAt',
  },
  {
    id: 'lastApprovedByMembership',
    isVisible: true,
    label: 'reimbursement:table.columns.lastApprovedByMembership',
  },
  {
    id: 'iban',
    isVisible: true,
    label: 'reimbursement:table.columns.iban',
  },
  {
    id: 'paidAt',
    isVisible: true,
    label: 'reimbursement:table.columns.paidAt',
  },
  {
    id: 'isPaid',
    isVisible: true,
    label: 'reimbursement:table.columns.isPaid',
  },
];

export const useArchiveReimbursementsTableConfigurations = ({
  filters,
  sortBy,
}: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { configurations } = useGetTableConfigurations({
    tableId: archiveReimbursementsTableId,
    defaultColumnsConfigurations,
  });

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: archiveReimbursementsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: archiveReimbursementsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const visibleColumnIds = configurations
    .filter((config): config is Configuration => config.isVisible)
    .map(config => config.id as ReimbursementTableColumnKeys);

  useUpdateColumnsBE({
    tableId: archiveReimbursementsTableId,
    defaultColumnsConfigurations,
  });

  return {
    availableReimbursementsColumnIds: visibleColumnIds,
    configurationsTable: configurations,
    isResetPending,
    isSavingConfigurations,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  };
};

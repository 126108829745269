import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isEmpty } from 'lodash';
import { Routes } from 'models';
import { useLocation } from 'react-router-dom-v5-compat';
import { ThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchWrapper } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchWrapper';
import { useInboxContext } from 'views/Inbox/Context';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { useDocumentTypeContext } from '../DocumentTypeContext';
import { useGetThreeWayMatchData } from '../ThreeWayMatch/hooks/useGetThreeWayMatchData';
import { useSapPurchaseOrders } from '../ThreeWayMatch/hooks/useSapPurchaseOrders';
import { PurchaseOrderSection } from './PurchaseOrderSection';

interface PurchaseOrderSectionContainerProps {
  loading: boolean;
  documentId: string;
  documentAmount?: number | null;
  documentStatus?: DocumentStatus | EcmDocumentStatus;
  contactName?: string;
  accountsPayableNumber?: string | null;
}

export const PurchaseOrderSectionContainer = ({
  loading,
  documentAmount,
  documentStatus,
  documentId,
  contactName,
  accountsPayableNumber,
}: PurchaseOrderSectionContainerProps) => {
  const { isOnlyApprover } = useUserRoles();
  const location = useLocation();
  const { result: newResult, isLoading: threeWayMatchLoading } =
    useGetThreeWayMatchData(documentId);

  const { purchaseOrders, isLoading: poListLoading } =
    useSapPurchaseOrders(documentId);

  const { useDocumentNavigationResult } = useInboxContext();
  const { documentTypeOld, documentType } = useDocumentTypeContext();

  const { isNavigationLoading } = useDocumentNavigationResult;

  const isCreditMemoDocument =
    documentTypeOld === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT ||
    documentType?.documentCategory?.documentType ===
      DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

  const isApprovalFormInEditMode =
    documentStatus === DocumentStatus.Open &&
    location.pathname.includes(Routes.INBOX);

  // if form is in edit mode, then purchase order is editable
  const isPurchaseOrderEditable =
    !isOnlyApprover &&
    (documentStatus === DocumentStatus.New ||
      documentStatus === DocumentStatus.Rejected ||
      isApprovalFormInEditMode);

  const hidePurchaseOrderSection =
    !isPurchaseOrderEditable && isEmpty(purchaseOrders);

  const isLoading = loading || poListLoading;

  if (hidePurchaseOrderSection || isNavigationLoading) return null;

  return (
    <ThreeWayMatchContext.Provider
      value={{
        newData: newResult,
        isLoading: threeWayMatchLoading,
        documentStatus,
        documentId,
        contactName,
        accountsPayableNumber,
        documentAmount,
        isPurchaseOrderEditable,
      }}
    >
      <ThreeWayMatchWrapper>
        <PurchaseOrderSection
          documentId={documentId}
          documentAmount={documentAmount}
          contactName={contactName}
          accountsPayableNumber={accountsPayableNumber}
          purchaseOrders={purchaseOrders}
          isLoading={!!isLoading}
          isCreditMemoDocument={isCreditMemoDocument}
        />
      </ThreeWayMatchWrapper>
    </ThreeWayMatchContext.Provider>
  );
};

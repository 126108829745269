import {
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { getUpdatedConfigurationsTable } from 'utils/table/getUpdatedConfigurationsTable';
import { useAvailableDocumentsColumns } from 'views/utils/getAvailableDocumentsColumns';

interface Params {
  filters: Filters<DocumentsTableData>;
  sortBy: SortingRule<DocumentsTableData>[];
}

export const useDefaultColumnsConfigurations = (): Configuration[] => {
  const { showDocumentTags } = useEcm();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const [purchaseOrderNumberFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.purchaseOrderNumber,
  ]);

  const { shouldUseSapPurchaseOrder } = useSap();

  const { shouldUseAccountingAreas } = useOtherIntegration();

  const sapPurchaseOrderColumns = shouldUseSapPurchaseOrder
    ? [
        {
          id: 'sapPurchaseOrderNumber',
          isVisible: true,
          label: 'documents-table:columns.sapPurchaseOrderNumber',
        },
        {
          id: 'goodsReceiptsCount',
          isVisible: true,
          label: 'documents-table:columns.goodsReceiptsCount',
        },
      ]
    : [];

  return [
    {
      id: 'status',
      isVisible: true,
      label: 'documents-table:columns.status',
      isFixed: true,
    },
    {
      id: 'contact',
      isVisible: true,
      label: 'documents-table:columns.contact',
      isFixed: true,
    },
    {
      id: 'accountsPayableNumber',
      isVisible: true,
      label: 'documents-table:columns.accountsPayableNumber',
    },
    {
      id: 'grossAmount',
      isVisible: true,
      label: 'documents-table:columns.grossAmount',
    },
    {
      id: 'invoiceNumber',
      isVisible: true,
      label: 'documents-table:columns.invoiceNumber',
    },
    ...sapPurchaseOrderColumns,
    showDocumentTags && {
      id: 'tags',
      isVisible: true,
      label: 'documents-table:columns.tags',
    },
    purchaseOrderNumberFF && {
      id: 'purchaseOrderNumber',
      isVisible: true,
      label: 'documents-table:columns.purchaseOrderNumber',
    },
    {
      id: 'invoiceDate',
      isVisible: true,
      label: 'documents-table:columns.invoiceDate',
    },
    {
      id: 'documentType',
      isVisible: true,
      label: 'documents-table:columns.documentType',
    },
    {
      id: 'isInvoiceCorrection',
      isVisible: true,
      label: 'documents-table:columns.isInvoiceCorrection',
    },
    {
      id: 'requester',
      isVisible: true,
      label: 'documents-table:columns.requester',
    },
    {
      id: 'approvers',
      isVisible: true,
      label: 'documents-table:columns.approvers',
    },
    {
      id: 'creditCardPayment',
      isVisible: true,
      label: 'documents-table:columns.creditCardPayment',
    },
    {
      id: 'createdAt',
      isVisible: true,
      label: 'documents-table:columns.createdAt',
    },
    shouldUseAccountingAreas && {
      id: 'accountingArea',
      isVisible: true,
      label: 'documents-table:columns.accountingArea',
    },
    {
      id: 'costCenter',
      isVisible: true,
      label: 'documents-table:columns.costCenter',
    },
    {
      id: 'costObject',
      isVisible: true,
      label: 'documents-table:columns.costObject',
    },
    {
      id: 'generalLedgerAccount',
      isVisible: true,
      label: 'documents-table:columns.generalLedgerAccount',
    },
    {
      id: 'extraCostInfo',
      isVisible: true,
      label: 'documents-table:columns.extraCostInfo',
    },
    {
      id: 'artistSocialInsuranceCode',
      isVisible: true,
      label: 'documents-table:columns.artistSocialInsuranceCode',
    },
    {
      id: 'note',
      isVisible: true,
      label: 'documents-table:columns.note',
    },
    {
      id: 'postingText',
      isVisible: true,
      label: 'documents-table:columns.postingText',
    },
    !shouldUseCoreDataApi && {
      id: 'isPayable',
      isVisible: true,
      label: 'documents-table:columns.isPayable',
    },
    !shouldUseCoreDataApi && {
      id: 'paymentStatus',
      isVisible: true,
      label: 'documents-table:columns.paymentStatus',
    },
    {
      id: 'paidAt',
      isVisible: true,
      label: 'documents-table:columns.paidAt',
    },
    {
      id: 'deliveryDate',
      isVisible: true,
      label: 'documents-table:columns.deliveryDate',
    },
    {
      id: 'dueDate',
      isVisible: true,
      label: 'documents-table:columns.dueDate',
    },
    {
      id: 'discountDateWPercentage',
      isVisible: true,
      label: 'documents-table:columns.discountDateWPercentage',
    },
    {
      id: 'paymentInfo',
      isVisible: true,
      label: 'documents-table:columns.paymentInfo',
    },
  ].filter((c): c is Configuration => Boolean(c));
};

export const archiveDocumentsTableId = 'archive-documents-table-configurations';

export const useArchiveDocumentsColumnsConfigs = ({
  filters,
  sortBy,
}: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const creditCardsSetup = useCreditCardsSetup();
  const { isLoadingDatev } = useDatev();

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const defaultColumnsConfigurations = useDefaultColumnsConfigurations();
  const { configurations } = useGetTableConfigurations({
    tableId: archiveDocumentsTableId,
    defaultColumnsConfigurations,
  });

  const allColumnIds = configurations.map(
    item => item.id as DocumentTableColumnKeys
  );

  const availableColumnsBasedOnOrgSettings =
    useAvailableDocumentsColumns(allColumnIds);

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: archiveDocumentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: archiveDocumentsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { updatedConfigBasedOnOrgSettings, visibleColumnIds } =
    getUpdatedConfigurationsTable(
      configurations,
      availableColumnsBasedOnOrgSettings
    );

  useUpdateColumnsBE({
    tableId: archiveDocumentsTableId,
    defaultColumnsConfigurations,
  });

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  return {
    availableDocumentColumnIds: visibleColumnIds as DocumentTableColumnKeys[],
    configurationsTable: updatedConfigBasedOnOrgSettings,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isLoadingConfigs:
      isLoadingDatev ||
      creditCardsSetup.isOnboardingStatusLoading ||
      isSavingConfigurations ||
      isResetPending,
  };
};

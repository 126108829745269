import { Box, Button, Flex, Grid } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  Notification,
  useClearAllNotificationsMutation,
  useRemoveAllMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { Routes } from 'models';
import { motion } from 'motion/react';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowError } from 'utils/error_message';
import { notificationsQuery } from 'views/AppContainer/components/Header/components/Notifications/queries';
import { EmptyNotificationList } from '../../EmptyNotificationList/EmptyNotificationList';
import { EmailImportErrorNotificationItem } from './EmailImportErrorNotificationItem/EmailImportErrorNotificationItem';
import { UploadFileNotificationItem } from './UploadFileNotificationItem/UploadFileNotificationItem';

const MotionGrid = motion(Grid);

export const ImportNotificationsContent = ({
  notifications,
  onClose,
  fileUploadsInProgress,
}: {
  notifications: Notification[];
  hasFailedEmails: boolean;
  onClose: () => void;
  fileUploadsInProgress: boolean;
}) => {
  const [t] = useTranslation();
  const showError = useShowError();
  const [clearList] = useRemoveAllMutation();
  const [clearAllNotifications] = useClearAllNotificationsMutation({
    refetchQueries: [{ query: notificationsQuery }],
  });

  const dispatch = useDocumentUploadDispatch();

  const onShowButtonClick = () => {
    dispatch('showModal');
    onClose?.();
  };

  const organizationSlug = useOrganizationId();

  const onClearAll = useCallback(async () => {
    try {
      await clearList();
      await clearAllNotifications();
    } catch (e) {
      showError(e as GQLError);
    }
  }, [clearList, clearAllNotifications, showError]);

  return (
    <Flex
      height="100%"
      direction="column"
      justifyContent="space-between"
      padding={0}
      data-cy="importNotificationContent"
    >
      {!!notifications.length ? (
        <MotionGrid
          padding="0px space4 0px space12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          height="100%"
          transition={{ ease: 'easeOut', duration: 0.8, delay: 0.25 }}
        >
          <Box
            as="ul"
            listStyle="none"
            height="100%"
            padding={0}
            overflowY="auto"
          >
            {notifications.map(notification => {
              switch (notification.data.__typename) {
                case 'UploadFileItem':
                  return (
                    <UploadFileNotificationItem
                      key={notification.data.id}
                      data={notification.data}
                      onClose={onClose}
                    />
                  );
                case 'EmailImportErrorNotification':
                  return (
                    <EmailImportErrorNotificationItem
                      key={notification.id}
                      data={notification.data}
                      onClose={onClose}
                    />
                  );
                default:
                  return <></>;
              }
            })}
          </Box>
        </MotionGrid>
      ) : (
        <EmptyNotificationList
          title={t(
            'navigation:notifications.emptyList.currentCompany.import.title'
          )}
          description={t(
            'navigation:notifications.emptyList.currentCompany.import.description'
          )}
        />
      )}
      <Flex
        position="sticky"
        bottom={0}
        justifyContent="space-between"
        alignItems="center"
        background="gray100"
        padding="space10 space12"
      >
        <Button
          variant="tertiary"
          size="small"
          onClick={onClearAll}
          data-cy="nav-upload-notifications-clear"
          disabled={fileUploadsInProgress || notifications.length < 1}
        >
          {t('uploads.clear')}
        </Button>
        <div>
          <Button variant="tertiary" size="small" onClick={onClose}>
            <RouterLink
              fontSize="small"
              to={`/${organizationSlug}${Routes.SETTINGS}${Routes.MAIL_SYNC}`}
            >
              {t('uploads.openEmailImport')}
            </RouterLink>
          </Button>
          <Button variant="tertiary" size="small" onClick={onShowButtonClick}>
            {t('uploads.openUploadBox')}
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};

import { Item, Popover, TabItem, usePopover } from '@candisio/design-system';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useInAppNotificationsPageBasedData } from 'containers/notifications/hooks/useInAppNotificationsPageBasedData';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentUploadModal } from 'views/AppContainer/components/Header/components/DocumentUploadModal/DocumentUploadModal';
import { NotificationTabsPanel } from './MenuNotificationTabsPanel';
import { NotificationsPopoverContent } from './NotificationsPopoverContent/NotificationsPopoverContent';
import { useUserRoles } from 'hooks/useUserRoles';
import { NavigationIcon } from '../../NavigationIcon';

enum NotificationTabsKeys {
  ALL_COMPANIES = 'ALL_COMPANIES',
  CURRENT_COMPANIES = 'CURRENT_COMPANIES',
}

export const MenuNotifications = ({ expanded }: { expanded: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const [isActive, setIsActive] = useState(false);
  const organizationId = useOrganizationId();
  const { hasRoles } = useUserRoles();

  const {
    notificationDetails: { unreadNotifications: unreadNotificationsAllCompany },
  } = useInAppNotificationsPageBasedData({
    organizationId: undefined,
    showOnlyUnread: true,
  });

  const {
    notificationDetails: {
      unreadNotifications: unreadNotificationsCurrentCompany,
    },
  } = useInAppNotificationsPageBasedData({
    organizationId: organizationId ?? '',
    showOnlyUnread: true,
  });

  const { popoverProps, popoverRef, triggerProps, triggerRef, isOpen, close } =
    usePopover({
      placement: 'right',
      onOpenChange: isOpen => {
        setIsActive(isOpen);
      },
    });

  const hasUnreadNotificationsAllCompany =
    unreadNotificationsAllCompany.length > 0;

  const hasUnreadNotificationsCurrentCompany =
    unreadNotificationsCurrentCompany.length > 0;

  const tabItems: TabItem[] = [
    {
      children: (
        <NotificationsPopoverContent onClose={close} currentCompanyOnly />
      ),
      key: NotificationTabsKeys.CURRENT_COMPANIES,
      title: t('notifications.tabs.currentCompany'),
      suffix: hasUnreadNotificationsCurrentCompany && (
        <PulseIndicator status="urgent" source="menuNotifications" />
      ),
    },
    {
      children: <NotificationsPopoverContent onClose={close} />,
      key: NotificationTabsKeys.ALL_COMPANIES,
      title: t('notifications.tabs.allCompanies'),
      suffix: hasUnreadNotificationsAllCompany &&
        !hasUnreadNotificationsCurrentCompany && (
          <PulseIndicator status="urgent" source="menuNotifications" />
        ),
    },
  ];

  return (
    <>
      <NavigationIcon
        icon="menuNotifications"
        expanded={expanded}
        label={t('menuLabels.notifications')}
        ref={triggerRef}
        showMenuIcon={hasRoles}
        data-cy="nav-item-notifications"
        {...triggerProps}
        isActive={isActive}
        count={unreadNotificationsAllCompany.length}
        pulseIndicator={
          hasUnreadNotificationsAllCompany && (
            <PulseIndicator status="urgent" source="menuNotifications" />
          )
        }
        showLabelInTooltip={!isOpen}
      />

      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="467px"
          height="60%"
          padding={0}
          restoreFocus={false}
          shouldCloseOnBlur={false}
        >
          <NotificationTabsPanel
            aria-label="notificationTabs"
            defaultSelectedKey={NotificationTabsKeys.CURRENT_COMPANIES}
            items={tabItems}
            children={item => (
              <Item key={item.key} title={item.title} textValue={item.title}>
                {item.children}
              </Item>
            )}
          />
        </Popover>
      )}
      <DocumentUploadModal />
    </>
  );
};

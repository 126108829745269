import { Grid } from '@candisio/design-system';
import { DownloadSection } from 'components/DocumentViewer/Download/DownloadSection';
import { PDFDetails } from 'components/DocumentViewer/utils';
import {
  Fragment,
  ComponentProps,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PdfInvoiceSkeleton } from '../PdfInvoiceSkeleton';
import { PdfViewerProps } from '../PdfViewer';
import { PdfViewerError } from '../PdfViewerError';
import { Attachment } from './Attachment';
import { DocumentWrapper } from './DocumentWrapper';
import { PageWrapper } from './PageWrapper';
import { PdfHeader, PdfHeaderProps } from './PdfHeader';
import { PdfStyledWrapper } from './styles';
import { useDocumentPages } from './useDocumentPages';
import { usePdfLabel } from './usePdfLabel';
import {
  UseReactPdfControlsProps,
  useReactPdfControls,
  type UseReactPdfControlsResult,
} from './useReactPdfControls';
import { PdfStatus, getPageId, options } from './utils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface ReactPdfProps
  extends PdfViewerProps,
    Pick<UseReactPdfControlsProps, 'smoothScroll' | 'initialScale'>,
    Pick<PdfHeaderProps, 'disabledControls' | 'pdfControlsWrapper'> {
  wrapper?: (props: {
    children: ReactNode;
    hasLoaded: boolean;
    documentId?: string;
  }) => JSX.Element;
}

const noopWrapper = (props: {
  children: ReactNode;
  hasLoaded: boolean;
  documentId?: string;
}) => <Fragment>{props.children}</Fragment>;

export const ReactPdf = ({
  documentFile,
  mobileAppPromo, // TODO adjust templateRows in Grid below when mobileAppPromo is removed
  attachmentSection,
  attachments,
  onSelectDocument,
  selectedFile,
  smoothScroll = true,
  wrapper: OptionalWrapper = noopWrapper,
  maxHeight,
  documentId,
  disabledControls,
  pageCss,
  initialScale,
  pdfControlsWrapper,
}: ReactPdfProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [status, setStatus] = useState<PdfStatus>('idle');

  const {
    currentPage,
    fitType,
    height,
    onFitPageHeight,
    onFitPageWidth,
    onRotateLeft,
    onZoomIn,
    onZoomOut,
    rotation,
    scale,
    setCurrentPage,
    width,
    wrapperRef,
    goToPage,
    onGoToNextPage,
    onGoToPreviousPage,
    pdfViewerInstanceId,
    maxZoomInReached,
    maxZoomOutReached,
  } = useReactPdfControls({
    smoothScroll,
    initialScale,
  });

  const {
    getPageNumberOffset,
    handleChangePageVisibility,
    totalPages,
    onDocumentPagesLoaded,
  } = useDocumentPages({
    currentPage,
    documentFile,
    setCurrentPage,
    attachments,
    onSelectDocument,
    selectedFile,
    goToPage,
  });

  const [isPdfProtected, setIsPdfProtected] = useState(false);

  // called when main document is loaded
  const onDocumentLoadSuccess: NonNullable<
    ComponentProps<typeof DocumentWrapper>['onLoadSuccess']
  > = useCallback(
    doc => {
      setNumPages(doc?.numPages ?? 0);
      if (documentFile?.id)
        onDocumentPagesLoaded(documentFile.id, doc.numPages);
      setStatus('loaded');
    },
    [documentFile.id, onDocumentPagesLoaded]
  );

  const handleLoadProgress = useCallback(() => setStatus('loading'), []);

  const handleLoadError = useCallback(() => setStatus('error'), []);

  // called when PDF asks for a password
  // we treat document as loaded and set its page to exactly one (the page with password)
  const handleOnPassword = useCallback(() => {
    setStatus('loaded');
    setIsPdfProtected(true);
    if (documentFile?.id) onDocumentPagesLoaded(documentFile.id, 1);
  }, [documentFile.id, onDocumentPagesLoaded]);

  const wrapper = (
    <PdfStyledWrapper
      ref={wrapperRef}
      data-cy="document-viewer-body"
      /** Internal code of react-pdf modifies z-index, so we have to reset it here to 0 */
      zIndex={0}
      height="100%"
      maxHeight={maxHeight}
      pageCss={pageCss}
    >
      {(status === 'idle' || status === 'loading') && <PdfInvoiceSkeleton />}
      {status === 'error' && <PdfViewerError />}

      {status !== 'error' && (
        <Grid
          gap="space16"
          height="100%"
          visibility={status !== 'loaded' ? 'hidden' : 'visible'}
        >
          <DocumentWrapper
            fileUrl={documentFile?.url}
            file={documentFile?.url}
            onLoadProgress={handleLoadProgress}
            onLoadError={handleLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={null}
            error={null}
            externalLinkTarget="_blank"
            onPassword={handleOnPassword}
            changePageVisibility={handleChangePageVisibility}
            options={options}
            protectedPageId={getPageId(pdfViewerInstanceId, 1)}
            protectedPageNumber={1}
          >
            <DocumentPagesWrapper
              documentFile={documentFile}
              numPages={numPages}
              pdfViewerInstanceId={pdfViewerInstanceId}
              width={width}
              fitType={fitType}
              height={height}
              scale={scale}
              rotation={rotation}
              handleChangePageVisibility={handleChangePageVisibility}
            />
          </DocumentWrapper>

          {attachments?.map(attachment => (
            <Attachment
              key={attachment.id}
              attachment={attachment}
              width={width}
              scale={scale}
              rotation={rotation}
              handleChangePageVisibility={handleChangePageVisibility}
              pageNumberOffset={getPageNumberOffset(attachment.id ?? '') ?? 0}
              fitType={fitType}
              height={height}
              onDocumentPagesLoaded={onDocumentPagesLoaded}
              uniqueId={pdfViewerInstanceId}
            />
          ))}
        </Grid>
      )}
    </PdfStyledWrapper>
  );

  const label = usePdfLabel(documentId);

  const hasLoaded = status === 'loaded';

  return (
    <Grid
      height="100%"
      // TODO: adjust this to min-content minmax(0, auto) when mobileAppPromo is removed
      templateRows={
        hasLoaded && !isPdfProtected && mobileAppPromo
          ? 'min-content min-content minmax(0, auto)'
          : 'min-content minmax(0, auto)'
      }
      gap="space8"
    >
      <Grid
        color="gray500"
        gap="space4"
        alignItems="center"
        templateColumns={label ? 'auto 1fr auto' : '1fr auto'}
        data-cy="document-viewer-details"
      >
        {label}
        <PdfHeader
          currentPage={currentPage}
          onFitPageHeightClick={onFitPageHeight}
          onFitPageWidthClick={onFitPageWidth}
          onHandleRotateLeftClick={onRotateLeft}
          onNextPageClick={onGoToNextPage}
          onPreviousPageClick={onGoToPreviousPage}
          onZoomInClick={onZoomIn}
          zoomInDisabled={maxZoomInReached}
          zoomOutDisabled={maxZoomOutReached}
          onZoomOutClick={onZoomOut}
          status={status}
          totalPages={totalPages}
          attachmentSection={attachmentSection}
          disabledControls={disabledControls}
          pdfControlsWrapper={pdfControlsWrapper}
          downloadSection={
            <DownloadSection
              documentFile={documentFile}
              attachments={attachments}
            />
          }
        />
      </Grid>
      {hasLoaded && !isPdfProtected && mobileAppPromo}
      <OptionalWrapper hasLoaded={hasLoaded} documentId={documentId}>
        {wrapper}
      </OptionalWrapper>
    </Grid>
  );
};

interface DocumentPagesWrapperProps
  extends Pick<
      UseReactPdfControlsResult,
      | 'height'
      | 'width'
      | 'scale'
      | 'rotation'
      | 'fitType'
      | 'pdfViewerInstanceId'
    >,
    Pick<ReturnType<typeof useDocumentPages>, 'handleChangePageVisibility'> {
  documentFile: PDFDetails;
  numPages: number | undefined;
}

const DocumentPagesWrapper = ({
  documentFile,
  numPages,
  pdfViewerInstanceId,
  width,
  fitType,
  height,
  scale,
  rotation,
  handleChangePageVisibility,
}: DocumentPagesWrapperProps) => (
  <Grid id={documentFile?.id} gap="space16">
    {Array.from(new Array(numPages), (_el, index) => (
      <PageWrapper
        key={getPageId(pdfViewerInstanceId, index + 1)}
        pageNumber={index + 1}
        width={width}
        fitType={fitType}
        height={height}
        scale={scale}
        rotation={rotation}
        handleChangePageVisibility={handleChangePageVisibility}
        pageNumberInTotal={index + 1}
        uniqueId={pdfViewerInstanceId}
      />
    ))}
  </Grid>
);

import { Box } from '@candisio/design-system';
import { motion } from 'motion/react';
import { ReactNode } from 'react';

const MotionBox = motion(Box);

interface WizardModalFooterProps {
  children: ReactNode;
}

export const WizardModalFooter = ({ children }: WizardModalFooterProps) => {
  return (
    <MotionBox
      animate="visible"
      exit="hidden"
      initial="hidden"
      variants={{
        hidden: { y: '100%', opacity: 0 },
        visible: { y: 0, opacity: 1 },
      }}
      transition={{ bounce: 0 }}
      background="gray0"
      borderBottomRadius="medium"
      flex="none"
      padding="space32"
    >
      {children}
    </MotionBox>
  );
};

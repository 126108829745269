import {
  Cell,
  Filter,
  Grid,
  Paragraph,
  Table,
  useTheme,
} from '@candisio/design-system';
import { DateCell } from 'components/DocumentsTable/Cells/DateCell';
import { InvoiceListCell } from 'components/PurchaseOrders/Table/InvoiceListCell';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { paginationFiltersHooks } from 'views/Archive/PurchaseOrders/hooks/paginationFilterHooks';
import { useGetGoodsReceipts } from '../hooks/useGetGoodsReceipts';
import { useGetGoodsReceiptsTableFilterOptions } from '../hooks/useGetTableFilterOptions';
import {
  ColumnWidths,
  GoodsReceiptsTableColumns,
  GoodsReceiptsTableData,
  GoodsReceiptsTableProps,
} from '../types';
import { ConnectedOrdersCell } from './ConnectedOrdersCell';
import { GoodsReceiptStatusCell } from './GoodsReceiptStatusCell';
import { LineItemsCell } from './LineItemsCell';
import { TableHeader } from './TableHeader';
import { GoodsReceiptsAmountCell } from './AmountCell';
import { useMemoizedState } from 'utils/useMemoizedState';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { compiledRoutes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';

const useColumns = (
  visibleColumns: GoodsReceiptsTableProps['columns'],
  filterOptions: GoodsReceiptsTableProps['filterOptions'],
  customColumnWidths: GoodsReceiptsTableProps['columnsWidths']
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { space } = useTheme();

  const shownColumns = useMemoizedState(visibleColumns);

  const defaultColumnWidths: ColumnWidths = {
    contact: space.space256,
    connectedInvoices: space.space256,
    receiptLines: space.space256,
    amount: space.space128,
    supplierRefNumber: space.space200,
  };
  const columnWidths = useMemoizedState({
    ...defaultColumnWidths,
    ...customColumnWidths,
  });
  const defaultColumn = useMemo(
    (): Partial<Column<GoodsReceiptsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: TableHeader,
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof GoodsReceiptsTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<GoodsReceiptsTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  applyFilterButton={t('filterApply')}
                  resetFilterButton={t('filterReset')}
                  searchFieldPlaceholder={t('filterSearchPlaceholder')}
                  filterLabel={t('filterLabel')}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
    }),
    [filterOptions, t]
  );

  const columns = useMemo(() => {
    const allColumns: GoodsReceiptsTableColumns = {
      contact: {
        accessor: 'contact',
        disableSortBy: true,
        Cell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
            customPaginationFilterHooks={paginationFiltersHooks}
          />
        ),
        width: columnWidths?.contact,
      },
      amount: {
        accessor: 'amount',
        disableFilters: true,
        Cell: GoodsReceiptsAmountCell,
        ...amountCellProps,
      },
      orderNumber: {
        accessor: 'orderNumber',
        Cell: ConnectedOrdersCell,
        disableSortBy: true,
        disableFilters: true,
        width: columnWidths?.orderNumber,
      },
      supplierRefNumber: {
        accessor: 'supplierRefNumber',
        Cell,
        disableFilters: true,
        width: columnWidths?.supplierRefNumber,
      },
      deliveryDate: {
        accessor: 'deliveryDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        disableFilters: true,
        width: columnWidths?.deliveryDate,
      },
      status: {
        accessor: 'status',
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return <GoodsReceiptStatusCell status={value} />;
        },
        width: columnWidths?.status,
      },
      connectedInvoices: {
        accessor: 'connectedInvoices',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value }) => (
          <InvoiceListCell connectedInvoices={value || []} />
        ),
        width: columnWidths?.connectedInvoices,
      },
      receiptNumber: {
        accessor: 'receiptNumber',
        Cell,
        disableFilters: true,
        width: columnWidths?.receiptNumber,
      },
      receiptLines: {
        accessor: 'receiptLines',
        Cell: LineItemsCell,
        disableSortBy: true,
        disableFilters: true,
        width: columnWidths?.receiptLines,
      },
    };

    if (!shownColumns.length) {
      return Object.values(allColumns);
    }

    return shownColumns.map(accessor => allColumns[accessor]);
  }, [shownColumns, columnWidths]);

  return { columns, defaultColumn };
};

export const GoodsReceiptsTable = ({
  columns: visibleColumns,
  defaultFilters,
  defaultSortBy,
  searchValue = '',
  selectionOptions,
  columnsWidths,
  showFooterText = false,
  onFilter,
  onSort,
  customEmptyState,
}: GoodsReceiptsTableProps) => {
  const filterOptions = useGetGoodsReceiptsTableFilterOptions();

  const { tableData, isLoading, onLoadMore, isTableEmpty } =
    useGetGoodsReceipts({
      searchValue,
      filters: defaultFilters ?? [],
      sortBy: defaultSortBy ?? [],
    });

  const { columns, defaultColumn } = useColumns(
    visibleColumns,
    filterOptions,
    columnsWidths
  );
  const orgId = useOrganizationId();

  return (
    <Table<GoodsReceiptsTableData>
      columns={columns}
      data={tableData}
      defaultColumn={defaultColumn}
      filterOptions={filterOptions}
      isLoading={isLoading}
      onEndReached={onLoadMore}
      onFilter={onFilter}
      onSort={onSort}
      customEmptyState={isTableEmpty ? customEmptyState : undefined}
      initialState={{
        filters: defaultFilters ?? [],
        sortBy: defaultSortBy ?? [],
      }}
      borderTopRadius="none"
      onRowSelected={selectionOptions?.onSelectionRowChanged}
      selectedRowIds={selectionOptions?.selectedRowsIds}
      tableFooter={
        !isTableEmpty &&
        !isLoading &&
        showFooterText && (
          <Grid placeContent="center" padding="space24">
            <Paragraph maxWidth="80%" textAlign="center">
              <Trans
                i18nKey="addPurchaseOrderModal.goodsReceiptsFooterText"
                ns={LOCALE_NAME_SPACE.PURCHASE_ORDERS}
              >
                Can't find the goods receipt? Then please check the selected
                contact. You can also see the latest orders from
                <RouterLink
                  to={`/${orgId}${compiledRoutes.goodsReceiptsImportHistoryRoute}`}
                >
                  SAP Import History
                </RouterLink>
              </Trans>
            </Paragraph>
          </Grid>
        )
      }
    />
  );
};

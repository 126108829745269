import { Item } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import {
  FilterableList,
  FilterableListProps,
} from 'components/FilterableList/FilterableList';
import { nohistoryData } from 'components/Lottie/Lottie';
import { Trans } from 'providers/LocaleProvider';
import { ProjectCodeImportHistoryItem } from './ProjectCodeImportHistoryItem';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProjectCodeImportHistoryItemProps } from './types';
import { useProjectCodesImportHistoryData } from './useProjectCodesImportHistoryData';

export interface ProjectCodeImportHistoryListProps {
  isLoading: boolean;
  data: ProjectCodeImportHistoryItemProps[];
  onEndReached?: FilterableListProps['onEndReached'];
}

// TODO: (TPX-1206, TPX-1315) when types are added, navigate if row import item has error
export const ProjectCodeImportHistoryList = () => {
  const { items, loading, onLoadMore } = useProjectCodesImportHistoryData();
  const navigate = useNavigate();

  return (
    <FilterableList
      width="50rem"
      isLoading={loading}
      children={items.map(item => (
        <Item key={item.id} textValue={item.username}>
          <FilterableListItem
            itemId={item.id || ''}
            onSelect={() => navigate(`${item.id}`, { relative: 'path' })}
          >
            <ProjectCodeImportHistoryItem {...item} />
          </FilterableListItem>
        </Item>
      ))}
      emptyDataState={
        !loading && (
          <EmptyState animationData={nohistoryData}>
            <Trans i18nKey="purchase-orders:history.noData">
              You do not have any imports yet.
            </Trans>
          </EmptyState>
        )
      }
      onEndReached={onLoadMore}
    />
  );
};

import { Item, SelectField, Text } from '@candisio/design-system';
import { SearchScope } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useDefaultSearchInput } from './useDefaultSearchInput';

type SearchFilterScopeSelectProps =
  | {
      value: string;
      onChange: (value: string) => void;
      onFocus?: () => void;
      disabled?: boolean;
    }
  | {
      value?: string;
      onChange?: (value: string) => void;
      onFocus: () => void;
      disabled: boolean;
    };

export function SearchScopeSelect({
  value,
  onChange,
  onFocus,
  disabled,
}: SearchFilterScopeSelectProps) {
  const { DEFAULT_SEARCH_SCOPE } = useDefaultSearchInput();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const items = [
    {
      id: SearchScope.EntireDocument,
      name: t('searchFilter.popover.fields.searchScope.fullTextSearch'),
    },
    {
      id: SearchScope.DataFields,
      name: t('searchFilter.popover.fields.searchScope.fieldSearch'),
    },
  ];

  return (
    <SelectField
      label={t('searchFilter.popover.fields.searchScope.label')}
      aria-label={t('searchFilter.popover.fields.searchScope.placeholder')}
      disabled={disabled}
      onFocus={onFocus}
      select={{
        items,
        placeholder: t('searchFilter.popover.fields.searchScope.placeholder'),
        children: item => {
          return (
            <Item key={item.id} textValue={item.name}>
              {item.name}
              {item.id === DEFAULT_SEARCH_SCOPE && (
                <Text color="gray500">
                  &nbsp;({t('searchFilter.popover.fields.default')})
                </Text>
              )}
            </Item>
          );
        },
        defaultSelectedKey: value || DEFAULT_SEARCH_SCOPE,
        onSelectionChange: v => onChange?.(v as SearchScope),
      }}
    />
  );
}

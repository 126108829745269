import { mapDocumentStatusToStatus } from 'components/DocumentViewer/AttachmentsTable/utils';
import { Amount, DocumentsTableData } from 'components/DocumentsTable/types';
import {
  ArchiveInvoiceDocumentBooking,
  ArchiveInvoiceDocumentEdge,
  ArtistSocialInsuranceCode,
  Maybe,
  PaymentMethod,
  PurchaseOrder,
  PurchaseOrderIntegrationType,
} from 'generated-types/graphql.types';

type MappedBooking = {
  costCenter?: string;
  costObject?: string;
  extraCostInfo?: string;
  artistSocialInsuranceCode?: string;
  generalLedgerAccount?: string;
  dueDate?: Date;
  note?: string;
  postingText?: string;
  discountAmount?: number;
  discountPercentage?: number;
  discountPaymentDate?: Date;
};

type AllBookingDetails = {
  costCenters: (string | undefined)[];
  costObjects: (string | undefined)[];
  extraCostInfos: (string | undefined)[];
  artistSocialInsuranceCodes: (string | undefined)[];
  generalLedgerAccounts: (string | undefined)[];
  dueDates: (Date | undefined)[];
  notes: (string | undefined)[];
  postingTexts: (string | undefined)[];
  discountAmounts: (number | undefined)[];
  discountPercentages: (number | undefined)[];
  discountPaymentDates: (Date | undefined)[];
};

const mapToBookings =
  (
    artistSocialInsuranceCodeTranslations: Record<
      ArtistSocialInsuranceCode,
      string
    >
  ) =>
  (
    booking: ArchiveInvoiceDocumentBooking | null | undefined
  ): MappedBooking => {
    if (!booking) {
      return {};
    }

    const artistSocialInsuranceCode = booking.artistSocialInsuranceCode
      ? artistSocialInsuranceCodeTranslations[booking.artistSocialInsuranceCode]
      : undefined;

    return {
      costCenter: booking.costCenter?.readableName ?? undefined,
      costObject: booking.costObject?.readableName ?? undefined,
      extraCostInfo: booking.extraCostInfo?.readableName ?? undefined,
      artistSocialInsuranceCode,
      dueDate: booking.dueDate ? new Date(booking.dueDate) : undefined,
      discountAmount: booking.discountAmount ?? undefined,
      discountPercentage: booking.discountPercentage ?? undefined,
      discountPaymentDate: booking.discountPaymentDate
        ? new Date(booking.discountPaymentDate)
        : undefined,
      note: booking.note ?? undefined,
      generalLedgerAccount:
        booking.generalLedgerAccount?.readableName ?? undefined,
      postingText: booking.postingText ?? undefined,
    };
  };

const reduceAllDetails = (
  allBookingDetails: AllBookingDetails,
  booking: MappedBooking
): AllBookingDetails => {
  const {
    costCenters,
    costObjects,
    extraCostInfos,
    artistSocialInsuranceCodes,
    discountAmounts,
    discountPaymentDates,
    discountPercentages,
    dueDates,
    generalLedgerAccounts,
    notes,
    postingTexts,
  } = allBookingDetails;

  return {
    costCenters: [...costCenters, booking.costCenter],
    costObjects: [...costObjects, booking.costObject],
    extraCostInfos: [...extraCostInfos, booking.extraCostInfo],
    artistSocialInsuranceCodes: [
      ...artistSocialInsuranceCodes,
      booking.artistSocialInsuranceCode,
    ],
    discountAmounts: [...discountAmounts, booking.discountAmount],
    discountPaymentDates: [
      ...discountPaymentDates,
      booking.discountPaymentDate,
    ],
    discountPercentages: [...discountPercentages, booking.discountPercentage],
    dueDates: [...dueDates, booking.dueDate],
    generalLedgerAccounts: [
      ...generalLedgerAccounts,
      booking.generalLedgerAccount,
    ],
    notes: [...notes, booking.note],
    postingTexts: [...postingTexts, booking.postingText],
  };
};

const calculateGoodsReceiptsCount = (
  purchaseOrders: Maybe<PurchaseOrder>[] | null
): string | undefined => {
  if (!purchaseOrders) return undefined;
  const count = purchaseOrders.reduce((acc, po) => {
    return acc + (po?.goodsReceipts?.length ?? 0);
  }, 0);

  return count.toString();
};

export const mapToArchiveInvoiceDocumentsTableData = (
  edges: ArchiveInvoiceDocumentEdge[],
  artistSocialInsuranceCodeTranslations: Record<
    ArtistSocialInsuranceCode,
    string
  >,
  previewDocumentId?: string | null
) => {
  const documentsTableData: DocumentsTableData[] = edges.map(edge => {
    const amount = edge.node.amount?.amount;
    const currency = edge.node.amount?.currency;
    const grossAmount: Amount | undefined =
      amount && currency
        ? { amount, currency, isBasicMonetaryUnit: true }
        : undefined;

    const bookings: MappedBooking[] = (edge.node.bookings ?? []).map(
      mapToBookings(artistSocialInsuranceCodeTranslations)
    );

    const accumulatorDetails: AllBookingDetails = {
      costCenters: [],
      costObjects: [],
      extraCostInfos: [],
      artistSocialInsuranceCodes: [],
      discountAmounts: [],
      discountPaymentDates: [],
      discountPercentages: [],
      dueDates: [],
      generalLedgerAccounts: [],
      notes: [],
      postingTexts: [],
    };

    const allBookingDetails = bookings.reduce(
      reduceAllDetails,
      accumulatorDetails
    );

    const isSapPurchaseOrder =
      edge.node.purchaseOrderData?.integrationType ===
      PurchaseOrderIntegrationType.Sap;

    return {
      id: edge.node.id,
      status: {
        status: mapDocumentStatusToStatus(edge.node.status),
        isOverdue: !!edge.node.isOverdue,
        isDuplicate: !!edge.node.isDuplicate,
        isEInvoice: !!edge.node.eInvoice?.format,
        eInvoice: {
          format: edge.node.eInvoice?.format,
          error: edge.node.eInvoice?.error,
        },
      },
      contact: edge.node.contact?.name ?? undefined,
      accountsPayableNumber:
        edge.node.contact?.accountsPayableNumber ?? undefined,
      createdAt: edge.node.createdAt
        ? new Date(edge.node.createdAt)
        : undefined,
      grossAmount,
      invoiceNumber: edge.node.invoiceNumber,
      invoiceDate: edge.node.invoiceDate
        ? new Date(edge.node.invoiceDate)
        : undefined,
      dueDate: allBookingDetails['dueDates'],
      costCenter: allBookingDetails['costCenters'],
      costObject: allBookingDetails['costObjects'],
      extraCostInfo: allBookingDetails['extraCostInfos'],
      postingText: allBookingDetails['postingTexts'],
      generalLedgerAccount: allBookingDetails['generalLedgerAccounts'],
      note: allBookingDetails['notes'],
      requester: edge.node.requester ?? undefined,
      approvers: edge.node.approvers ?? [],
      requestedAt: edge.node.requestedAt
        ? new Date(edge.node.requestedAt)
        : undefined,
      artistSocialInsuranceCode:
        allBookingDetails['artistSocialInsuranceCodes'],
      isPayable: edge.node.isPayable ?? false,
      cursor: edge.cursor,
      deliveryDate: edge.node.invoiceDeliveryDate
        ? new Date(edge.node.invoiceDeliveryDate)
        : undefined,
      discountDateWPercentage: edge.node.bookings?.[0]
        ? {
            discountPaymentDate: bookings[0].discountPaymentDate,
            discountPercentage: bookings[0].discountPercentage,
          }
        : undefined,
      creditCardPayment:
        edge.node.payment?.isPaid === true &&
        edge.node.payment?.method === PaymentMethod.CardTransaction,
      purchaseOrderNumber: edge.node.purchaseOrderData?.orderNumber,
      hasPurchaseOrderLinked:
        edge.node.purchaseOrderData?.integrationType ===
        PurchaseOrderIntegrationType.Sap,
      tags: edge.node.tags,
      paymentInfo: {
        bankAccountNumber: undefined,
        iban: edge.node.iban ?? undefined,
        swiftCode: edge.node.swiftCode ?? undefined,
      },
      accountingArea: edge.node.accountingArea?.name ?? undefined,
      sapPurchaseOrderNumber: (edge.node.purchaseOrders ?? []).map(
        po => po?.orderNumber ?? ''
      ),
      goodsReceiptsCount: isSapPurchaseOrder
        ? calculateGoodsReceiptsCount(edge.node?.purchaseOrders ?? [])
        : undefined,
      selected: previewDocumentId === edge.node.id,
      fileName: edge.node.documentFile?.name ?? undefined,
      fileSize: edge.node.documentFile?.size ?? undefined,
      documentType: edge.node.invoiceCategoryType,
      paymentStatus: edge.node.payment?.isPaid
        ? 'PaidDocumentState'
        : 'UnpaidDocumentState',
      paidAt: edge.node.payment?.paidAt
        ? new Date(edge.node.payment.paidAt)
        : undefined,
      isInvoiceCorrection: edge.node.amount
        ? Boolean(edge.node.amount.amount < 0)
        : false,
      isWaitingForClarification: false, // Not implemented in new ES
    };
  });

  return documentsTableData;
};

import { Button, Grid, Link, Paragraph } from '@candisio/design-system';
import { commonSapBtnStyles } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/PurchaseOrderField/PurchaseOrderDropdownMenu';
import { Routes } from 'models';
import { motion } from 'motion/react';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion(Grid);

interface NewContactActionsProps {
  newContactTitle?: string;
  newContactDescription?: string;
  addContactLabel?: string;
  onAddContact?: () => void;
}

export const ProcessingFormNewContactActions = ({
  newContactTitle,
  newContactDescription,
  addContactLabel,
  onAddContact,
}: NewContactActionsProps) => {
  const [t] = useTranslation();
  const organization = useOrganizationId();
  const sapIsActive = useSap().isActive;
  const { shouldUseCoreDataApi } = useOtherIntegration();

  let content;
  if (sapIsActive) {
    content = (
      <>
        <SyncFromSap
          type={SAP_SYNC.ContactsProcessing}
          btnProps={commonSapBtnStyles}
        />
        <Link
          textAlign="center"
          external
          href={`/${organization ?? ''}${Routes.CONTACT_IMPORT_HISTORY}`}
        >
          {t('common:settings.contacts.contextMenu.history')}
        </Link>
      </>
    );
  } else if (shouldUseCoreDataApi) {
    content = (
      <Paragraph>
        {addContactLabel ??
          t('core-data-api:contacts.processing.newContact.add')}
      </Paragraph>
    );
  } else {
    content = (
      <Button
        onPointerDown={onAddContact}
        onClick={onAddContact}
        variant="secondary"
      >
        {addContactLabel ?? t('document.requestApproval.newContact.add')}
      </Button>
    );
  }

  return (
    <MotionGrid
      gap="space8"
      background="gray0"
      borderRadius="medium"
      padding="space16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Paragraph fontSize="basic">
        {newContactTitle ?? t('document.requestApproval.newContact.title')}
      </Paragraph>
      <Paragraph>
        {newContactDescription ??
          t('document.requestApproval.newContact.description')}
      </Paragraph>
      {content}
    </MotionGrid>
  );
};

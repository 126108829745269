/**
 * hook to update sap rounding amount as bookings data changes
 */
import {
  DocumentCurrency,
  useCalculateDocumentGrossNetTaxRoundingAmountsQuery,
} from 'generated-types/graphql.types';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { ProcessingFormValues } from './processingFormSchema';

type UseUpdateRoundingAmountProps = {
  form: UseFormReturn<ProcessingFormValues>;
};

export const useUpdateRoundingAmount = ({
  form,
}: UseUpdateRoundingAmountProps) => {
  const watchedBookings = useWatch({ control: form.control, name: 'bookings' });
  const currency = form.getValues('currency');
  const grossAmount = form.getValues('grossAmount');

  useCalculateDocumentGrossNetTaxRoundingAmountsQuery({
    variables: {
      input: {
        currency: currency as DocumentCurrency,
        grossAmount: grossAmount ?? 0,
        splitBookingsAmounts: watchedBookings.map(booking => ({
          netAmount: booking.netAmount ?? 0,
          taxAmount: booking.taxAmount ?? 0,
        })),
      },
    },
    onCompleted: data => {
      if (
        data.calculateDocumentGrossNetTaxRoundingAmounts.__typename ===
        'CalculateDocumentGrossNetTaxRoundingAmountsSuccessResult'
      ) {
        form.setValue(
          'roundingAmount',
          data.calculateDocumentGrossNetTaxRoundingAmounts.rounding
        );
      }
    },
  });
};

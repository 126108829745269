import {
  ReimbursementError,
  ReimbursementErrorType,
  ReimbursementItemStatus,
  ReimbursementItemType,
  useReimbursementCaseErrorsByIdQuery,
  useReimbursementItemsErrorsQuery,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { isNotRelevantErrorForCase } from '../utils/isNotRelevantErrorForCase';

export interface ReimbursementItemError {
  id: string;
  errorsCount: number;
  type: ReimbursementItemType;
  title?: string;
}

interface UseReimbursementErrorsResult {
  hasNoReimbursementItemsError: boolean;
  hasNoIncludedExpenseError: boolean;
  hasReimbursementCaseErrors: boolean;
  reimbursementItemsErrors: ReimbursementItemError[];
}

type ItemError = Pick<ReimbursementError, 'errors' | 'field'>;

const hasErrorType = (
  errors: ReimbursementErrorType[],
  errorType: ReimbursementErrorType
) => errors.includes(errorType);

const filterNonBookingErrors = (errors: ItemError[]) =>
  errors.filter(
    error => !hasErrorType(error.errors, ReimbursementErrorType.InvalidBookings)
  );

export const useReimbursementErrors = (): UseReimbursementErrorsResult => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const shouldSkipQueries = !reimbursementId || !canUseReimbursement;

  const { data: caseData } = useReimbursementCaseErrorsByIdQuery({
    variables: { id: reimbursementId ?? '' },
    skip: shouldSkipQueries,
  });

  const { data: itemsData } = useReimbursementItemsErrorsQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    skip: shouldSkipQueries,
  });

  const reimbursementCaseErrors = caseData?.reimbursementCaseErrorsById?.errors;

  const itemsList = itemsData?.reimbursementItemsErrors ?? [];

  const reimbursementItemsErrors = itemsList
    .filter(item => {
      // filter out excluded items
      if (item.status === ReimbursementItemStatus.Excluded) return false;

      // get non-booking errors
      const nonBookingErrors = filterNonBookingErrors(item.errors);

      // we only include non-booking errors in the list
      return nonBookingErrors.length > 0;
    })
    .map(({ id, type, errors, title }) => ({
      id,
      type,
      errorsCount: filterNonBookingErrors(errors).length,
      title: title ?? undefined,
    }));

  const hasNoReimbursementItemsError =
    reimbursementCaseErrors?.some(item =>
      hasErrorType(item.errors, ReimbursementErrorType.NotFound)
    ) ?? false;

  const hasNoIncludedExpenseError =
    reimbursementCaseErrors?.some(item =>
      hasErrorType(item.errors, ReimbursementErrorType.InvalidArrayContent)
    ) ?? false;

  const relevantReimbursementCaseErrors = reimbursementCaseErrors?.filter(
    isNotRelevantErrorForCase
  );

  return {
    reimbursementItemsErrors,
    hasNoIncludedExpenseError,
    hasNoReimbursementItemsError,
    hasReimbursementCaseErrors: !!relevantReimbursementCaseErrors?.length,
  };
};

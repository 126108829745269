import { Box } from '@candisio/design-system';
import { useMemo } from 'react';
import { HistoryLoading } from '../History/HistoryLoading';
import { DocumentHistory } from './DocumentHistory';
import { supportedEventKeys } from './common';
import { useDocumentHistory } from './useDocumentHistory';

export interface DocumentHistoryContainerProps {
  documentId: string;
  isEcmDocument?: boolean;
  isSensitiveDocument?: boolean;
}

export const DocumentHistoryContainer = ({
  documentId,
}: DocumentHistoryContainerProps) => {
  const { documentData, loading } = useDocumentHistory(documentId);

  const supportedEvents: any[] = useMemo(() => {
    if (!documentData) return [];

    const filteredEvents = documentData.timeline ?? [];

    // Keep only events supported by DocumentHistory
    return filteredEvents.filter(e =>
      supportedEventKeys.includes(e.__typename)
    );
  }, [documentData]);

  if (!loading && !document) {
    // TODO add error state
    return null;
  }

  if (loading) {
    return (
      <Box key="history-wrapper" padding="space16" paddingTop="space8">
        <HistoryLoading itemCount={4} />
      </Box>
    );
  }

  return (
    <Box key="history-wrapper" paddingX="space8">
      <DocumentHistory events={supportedEvents} />
    </Box>
  );
};

import { Button, Card, Grid, TruncatedText } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useDeleteReimbursement } from 'views/Reimbursement/hooks/useDeleteReimbursement';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
// import { AutoSaveStatusContainer } from './AutoSaveStatusContainer';
import { DeleteAction } from './DeleteAction';
import { ErrorSection } from './ErrorSection';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { FooterHeader } from './FooterHeader';
import { useReimbursementExpenseItemsList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

export interface FooterActionsProps {
  isSubmittingForReview: boolean;
  reimbursement?: Reimbursement;
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
  }>;
  reimbursementItems: ReturnType<
    typeof useReimbursementExpenseItemsList
  >['reimbursementItems'];
  refetchReimbursement: () => void;
  refetchReimbursementItems: () => void;
}

export const FooterActions = ({
  isSubmittingForReview,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  reimbursement,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
}: FooterActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error, success } = useToastMessage();

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });
  const { deletePending, deleteReimbursement } = useDeleteReimbursement();

  const {
    triggerAllValidations,
    lastModifiedExpense,
    hasReimbursementErrors,
    setHasReimbursementErrors,
  } = useReimbursementFormsContext();

  const errMessage = t('reimbursementView.createErr');
  const successMessage = t(
    'reimbursementView.rightSection.footerActions.doubleConfirm.deleteSuccessToast'
  );

  const handleDelete = async () => {
    const status = await deleteReimbursement(reimbursementId);
    if (status === 'error') {
      error(errMessage);

      return;
    }

    success(successMessage);
    onCycleReimbursments();
  };

  const handleClick = async () => {
    const lastModifiedExpenseData =
      lastModifiedExpense !== null
        ? getExpenses('expenses')[lastModifiedExpense]
        : undefined;

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'general'
    ) {
      await updateGeneralExpense(lastModifiedExpenseData);
    }

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'hospitality'
    ) {
      await updateHospitalityExpense(lastModifiedExpenseData);
    }

    const response = await onSubmitForReview(reimbursementId);
    if (response.status === 'error') {
      triggerAllValidations();
      setHasReimbursementErrors(true);

      return;
    }

    successMessageWithDeepLink({
      key: 'reimbursementView.rightSection.footerActions.submitSuccess',
    });
    setHasReimbursementErrors(false);
    onCycleReimbursments();
  };

  const isReimbursementItemUpdatePending =
    isUpdateHospitalityExpensePending || isUpdateGeneralExpensePending;

  const isSubmitPending =
    isSubmittingForReview || isReimbursementItemUpdatePending;

  const isErrorSectionVisible = hasReimbursementErrors;
  return (
    <Card corners="top" boxShadow="elevatedShadow4" padding="space20">
      <Grid gap="space8">
        <FooterHeader
          isSavePending={isReimbursementItemUpdatePending}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
          refetchReimbursement={refetchReimbursement}
          refetchReimbursementItems={refetchReimbursementItems}
        />
        {isErrorSectionVisible && <ErrorSection />}
        <Grid gap="space8">
          <Button
            color="blue"
            onClick={handleClick}
            disabled={isSubmitPending || deletePending}
            loading={isSubmitPending}
          >
            <TruncatedText>
              {t('reimbursementView.rightSection.footerActions.submitCta')}
            </TruncatedText>
          </Button>
          <DeleteAction
            loading={deletePending}
            onDelete={handleDelete}
            disabled={isSubmitPending || deletePending}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

import { Box, Button, Grid, Heading, Item } from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { nodocumentData } from 'components/Lottie/Lottie';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import {
  DASHBOARDLIST_PARAM_VALUES,
  REIMBURSEMENT_URL_PARAM,
} from '../Reimbursement';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { REIMBURSEMENT_VIEW_ROUTES } from '../hooks/useReimbursementNavigation';
import { completedStatuses } from './CompletedReImbursmentListContainer';
import { ReimbursementItem } from './ReimbursementItem';

interface CompletedReimbursementListProps {
  isLoading: boolean;
  reimbursements: Reimbursement[];
  reimbursementsCount: number;
  onEndReached?: (index: number) => void;
  onReset?: () => void;
}

export const CompletedReimbursementList = ({
  isLoading,
  reimbursements,
  reimbursementsCount,
  onEndReached,
}: CompletedReimbursementListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams } = useMutateSearchParams();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const handleClick = () => {
    const archiveReimbursementPath = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}`,
      {
        organizationSlug,
      }
    );

    const searchParams = new URLSearchParams();
    completedStatuses.forEach(status => searchParams.append('status', status));
    navigate({
      pathname: archiveReimbursementPath,
      search: searchParams.toString(),
    });
  };

  const handleClickItem = (id: string, cursor: string | undefined) => {
    if (!cursor) return;

    searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor);
    searchParams.set(
      REIMBURSEMENT_URL_PARAM.DASHBOARDLIST,
      DASHBOARDLIST_PARAM_VALUES.completedReimbursements
    );
    const pathname = generatePath(
      REIMBURSEMENT_VIEW_ROUTES[Routes.REIMBURSEMENTS_DASHBOARD],
      { organizationSlug, reimbursementId: id }
    );

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <FilterableList
      heading={
        <Grid templateColumns="1fr auto" alignItems="center" width="100%">
          <Heading as="h2">{t('dashboard.list.completedListHeader')}</Heading>
          {reimbursementsCount > 0 && (
            <Button variant="tertiary" justifySelf="end" onClick={handleClick}>
              {t('dashboard.list.actions.goToArchiveCta')}
            </Button>
          )}
        </Grid>
      }
      isLoading={isLoading}
      onEndReached={onEndReached}
      emptyDataState={
        <Box paddingX="space16">
          <EmptyDataState
            lottieAnimation={nodocumentData}
            translation="reimbursement:dashboard.list.completedListNoData"
          />
        </Box>
      }
    >
      {reimbursements.map(item => (
        <Item key={item.id} textValue={item.id}>
          <ReimbursementItem {...item} onClick={handleClickItem} />
        </Item>
      ))}
    </FilterableList>
  );
};

import { Button, InfoPanel, Text } from '@candisio/design-system';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { useSapB1SettingsQuery } from 'generated-types/graphql.types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ConfigureButton } from 'views/Integrations/Export/toolkit/components/ConfigureButton';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { hasTransaction } from 'views/Integrations/Export/toolkit/utils';
import { ExportEntity } from 'views/Integrations/Export/types';

type SapB1ExportButtonProps = {
  loading: boolean;
  onExport: () => void;
  entities: ExportEntity[];
  hasExportableEntity: boolean;
};

export const SapB1ExportButton = ({
  onExport,
  loading,
  entities,
  hasExportableEntity,
}: SapB1ExportButtonProps) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();
  const { isExporting } = useIsExporting();

  const { data } = useGetSapB1Credentials();
  const isSapB1Configured = Boolean(data?.sapB1Credentials?.companyDbId);

  const { data: sapB1Settings, loading: settingsLoading } =
    useSapB1SettingsQuery();

  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const hasError = !hasExportableEntity && isExportTriggered;

  const handleExport = () => {
    setIsExportTriggered(true);
    if (hasExportableEntity) {
      void onExport();
    }
  };

  if (isExporting || settingsLoading) {
    let progressText;
    if (isExporting) {
      progressText = t('export.exportForm.actionBar.exporting');
    } else {
      progressText = t('export.exportForm.actionBar.loading');
    }

    return (
      <ActionBar>
        <ProgressBar completion={0}>{progressText}</ProgressBar>
      </ActionBar>
    );
  }

  const creditCardTransactionExists = entities.some(hasTransaction);
  if (
    creditCardTransactionExists &&
    !sapB1Settings?.sapB1Settings?.creditCardId
  ) {
    return (
      <ActionBar>
        <ConfigureButton
          message={t('credit-cards:creditCardExportingSap.missingCreditCardId')}
          buttonLabel={t(
            'credit-cards:creditCardExportingSap.accountNumbers.creditCardConfigLink'
          )}
          loading={false}
          onClick={() =>
            navigate(
              `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
            )
          }
        />
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      {!isSapB1Configured && (
        <InfoPanel variant="information">
          {t('export.exportForm.actionBar.infoMessage')}
        </InfoPanel>
      )}
      {hasError && (
        <Text color="red500" fontSize="xsmall">
          {t('export.exportForm.actionBar.noExportableDocuments')}
        </Text>
      )}
      <Button
        onClick={isSapB1Configured ? handleExport : undefined}
        // Temporarily disabling to prevent orgs from exporting to SAP B1
        // will undo when feature is much more stable
        disabled={!isSapB1Configured || hasError}
        data-cy="submit"
        loading={loading || isExporting}
      >
        {t('export.exportForm.actionBar.export')}
      </Button>
    </ActionBar>
  );
};

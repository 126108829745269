import {
  Button,
  Flex,
  Grid,
  Text,
  TruncatedText,
  defaultTheme,
} from '@candisio/design-system';
import { AvailableEcmDocumentFiltersKeys } from 'components/EcmDocumentsTable/constants';
import { Routes } from 'models';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { MouseEvent, useState } from 'react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { useContractCustomSubCategoryData } from '../../toolkit/useContractCustomSubCategoryData';
import { MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH } from '../../toolkit/utils';

export const CONTRACT_TYPE_FILTER_NAME: AvailableEcmDocumentFiltersKeys =
  'contractType';

export interface ContractCustomSubCategoriesListItemProps {
  contractSubCategory: ReturnType<
    typeof useContractCustomSubCategoryData
  >['contractSubCategories'][number];
  queryString: string;
}

const StyledLink = motion(Button);
const colorOverride = { color: defaultTheme.colors.blue500 };

const preventDrawerOpen = (e: MouseEvent<HTMLAnchorElement>) => {
  e.stopPropagation();
};

export const ContractCustomSubCategoriesListItem = ({
  contractSubCategory: {
    id,
    name,
    description,
    isStatic = false,
    documentCount,
  },
  queryString,
}: ContractCustomSubCategoriesListItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const organizationSlug = useOrganizationId();
  const [isHovered, setIsHovered] = useState(false);

  const { hoverProps } = useHover({
    onHoverChange: isHovering => setIsHovered(isHovering),
  });

  const archiveWithFilterUrl = `/${organizationSlug}${Routes.ECM_CONTRACTS}?${CONTRACT_TYPE_FILTER_NAME}=${id}`;

  const hasAssignedDocuments =
    isStatic || (typeof documentCount === 'number' && documentCount > 0);

  return (
    <Grid
      autoFlow="column"
      lineHeight="20px"
      alignItems="center"
      gap="space32"
      color="gray500"
      fontSize="small"
      height="40px"
      {...hoverProps}
      templateColumns={`[name] ${MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH}ch [description] 1fr [isCustom] auto`}
    >
      <Flex direction="column">
        {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
        <TruncatedText fontWeight="semibold" fontSize="basic" color="gray800">
          {renderQueryHighlight({
            value: name ?? '',
            queryString: queryString,
          })}
        </TruncatedText>
        {!isStatic && (
          <Text>
            {t('contractCustomSubCategories.list.documentCount', {
              count: documentCount,
            })}
          </Text>
        )}
      </Flex>
      {/* @ts-expect-error for some reason TrucatedText complains about JSX.Element[] */}
      <TruncatedText lineClamp={2}>
        {renderQueryHighlight({
          value: description ?? '',
          queryString: queryString,
        })}
      </TruncatedText>
      {!isStatic && (
        <Text fontWeight="regular">
          {t('contractCustomSubCategories.list.customType')}
        </Text>
      )}
      {hasAssignedDocuments && isHovered && (
        <Flex
          position="absolute"
          alignItems="center"
          right="0"
          height="57px"
          paddingLeft="space32"
          paddingRight="space16"
          transition="background-image 0.2s ease-in-out"
          background="linear-gradient(90deg, #EBEDF780 0, #EBEDF7FF 10%)"
          style={{ marginRight: -16 }}
        >
          <StyledLink
            initial={colorOverride}
            whileHover={colorOverride}
            as="a"
            variant="tertiary"
            color="blue"
            size="small"
            icon="newTab"
            target="_blank"
            href={archiveWithFilterUrl}
            onClick={preventDrawerOpen}
            justifySelf="end"
          >
            {t('contractCustomSubCategories.list.viewDocuments')}
          </StyledLink>
        </Flex>
      )}
    </Grid>
  );
};

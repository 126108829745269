import {
  Query,
  ReimbursementCaseFiltersInput,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { completedReimbursementsCursorBasedQuery } from '../toolkit/queries';
import { useFormattedReimbursement } from './useFormattedReimbursement';
import { defaultReimbursementDashboardSort } from './useOpenReimbursementsCursorBasedData';

interface UseCompleteReimbursementsCursorBasedDataProps {
  filters?: ReimbursementCaseFiltersInput;
}

export const useCompletedReimbursementsCursorBasedData = ({
  filters,
}: UseCompleteReimbursementsCursorBasedDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const toReimbursement = useFormattedReimbursement();

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'archiveReimbursementCases'>
  >(completedReimbursementsCursorBasedQuery, 'archiveReimbursementCases', {
    variables: {
      limit: 20,
      filters,
      sorts: defaultReimbursementDashboardSort,
    },
    skip: !canUseReimbursement,
  });

  const { pageInfo, edges } = data?.archiveReimbursementCases ?? {};

  const totalReimbursements = pageInfo?.totalCount ?? 0;

  const reimbursementList = (edges ?? []).map(edge => ({
    ...toReimbursement({
      cursor: edge.cursor,
      ...edge.node,
    }),
  }));

  return {
    loading,
    loadMore: onLoadMore,
    reimbursementList,
    totalReimbursements,
  };
};

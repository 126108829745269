import { Icon } from '@candisio/design-system';
import { NavLink, NavLinkProps } from 'react-router-dom-v5-compat';
import { SwimlaneTile } from '../SwimlaneTile';
import styles from './SwimlaneTileShowAll.module.css';

export const SwimlaneTileShowAll = ({ to }: { to: NavLinkProps['to'] }) => {
  return (
    <SwimlaneTile asChild className={styles.tile} variant="gray">
      <NavLink to={to}>
        <span className={styles.link}>
          Show all <Icon icon="arrowRight" size={16} />
        </span>
      </NavLink>
    </SwimlaneTile>
  );
};

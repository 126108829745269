import { Box, Grid } from '@candisio/design-system';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { PdfHeaderProps } from 'components/DocumentViewer/PdfViewer/ReactPdf/PdfHeader';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo } from 'react';
import { useCallback, useState } from 'react';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { Expense } from './ExpenseFormWrapper';
import { ReplaceInvoice } from './ReplaceInvoice';

interface ExpenseInvoicePreviewContainerProps {
  id: string;
  name: string;
  invoice?: ExpenseInvoice | null;
  createInvoicePending?: boolean;
  isReadOnly?: boolean;
  isExcluded?: boolean;
  height?: string;
  onChange: (file: File) => Promise<void>;
  onInvoiceUpdate?: () => Promise<void>;
  expenseType: Expense;
}

export const ExpenseInvoicePreviewContainer = memo(
  ({
    id,
    name,
    invoice,
    createInvoicePending,
    isReadOnly,
    isExcluded,
    height,
    onChange,
    onInvoiceUpdate,
    expenseType,
  }: ExpenseInvoicePreviewContainerProps) => {
    const [isFileChanging, setIsFileChanging] = useState(false);
    const reimbursementExpenseItemFileExtractionFF = useCandisFeatureFlags(
      FEATURE_FLAGS.reimbursementExpenseItemFileExtraction
    );

    const isLoading = createInvoicePending || isFileChanging;
    const initialScale = expenseType === 'general' ? 0.6 : 0.5;

    const handleIsFileChanging = useCallback((val: boolean) => {
      setIsFileChanging(val);
    }, []);

    if (isLoading) {
      return (
        <Grid height="500px">
          <PdfInvoiceSkeleton />
        </Grid>
      );
    }

    if (!invoice) {
      return null;
    }

    const disabledControls: PdfHeaderProps['disabledControls'] =
      reimbursementExpenseItemFileExtractionFF
        ? ['downloadFiles', 'currentPage', 'previousPage', 'nextPage']
        : ['downloadFiles'];

    return (
      <Grid
        gap="space24"
        background="gray0"
        maxHeight={height}
        overflow="hidden"
        padding="space8 space16"
        alignContent="start"
        borderRadius="medium"
      >
        {/** Overlay to blur the whole pdf viewer */}
        {isExcluded && (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            opacity="0.4"
            background="white"
            zIndex={10}
          />
        )}
        <PdfViewer
          documentFile={invoice}
          maxHeight={height}
          disabledControls={disabledControls}
          initialScale={
            reimbursementExpenseItemFileExtractionFF ? 1 : initialScale
          }
          attachmentSection={
            <ReplaceInvoice
              id={id}
              name={name}
              disabled={isReadOnly || isLoading || isExcluded}
              onChange={onChange}
              onInvoiceUpdate={onInvoiceUpdate}
              isFileChanging={isFileChanging}
              onIsFileChanging={handleIsFileChanging}
            />
          }
        />
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    prevProps.invoice?.id === nextProps.invoice?.id &&
    prevProps.isExcluded === nextProps.isExcluded
);

import { Box, useTheme } from '@candisio/design-system';
import { motion } from 'motion/react';
import { CSSProperties, memo } from 'react';

interface PercentageProgressBarProps {
  percentage: number;
  color: string;
  style?: CSSProperties;
  showAnimation?: boolean;
}

export const PercentageProgressBar = memo(
  ({
    percentage,
    color,
    style,
    showAnimation = true,
  }: PercentageProgressBarProps) => {
    const { colors } = useTheme();

    const transition = { duration: 0.6, ease: 'easeInOut', delay: 0.3 };

    return (
      <Box
        height="space8"
        width="100%"
        background="gray300"
        borderRadius={12}
        style={style}
      >
        <motion.div
          style={{
            background: colors[color as never],
            minWidth: '0%',
            maxWidth: '100%',
            height: '100%',
            borderRadius: 'inherit',
            opacity: '0.6',
            width: `${percentage}%`,
          }}
          initial={showAnimation ? { width: 0, opacity: 0 } : undefined}
          animate={
            showAnimation
              ? { width: `${percentage}%`, opacity: 0.6 }
              : undefined
          }
          transition={showAnimation ? transition : undefined}
        />
      </Box>
    );
  }
);

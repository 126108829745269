import {
  SubmitReimbursementCaseWorkflowStepInput,
  useSubmitReimbursementCaseWorkflowStepMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsErrorsQuery,
} from '../toolkit/queries';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementRouteParams } from './useReimbursementNavigation';

export type SubmitReimbursementCaseWorkflowStep = Omit<
  SubmitReimbursementCaseWorkflowStepInput,
  'reimbursementCaseId'
>;

export const useSubmitReimbursmentWorkflowStep = () => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const [
    submitWorkflowStepMutation,
    { loading: isWorkflowStepPending, client },
  ] = useSubmitReimbursementCaseWorkflowStepMutation();

  const submitWorkflowStep = useCallback(
    async (input: SubmitReimbursementCaseWorkflowStep) => {
      const response = await submitWorkflowStepMutation({
        variables: {
          input: {
            stepId: input.stepId,
            resolution: input.resolution,
            comment: input.comment ?? undefined,
            reimbursementCaseId: reimbursementId,
          },
        },
        onCompleted: () => {
          // Instead of refetching the 3 different queries with different params we evict the cache
          client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
        },
        refetchQueries: [
          'inboxReimbursementCasesCount',
          'archiveReimbursementCasesCount',
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsErrorsQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
        ],
      });

      const isReponseInvalid =
        !response.data?.submitReimbursementCaseWorkflowStep.success;

      if (isReponseInvalid) {
        return 'error';
      }

      return 'success';
    },
    [submitWorkflowStepMutation, reimbursementId, client]
  );

  return { submitWorkflowStep, isWorkflowStepPending };
};

import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { faker } from 'utils/faker';
import { ArchiveReimbursementCase } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';

export const ReimbursementDraftSwimlane = ({
  draftReimbursementCases,
  totalCount,
}: {
  draftReimbursementCases?: ArchiveReimbursementCase[];
  totalCount?: number;
}) => {
  const hasActionTile = false;
  const hasPromoTile = true;

  if (!draftReimbursementCases?.length) {
    return null;
  }

  return (
    <Swimlane>
      <SwimlaneTiles
        contentTileCount={draftReimbursementCases.length}
        hasActionTile={hasActionTile}
        hasPromoTile={hasPromoTile}
      >
        {({
          contentTileVisibleCount,
          isActionTileVisible,
          isPromoTileVisible,
          isShowAllTileVisible,
        }) => {
          return (
            <>
              <SwimlaneHeader icon="add" label="Reimbursement Draft">
                <span>{`${contentTileVisibleCount} of ${totalCount}`}</span>
              </SwimlaneHeader>
              {isActionTileVisible && (
                <SwimlaneTile asChild variant="red" className="col-span-2">
                  <a href="#attention">Attention!</a>
                </SwimlaneTile>
              )}
              {Array.from({ length: contentTileVisibleCount }, (_, index) => (
                <SwimlaneTile
                  asChild
                  variant="yellow"
                  key={`${draftReimbursementCases?.[index]?.id}-${faker.string()}`}
                >
                  <a href="/">{draftReimbursementCases?.[index]?.status}</a>
                </SwimlaneTile>
              ))}

              {isShowAllTileVisible && <SwimlaneTileShowAll to="/" />}
              {isPromoTileVisible && (
                <SwimlaneTile asChild variant="blue" className="col-span-2">
                  <a href="#promo">Promo</a>
                </SwimlaneTile>
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

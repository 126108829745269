import {
  CustomEmptyStateProps,
  FilterOptionsAccessor,
  SelectionOptions,
  TableProps,
} from '@candisio/design-system';
import {
  ConnectedInvoicesData,
  GoodsLineItem,
  Maybe,
  PurchaseOrderStatus,
  PurchaseOrdersInvoiceAssociationStatus,
  Scalars,
  ServiceLineItem,
} from 'generated-types/graphql.types';
import { ReactNode } from 'react';
import { Filters, SortingRule, TableState } from 'react-table';

export interface Amount {
  value: number;
  currency: string;
}
export interface PurchaseOrdersTableData {
  cursor?: Scalars['String']['output'];
  id: string;
  contact?: Maybe<string>;
  status?: Maybe<PurchaseOrderStatus>;
  grossAmount?: Maybe<Amount>;
  orderNumber?: Maybe<string>;
  postingDate?: Maybe<Date>;
  invoiceAssociationStatus?: Maybe<PurchaseOrdersInvoiceAssociationStatus>;
  connectedInvoices?: ConnectedInvoicesData[];
  lineItems?: {
    goodsLineItems?: Maybe<Array<GoodsLineItem>>;
    serviceLineItems?: Maybe<Array<ServiceLineItem>>;
  };
  accountsPayableNumber?: Maybe<string>;
  goodsReceiptCount?: {
    open: number;
    closed: number;
    total: number;
  };
  supplierRefNumber?: Maybe<string>;
}

export interface PurchaseOrdersTableProps {
  data: Array<PurchaseOrdersTableData>;
  columnWidths?: ColumnWidths;
  columns?: Array<keyof PurchaseOrdersTableData>;
  onSort?: (sortBy: SortingRule<PurchaseOrdersTableData>[]) => void;
  onFilter?: (filters: Filters<PurchaseOrdersTableData>) => void;
  selectionOptions?: SelectionOptions<PurchaseOrdersTableData>;
  filterOptions?: FilterOptionsAccessor<PurchaseOrdersTableData>;
  defaultFilters?: TableState<PurchaseOrdersTableData>['filters'];
  defaultSortBy?: TableState<PurchaseOrdersTableData>['sortBy'];
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  isLoading?: boolean;
  onEndReached: (index: number) => void;
  borderTopRadius?: TableProps<PurchaseOrdersTableData>['borderTopRadius'];
  tableFooter?: ReactNode;
  documentAmount?: number | null;
}

export interface UseControlledStateOptions {
  filters: Filters<PurchaseOrdersTableData>;
  sortBy: SortingRule<PurchaseOrdersTableData>[];
  onSort?: (sortBy: SortingRule<PurchaseOrdersTableData>[]) => void;
  onFilter?: (filters: Filters<PurchaseOrdersTableData>) => void;
  handleFiltersInUrl?: (
    sortBy: SortingRule<PurchaseOrdersTableData>[],
    filters: Filters<PurchaseOrdersTableData>
  ) => void;
}

export type PruchaseOrdersTableDataKeys = Exclude<
  keyof PurchaseOrdersTableData,
  | 'cursor'
  | 'id'
  | 'contact'
  | 'lineItems'
  | 'connectedInvoices'
  | 'accountsPayableNumber'
  | 'goodsReceiptCount'
  | 'isDisabled'
  | 'canBeSelectedTooltipText'
>;

export enum ViewUsingPruchaseOrders {
  // INBOX = 'INBOX',
  ARCHIVE = 'ARCHIVE',
  // PROCESSING_DOCUMENT = 'PROCESSING_DOCUMENT',
}

export type AvailableFiltersPurchaseOrdersTable = Pick<
  PurchaseOrdersTableData,
  'contact' | 'invoiceAssociationStatus' | 'status' | 'accountsPayableNumber'
>;

export enum PURCHASE_ORDERS_QUERY_PARAMS {
  contact = 'contact',
  invoiceAssociationStatus = 'invoiceAssociationStatus',
  category = 'category',
  status = 'status',
  accountPayableNumber = 'accountPayableNumber',
}

export type ColumnWidths = Partial<
  Record<keyof PurchaseOrdersTableData, string>
>;

import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { UseFormReturn } from 'react-hook-form';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { ExpenseFooter } from './ExpenseFooter';
import { ExpenseLayout } from './ExpenseLayout';
import { InvoiceLeftSection } from './InvoiceLeftSection';
import {
  FieldNames,
  generateGeneralExpenseFormFieldNames,
} from './utils/generateGeneralExpenseFormFieldNames';
import { BooleanMap } from './utils/useExpensesFormActions';
import { FieldsOptions } from './utils/useFieldsOptions';
import { useGeneralRightSectionView } from './utils/useRightSectionView';

interface GeneralExpensesFormProps {
  isExpenseFormEditable: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateExpense: (index: number) => () => Promise<void>;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  isCreatingInvoice: BooleanMap;
  fieldOptions: FieldsOptions;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
  isExtractingInvoiceData: BooleanMap;
  onGeneralInvoiceDataExtraction: (
    index: number,
    fields: FieldNames
  ) => Promise<void>;
}

export const GeneralExpensesForm = ({
  isExpenseFormEditable,
  reimbursementStatus,
  clearableField,
  expenseId,
  formIndex,
  formMethods,
  isCreatingInvoice,
  isDeletingForm,
  fieldOptions,
  onCleareableField,
  onInvoiceChange,
  onUpdateExpense,
  onRemoveForm,
  canManageReimbursementItems,
  isExtractingInvoiceData,
  onGeneralInvoiceDataExtraction,
}: GeneralExpensesFormProps) => {
  const { watch, getValues, trigger } = formMethods;
  const { isExcluded: isExcludedFieldOptions } = fieldOptions;
  const fields = generateGeneralExpenseFormFieldNames(formIndex);

  const invoices = watch(fields.files);

  const invoice = invoices?.[0];
  const isExcluded = watch(fields.isExpenseExcluded);

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const itemStatus = getValues(fields.itemStatus);

  const isExtractedDataAccepted = getValues(fields.isExtractedDataAccepted);
  const showInvoicePreview = isCreatingInvoice[formIndex] || !!invoice;

  const isExcludedInfoVisible =
    isExcluded && isExcludedFieldOptions.showInfoIsExcluded;

  const handleInvoiceUpdate = async () => {
    await onGeneralInvoiceDataExtraction(formIndex, fields);
  };

  const RightSection = useGeneralRightSectionView({
    isExpenseFormEditable,
  });

  const rightSectionProps = {
    formIndex,
    isExcluded,
    isExpenseFormEditable,
    isExtractedDataAccepted,
    onUpdateExpense,
    reimbursementStatus,
    clearableField,
    onCleareableField,
    isCreatingInvoice,
    isExtractingInvoiceData,
    invoice,
  };

  return (
    <ExpenseLayout
      expenseType="general"
      expenseId={expenseId}
      totalAmount={totalAmount}
      reason={reason}
      isExcluded={isExcluded}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
      leftSectionInvoice={
        <InvoiceLeftSection
          canManageReimbursementItems={canManageReimbursementItems}
          formIndex={formIndex}
          handleInvoiceUpdate={handleInvoiceUpdate}
          invoice={invoice}
          isCreatingInvoice={isCreatingInvoice}
          isExcluded={isExcluded}
          onInvoiceChange={onInvoiceChange}
          showInvoicePreview={showInvoicePreview}
        />
      }
      rightSectionFields={<RightSection {...rightSectionProps} />}
      footer={
        <ExpenseFooter
          canManageReimbursementItems={canManageReimbursementItems}
          expenseId={expenseId}
          formIndex={formIndex}
          isDeletingForm={isDeletingForm}
          isExcludedFieldOptions={isExcludedFieldOptions}
          isExcludedInfoVisible={isExcludedInfoVisible}
          onRemoveForm={onRemoveForm}
          onUpdateExpense={onUpdateExpense}
          onTriggerValidation={trigger}
        />
      }
    />
  );
};

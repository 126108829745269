import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateReimbursementCaseMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { reimbursementHistoryQuery } from '../toolkit/queries';
import { ReimbursementFormValues } from '../toolkit/reimbursementFormSchema';

export const useUpdateReimbursement = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error } = useToastMessage();
  const [updateReimbursement, { loading: updateReimbursementPending, client }] =
    useUpdateReimbursementCaseMutation({
      onCompleted: () => {
        client.cache.evict({ fieldName: 'inboxReimbursementCases' });
        client.cache.evict({ fieldName: 'archiveReimbursementCases' });
        client.cache.evict({
          fieldName: 'reimbursementCaseById',
          args: { id: reimbursementId },
        });
      },
      refetchQueries: [
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
      ],
    });

  const handleUpdateReimbursement = async (values: ReimbursementFormValues) => {
    if (!reimbursementId) return;

    const { errors } = await updateReimbursement({
      variables: {
        id: reimbursementId,
        input: {
          title: values.title ?? null,
          note: values.note,
          targetMembershipId: values.targetMembership?.[0],
          suggestedCostCenterId: values.suggestedCostCenter?.value,
          suggestedCostObjectId: values.suggestedCostObject?.value,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));

      return;
    }
  };

  return {
    updateReimbursementPending,
    updateReimbursement: handleUpdateReimbursement,
  };
};

import {
  DocumentType,
  EcmDocumentTypeSettingRecord,
  Locale,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useSap } from 'orgConfig/sap';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE_TRANSLATION_MAP } from 'views/Settings/DocumentTypes/toolkit/consts';
import { useDocumentTypesData } from 'views/Settings/DocumentTypes/toolkit/useDocumentTypesData';

export interface StorageFormDocumentTypeFieldItem {
  key: DocumentType;
  children: string;
}

interface FilterItem {
  id: DocumentType;
  label: string;
}

export type StorageFormDocumentTypeList = [StorageFormDocumentTypeFieldItem];

interface StorageFormDocumentTypeReturn {
  isLoading: boolean;
  /** A map of the DocumentType enum to the translated string */
  ecmDocumentTypeTranslationMap: Record<DocumentType, string>;
  /** A list of select items used in the select component */
  ecmDocumentTypeSelectItems: Array<StorageFormDocumentTypeFieldItem>;
  /** A list of filter items used in the table */
  ecmDocumentTypeFilterOptions: Array<FilterItem>;
}

/**
 * Document types that should not be available for SAP users
 */
export const sapDocumentTypesBlacklist = [
  DocumentType.DeliveryNote,
  DocumentType.PurchaseOrder,
];

interface TranslatedEcmDocumentTypeSettingRecord
  extends EcmDocumentTypeSettingRecord {
  translatedName: string;
}

const byLocalCompare = (
  a: TranslatedEcmDocumentTypeSettingRecord,
  b: TranslatedEcmDocumentTypeSettingRecord
): number => {
  const { name: _a, translatedName: translationA } = a;
  const { name: _b, translatedName: translationB } = b;
  // push "Other" to the end of the list
  if (_a === DocumentType.Other) return 1;
  if (_b === DocumentType.Other) return -1;

  return translationA.localeCompare(translationB);
};

/**
 * A custom hook to get the translation map and filter items for the ECM
 * document type based on the DocumentType enum
 */

const getReimbursementItemsStatusesBlackList = ({
  canUseReimbursement,
}: { canUseReimbursement: boolean }) => {
  return canUseReimbursement
    ? [DocumentType.TravelReimbursementItem]
    : [
        DocumentType.TravelReimbursementItem,
        DocumentType.ExpenseReimbursementItem,
      ];
};

export const useEcmDocumentTypeOptions = (): StorageFormDocumentTypeReturn => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const { canUseReimbursement } = useReimbursement();
  const [documentTypesSettingFF, newAllDocumentsQueryFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.documentTypesSetting,
      FEATURE_FLAGS.useNewAllDocumentsQuery,
    ]);

  const reimbursementItemsStatusesBlackList = useMemo(
    () =>
      getReimbursementItemsStatusesBlackList({
        canUseReimbursement: newAllDocumentsQueryFF && canUseReimbursement,
      }),
    [canUseReimbursement, newAllDocumentsQueryFF]
  );

  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const ecmDocumentTypeTranslationMap = useMemo(() => {
    const translatedMap = Object.entries(DOCUMENT_TYPE_TRANSLATION_MAP).map(
      ([documentType, translationKey]) => [documentType, t(translationKey)]
    );
    return Object.fromEntries(translatedMap);
  }, [t, locale]);

  const { isActiveIntegration: isSapOrganization } = useSap();
  const { data, loading } = useDocumentTypesData({ includeNonEcmTypes: true });

  const ecmDocumentTypeList = useMemo(() => {
    if (!data?.ecmDocumentTypeSettings) return [];
    return (
      data.ecmDocumentTypeSettings
        // Check if SAP is active to filter out document types that are irrelevant to SAP users
        // Sort list alphabetically, then append "Other" to the end of the list
        .filter(
          ({ name }) =>
            !isSapOrganization ||
            !sapDocumentTypesBlacklist.includes(name as DocumentType)
        )
        .filter(
          ({ name }) =>
            !reimbursementItemsStatusesBlackList.includes(name as DocumentType)
        )
        .filter(({ name }) => name in ecmDocumentTypeTranslationMap)
        .map(type => ({
          ...type,
          translatedName:
            ecmDocumentTypeTranslationMap[type.name as DocumentType],
        }))
        .sort(byLocalCompare)
    );
  }, [
    data,
    isSapOrganization,
    ecmDocumentTypeTranslationMap,
    reimbursementItemsStatusesBlackList,
  ]);

  // Transform list to match the format of the Select component
  const ecmDocumentTypeSelectItems = ecmDocumentTypeList
    .filter(({ isVisible }) => isVisible)
    .filter(({ isNonEcmType }) => isNonEcmType === false)
    .map(
      ({ name: key, translatedName: children }) =>
        ({ key, children }) as StorageFormDocumentTypeFieldItem
    );

  const ecmDocumenttypeFilterOptions = documentTypesSettingFF
    ? ecmDocumentTypeList.filter(({ documentCount }) => !!documentCount)
    : ecmDocumentTypeList;

  // Transform list to match the format of the table filter
  const mappedEcmDocumenttypeFilterOptions = ecmDocumenttypeFilterOptions.map(
    ({ name: id, translatedName: label }) => ({ id, label }) as FilterItem
  );

  return {
    ecmDocumentTypeTranslationMap,
    ecmDocumentTypeSelectItems,
    ecmDocumentTypeFilterOptions: mappedEcmDocumenttypeFilterOptions,
    isLoading: loading,
  };
};

import { useOverflowGrid } from 'components/OverflowGrid/useOverflowGrid';
import { ReactNode } from 'react';
import { SwimlaneTileRow } from './SwimlaneTileRow';
import { MAX_COLUMNS, TILE_GAP, TILE_HEIGHT, TILE_WIDTH } from './constants';
import { useSwimlaneTileRules } from './useSwimlaneTileRules';

export type SwimlaneTilesProps = {
  children:
    | ReactNode
    | ((values: ReturnType<typeof useSwimlaneTileRules>) => ReactNode);
  contentTileCount: number;
  hasActionTile?: boolean;
  hasPromoTile?: boolean;
};

export const SwimlaneTiles = (props: SwimlaneTilesProps) => {
  const {
    children,
    contentTileCount,
    hasActionTile = false,
    hasPromoTile = false,
  } = props;
  const { colCount, ref } = useOverflowGrid<HTMLDivElement>(
    TILE_WIDTH,
    TILE_HEIGHT,
    TILE_GAP
  );
  const totalColumnCount = Math.min(colCount, MAX_COLUMNS);
  const rules = useSwimlaneTileRules({
    contentTileCount,
    contentTileLimit: totalColumnCount,
    hasActionTile,
    hasPromoTile,
  });

  return (
    <SwimlaneTileRow colCount={totalColumnCount} ref={ref}>
      {typeof children === 'function' ? children(rules) : children}
    </SwimlaneTileRow>
  );
};

import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { grossToNet } from 'containers/SplitBookings/toolkit/utils';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { useCallback, useMemo } from 'react';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { useReimbursementUpdatePending } from 'views/Reimbursement/hooks/useReimbursementUpdatePending';
import { useUpdateGeneralExpenseBookings } from 'views/Reimbursement/hooks/useUpdateGeneralExpenseBookings';
import { useUpdateHospitalityExpenseBookings } from 'views/Reimbursement/hooks/useUpdateHospitalityExpenseBookings';
import { ReimbursementSplitBookingsFormValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { TopLevelFieldNames } from '../../utils/generateSplitBookingsFormFieldNames';

type SubmitValues =
  ReimbursementSplitBookingsFormValues['reimbursementItemBookings'][number];

type SumbitAction = Record<
  ReimbursementItemType,
  (values: SubmitValues) => Promise<
    | {
        status: string;
      }
    | undefined
  >
>;
interface ReimbursementSplitBookingFormActionsProps {
  topFields: TopLevelFieldNames;
  setShowErrors: (val: boolean) => void;
  onRemoveBooking: UseFieldArrayRemove;
  onAppendBooking: UseFieldArrayAppend<
    ReimbursementSplitBookingsFormValues,
    `reimbursementItemBookings.${number}.bookings`
  >;
}

export const useReimbursementSplitBookingFormActions = ({
  topFields,
  setShowErrors,
  onAppendBooking,
  onRemoveBooking,
}: ReimbursementSplitBookingFormActionsProps) => {
  const { updateActiveBooking, setDrawerView, formMethods, activeBooking } =
    useReimbursementSplitBookingHelpers();

  const {
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = formMethods;

  const { updateHospitalityExpenseBookings, isUpdatingHospitalityBookings } =
    useUpdateHospitalityExpenseBookings();

  const { updateGeneralExpenseBookings, isUpdatingGeneralBookings } =
    useUpdateGeneralExpenseBookings();

  const isUpdatingBooking =
    isUpdatingHospitalityBookings || isUpdatingGeneralBookings;

  useReimbursementUpdatePending(isUpdatingBooking);

  const submitActions: Partial<SumbitAction> = useMemo(
    () => ({
      GENERAL_EXPENSE: updateGeneralExpenseBookings,
      HOSPITALITY_EXPENSE: updateHospitalityExpenseBookings,
    }),
    [updateGeneralExpenseBookings, updateHospitalityExpenseBookings]
  );

  const handleSetRemainingAmount = useCallback(
    (remainingAmount: number) => {
      setValue(topFields.remainingAmount, remainingAmount, {
        shouldValidate: true,
      });
    },
    [setValue, topFields.remainingAmount]
  );

  const handleAddSplitBooking = useCallback(
    async (remainingAmount: number) => {
      const isBookingEntryValid = await trigger([topFields.bookings], {
        shouldFocus: true,
      });

      // we can only proceed if validation passes
      if (!isBookingEntryValid) {
        setShowErrors(true);
        return;
      }

      const currentBookings = getValues(topFields.bookings) ?? [];
      const previousBooking = currentBookings[currentBookings.length - 1];
      const updatedSplitAmount =
        previousBooking?.taxPresentation === TaxPresentation.Gross
          ? remainingAmount
          : grossToNet(remainingAmount, previousBooking.vatRate ?? 0);

      const booking = {
        ...previousBooking,
        bookingId: uuidv4(),
        splitAmount: updatedSplitAmount,
      };

      onAppendBooking(booking, { shouldFocus: true });

      // we update and set next booking as active
      updateActiveBooking({ bookingId: booking.bookingId });
      scrollToTarget(booking.bookingId);
    },
    [
      getValues,
      onAppendBooking,
      setShowErrors,
      topFields.bookings,
      trigger,
      updateActiveBooking,
    ]
  );

  const handleDeleteBooking = useCallback(
    async (bookingIndex: number) => {
      const currentBookings = getValues(topFields.bookings) ?? [];

      // we only proceed if we have multiple bookings
      if (currentBookings.length <= 1) return;

      // we determine which booking to open after deletion:
      // if deleting the last booking, select the previous one
      // otherwise, select the next booking in the list
      const nextIndex =
        bookingIndex === currentBookings.length - 1
          ? bookingIndex - 1
          : bookingIndex + 1;

      const nextBookingId = currentBookings[nextIndex].bookingId;

      // we use a Promise to create a microtask to avoid stale state issues as a result of shifting array indices
      // this ensures that booking removal and active booking update occur in sequence
      await Promise.resolve().then(() => {
        onRemoveBooking(bookingIndex);
        updateActiveBooking({ bookingId: nextBookingId });
      });
    },
    [getValues, onRemoveBooking, topFields.bookings, updateActiveBooking]
  );

  const handleAcceptSplits = useCallback(async () => {
    const isFormValid = await trigger(
      `reimbursementItemBookings.${activeBooking.entryIndex}`,
      { shouldFocus: true }
    );

    const values = getValues(
      `reimbursementItemBookings.${activeBooking.entryIndex}`
    );

    // we can only proceed if validation passes
    if (!isFormValid) {
      setShowErrors(true);

      return;
    }

    const reimbursementItemType = values?.reimbursementItemType;
    if (!reimbursementItemType) {
      return;
    }

    const response = await submitActions[reimbursementItemType]?.(values);
    if (response) {
      setDrawerView(undefined);
    }
  }, [
    trigger,
    activeBooking.entryIndex,
    getValues,
    submitActions,
    setShowErrors,
    setDrawerView,
  ]);

  const checkFormErrors = useCallback(
    (entryIndex: number, remainingAmount: number) => {
      const bookingErrors =
        errors.reimbursementItemBookings?.[entryIndex]?.bookings ?? [];

      return {
        bookingErrors,
        hasErrors: !!bookingErrors.length || !!remainingAmount,
      };
    },
    [errors]
  );

  return {
    isUpdatingBooking,
    handleAddSplitBooking,
    handleAcceptSplits,
    handleDeleteBooking,
    checkFormErrors,
    handleSetRemainingAmount,
  };
};

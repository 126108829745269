import {
  Card,
  Flex,
  Grid,
  Icon,
  Separator,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { motion } from 'motion/react';
import { Fragment, ReactNode, useState } from 'react';

interface AccordionCardProps {
  items: {
    title: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
  }[];
  loading?: boolean;
  open?: boolean;
  collapsable?: boolean;
}

const AccordionItem = ({
  title,
  isOpen,
  content,
  footer,
}: {
  title: ReactNode;
  content: ReactNode;
  footer: ReactNode;
  isOpen?: boolean;
}) => {
  return (
    <Grid gap="space4">
      <Text fontSize="basic" fontWeight="semibold" color="gray800">
        {title}
      </Text>
      <motion.div
        initial="collapsed"
        animate={isOpen ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: {
            height: 'auto',
            opacity: 1,
            display: 'block',
            transition: {
              height: {
                duration: 0.2,
              },
              opacity: {
                duration: 0.15,
                delay: 0.05,
              },
            },
          },
          collapsed: {
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.2,
              },
              opacity: {
                duration: 0.15,
              },
            },
            transitionEnd: {
              display: 'none',
            },
          },
        }}
      >
        <Text fontSize="small" color="gray600">
          {content}
        </Text>
      </motion.div>
      {footer && (
        <Text fontSize="basic" fontWeight="semibold" color="gray800">
          {footer}
        </Text>
      )}
    </Grid>
  );
};

export const SummaryAccordionCard = ({
  items,
  open,
  loading,
  collapsable = true,
}: AccordionCardProps) => {
  const MotionIcon = motion(Icon);

  const [isOpen, setIsOpen] = useState(open ?? false);

  return items ? (
    <Card background="gray100" padding="space12">
      <Grid templateColumns="1fr auto" gap="space8">
        {loading ? (
          <Flex alignItems="center">
            <Skeleton width="80%" height="space16" />
          </Flex>
        ) : (
          <Grid autoFlow="row" gap="space8">
            {items.map((item, index) => {
              const isLastItem = index === items.length - 1;

              return (
                <Fragment key={index}>
                  <AccordionItem
                    title={item.title}
                    content={item.content}
                    isOpen={isOpen}
                    footer={item.footer}
                  />
                  {!isLastItem && <Separator />}
                </Fragment>
              );
            })}
          </Grid>
        )}
        {collapsable && (
          <Flex
            padding="space4"
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: 'pointer' }}
          >
            <MotionIcon
              icon="caretDown"
              size="space16"
              animate={isOpen ? 'open' : 'closed'}
              variants={{
                open: { rotate: 180 },
                closed: { rotate: 0 },
              }}
              transition={{
                rotate: { duration: 0.2 },
                ease: 'easeInOut',
              }}
            />
          </Flex>
        )}
      </Grid>
    </Card>
  ) : null;
};

import { Button, Card, Grid, Link, Text } from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export interface TransactionsActivationHintProps {}

export const TransactionsActivationHint = (
  _props: TransactionsActivationHintProps
) => {
  const currentUser = useCurrentUser();

  return (
    <Grid padding="space20" gap="space20">
      <Text fontSize="basic">
        Don't you have access to Credit Cards? Please request access from{' '}
        <Link
          external
          href="https://candis.slack.com/app_redirect?channel=C01L3NGNLJ0"
        >
          Team Achilles
        </Link>
      </Text>
      <Text> Here is a template message you can use:</Text>
      <Card background="gray100">
        <Grid rowGap="space12">
          <code>
            Hello, could you please assist in adding `{currentUser?.email}` to
            an organization with access to credit cards and transactions
          </code>
          <Button
            icon="copy"
            iconPosition="right"
            width="max-content"
            size="small"
            onClick={() => {
              void navigator.clipboard.writeText('text');
            }}
          >
            Copy
          </Button>
        </Grid>
      </Card>
      <Grid>
        <Text>
          Find out more details in this{' '}
          <Link
            external
            href="https://app.getguru.com/card/iM5nbdAT/Creating-TestDemo-Credit-Card-Transactions?query=achilles"
          >
            Guru card
          </Link>
        </Text>
      </Grid>
    </Grid>
  );
};

import { Button, Grid, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import {
  ExpenseSchema,
  ExpensesFormOutput,
} from 'views/Reimbursement/toolkit/expensesFormSchema';
import { customStyle } from './GeneralExpenseInvoiceData';
import { generateGeneralExpenseFormFieldNames } from './utils/generateGeneralExpenseFormFieldNames';
import { useFormatGeneralExpenseData } from './utils/useFormatGeneralExpenseData';

export const DATA_FIELDS_GENERAL_EXPENSE: Array<{
  key: keyof Extract<ExpenseSchema, { expenseType: 'general' }>;
  label: string;
}> = [
  {
    key: 'reason',
    label: 'reimbursementView.middleSection.form.general.reason.label',
  },
  {
    key: 'expenseDate',
    label: 'reimbursementView.middleSection.form.extractedData.date',
  },
  {
    key: 'invoiceNumber',
    label: 'reimbursementView.middleSection.form.extractedData.invoiceNumber',
  },
  {
    key: 'totalAmount',
    label: 'reimbursementView.middleSection.form.extractedData.grossAmount',
  },
];

interface SummaryGeneralExpenseProps {
  formIndex: number;
  reimbursementStatus: ReimbursementCaseStatus | undefined;
  isExtractedDataAccepted: boolean;
  isExcluded: boolean;
  isExpenseFormEditable: boolean;
  onUpdateExpense: (index: number) => () => Promise<void>;
}

export const SummaryGeneralExpense = ({
  formIndex,
  reimbursementStatus,
  isExcluded,
  isExpenseFormEditable,
  isExtractedDataAccepted,
  onUpdateExpense,
}: SummaryGeneralExpenseProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { getValues, setValue } = useFormContext<ExpensesFormOutput>();
  const fieldsNames = generateGeneralExpenseFormFieldNames(formIndex);

  const formatData = useFormatGeneralExpenseData();

  const isReviewStatus = reimbursementStatus === ReimbursementCaseStatus.Review;

  const isEditButtonVisible =
    isReviewStatus &&
    isExtractedDataAccepted &&
    !isExcluded &&
    isExpenseFormEditable;

  const handleEditClick = async () => {
    setValue(fieldsNames.isExtractedDataAccepted, false);

    await onUpdateExpense(formIndex)();
  };

  const formValues = getValues(`expenses.${formIndex}`);

  if (formValues.expenseType === 'hospitality') {
    return null;
  }

  return (
    <Grid alignContent="start">
      <Grid
        background="gray200"
        padding="space16"
        paddingBottom="space8"
        gap="space8"
        borderTopRadius="medium"
        borderBottomRadius={isEditButtonVisible ? 'none' : 'medium'}
      >
        <Grid gap="space8">
          {DATA_FIELDS_GENERAL_EXPENSE.map(({ key, label }) => {
            const data = formatData(formValues, key);

            return (
              <Grid key={key}>
                <TruncatedText color="gray600">{t(label)}</TruncatedText>
                <TruncatedText fontSize="basic">
                  {data?.toString() ?? '-'}
                </TruncatedText>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {isEditButtonVisible && (
        <Grid background="gray100">
          <Button
            icon="edit"
            size="small"
            variant="tertiary"
            onClick={handleEditClick}
            style={customStyle}
          >
            {t('reimbursementView.middleSection.form.extractedData.edit')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

import { Button, Flex, Grid, Heading, Link } from '@candisio/design-system';
import { useSSO } from 'orgConfig/sso/sso';
import { useTranslation } from 'react-i18next';
import { useGetRolesForUser } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { RolesField } from './RolesField';

interface RolesSectionProps {
  readOnly?: boolean;
}

export const RolesSection = ({ readOnly }: RolesSectionProps) => {
  const [t] = useTranslation();
  const sso = useSSO();

  const { roles, loading: rolesLoading } = useGetRolesForUser();
  const readOnlyReason = sso.provides.roles
    ? t('settings.teamMembers.roles.readOnlySSO')
    : undefined;

  if (rolesLoading) {
    return null;
  }

  return (
    <Grid alignContent="space-between" height="100%">
      <Flex direction="column" gap="space8">
        <Grid autoFlow="column" justifyContent="space-between">
          <Flex columnGap="space16" alignItems="baseline" wrap="wrap">
            <Heading as="h3">
              {t('settings.teamMembers.form.rolesForm.heading')}
            </Heading>
            <Link
              external
              href={t('settings.teamMembers.form.rolesForm.linkUrl')}
            >
              {t('settings.teamMembers.form.rolesForm.linkText')}
            </Link>
          </Flex>
          <Button
            id="roles-feedback-button"
            icon="megaphone"
            variant="tertiary"
            color="blue"
          >
            {t('common.feedbackButton')}
          </Button>
        </Grid>

        <RolesField
          roles={roles}
          name="roles"
          isReadOnly={sso.provides.roles || readOnly}
          readOnlyReason={readOnlyReason}
        />
      </Flex>
    </Grid>
  );
};
